import { getJSONHeaders } from "../../AppSettings";
import { RequestHandler } from "../../Models/CalendarDay";

class FileUtils {
    public downloadFile  = async (url : string, tryAutoOpen : boolean = true) => { 
        if (url != null && url.length > 0) 
        {            
            RequestHandler.requestSend();
            fetch(url, {
                method: 'GET',
                headers: await getJSONHeaders(),
            })
            .then(async response => { 
                return {
                    blob : await response?.blob(),
                    fileName : this.tryGetFileName(response)
                };
            }).then((ab) => {    
                var link = document.createElement("a");
                var blobUrl = URL.createObjectURL(ab.blob); 
                if(tryAutoOpen)
                {
                    link.href = blobUrl; 
                    link.target = '_blank'; // Questo imposterà il target su una nuova scheda 
                } else {  
                    link.setAttribute("href", blobUrl);
                    link.setAttribute("download", ab.fileName);
                }
                link.click();
            }).finally(() => {
                RequestHandler.responseReceived("");
            }) 
        }
    }

    tryGetFileName = (response : Response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = "";
        if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches && matches[1]) {
                fileName = matches[1].replace(/['"]/g, ''); 
                console.log('File name:', fileName);
            }
        }
        return fileName
    }
}

export const fileUtils = new FileUtils();