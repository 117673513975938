import { useState } from "react";
import BaseModel from "../../../../../Models/BaseModel";
import "./DistinctFilterHeader.css"
import ChildrenHelper from "../../../Utils/ChildrenHelper";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterHeaderProps, GridHeaderProps } from "../HeadersProps";

type DistinctFilterHeaderProps<M extends BaseModel> = {
	itemList?: M[];
	header?: GridHeaderProps<M>
} & FilterHeaderProps<M>;

export default function DistinctFilterHeader<M extends BaseModel>(props: DistinctFilterHeaderProps<M>) {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState("ALL");
	let list: M[keyof M][] = [];
	if (props.itemList && props.header) {
		list = ChildrenHelper.getDistinct(props.itemList, props.header.key!);
		list.push("ALL" as M[keyof M]);
	}

	return(
		<div className="dropdown">
			<div
			className={`drop-button ${selected === "ALL" ? "" : "selected-row"}`}
			onClick={(e) => {setOpen(!open);}}
			>
				<div className="button-title">
					<div>
						{`${props.header?.key?.toString()[0].toUpperCase()}${props.header?.key?.toString().slice(1)}`}
					</div>
					{ selected }
				</div>
				<FontAwesomeIcon className="button-chevron" icon={faChevronDown} rotation={open ? 180 : undefined}/>
			</div>
			<div className="dropdown-list" style={{maxHeight: open ? "200px" : "0px", minWidth: props.header?.columnWidth}}>
				{
					list.length === 0 ?
					<div className="row-cell">Nothing to show.</div>
					:
					list.map(x => 
						<div key={`${x}-option`} className="row-cell"
						onClick={() => {
							setSelected(x as string); 
							setOpen(false); 
							if (props.header?.key && props.header.callback) {
								props.header.callback((x as string) === "ALL" ? "" : x as string, props.header.key);
							}
						}}> { x as string } </div>
					)
				}
			</div>
		</div>
	);
};