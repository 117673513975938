import { Calendar, Icon } from "@fluentui/react";
import { useState, useCallback, useEffect } from "react";
import ClickOutsideHook from "../../Utils/Hooks/ClickOutsideHook";

export function MonthFilterHeader(props: {onSelect?: (date: Date) => void}) {
	const [open, setOpen] = useState(false)

	const { ref, visible, setIsVisible } = ClickOutsideHook(open);

	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const onSelectDate = useCallback((date: Date, dateRangeArray?: Date[]): void => {
		setSelectedDate(date);
		if (props.onSelect) {
			props.onSelect(date);
		}
		setOpen(false);
	}, [props]);

	useEffect(()=> {setIsVisible(open)}, [open, setIsVisible]);
	useEffect(()=> {setOpen(visible)}, [visible]);

	return (
		<>
			<div className="drop-button selected-row" onClick={() => setOpen(!open)}>
				<div className="button-title">
					<div>
						{selectedDate?.toLocaleString("en-US", { year: "numeric"})}
					</div>
					{selectedDate?.toLocaleString("en-US", { month: "long"})}
				</div>
				<Icon iconName="calendar"/>
				<Icon className={visible ? "fa fa-rotate-180" : undefined} iconName="chevronDown"/>
			</div>
			<Calendar
			ref={ref}
			className={`calendar-drop${visible ? " open" : ""}`}
			styles={{
				root: {
					background: "#FFFFFF",
					color: "black",
					position: "absolute",
					top: 65,
					right: 0,
					overflow: "visible",
					borderRadius: "2px",
					boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
				}
			}}
			showGoToToday
			navigationIcons={{leftNavigation: "chevronDown", rightNavigation: "chevronUp"}}
			highlightSelectedMonth
			isDayPickerVisible={false}
			onSelectDate={onSelectDate}
			value={selectedDate}
			/>
		</>
	);
};