// import React, { Component, ChangeEvent } from "react";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { AnimationClassNames, CommandBarButton, ICommandBarItemProps, Icon, Persona, PersonaSize, SearchBox, Stack, TooltipHost } from "@fluentui/react";
import { IActivity } from '../../Models/CalendarDay';
import { DefaultPadding, HeaderSize, ToolbarSize } from "../Utils/ComponentSizes";

interface SearchableProjectsProps {
    projects: IActivity[];
    onProjectSelect: Function;
}

enum ProjectStatus {
    Closed = 1,
    Open,
}

export default function SearchableActivities(props: SearchableProjectsProps) {
    const [keyword, setKeyword] = React.useState("");
    const [selectedProject, setSelectedProject] = React.useState<IActivity>();
    const [selectedType, setSelectedType] = React.useState<string>();
    const [selectedStatus, setSelectedStatus] = React.useState<ProjectStatus>();

    useEffect(() => {
        props.onProjectSelect(selectedProject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    const useFilteredList = useMemo(() => {
        let filteredList = props.projects;

        if (selectedType) {
            filteredList = filteredList.filter((activity) => activity.type?.toLowerCase() === selectedType.toLowerCase());
        }

        if (selectedStatus !== undefined) {
            filteredList = filteredList.filter((activity) => {
                if (selectedStatus === ProjectStatus.Closed) {
                    return new Date() > new Date(activity.endDate ?? 0);
                } else if (selectedStatus === ProjectStatus.Open) {
                    return new Date() < new Date(activity.endDate ?? 0);
                }
                return true;
            });
        }

        filteredList = filteredList.filter((activity) => {
            const keywords: string[] = keyword.split(" ");
            let result: boolean = true;
            keywords.forEach((keyword) => {
                if (`${activity.description}${activity.projectManager}`.toLowerCase().replace(/\s/g, "").indexOf(keyword.toLowerCase()) === -1) {
                    result = false;
                }
            })
            return result;
        });

        return filteredList;
    }, [keyword, props.projects, selectedStatus, selectedType]);

    useEffect(() => {
        if (!useFilteredList.some(activity => activity.id === selectedProject?.id)) {
            setSelectedProject(undefined);
        }
    }, [useFilteredList, selectedProject?.id])

    const distinctTypes: string[] = useMemo(() => {
        return [...new Set(props.projects.map(item => item.type?.replace("*", " ").toUpperCase() ?? ""))];
    }, [props.projects]);

    return (
        <Stack className="rounded"
            tokens={{ childrenGap: 8 }}
            styles={{
                root: {
                    maxHeight: `calc(100vh - ${HeaderSize} - ${DefaultPadding} - ${DefaultPadding})`,
                    userSelect: "none",
                }
            }}
        >
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <CommandBarButton
                    className="btn btn-dark rounded"
                    styles={{ root: { height: ToolbarSize, width: "50%", textAlign: "start" } }}
                    text={selectedType ? `${selectedType[0].toUpperCase()}${selectedType?.slice(1)}` : "Project Type"}
                    iconProps={{ iconName: "Filter" }}
                    onRenderMenuIcon={() => <Icon iconName="ChevronDown" />}
                    menuProps={{
                        onItemClick(ev, item) {
                            setSelectedType(item?.key === "clear-type-filter" ? undefined : item?.text);
                        },
                        useTargetWidth: true,
                        items: [
                            ...distinctTypes.map((accountType) => {
                                if (accountType)
                                    return {
                                        key: accountType,
                                        text: `${accountType ? accountType[0].toUpperCase() : ""}${accountType?.slice(1)}`,
                                    }
                                return undefined;
                            }).filter(item => item) as ICommandBarItemProps[],
                            { key: "clear-type-filter", text: "Remove Filter", iconProps: { iconName: "ClearFilter" } }
                        ]
                    }}
                />
                <CommandBarButton
                    className="btn btn-dark"
                    styles={{ root: { height: ToolbarSize, width: "50%", textAlign: "start" } }}
                    text={selectedStatus !== undefined ? ProjectStatus[selectedStatus] : "Project Status"}
                    iconProps={{ iconName: "Filter" }}
                    onRenderMenuIcon={() => <Icon iconName="ChevronDown" />}
                    menuProps={{
                        onItemClick(ev, item) {
                            setSelectedStatus(item?.key === "clear-status-filter" ? undefined : item?.data);
                        },
                        useTargetWidth: true,
                        items: [...Object.keys(ProjectStatus).filter(key => isNaN(Number(key)) && key !== "All").map((status) => {
                            if (status !== "All") return {
                                key: `ActivityStatus-${status}`,
                                text: status,
                                data: ProjectStatus[status as keyof typeof ProjectStatus],
                            }
                            return { key: "" };
                        }), { key: "clear-status-filter", text: "Remove Filter", iconProps: { iconName: "ClearFilter" } } as ICommandBarItemProps]
                    }}
                />
            </Stack>

            <Stack className="rounded" style={{
                boxShadow: "0 0 2px #99999999", overflowY: "hidden",
                height: `calc(100vh - ${HeaderSize} - ${DefaultPadding} - ${DefaultPadding} - ${ToolbarSize})`,
            }}>
                <SearchBox
                    value={keyword}
                    onChange={(event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
                        setKeyword(newValue ?? "");
                    }}
                    styles={{ root: { minHeight: ToolbarSize } }}
                    placeholder="Search"
                    underlined
                />
                {
                    useFilteredList.length ? (
                        <div className="list-group rounded-0 overflow-y-scroll py-0 my-0">
                            {useFilteredList.sort((a, b) => a.isFavourite && !b.isFavourite ? -1 : 1).map((activity) => {
                                return (
                                    <div
                                        className={`list-group-item rounded-0 list-group-item-action border-start-0 border-end-0 w-100 m-0 py-0 ps-0 ${selectedProject?.id === activity.id ? "list-group-item-secondary sticky-top sticky-bottom" : ""} ${AnimationClassNames.slideUpIn20}`}
                                        key={activity.id}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            className="hstack w-100 list-group-item-action py-0"
                                            onClick={() => setSelectedProject(activity)}
                                        >
                                            <Persona
                                                className="p-0 w-100"
                                                text={activity.description}
                                                secondaryText={activity.projectManager}
                                                optionalText={`${activity.projectArea?.description ?? ""} (${activity.type ? activity.type.toUpperCase() : ""})`}
                                                tertiaryText={`${new Date(activity.startDate ?? 0).toLocaleString(undefined, { day: "2-digit", year: "numeric", month: "numeric" }) ?? ""} - ${new Date(activity.endDate ?? 0).toLocaleString(undefined, { day: "2-digit", year: "numeric", month: "numeric" }) ?? ""}`}
                                                styles={{
                                                    root: {
                                                        selectors: { ".ms-Persona-coin": { visibility: "hidden", width: "0px", height: "0px", display: "none" } }
                                                    },
                                                    primaryText: { fontSize: "15px" },
                                                    secondaryText: { fontSize: "14px" },
                                                    tertiaryText: { fontSize: "13px" },
                                                    optionalText: { fontSize: "12px" }
                                                }}
                                                onClick={() => setSelectedProject(activity)}
                                                size={PersonaSize.size100}
                                            />
                                            <Stack>
                                                <Icon iconName={activity.isFavourite ? "FavoriteStar" : ""} />
                                                {new Date(activity.endDate ?? 0) < new Date() &&
                                                    <TooltipHost id={activity.id} content={"Closed"} className='ms-auto'>
                                                        <Icon aria-describedby={activity.id} iconName="Lock" className='ms-auto' styles={{ root: { color: "orange" } }} />
                                                    </TooltipHost>
                                                }
                                            </Stack>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>)
                        : (
                            <div
                                style={{
                                    minHeight: `calc(100vh - ${HeaderSize} - 24px - ${ToolbarSize} - 8px - ${ToolbarSize})`,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingBottom: ToolbarSize
                                }}
                            >
                                No projects found
                            </div>
                        )}
            </Stack>
        </Stack >
    );
}
