import BaseService from "./BaseService";
import { Bearer, webApiActivityUsers } from "../AppSettings";
import { ActivityUserModel } from "../Models/ActivityUserModel";
import { LoadingHandling } from "../Models/CalendarDay";

export default class ActivityUserService extends BaseService<ActivityUserModel> {
    private postWithFormHeaders: Headers = new Headers();
    constructor() {
        super(`${webApiActivityUsers}`);
        this.postWithFormHeaders.append("Authorization", Bearer);
        this.postWithFormHeaders.append("Content-Type", "application/x-www-url-formencoded");
        // this.postWithFormHeaders.append("Accept", "application/json");
    };

    public getAllActivityUsers(activityId: string, callback?: React.Dispatch<React.SetStateAction<ActivityUserModel[]>>) {
        LoadingHandling.showLoader()
        this.getAll(callback, `${this.endpoint}/${activityId}`)
    }

    /** 
        @example 
        Form Data response type:
            var data = new FormData();
            data.append( "json", JSON.stringify( {userIds: userIds} ) );
        JSON Body response type:
         JSON.stringify( userIds )
    */
    public assignAll(activityid: string, userIds: string[], callback?: () => void) {
        LoadingHandling.showLoader()
        this.postAll(undefined, `${this.endpoint}/${activityid}`, JSON.stringify(userIds), undefined, callback);
    }

    public unassign(activityId: string, userId: string, callback?: () => void) {
        LoadingHandling.showLoader()
        this.delete(undefined, `${this.endpoint}/${activityId}/${userId}`, undefined, undefined, callback);
    }
}