import { Component } from 'react';
import MonthSelector from '../Utils/MonthSelector';
import RequestsPageTable from './RequestPageTable';
import RequestPageOffCanvas from './RequestPageOffCanvas';
import DropDownSelector from '../Utils/DropDownSelector';
import { Account, cancelAbsenceRequestAsync, getJSONHeaders, postAbsenceRequestArray, webApiAbsenceRequest } from '../../AppSettings';
import { editMode, IRequest, RequestVisualizationType, LoadingHandling, RequestHandler, ErrorHandling } from '../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import addDays from 'date-fns/esm/fp/addDays/index.js';
import { format } from 'date-fns';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
}

interface IState {
    requests: IRequest[];
    isVisibleNewRequest: boolean;
    editMode: editMode;
    visualRequest: IRequest;
    selectedDate: Date;
    visType: RequestVisualizationType;
    filteredList: IRequest[];
}

export const checkEqualDates = (day1: Date, day2: Date) => {
    if (day1.getFullYear() === day2.getFullYear()
        && day1.getMonth() === day2.getMonth()
        && day1.getUTCDate() === day2.getUTCDate()) { return true; }

    return false;
}

const isWorkingDay = (day: Date) => {
    // WEEKEND
    if (day.getDay() === 6 || day.getDay() === 0)
        return false;

    // TODO: check if this day is an holiday

    return true;
}

// Count the hours in a day:
// 1 - count from the specified hour to the end
// 2 - count from begin of the day unil the specified hours
const countHours = (day: Date, type: number) => {
    let count: number = 0;

    switch (type) {
        case 1:
            if (day.getHours() <= 13) {
                count += (13 - day.getHours()) + 4;
            }
            else {
                count += 18 - day.getHours();
            }
            break;
        case 2:
            if (day.getHours() >= 14) {
                count += (day.getHours() - 14) + 4;
            }
            else {
                count += day.getHours() - 9;
            }
            break;
    }
    return count;
}

// USEFULL if the db tell us the start date and end date, otherwise is useless
export const diffHours = (startDate: Date, endDate: Date) => {

    // Check that endDate is after startDate, if not return 0
    if (startDate.getTime() >= endDate.getTime()) {
        return 0;
    }

    // Count the hours of permissions in the first day
    let count: number = countHours(startDate, 1);

    // count the days between the two dates
    let day: Date = startDate;
    let daysCount: number = -1;

    while (!checkEqualDates(day, endDate)) {
        day = addDays(1, day)
        daysCount++;
        if (!isWorkingDay(day)) {
            daysCount--;
        }
    }

    // If start and end the same day
    if (daysCount === -1) {
        let lastCount: number = countHours(endDate, 1);
        return count - lastCount;
    }

    count += (8 * daysCount);

    // hours in the last day
    let lastCount: number = countHours(endDate, 2);
    return count + lastCount;
}

export default class RequestsPage extends Component<IProps, IState> {

    selectorOptions: string[] = ["All", "Pending", "Approved", "Rejected", "Canceled"];

    // Constructor
    constructor(props: IProps) {
        super(props);
        this.onAddNewRequest = this.onAddNewRequest.bind(this);
        this.onAddNewRow = this.onAddNewRow.bind(this);
        this.onCloseNewRequest = this.onCloseNewRequest.bind(this);
        this.onShowRequest = this.onShowRequest.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onChangeSelector = this.onChangeSelector.bind(this);

        // Initiliza the state, specified in the interface and initialized here, in the constructor
        this.state = {
            requests: [],
            isVisibleNewRequest: false,
            editMode: editMode.None,
            visualRequest: {} as IRequest,
            selectedDate: new Date(),
            visType: RequestVisualizationType.All,
            filteredList: []
        };
        // Here the value in the state is public, in the orther place I have to use this.setState({name: value})
    }

    componentDidMount() {
        this.loadRequests();
    }

    async loadRequests(startDate?: Date, endDate?: Date) {
        LoadingHandling.showLoader();

        var date = new Date();

        let query: string = "";
        if (startDate && endDate) {
            query = webApiAbsenceRequest + "?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        }
        else if (startDate) {
            date = startDate;
            startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            query = webApiAbsenceRequest + "?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        }
        else {
            startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            query = webApiAbsenceRequest + "?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        }

        fetch(query, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resReq => resReq.json())
            .then((resultReq) => {

                this.setState({
                    requests: this.convertDate(resultReq),
                })
                LoadingHandling.hideLoader();

            })
            .catch(error => RequestHandler.badRequestReceived(error, "Something went wrong"))
    }

    convertDate(req: IRequest[]) {
        let convertedRequest: IRequest[] = req.map((requestEntry: IRequest) => {
            return {
                id: requestEntry.id,
                account: requestEntry.account,
                requestDate: new Date(requestEntry.requestDate),
                absence: requestEntry.absence,
                startDate: new Date(requestEntry.startDate),
                endDate: new Date(requestEntry.endDate),
                status: requestEntry.status,
                note: requestEntry.note,
                schedulerNote: requestEntry.schedulerNote,
                schedulerResponseDate: requestEntry.schedulerResponseDate,
                schedulerAccount: requestEntry.schedulerAccount
            }
        })

        return convertedRequest;
    }



    onAddNewRequest() {
        this.setState({
            visualRequest: {
                id: "0001",
                account: Account,
                requestDate: new Date(),
                startDate: new Date(new Date().setHours(9, 0, 0, 0)),
                endDate: new Date(new Date().setHours(18, 0, 0, 0)),
                note: "",
                status: "Pending",
                absence: { id: "", description: "", type: "", activityId: "" },
                schedulerNote: ""
            },
            isVisibleNewRequest: true,
            editMode: editMode.Add,
        })
        document.body.style.overflowY = "hidden";
    }

    onShowRequest(req: IRequest) {
        this.setState({
            visualRequest: req,
            isVisibleNewRequest: true,
            editMode: this.state.visType === RequestVisualizationType.Canceled ? editMode.None : editMode.Review,
        })
        document.body.style.overflowY = "hidden";
    }

    onCloseNewRequest() {
        this.setState({ isVisibleNewRequest: false });
        document.body.style.overflowY = "auto";
    }

    async onAddNewRow(request: IRequest) {

        LoadingHandling.showLoader();

        if (request) {
            let requestArr: IRequest[] = [];
            requestArr.push(request);

            // POST to the server
            let response = await postAbsenceRequestArray(requestArr).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong with the request"));
            if (response) {
                RequestHandler.responseReceived("Request sent");
                this.loadRequests(this.state.selectedDate)
                return;
            }
        }

        LoadingHandling.hideLoader();
    }

    onCanceledRequest = async (request: IRequest[]) => {
        RequestHandler.requestSend();

        let requestArray = request.map(req => req.id);

        let result = await cancelAbsenceRequestAsync(requestArray).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));

        if (result) {
            this.updateAbsenceRequests(this.convertDate(result));
            RequestHandler.responseReceived("Absence request Canceled");
        }
        this.onCloseNewRequest();
    }

    updateAbsenceRequests(absenceRequests: IRequest[]) {

        let stateAbsenceRequests = [...this.state.requests];

        absenceRequests.forEach(abs => {
            let index = stateAbsenceRequests.findIndex(req => abs.id === req.id);
            if (index !== -1) {
                stateAbsenceRequests.splice(index, 1, abs);
            }
        })

        this.setState({ requests: stateAbsenceRequests });
    }


    onDateChange(start: Date, end: Date) {
        this.setState({ selectedDate: start, visType: RequestVisualizationType.All })
        this.loadRequests(start, end);
    }

    onChangeSelector(value: number) {
        let visType = value as RequestVisualizationType;
        this.setState({ visType: visType, filteredList: this.state.requests.filter(x => x.status === RequestVisualizationType[visType]) })
    }

    render() {

        return (<div style={{ margin: "50px 0px 0px 0px" }}>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4 d-flex">
                        <h3 style={{ fontWeight: "bold" }}>My Requests</h3>
                    </div>
                    <div className="col-8 d-flex" style={{ justifyContent: "flex-end" }}>
                        <div>
                            <DropDownSelector
                                data={RequestVisualizationType}
                                selectedData={this.state.visType as number}
                                onSelect={this.onChangeSelector}
                            />
                        </div>
                        <div>
                            <MonthSelector
                                onDateSelected={this.onDateChange}
                                date={new Date(this.state.selectedDate)}
                            />
                        </div>
                        <button 
                            className="btn btn-dark btn-select-day d-flex justify-content-between"
                            onClick={async () => { 
                                LoadingHandling.showLoader()
                                fetch(webApiAbsenceRequest + "/" + this.state.selectedDate.getFullYear() + "/" + (this.state.selectedDate.getMonth() + 1) + "/export", {
                                    method: "GET",
                                    headers: await getJSONHeaders(),
                                })
                                    .then(response => {
                                        ErrorHandling.handleError(response);
                                        return response?.blob();
                                    })
                                    .then(blob => URL.createObjectURL(blob))
                                    .then(url => {
                                        RequestHandler.responseReceived("");
                        
                                        var link = document.createElement("a");
                                        link.setAttribute("href", url);
                                        link.setAttribute("download", this.state.selectedDate.toDateString().replaceAll(" ", "_"));
                                        LoadingHandling.hideLoader()
                                        link.click();
                                    })
                                    .catch(error => {
                                        RequestHandler.badRequestReceived(error, "Download went wrong")
                                        LoadingHandling.hideLoader()
                                    });
                            }}
                        >
                            Download requests
                        </button>
                    </div>

                </div>
                <br />
                <hr />
                <br />
                {this.state.requests.length > 0 ? (
                    <div>
                        <RequestsPageTable
                            requestData={this.state.visType === RequestVisualizationType.All ? this.state.requests : this.state.filteredList}
                            showRequest={this.onShowRequest}
                        />
                    </div>
                ) : (<div><p style={{ fontWeight: "bold" }}>No requests included</p></div>)}
                <br />

                <button type="button" className="btn btn-outline-dark" onClick={this.onAddNewRequest}>
                    <FontAwesomeIcon icon={faPlus} /> Add request
                </button>

                <RequestPageOffCanvas
                    requestPageOffCanvasIsVisible={this.state.isVisibleNewRequest}
                    requestPageOffCanvasTitle="New Request"
                    onClose={this.onCloseNewRequest}
                    onAddRequest={this.onAddNewRow}
                    onCanceledRequest={this.onCanceledRequest}
                    editMode={this.state.editMode}
                    visualRequest={this.state.visualRequest}
                />
            </div >
            <br />
            <br />
        </div>);
    }

}
