import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getISOWeek, getISOWeekYear, addDays, startOfISOWeek } from 'date-fns';
import DatePicker from "react-datepicker";

interface IProps {
    date: Date;
    onDateChange: Function;
}

interface IState {
    date: Date;
    week: string;
    isOpen: boolean;
}

enum Click {
    Left = -1,
    Right = 1
}

export default class WeekSelector extends Component<IProps, IState> {
    weekSelectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.weekSelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            date: this.props.date,
            week: "Wk: " + getISOWeek(this.props.date) + " Yr: " + getISOWeekYear(this.props.date),
            isOpen: false
        };
    }

    changeData(date: Date) {
        this.setState({
            date: date,
            week: "Wk: " + getISOWeek(date) + " Yr: " + getISOWeekYear(date),
            isOpen: false
        })
    }

    handleClick(clickType: Click) {
        let newDate = addDays(new Date(this.state.date), (clickType as number) * 7);

        this.changeData(newDate);
        this.props.onDateChange(newDate);
    }

    handleChangeData(date: Date) {
        let newDate = startOfISOWeek(date);

        if (this.state.date.getTime() !== newDate.getTime()) {
            this.changeData(newDate);

            this.props.onDateChange(newDate);
        } else {
            this.setState({isOpen:false})
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.weekSelectorRef && !this.weekSelectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.date !== this.props.date) {
            this.changeData(this.props.date)
        }

        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }
    }

    render() {
        return (
            <div
                className="mx-2"
                ref={this.weekSelectorRef}
            >
                <div
                    className="btn-group justify-content-between"
                    role="group"
                    style={{ width: "275px" }}
                >
                    <button
                        className="btn btn-dark btn-select-day"
                        onClick={() => this.handleClick(Click.Left)}                        
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                        className="btn btn-dark btn-select-day"
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    >
                        {this.state.week}
                    </button>
                    <button
                        className="btn btn-dark btn-select-day"
                        onClick={() => this.handleClick(Click.Right)}
                    >
                        {<FontAwesomeIcon icon={faChevronRight} />}
                    </button>
                </div>

                {this.state.isOpen && (
                    <DatePicker
                        selected={this.state.date}
                        onChange={(date: Date) => this.handleChangeData(date)}
                        calendarStartDay={1}
                        locale="en-GB"
                        showWeekNumbers
                        inline
                    />
                )}
            </div>
        )
    }
}