import { ITextProps } from "@fluentui/react";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import React from "react";
import { toast } from "react-toastify";

class MyMenu implements IMenu {
	Timesheet: IMenuItem = { key: "Timesheet", authorization: "timesheet", description: "Timesheet" };
	Expenses: IMenuItem = { key: "Expenses", authorization: "expensesheet", description: "Expenses" };
	Scheduler: IMenuItem = { key: "Scheduler", authorization: "scheduler", description: "Scheduler" };
	ProjectAssignment: IMenuItem = { key: "ProjectAssignment", authorization: "project-assignment", description: "Project Assignment" };
	ProjectManagement: IMenuItem = { key: "ProjectManagement", authorization: "project-management", description: "Project Management" };
	JobOrderCard: IMenuItem = { key: "JobOrderCard", authorization: "jobordercard", description: "Job Order Card" };
	DownloadReport: IMenuItem = { key: "DownloadReport", authorization: "download-report", description: "Download Report" };
	Permits: IMenuItem = { key: "Permits", authorization: "permit-request", description: "Permits" };
	PermitsManagement: IMenuItem = { key: "PermitsManagement", authorization: "permits-management", description: "Permits Management" };
	Sales: IMenuItem = { key: "Sales", authorization: "invoice-management", description: "Sales" };
	DownloadRequest: IMenuItem = { key: "DownloadRequest", authorization: "download-request", description: "Download Request" };
	GoodsManagement: IMenuItem = { key: "GoodsManagement", authorization: "goods-management", description: "Goods Management" };

	GetKeys = () => [
		this.Timesheet.key,
		this.Expenses.key,
		this.Scheduler.key,
		this.ProjectAssignment.key,
		this.ProjectManagement.key,
		this.JobOrderCard.key,
		this.DownloadReport.key,
		this.Permits.key,
		this.PermitsManagement.key,
		// this.Sales.key,
		// this.GoodsManagement.key,
		this.DownloadRequest.key,
	];

	FindItem = (key: string): IMenuItem => {
		switch (key) {
			case this.Timesheet.key:
				return this.Timesheet;
			case this.Expenses.key:
				return this.Expenses;
			case this.Scheduler.key:
				return this.Scheduler;
			case this.ProjectAssignment.key:
				return this.ProjectAssignment;
			case this.ProjectManagement.key:
				return this.ProjectManagement;
			case this.JobOrderCard.key:
				return this.JobOrderCard;
			case this.DownloadReport.key:
				return this.DownloadReport;
			case this.Permits.key:
				return this.Permits;
			case this.PermitsManagement.key:
				return this.PermitsManagement;
			case this.Sales.key:
				return this.Sales;
			case this.DownloadRequest.key:
				return this.DownloadRequest;
			case this.GoodsManagement.key:
				return this.GoodsManagement;
		}

		return this.Timesheet;
	};

	FindByAuthorizationName = (auth: string): IMenuItem => {
		switch (auth) {
			case this.Timesheet.authorization:
				return this.Timesheet;
			case this.Expenses.authorization:
				return this.Expenses;
			case this.Scheduler.authorization:
				return this.Scheduler;
			case this.ProjectManagement.authorization:
				return this.ProjectManagement;
			case this.JobOrderCard.authorization:
				return this.JobOrderCard;
			case this.DownloadReport.authorization:
				return this.DownloadReport;
			case this.Permits.authorization:
				return this.Permits;
			case this.PermitsManagement.authorization:
				return this.PermitsManagement;
			case this.Sales.authorization:
				return this.Sales;
			case this.DownloadRequest.authorization:
				return this.DownloadRequest;
			case this.GoodsManagement.authorization:
				return this.GoodsManagement;
		}

		return this.Timesheet;
	};
}

export let Menu = new MyMenu();

/* INTERFACES */
export interface IMenu {
	Timesheet: IMenuItem;
	Expenses: IMenuItem;
	Scheduler: IMenuItem;
	ProjectAssignment: IMenuItem;
	ProjectManagement: IMenuItem;
	JobOrderCard: IMenuItem;
	DownloadReport: IMenuItem;
	Permits: IMenuItem;
	PermitsManagement: IMenuItem;
	Sales: IMenuItem;
	DownloadRequest: IMenuItem;
	GoodsManagement: IMenuItem;

	GetKeys: () => string[];
	FindItem: (key: string) => IMenuItem;
}

export interface IMenuItem {
	key: string;
	authorization: string;
	description: string;
}

export interface IJobOrderCardTable {
	id: string;
	isTask?: boolean;
	// profileCost: string;
	rowType: string;
	description: string;
	unitPrice: number;
	YTDAmount: number;
	YTGAmount: number;
	YTDUnits: number;
	YTGUnits: number;
	datesData: IJobOrderCardTableDates[];
}

export interface IJobOrderCardTableDates {
	month: number;
	year: number;
	value: number;
	key: string;
	// profileCost: string;
	isPlan: boolean;
}

export interface ITopRowDates {
	month: string;
	year: number;
}

export interface IStatusData {
	description: string;
	revenues: number;
	costs: number;
	margin: number;
	marginPerc: number;
	averageCost: number;
	averageRevenue: number;
	costsExp: number;
	unpaidInvoices: number;
	actualCostsExp: number;
}

export interface IInvoice {
	id: string;
	position: string;
	description: string;
	amount: number;
	rateCost: number;
	quantity: number;
	status: string;
	year: number;
	month: number;
}

export interface IResult {
	month: number;
	year: number;
	revenueType: string;
	value: number;
}

export interface IFinalBalance {
	id: string;
	year: number;
	month: number;
	hours: number;
	rateCost: number;
	profileCost: string;
}

export interface IGood {
	id: string;
	odA: string;
	odAPosition: string;
	material: string;
	quantity: number;
	rateCost: number;
	year: number;
	month: number;
	hours: number;
	profileCost: string;
}

export interface IPlan {
	id: string;
	year: number;
	month: number;
	hours: number;
	rateCost: number;
	profileCost: string;
}

export interface IResourceEntry {
	finalBalances: IFinalBalance[];
	goods: IGood[];
	plans: IPlan[];
	id: string;
	company: string;
	companyId?: string;
	lastName: string;
	firstName: string;
	email: string;
	type: string;
	area: string;
	cost: number;
	profileCost: string;
	rateCost: number;
	rateEarn: number;
	rateIC: number;
	isTask?: boolean;
	isFullTimeAllocation: boolean;
	startDate?: Date;
	endDate?: Date;
	authorizations: number[];
}

export interface IAPIResponse {
	invoices: IInvoice[];
	results: IResult[];
	resourceEntries: IResourceEntry[];
	activeAccounts: IAccount[];
}

export interface IJobOrderCardPlan {
	accountId: string;
	activityId: string;
	schedules: IJobOrderCardPlanSchedule[];
}

export interface IJobOrderCardPlanSchedule {
	rateCost: number;
	accountId: string;
	key: string;
	year: number;
	month: number;
	days: number;
}

export interface IUser {
	id: string;
	firstName: string;
	lastName: string;
}

export interface ISales {
	invoiceId: string;
	activity: IActivity;
	materialDescription: string;
	materialCode: string;
	invoiceRowNumber: string;
	invoiceRowDate?: Date;
	quantity: number;
	unitPrice: number;
	isToInvoice: boolean;
	value: number;
	invoiceStatus: string;
	noteFromCustomer: string;
	noteToCustomer: string;

	[key: string]: any;
}

export interface IItem extends IActivity {
	id: string;
	description: string;
	type?: string;
	isFavourite?: boolean;
	color?: string | undefined;
	code?: string;
	onRender?: (item: IItem, defaultRender?: (props?: ITextProps) => React.ReactElement) => React.ReactElement;
}

export interface IPostActivityEntry {
	id?: string;
	accountId: string;
	activityId: string;
	locationId?: string;
	absenceId?: string;
	year: number;
	month: number;
	day: number;
	hours: number;
	note: string;
	hasAttachment: boolean;
}

export interface IPostExpenseEntry {
	id?: string;
	accountId: string;
	expenseId: string;
	activityId: string;
	year: number;
	month: number;
	day: number;
	amount: number;
	receiptNumber: string | null;
	distance?: number;
	amountPerDistanceUnit?: number;
	departure?: string;
	arrival?: string;
	note: string;
}

export interface IUpdateActivityAccountTemplate {
	isFavourite?: boolean;
	color?: string;
}

export interface IUpdateExpenseAccountTemplate {
	isFavourite?: boolean;
	color?: string;
}

export interface IPostJobOrderCardPlan {
	accountId: string;
	activityId: string;
	schedules: IPostJobOrderCardPlanSchedule[];
}

export interface IPostJobOrderCardPlanSchedule {
	accountId: string;
	year: number;
	month: number;
	days: number;
}

export interface IPostAbsenceRequest {
	absenceId: string;
	startDate: Date;
	endDate: Date;
	note: string;
}

export interface IUpdateSale {
	invoiceId: string;
	invoiceRowNumber: string;
	quantity: number;
	value: number;
	noteFromCustomer: string;
	noteToCustomer: string;
}

export interface IEmitSale {
	invoiceId: string;
	invoiceRowNumber: string;
	quantity: number;
	unitPrice: number;
	value: number;
}

export interface IUpdateGoodsReceipt {
	idSupplier: string;
	quantity: number;
	description?: string;
	goodsReceiptDate: Date;
}

export interface IPostQueueDownloadRequest {
	from: string;
	to: string | null;
	type: string;
	referenceId?: string;
}

export interface ICalendar {
	startDate: Date;
	endDate: Date;
	account: IAccount;
	firstDayOfMonthDayOfWeek: number;
	daysInMonth: number;
	calendarDays: IDictionaryCalendarDays;
	getCalendarDay(key: string): ICalendarDay;
	addActivity(activity: IActivityEntry): void;
	addActivities(activities: IActivityEntry[]): void;
	addExpense(expense: IExpenseEntry): void;
	addExpenses(expenses: IExpenseEntry[]): void;
	getTotalExpenses(): number;
	removeActivity(activity: IActivityEntry): void;
	removeExpense(expense: IExpenseEntry): void;
	moveActivity(activity: IActivityEntry, dateTo: Date): void;
	moveExpense(expense: IExpenseEntry, dateTo: Date): void;
	selectDays(dateFrom: Date, dateTo: Date, isWeekendVisible: boolean): void;
	removeAllSelectedDays(): void;
	toggleDaySelected(date: Date): void;
	getSelectedDays(): Date[];
	changeActivityColor(activityId: string, color: string): void;
	changeExpenseColor(expenseId: string, color: string): void;
	setDayIsEditable(monthStatus: IMonthsStatus): void;
	isConfirmable: boolean;
	setIsConfirmable(): void;
}

interface IDictionaryCalendarDays {
	[key: string]: ICalendarDay;
}

export interface ICalendarDay {
	dayActivities: IActivityEntry[];
	dayExpenses: IExpenseEntry[];
	day: Date;
	isDaySelected: boolean;
	isEditable: boolean;
	selectedActivity: IActivity | null;
	getActivitiesForExpenses(): IActivityForExpense[];
	workedHours: number;
	progressbarValue: number;
	isDayCompleted: boolean;
	addActivity(activity: IActivityEntry): void;
	removeActivity(activityId: string): void;
	addExpense(expense: IExpenseEntry): void;
	removeExpense(expenseId: string): void;
	refreshValues(): void;
	selectDay(value: boolean): void;
	toggleDaySelected(): void;
	selectActivityForExpenses(activity: IActivity): void;
}

export interface IActivityEntry {
	id: string;
	account: IAccount;
	activity: IActivity;
	location: ILocation | null;
	absence: IAbsence | null;
	day: Date;
	hours: number;
	note: string;

	[key: string]: any;
}

export interface IExpenseEntry {
	id: string;
	account: IAccount;
	activity: IActivity;
	expense: IExpense;
	day: Date;
	amount: number;
	receiptNumber: string | null;
	distance: number;
	amountPerDistanceUnit?: number;
	departure: string;
	arrival: string;
	note: string;

	[key: string]: any;
}

export interface IAbsence {
	id: string;
	activityId?: string;
	type: string;
	description: string;
}

export interface IExpense {
	id: string;
	description: string;
	company?: string;
	type?: string;
	amountPerDistanceUnit?: number;
	isFavourite?: boolean;
	color?: string;
}

export interface IAccount {
	id: string;
	company: string;
	companyId?: string;
	firstName: string;
	lastName: string;
	email: string;
	type?: string;
	isTask?: boolean;
	Role?: string;
	profileCost?: string;
	startDate?: Date;
	endDate?: Date;
	//area?: string;
	userArea?: {
		companyId: string;
		companyName: string;
		description: string;
		id: string;
	};
	isFullTimeAllocation?: boolean;
	authorizations?: string[];
	userCompanies: IUserCompany[];
	site: string;
	rateCost?: number;
	rateIC?: number;
}

export interface IUserCompany {
	companyId: string;
	companyName: string;
	isSelected: boolean;
}

export interface IAccountMonthStatus {
	accountId: string;
	company: string;
	lastName: string;
	firstName: string;
	email: string;
	accountType: string;
	year: number;
	month: number;
	isConfirmed: boolean;
	isApproved: boolean;
	isLocked: boolean;
	isError: boolean;
}
export interface IActivity {
	id: string;
	description: string;
	isAbsent?: boolean;
	isFavourite?: boolean;
	color?: string;
	company?: string;
	personnelArea?: string;
	projectArea?: { description: string };
	projectManager?: string;
	startDate?: Date;
	endDate?: Date;
	type?: string;
	code?: string;
}

export interface IActivityForExpense extends IActivity {
	isSelected: boolean;
}

export interface ILocation {
	id: string;
	name: string;
	description: string;
}

export interface IHour {
	id: string;
	hour: number;
}

export interface IEntriesMonthStatus {
	accountId: string;
	month: number;
	year: number;
	isConfirmed: boolean;
	isApproved: boolean;
	isLocked: boolean;
	hasAttachment: boolean;
	errorMessage: string;
	isError: boolean;
	account?: IAccount;
}
export interface IMonthsStatus {
	previousMonth: IEntriesMonthStatus;
	currentMonth: IEntriesMonthStatus;
	nextMonth: IEntriesMonthStatus;
}

export interface IRequest {
	id: string;
	account: IAccount;
	requestDate: Date;
	absence: IAbsence;
	startDate: Date;
	endDate: Date;
	status: string;
	note: string;
	schedulerNote?: string;
	schedulerResponseDate?: Date;
	schedulerAccount?: IAccount;

	[key: string]: any;
}

export interface IGoodsReceipt {
	activity: IActivity;
	idSupplier: string;
	supplierName: string;
	idConsultant: IAccount;
	consultantName: string;
	oda: string;
	odaRow: string;
	quantity: number;
	unitPrice: number;
	value: number;
	description: string;
	[key: string]: any;
}

export interface IFactoryCalendarDate {
	id: string;
	date: string;
	description: string;
	isWeekEnd: boolean;
	isHoliday: boolean;
	site: string;
}

export interface IDownloadSelector {
	key: string;
	value: string;
}

/* ENUM */

export enum RequestStatus {
	Pending = "#ff9b00",
	Approved = "#7bc22f",
	Rejected = "#ff5555",
	Canceled = "#000000",
}

export enum AccountType {
	unknown = "-",
	internal = "Internal",
	external = "External",
	Trainee = "Stager",
	temporary = "Temporary",
	// dismissed = "Dismissed"
}

export enum RequestVisualizationType {
	All = 1,
	Pending = 2,
	Approved = 3,
	Rejected = 4,
	Canceled = 5,
}

export enum SalesStatus {
	Open = "#a6b7cb",
	Invoiced = "#259bff",
	Payed = "#7ac22e",
	Overdue = "#ff5555",
}

export enum SalesVisualizationType {
	All = 1,
	Open = 2,
	Invoiced = 3,
	Payed = 4,
	Overdue = 5,
}

export enum UserType {
	All = 1,
	External = 2,
	Internal = 3,
	Temporary = 4,
	Stager = 5,
	Dismissed = 6,
}

export enum MonthStatus {
	"All" = 1,
	"Not confirmed",
	"Confirmed",
	"Approved",
	"Locked",
	"Error",
}

export enum VisualizationType {
	Month = 1,
	Week = 2,
}

export enum editMode {
	None = 0,
	Add = 1,
	Edit,
	Review,
}

export enum SchedulerType {
	Scheduler = 0,
	ProjectManager = 1,
}

export enum selectionType {
	None = 0,
	CopyActivityEntry = 1,
	AddActivityEntry,
	CopyExpenseEntry,
	AddExpenseEntry,
	SelectMultipleActivityEntry,
	SelectMultipleExpenseEntry,
}

export enum JobOrderCardTableEnum {
	Description = 0,
	UnitPrice,
	YTDAmount,
	YTGAmount,
	YTDUnits,
	YTGUnits,
	datesData,
}

//list of strings used to do checks
export var studyPermit: string = "PY";
export var paternity: string = "PBP";
export var breastfeeding: string = "L";
export var dailyAllowance: string = "daily-allowance";

export var paidLeave: string = "PAIDLEAVE";
export var vacation: string = "VACATION";

class Calendar {
	startDate!: Date;
	endDate!: Date;
	isConfirmable!: boolean;
	account!: IAccount;
	firstDayOfMonthDayOfWeek!: number;
	daysInMonth!: number;
	calendarDays!: IDictionaryCalendarDays;

	getCalendarDay(key: string): CalendarDay {
		let result = this.calendarDays[key];
		if (result === undefined) {
			let tempDate = this.startDate;
			tempDate.setDate(parseInt(key));

			if (this.startDate <= tempDate && tempDate <= this.endDate) {
				result = new CalendarDay(tempDate);
			} else {
				tempDate = this.endDate;
				tempDate.setDate(parseInt(key));

				if (this.startDate <= tempDate && tempDate <= this.endDate) {
					result = new CalendarDay(tempDate);
				}
			}
		}
		return result;
	}

	addActivity(activity: IActivityEntry, calculateIsConfirmable: boolean = true): void {
		this.getCalendarDay(new Date(activity.day).getDate().toString().padStart(2, "0")).addActivity(activity);
		if (calculateIsConfirmable) {
			this.setIsConfirmable();
		}
	}

	addActivities(activities: IActivityEntry[]): void {
		activities.forEach((act) => this.addActivity(act, false));
		this.setIsConfirmable();
	}

	addExpenses(expenses: IExpenseEntry[]): void {
		expenses.forEach((exp) => this.addExpense(exp));
	}

	addExpense(expense: IExpenseEntry): void {
		this.getCalendarDay(new Date(expense.day).getDate().toString().padStart(2, "0")).addExpense(expense);
	}

	removeActivity(activity: IActivityEntry, calculateIsConfirmable: boolean = true): void {
		this.getCalendarDay(new Date(activity.day).getDate().toString().padStart(2, "0")).removeActivity(activity.id);
		if (calculateIsConfirmable) {
			this.setIsConfirmable();
		}
	}

	removeExpense(expense: IExpenseEntry): void {
		this.getCalendarDay(new Date(expense.day).getDate().toString().padStart(2, "0")).removeExpense(expense.id);
	}

	moveActivity(activity: IActivityEntry, dateTo: Date): void {
		let newActivity: IActivityEntry = {
			id: activity.id,
			account: activity.account,
			activity: activity.activity,
			location: activity.location,
			absence: activity.absence,
			day: new Date(dateTo),
			hours: activity.hours,
			note: activity.note,
			hasAttachment: activity.hasAttachment,
		};

		this.removeActivity(activity, false);
		this.addActivity(newActivity, false);
		this.setIsConfirmable();
	}

	selectDays(dateFrom: Date, dateTo: Date, isWeekendVisible: boolean = true): void {
		dateFrom = new Date(dateFrom);
		dateTo = new Date(dateTo);

		if (dateFrom > dateTo) {
			let tempDate: Date;
			tempDate = dateFrom;
			dateFrom = dateTo;
			dateTo = tempDate;
		}

		for (let currentDate: Date = new Date(dateFrom); currentDate <= dateTo; currentDate.setDate(currentDate.getDate() + 1)) {
			if (isWeekendVisible || (currentDate.getDay() !== 0 && currentDate.getDay() !== 6)) {
				this.getCalendarDay(currentDate.getDate().toString().padStart(2, "0")).selectDay(true);
			}
		}
	}

	removeAllSelectedDays(): void {
		for (let currentDate: Date = new Date(this.startDate); currentDate <= this.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			this.getCalendarDay(index).selectDay(false);
			this.getCalendarDay(index).selectedActivity = null;
		}
	}

	getTotalExpenses(): number {
		let total: number = 0;
		for (let currentDate: Date = new Date(this.startDate); currentDate <= this.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			this.calendarDays[index].dayExpenses.forEach((exp) => {
				total += exp.amount;
			});
		}
		return total;
	}

	getSelectedDays() {
		let days: Date[] = [];
		for (let currentDate: Date = new Date(this.startDate); currentDate <= this.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			if (this.calendarDays[index].isDaySelected || this.getCalendarDay(index).selectedActivity !== null)
				days.push(this.calendarDays[index].day);
		}
		return days;
	}

	toggleDaySelected(date: Date): void {
		this.getCalendarDay(new Date(date).getDate().toString().padStart(2, "0")).toggleDaySelected();
	}

	changeActivityColor(activityId: string, color: string): void {
		for (let currentDate: Date = new Date(this.startDate); currentDate <= this.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			this.getCalendarDay(index)
				.dayActivities.filter((act) => act.activity.id === activityId)
				.map((act) => (act.activity.color = color));
			this.getCalendarDay(index)
				.dayExpenses.filter((exp) => exp.activity.id === activityId)
				.map((exp) => (exp.activity.color = color));
		}
	}

	changeExpenseColor(expenseId: string, color: string): void {
		for (let currentDate: Date = new Date(this.startDate); currentDate <= this.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			this.getCalendarDay(index)
				.dayExpenses.filter((exp) => exp.expense.id === expenseId)
				.map((exp) => (exp.expense.color = color));
		}
	}

	moveExpense(expense: IExpenseEntry, dateTo: Date): void {
		let newExpense: IExpenseEntry = {
			id: expense.id,
			account: expense.account,
			activity: expense.activity,
			day: new Date(dateTo),
			amount: expense.amount,
			receiptNumber: expense.receiptNumber,
			distance: expense.distance,
			amountPerDistanceUnit: expense.distance,
			departure: expense.departure,
			arrival: expense.arrival,
			note: expense.note,
			expense: expense.expense,
		};
		this.removeExpense(expense);
		this.addExpense(newExpense);
	}

	protected getDaysInMonth(year: number, month: number) {
		return new Date(year, month, 0).getDate();
	}

	protected getFirstDayOfWeekInMonth(year: number, month: number) {
		return (new Date(year, month - 1, 1).getDay() + 6) % 7;
	}

	setDayIsEditable(monthsStatus: IMonthsStatus): void {
		for (const [key, value] of Object.entries(monthsStatus)) {
			let monthStatus = value as IEntriesMonthStatus;
			let monthStartDate = new Date(new Date(monthStatus.year, monthStatus.month - 1, 1).setHours(0, 0, 0, 0));
			let monthEndDate = new Date(new Date(monthStatus.year, monthStatus.month, 0).setHours(23, 59, 59, 999));
			let isEditable: boolean = !(monthStatus.isConfirmed || monthStatus.isApproved || monthStatus.isLocked);

			if (this.startDate.getTime() > monthEndDate.getTime() || this.endDate.getTime() < monthStartDate.getTime()) {
				continue;
			}

			if (this.startDate.getTime() > monthStartDate.getTime()) {
				monthStartDate = new Date(this.startDate);
			}

			if (this.endDate.getTime() < monthEndDate.getTime()) {
				monthEndDate = new Date(this.endDate);
			}

			for (
				let currentDate: Date = new Date(monthStartDate);
				currentDate <= new Date(monthEndDate);
				currentDate.setDate(currentDate.getDate() + 1)
			) {
				let index = currentDate.getDate().toString().padStart(2, "0");
				this.getCalendarDay(index).isEditable = isEditable;
			}
		}
	}

	setIsConfirmable() {
		let startEmploymentDate: Date | undefined = this.account.startDate;
		let endEmploymentDate: Date | undefined = this.account.endDate;

		let result = false;
		let startDate: Date, endDate: Date;

		if (
			(startEmploymentDate !== undefined && startEmploymentDate !== null && new Date(startEmploymentDate).getTime() > this.endDate.getTime()) ||
			(endEmploymentDate !== undefined && endEmploymentDate !== null && new Date(endEmploymentDate).getTime() < this.startDate.getTime())
		) {
			this.isConfirmable = false;
			return;
		}

		if (startEmploymentDate === undefined || startEmploymentDate === null || new Date(startEmploymentDate).getTime() < this.startDate.getTime()) {
			startDate = new Date(this.startDate);
		} else {
			startDate = new Date(startEmploymentDate);
		}

		if (endEmploymentDate === undefined || endEmploymentDate === null || new Date(endEmploymentDate).getTime() > this.endDate.getTime()) {
			endDate = new Date(this.endDate);
		} else {
			endDate = new Date(endEmploymentDate);
		}

		for (let currentDate: Date = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
			let index = currentDate.getDate().toString().padStart(2, "0");
			result = this.getCalendarDay(index).isDayCompleted;
			if (!result) {
				break;
			}
		}

		this.isConfirmable = result;
		return;
	}
}

export class Month extends Calendar implements ICalendar {
	constructor(account: IAccount, year: number, month: number) {
		super();

		this.startDate = new Date(year, month - 1, 1);
		this.endDate = new Date(year, month, 0);
		this.firstDayOfMonthDayOfWeek = this.getFirstDayOfWeekInMonth(year, month);

		this.daysInMonth = this.getDaysInMonth(year, month);
		this.account = account;
		this.calendarDays = {};

		for (
			let currentDate: Date = new Date(this.startDate);
			currentDate <= this.endDate;
			currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
		) {
			let year = currentDate.getFullYear();
			let month = currentDate.getMonth();
			let day = currentDate.getDate();
			let key = day.toString().padStart(2, "0");

			this.calendarDays[key] = new CalendarDay(new Date(year, month, day));
		}
	}
}

export class Week extends Calendar implements ICalendar {
	constructor(account: IAccount, year: number, week: number) {
		super();
		let date: Date = new Date(year, 0, 4 + (week - 1) * 7);
		this.startDate = startOfISOWeek(date);
		this.endDate = endOfISOWeek(date);
		this.firstDayOfMonthDayOfWeek = 0;
		this.daysInMonth = 7;
		this.account = account;
		this.calendarDays = {};

		for (
			let currentDate: Date = new Date(this.startDate);
			currentDate <= this.endDate;
			currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
		) {
			let year = currentDate.getFullYear();
			let month = currentDate.getMonth();
			let day = currentDate.getDate();
			let key = day.toString().padStart(2, "0");

			this.calendarDays[key] = new CalendarDay(new Date(year, month, day));
		}
	}
}

export class CalendarDay implements ICalendarDay {
	dayActivities!: IActivityEntry[];
	dayExpenses!: IExpenseEntry[];
	day!: Date;
	isDaySelected!: boolean;
	workedHours!: number;
	progressbarValue!: number;
	isDayCompleted!: boolean;
	selectedActivity!: IActivity | null;
	isEditable = true;

	constructor(date: Date) {
		if (date === null) {
			return;
		}
		this.day = date;
		this.dayActivities = [];
		this.dayExpenses = [];
		this.workedHours = 0;
		this.progressbarValue = 0;
		this.isDayCompleted = false || new Date(date).getDay() === 0 || new Date(date).getDay() === 6;
		this.isDaySelected = false;
		this.selectedActivity = null;
	}

	getActivitiesForExpenses(): IActivityForExpense[] {
		function uniqueArray(ar: IActivity[]) {
			//var j = {};
			var j: any = {};

			ar.forEach(function (v: IActivity) {
				j[v.id + "::" + typeof v.id] = v;
			});

			return Object.keys(j).map(function (v: string) {
				return j[v];
			});
		}

		let result: IActivityForExpense[] = uniqueArray(
			this.dayActivities.map((act) => {
				return act.activity;
			})
		).map((act) => {
			act.isSelected = this.selectedActivity === null ? false : this.selectedActivity.id === act.id;
			return act;
		});

		return result;
	}

	addActivity(activity: IActivityEntry): void {
		if (this.dayActivities.findIndex((act) => act.id === activity.id) >= 0) {
			this.removeActivity(activity.id);
		}

		this.dayActivities.push(activity);
		this.refreshValues();
	}

	addExpense(expense: IExpenseEntry): void {
		if (this.dayExpenses.findIndex((act) => act.id === expense.id) >= 0) {
			this.removeExpense(expense.id);
		}

		this.dayExpenses.push(expense);
	}

	refreshValues(): void {
		let workedHours: number = 0;
		this.dayActivities.forEach((act) => (workedHours += act.hours));
		this.workedHours = workedHours;
		this.progressbarValue = (workedHours * 100) / 8.0;
		this.isDayCompleted = workedHours >= 8 || new Date(this.day).getDay() === 0 || new Date(this.day).getDay() === 6;
	}

	removeActivity(activityId: string): void {
		if (this.dayActivities) {
			let index: number = this.dayActivities.findIndex((act) => act.id === activityId);
			if (index >= 0) {
				this.dayActivities.splice(index, 1);
				this.refreshValues();
			}
		}
	}

	removeExpense(expenseId: string): void {
		if (this.dayExpenses) {
			let index: number = this.dayExpenses.findIndex((act) => act.id === expenseId);
			if (index >= 0) {
				this.dayExpenses.splice(index, 1);
			}
		}
	}

	selectDay(value: boolean): void {
		this.isDaySelected = value;
		if (this.isDaySelected) {
			this.selectedActivity = null;
		}
	}

	selectActivityForExpenses(activity: IActivity): void {
		this.isDaySelected = false;
		if (this.selectedActivity === activity) {
			this.selectedActivity = null;
		} else {
			this.selectedActivity = activity;
		}
	}

	toggleDaySelected(): void {
		this.isDaySelected = !this.isDaySelected;
		if (this.isDaySelected) {
			this.selectedActivity = null;
		}
	}
}

export class RequestHandler {
	static requestSend() {
		LoadingHandling.showLoader();
	}

	static responseReceived(msg: string, timer: number = 2000) {
		if (msg !== "") ErrorHandling.successToast(msg, timer);
		LoadingHandling.hideLoader();
	}

	static badRequestReceived(error: string | undefined, msg: string, timer: number | undefined = undefined) {
		ErrorHandling.manageError(error, msg, timer);
		LoadingHandling.hideLoader();
	}
}

export class LoadingHandling {
	static showLoader = async () => {
		let loader = document.querySelector(".loader-container");
		await loader?.classList?.remove("hidden");
	};

	static hideLoader = async () => {
		let loader = document.querySelector(".loader-container");
		await loader?.classList?.add("hidden");
	};
}

export class ErrorHandling {
	static handleError(response: Response) {
		if (!response.ok) {
			if (response.statusText === "") throw new Error(response.url);
			else throw new Error(response.statusText);
		}
		return response;
	}

	static manageError(error: string = "", msg: string, timer: number | undefined) {
		console.log(error);
		this.errorToast(msg, timer);
	}

	static errorToast(msg: string, timer: number | undefined = undefined) {
		toast.error(msg, {
			position: "top-right",
			autoClose: timer ?? false,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: "colored",
		});
	}

	static successToast(msg: string, timer: number = 2000) {
		toast.success(msg, {
			position: "top-right",
			autoClose: timer,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: "colored",
		});
	}

	static warningToast(msg: string, timer: number = 5000, toastId?: string) {
		toast.warn(msg, {
			position: "top-right",
			autoClose: timer,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: "dark",
			toastId: toastId ? toastId : undefined,
		});
	}
}
