import React, { Component } from "react";
import { IDownloadSelector } from "../../Models/CalendarDay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    title: string;
    elements: IDownloadSelector[];
    onSelect: Function;
}
interface IState {
    isOpen: boolean;
}

export default class DownloadTypeSelector extends Component<IProps, IState> {
    downloadSelectorRef: React.RefObject<HTMLDivElement>;

    constructor(prop: IProps) {
        super(prop);

        this.downloadSelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onSelectElement = this.onSelectElement.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    handleClickOutside(event: MouseEvent): void {
        if (
            this.downloadSelectorRef &&
            !this.downloadSelectorRef?.current?.contains(event.target as Node)
        ) {
            this.setState({ isOpen: false });
        }
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener("mousedown", this.handleClickOutside);
            } else {
                document.removeEventListener("mousedown", this.handleClickOutside);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    onSelectElement(elem: IDownloadSelector) {
        this.props.onSelect(elem);
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div
                style={{
                    height: "50px",
                    maxHeight: "50px",
                    width: "150px",
                    maxWidth: "150px",
                }}
                ref={this.downloadSelectorRef}
            >
                <button
                    style={{ width: "100%", height: "100%" }}
                    className="btn btn-dark btn-select-day d-flex justify-content-around align-items-center"
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                >
                    <FontAwesomeIcon icon={faArrowDown} /> {this.props.title}
                </button>
                {this.state.isOpen && (
                    <div style={{ width: "100%", zIndex: 1000, position: "sticky", backgroundColor: "#f5f6f8", border: "1px solid black", borderRadius: "5px" }}>
                        {this.props.elements.map((elem, index) => (
                            <button
                                key={index}
                                className="btn btn-dark-outline"
                                onClick={() => this.onSelectElement(elem)}
                                style={{ width: "100%" }}
                            >
                                {elem.value}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
