import React from "react";

export interface IShowDayWeekAndDateProps {
    day: Date;
}

export const ShowDayWeekAndDate = (props: IShowDayWeekAndDateProps) => {
 
    const getWeekDay = (day: Date) => {
        let weekday = new Array(7);
        weekday[0] = "Sun";
        weekday[1] = "Mon";
        weekday[2] = "Tue";
        weekday[3] = "Wed";
        weekday[4] = "Thu";
        weekday[5] = "Fri";
        weekday[6] = "Sat";
        return weekday[(new Date(day)).getDay()];
    };

    return (
        <div className="text-center m-0">
            <div>
                <p className="text-size m-0 day-week">{getWeekDay(props.day)}</p>
            </div>
            <div>
                <p className="number-size m-0 day-week">{(new Date(props.day)).getDate().toString().padStart(2, "0")}</p>
            </div>
        </div>
    );
};
