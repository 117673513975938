import { Icon } from "@fluentui/react";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseModel from "../../../Models/BaseModel";
import ChildrenHelper from "../Utils/ChildrenHelper";

type GridRowProps<M extends BaseModel> = {
	headers: any[];
	model: M;
	selected: (model: M) => boolean;
	onSelection?: (model: M) => void;
	onDelete?: (element: M) => void;
};

export default function GridRow<M extends BaseModel>(props: GridRowProps<M>) {
	const model = props.model;

	return (
		<div
			className={`grid-row ${props.selected(model) ? "selected-row" : ""}`}
			onClick={() => {
				if (props.onSelection) {
					props.onSelection(model);
				}
			}}
		>
			{
				// eslint-disable-next-line
				props.headers?.map((header) => {
					return Object.entries(props.model).map(([key, value]) => {
						if (key === header.key) {
							if (header.columnCustomComponent) {
								return (
									<div
										key={`${model.id}-${key}-cell`}
										className={`row-cell`}
										style={{
											maxWidth: header.columnWidth,
											wordWrap: "break-word",
										}}
									>
										{ChildrenHelper.addPropsToChildren(
											header.columnCustomComponent,
											{ model }
										)}
									</div>
								);
							}
							return (
								<div
									key={`${model.id}-${key}-cell`}
									className={`row-cell`}
									style={{ maxWidth: header.columnWidth }}
								>
									{value}
								</div>
							);
						}
						return null;
					});
				})
			}
			{
				props.onDelete ? 
				<div
					key={`${model.id}-delete-cell`}
					className={`row-cell delete-button`}
					onClick={() => {if (props.onDelete) props.onDelete(model);}}
					style={{ maxWidth: 50}}
				>
					<FontAwesomeIcon icon={faMinus} />
				</div>
				:
				null
			}
		</div>
	);
}
