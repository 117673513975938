import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Components/Auth/authConfig";
import { App } from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './index.css';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons(/* optional base url */);

export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
);
