import * as React from 'react';
import { DefaultButton, IButtonProps, IButtonStyles } from '@fluentui/react/lib/Button';
import { Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';
import { ITeachingBubbleStyles, TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import { useBoolean, useId } from '@fluentui/react-hooks';

type TeachingBubbleButtonProps = {
	button: {
		label: string;
		styles?: IButtonStyles; 
	};
	bubble: {
		text: string;
		position: DirectionalHint;
		hasCloseButton: boolean;
		closeButtonLabel: string;
		headline: string;
		primaryButton?: IButtonProps;
		background?: string;
	};
};

export const TeachingBubbleButton: React.FunctionComponent<TeachingBubbleButtonProps> = (props: TeachingBubbleButtonProps) => {
	const buttonId = useId('targetButton');
	const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

// ----------------------------------------------------------------------------------//
	//	Button Example:
	//
	//		const exampleSecondaryButtonProps: IButtonProps = React.useMemo(
	//			() => ({
	//				children: 'Maybe later',
	//				onClick: toggleTeachingBubbleVisible,
	//			}), [toggleTeachingBubbleVisible],
	//		);
// ----------------------------------------------------------------------------------//
	//	Copy to clipboard example:
	//
	//		onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}
// ----------------------------------------------------------------------------------//
	const styles = mergeStyleSets({
		button: {
			width: 130,
		},
		callout: {
			width: 520,
			maxWidth: "90%",
			padding: '20px 24px',
			background: props.bubble.background,
			wordWrap: "break-word", wordBreak: "break-word" 
			
		},
		title: {
			marginBottom: 12,
			fontWeight: FontWeights.semilight,
			color: "var(--bs-white)"
		},
		link: {
			display: 'block',
			marginTop: 20,
		},
		actions: {
			marginTop: 20,
		},
	});
	
	return (
		<div>
			<DefaultButton
				id={buttonId}
				onClick={toggleTeachingBubbleVisible}
				text={props.button.label}
				styles={props.button.styles}
			/>
			{/* <Callout be/> */}
			{teachingBubbleVisible && (
				<Callout
					// coverTarget
					role="dialog"
					className={styles.callout}
					directionalHint= {props.bubble.position}
					styles={{
						calloutMain: { background: props.bubble.background, color: "var(--bs-white)"},
						
						beakCurtain: { background: props.bubble.background, color: "var(--bs-white)" },
						beak: { background: props.bubble.background , color: "var(--bs-white)"},
					}}
					// primaryButtonProps={props.bubble.primaryButton}
					target={`#${buttonId}`}
					// styles={styles}
					// isWide={true}
					// hasCloseButton={true}
					// closeButtonAriaLabel="Close"
					onDismiss={toggleTeachingBubbleVisible}
					// headline={props.bubble.headline}
				>

					<Text as="h1" block variant="xLarge" className={styles.title}>
						{props.bubble.headline}
					</Text>
					<Text block variant="small" style={{color: 'var(--bs-white)'}}>
						{props.bubble.text}
					</Text>
					<div className={styles.actions}>
						<DefaultButton {...props.bubble.primaryButton} />
					</div>
				</Callout>
			)}
		</div>
	);
};
