import React, { useState } from 'react';
import { ICalendarDay, IMenuItem, Menu } from './../../Models/CalendarDay';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlus as fasPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    data: ICalendarDay,
    onDragStartFunction: Function,
    onDragOverFunction: Function,
    onDropFunction: Function,
    children: React.ReactNode,
    isMultipleDaysSelectionVisible: boolean,
    onAddProjectClickFunction: Function,
    onAddExpenseClickFunction: Function,
    onToggleDaySelectedFunction: Function,
    selectedMenu: IMenuItem
    onDragEndFunction: Function;
}

export const CalendarDay = ({ data, onDragStartFunction, onDragOverFunction, onDropFunction, isMultipleDaysSelectionVisible, onAddProjectClickFunction, onAddExpenseClickFunction, onToggleDaySelectedFunction, children, selectedMenu, onDragEndFunction }: IProps) => {

    const [mouseEnter, setMouseEnter] = useState(false);

    const confrontDates = function (date1: Date, date2: Date): boolean {
        if (date1.getDate() === date2.getDate()
            && date1.getMonth() === date2.getMonth()
            && date1.getFullYear() === date2.getFullYear()) {
            return true;
        }
        return false;
    }
    let progressBarClass: string;
    progressBarClass = "progress-bar";
    if (data.progressbarValue < 100) {
        progressBarClass += " bg-danger"
    } else if (data.progressbarValue === 100) {
        progressBarClass += " bg-success"
    } else {
        progressBarClass += " bg-warning"
    }

    let progressBarWidth: string = data.progressbarValue.toString() + "%";
    let id = "day-" + (new Date(data.day)).getDate().toString().padStart(2, '0');

    return (
        <div
            className={"card calendar-card " + (data.isEditable ? "droppable" : "")}
            style={{ height: "100%" }}
            onDragOver={(event) => {
                if (data.isEditable) {
                    onDragOverFunction(event)
                }
            }
            }
            onDrop={(event) => {
                if (data.isEditable) {
                    onDropFunction(event, data.day)
                }
            }
            }
            onDragEnd={(event) => {
                if (data.isEditable) {
                    onDragEndFunction(event)
                }
            }
            }
        >
            <div className="card-body">
                <div
                    className="progress calendar-progessbar mt-4"
                    style={{ visibility: (selectedMenu === Menu.Timesheet ? "visible" : "hidden") }}
                >
                    <div className={progressBarClass} style={{ width: progressBarWidth }}>
                    </div>
                </div>

                <div className="calendar-day">
                    <div className={confrontDates(new Date(data.day), new Date()) ? "day today" : "day"}>
                        {(new Date(data.day)).getDate().toString().padStart(2, '0')}
                    </div>
                </div>

                <div className="calendar-activities">
                    {children}
                    {data.isEditable && selectedMenu === Menu.Timesheet && (
                        <button className="btn add-element-calendar rmvBorder"
                            onMouseEnter={e => setMouseEnter(true)}
                            onMouseLeave={e => setMouseEnter(false)}
                            onClick={() => onAddProjectClickFunction([new Date(data.day)])}
                        >
                            <div className={`add-project-drag-n-drop scrollUpDown ${mouseEnter ? "active-element" : "not-active-element"} draggable`}
                                draggable
                                id={id}
                                onDragStart={(event) => onDragStartFunction(event, (new Date(data.day)).toString(), "AddProject")}
                            >
                            </div>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Add Project
                        </button>)}

                    {data.isEditable && selectedMenu === Menu.Expenses && (
                        <button className="btn add-element-calendar rmvBorder"
                            onMouseEnter={e => setMouseEnter(true)}
                            onMouseLeave={e => setMouseEnter(false)}
                            onClick={() => onAddExpenseClickFunction([new Date(data.day)])}
                        >
                            <div className={`add-project-drag-n-drop scrollUpDown ${mouseEnter ? "active-element" : "not-active-element"} draggable`}
                                draggable
                                id={id}
                                onDragStart={(event) => onDragStartFunction(event, (new Date(data.day)).toString(), "AddExpense")}
                            >
                            </div>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Add Expense
                        </button>)}

                    {data.isEditable && isMultipleDaysSelectionVisible && (
                        <div className="divHiddenButton">
                            <button
                                className={`btn ${data.isDaySelected ? "btn-primary-custom" : "btn-outline-primary"} shadow-none ${isMultipleDaysSelectionVisible ? "active-element" : "not-active-element"}`}
                                onClick={() => onToggleDaySelectedFunction(data.day)}
                            >
                                <FontAwesomeIcon icon={fasPlus} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}

