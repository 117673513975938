import React, { ChangeEvent, useEffect, useMemo } from "react";
import { AccountType, IAccountMonthStatus, MonthStatus } from '../../Models/CalendarDay';
import { AnimationClassNames, CommandBarButton, ICommandBarItemProps, Icon, Persona, SearchBox, Stack } from "@fluentui/react";
import { DefaultPadding, HeaderSize, ToolbarSize } from "../Utils/ComponentSizes";

interface ISearchableAccountsProps {
    accounts: IAccountMonthStatus[];
    onAccountSelect: Function;
    selectStatusFilter?: MonthStatus
}

export function SearchableAccounts(props: ISearchableAccountsProps) {
    const [keyword, setKeyword] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState<IAccountMonthStatus>();
    const [selectedType, setSelectedType] = React.useState<string>();
    const [selectedStatus, setSelectedStatus] = React.useState<MonthStatus>();

    useEffect(() => {
        props.onAccountSelect(selectedUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser]);

    useEffect(() => {
        if (props.selectStatusFilter) {
            setSelectedStatus(props.selectStatusFilter)
        }
    }, [props.selectStatusFilter])

    const useFilteredList = useMemo(() => {
        let filteredList = props.accounts;
        if (selectedType) {
            filteredList = filteredList.filter((account) => account.accountType === selectedType.toLowerCase() || (selectedType === "Trainee" && account.accountType === "stager"));
        }
        if (selectedStatus) {
            filteredList = filteredList.filter((user) => {
                switch (selectedStatus) {
                    case MonthStatus['Not confirmed']:
                        return user.isConfirmed === false && user.isApproved === false && user.isLocked === false && user.isError === false;
                    case MonthStatus.Confirmed:
                        return user.isConfirmed === true && user.isApproved === false && user.isLocked === false && user.isError === false;
                    case MonthStatus.Approved:
                        return user.isConfirmed === true && user.isApproved === true && user.isLocked === false && user.isError === false;
                    case MonthStatus.Locked:
                        return user.isConfirmed === true && user.isApproved === true && user.isLocked === true && user.isError === false;
                    case MonthStatus.Error:
                        return user.isError === true;
                    default: return user;
                }
            });
        }

        const keywords: string[] = keyword.split(" ");
        filteredList = filteredList.filter((account) => {

            let result: boolean = true;
            keywords.forEach((keyword) => {
                if (`${account.firstName}${account.lastName}`.replace(" ", "").toLowerCase().indexOf(keyword.toLowerCase()) === -1 &&
                    `${account.email}`.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
                    result = false;
                }
            })
            return result;
        });

        return filteredList;
    }, [keyword, props.accounts, selectedStatus, selectedType]);

    useEffect(() => {
        if (!useFilteredList.some(user => user.accountId === selectedUser?.accountId)) {
            setSelectedUser(undefined);
        }
    }, [useFilteredList, selectedUser?.accountId])

    return (
        <Stack className="rounded"
            tokens={{ childrenGap: 8 }}
            styles={{
                root: {
                    maxHeight: `calc(100vh - ${HeaderSize} - ${DefaultPadding} - ${DefaultPadding})`,
                    userSelect: "none",
                }
            }}
        >
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <CommandBarButton
                    className="btn btn-dark rounded"
                    styles={{ root: { height: ToolbarSize, width: "50%", textAlign: "start" } }}
                    text={selectedType ? `${selectedType[0].toUpperCase()}${selectedType?.slice(1)}` : "User Type"}
                    iconProps={{ iconName: "Filter" }}
                    onRenderMenuIcon={() => <Icon iconName="ChevronDown" />}
                    menuProps={{
                        onItemClick(ev, item) {
                            setSelectedType(item?.key === "clear-type-filter" ? undefined : item?.text);
                        },
                        useTargetWidth: true,
                        items: [...Object.keys(AccountType).map((accountType) => ({
                            key: accountType,
                            text: `${accountType ? accountType[0].toUpperCase() : ""}${accountType?.slice(1)}`,
                        })), { key: "clear-type-filter", text: "Remove Filter", iconProps: { iconName: "ClearFilter" } }]
                    }}
                />
                <CommandBarButton
                    className="btn btn-dark"
                    styles={{ root: { height: ToolbarSize, width: "50%", textAlign: "start" } }}
                    text={selectedStatus ? MonthStatus[selectedStatus] : "Month Status"}
                    iconProps={{ iconName: "Filter" }}
                    onRenderMenuIcon={() => <Icon iconName="ChevronDown" />}
                    onRenderIcon={() => {
                        return <Icon iconName={selectedStatus !== undefined ? "CircleFill" : "Filter"}
                            className="p-2 ms-auto"
                            styles={{
                                root: {
                                    color: selectedStatus === undefined
                                        ? "#0078d4"
                                        : selectedStatus === MonthStatus.Error
                                            ? "#FF0000"
                                            : selectedStatus === MonthStatus.Locked
                                                ? "#008F39"
                                                : selectedStatus === MonthStatus.Approved
                                                    ? "#AAAAAA"
                                                    : selectedStatus === MonthStatus.Confirmed
                                                        ? "#3388FF"
                                                        : "#000000"
                                }
                            }}
                        />
                    }}
                    menuProps={{
                        onItemClick(ev, item) {
                            setSelectedStatus(item?.key === "clear-status-filter" ? undefined : item?.data);
                        },
                        useTargetWidth: true,
                        items: [...Object.keys(MonthStatus).filter(key => isNaN(Number(key)) && key !== "All").map((monthStatus) => {
                            if (monthStatus !== "All") return {
                                key: `MonthStatus-${monthStatus}`,
                                text: monthStatus,
                                onRenderIcon: () => {
                                    return <Icon iconName="CircleFill"
                                        className="p-2 ms-auto"
                                        styles={{
                                            root: {
                                                color: MonthStatus[monthStatus as keyof typeof MonthStatus] === MonthStatus.Error
                                                    ? "#FF0000"
                                                    : MonthStatus[monthStatus as keyof typeof MonthStatus] === MonthStatus.Locked
                                                        ? "#008F39"
                                                        : MonthStatus[monthStatus as keyof typeof MonthStatus] === MonthStatus.Approved
                                                            ? "#AAAAAA"
                                                            : MonthStatus[monthStatus as keyof typeof MonthStatus] === MonthStatus.Confirmed
                                                                ? "#3388FF" : "#000000"
                                            }
                                        }}
                                    />
                                },
                                data: MonthStatus[monthStatus as keyof typeof MonthStatus],
                            } as ICommandBarItemProps;
                            return { key: "" };
                        }), { key: "clear-status-filter", text: "Remove Filter", iconProps: { iconName: "ClearFilter" } } as ICommandBarItemProps]
                    }}
                />
            </Stack>
            <Stack
                className="rounded"
                style={{
                    boxShadow: "0 0 2px #99999999",
                    overflowY: "hidden",
                    height: `calc(100vh - ${HeaderSize} - ${DefaultPadding} - ${DefaultPadding} - ${ToolbarSize} - 8px)`
                }}
            >
                <SearchBox
                    value={keyword}
                    onChange={(event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
                        setKeyword(newValue ?? "");
                    }}
                    styles={{ root: { minHeight: ToolbarSize } }}
                    placeholder="Search"
                    underlined
                />
                {
                    useFilteredList.length ? (
                        <div className="list-group rounded-0 overflow-y-scroll">
                            {useFilteredList.map((account) => {
                                return (
                                    <div
                                        className={`list-group-item rounded-0 list-group-item-action w-100 mx-0 ${selectedUser?.accountId === account.accountId ? "list-group-item-dark sticky-top sticky-bottom" : ""} ${AnimationClassNames.slideUpIn20}`}
                                        key={account.accountId}
                                        style={{
                                            cursor: "pointer",
                                            color: selectedUser?.accountId === account.accountId ? "#ffffff" : "#000000",
                                        }}
                                    >
                                        <div
                                            className="hstack w-100 list-group-item-action"
                                            key={account.accountId}
                                            onClick={() => setSelectedUser(account)}
                                        >
                                            <Persona
                                                className="p-2"
                                                text={`${account.firstName} ${account.lastName}`}
                                                secondaryText={`${account.company} (${account.accountType === "stager" ? "Trainee" : account.accountType[0].toUpperCase() + account.accountType.slice(1)})`}
                                                onClick={() => setSelectedUser(account)}
                                            />
                                            <Icon iconName="CircleFill"
                                                className="p-2 ms-auto"
                                                styles={{
                                                    root: {
                                                        // padding: "5px",
                                                        color: account.isError
                                                            ? "#FF0000"
                                                            : account.isLocked && account.isConfirmed && account.isApproved
                                                                ? "#008F39"
                                                                : account.isApproved && account.isConfirmed
                                                                    ? "#AAAAAA"
                                                                    : account.isConfirmed ? "#3388FF" : "#000000"
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>)
                        : (
                            <div
                                style={{
                                    minHeight: `calc(100vh - ${HeaderSize} - 24px - ${ToolbarSize} - 8px - ${ToolbarSize})`,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingBottom: ToolbarSize
                                }}
                            >
                                No users found
                            </div>
                        )}
            </Stack>
        </Stack >
    );
}
