import { Image, Stack } from "@fluentui/react";
import animation from "./Utils/manteinance_animation.gif";

export default function MaintenancePage() {
	return (
		<Stack horizontalAlign="center" verticalAlign="start" styles={{
			root: {
				width: "100vw",
				height: "100vh"
			}
		}}>
			<Stack horizontal verticalAlign="center" className="header w-100"
				styles={{ root: { color: "white", fontSize: "40px", padding: "0 12px" } }}>
				AEGIS
			</Stack>
			<Image src={animation} />
			<Stack horizontal verticalAlign="center"
				styles={{ root: { fontSize: "40px"} }}>
				Under maintenance
			</Stack>
			<Stack horizontal verticalAlign="center"
				styles={{ root: { fontSize: "30px"} }}>
				Try again later
			</Stack>
		</Stack>
	);
}