import React, { Component } from "react";

interface IProps {
    offCanvasTitle: string;
    isVisible: boolean;
    onClose: Function;
    onSave: Function;
	children: any;
	canSave?: () => boolean;
}

interface IState {
    offCanvasTitle: string;
    isVisible: boolean;
}

export default class DownloadRequestItemOffCanvas extends Component<IProps, IState> {
    offCanvasRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.offCanvasRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            offCanvasTitle: this.props.offCanvasTitle,
            isVisible: this.props.isVisible,
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.offCanvasRef && !this.offCanvasRef?.current?.contains(event.target as Node)) {
            this.setState({ isVisible: false });
            this.props.onClose();
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.isVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.isVisible
            });
            if (nextProps.isVisible) {
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        }
    }

    onClose() {
        this.setState({ isVisible: false });
        this.props.onClose();
    }

    render() {
        return (
            <div style={{
                visibility: this.state.isVisible ? "visible" : "hidden",
            }}>
                <div className={"offcanvas offcanvas-end offcanvas-style " + (this.state.isVisible ? "show" : "")}
                    ref={this.offCanvasRef}
                    tabIndex={-1}
                    id="offcanvas"
                    aria-labelledby="offcanvasRightLabel"
                    style={{ visibility: this.state.isVisible ? "visible" : "hidden", zIndex: 1050 }}
                >
                    <div className="offcanvas-header">
                        <h2>{this.props.offCanvasTitle}</h2>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        ></button>
                    </div>

                    <div className="offcanvas-body">
                        {this.props.children}
                    </div>

                    <div className="offcanvas-footer d-flex">
                        <button className="btn btn-outline-dark" onClick={() => this.onClose()}>
                            Cancel
                        </button>
						<button className="btn btn-dark" onClick={() => this.props.onSave()} disabled={this.props.canSave ? !this.props.canSave() : false}>
                            Add
                        </button>
                    </div>
                </div>
                <div className={"modal-backdrop fade show"} style={{ zIndex: 1040, opacity: .3 }}></div>
            </div>
        );
    }
}
