import {
	AnimationClassNames,
	DetailsList,
	IDetailsHeaderProps,
	IGroup,
	IRenderFunction,
	Panel,
	PanelType,
	Persona,
	PersonaSize,
	ScrollablePane,
	SelectionMode,
	Stack,
	Sticky,
	StickyPositionType,
	Text,
} from "@fluentui/react";
import { useForceUpdate } from "@fluentui/react-hooks";
import { useEffect, useMemo, useState } from "react";
import EntriesMonthStatusController from "../../../Controllers/EntriesMonthStatusController";
import { IAccountMonthStatus, IEntriesMonthStatus } from "../../../Models/CalendarDay";
import { ToastContainer } from "react-toastify";
import { HeaderSize } from "../../Utils/ComponentSizes";

type ErrorsPanelProps = {
	isOpen: boolean;
	onDismiss: (ev?: KeyboardEvent | React.SyntheticEvent<HTMLElement, Event> | undefined) => void;
	list: IAccountMonthStatus[];
};

export default function ErrorsPanel(props: ErrorsPanelProps) {
	const [errorList, setErrorList] = useState<IAccountMonthStatus[]>([]);
	const entriesMonthStatusController = new EntriesMonthStatusController();
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		setErrorList(props.list);
		// if (props.isOpen) {
		// 	entriesMonthStatusController.getErrors().then((errors) => {
		// 		setErrorList(errors);
		// 	});
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.list]);

	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
		return <Sticky stickyPosition={StickyPositionType.Header}>{defaultRender!(props)}</Sticky>;
	};

	const sortItems = (a: IAccountMonthStatus, b: IAccountMonthStatus) => {
		let itemA = a.year * 100;
		itemA += a.month;
		let itemB = b.year * 100;
		itemB += b.month;
		return itemA - itemB;
	};

	const groups: IGroup[] = useMemo(() => {
		return [...new Set(errorList.sort(sortItems).map((item) => `${item.year}-${item.month}`))].map((error) => {
			const entry = errorList.find((item) => `${item.year}-${item.month}` === error);
			return {
				key: `${entry?.year}-${entry?.month}`,
				startIndex: errorList.findIndex((item) => `${item.year}-${item.month}` === error),
				count: errorList.sort(sortItems).filter((item) => item.year === entry?.year && item.month === entry?.month).length,
				name: `${new Date(entry?.year ?? 0, (entry?.month ?? 1) - 1).toLocaleDateString(undefined, { year: "numeric", month: "long" })}`,
				level: 0,
			} as IGroup;
		});
	}, [errorList]);

	return (
		<Panel
			isOpen={props.isOpen}
			headerText="Errors"
			type={PanelType.smallFluid}
			onDismiss={props.onDismiss}
			className={AnimationClassNames.slideLeftIn400}
			styles={{ root: { height: "calc(100vh - 138px)", top: 138 }, main: { width: "calc(100% - 20px)", margin: 10, borderRadius: 10 } }}
		>
			<Stack>
				{errorList.length ? (
					<ScrollablePane styles={{ root: { position: "relative", height: `calc(100vh - ${HeaderSize} - 50px - 24px - 20px)` } }}>
						<ToastContainer containerId={"error-list-toast-container"} enableMultiContainer />
						<DetailsList
							groups={groups}
							items={errorList.sort(sortItems)}
							selectionMode={SelectionMode.none}
							onRenderDetailsHeader={onRenderDetailsHeader}
							columns={[
								{
									key: "user-column",
									name: "User",
									fieldName: "user",
									minWidth: 100,
									maxWidth: 250,
									isMultiline: true,
									onRender(item, index, column) {
										return (
											<Persona
												// text={item.accountId}
												text={`${item.firstName} ${item.lastName}`}
												size={PersonaSize.size32}
											/>
										);
									},
								},
								{ key: "error-column", name: "Error", fieldName: "errorMessage", minWidth: 300, isMultiline: true },
							]}
						/>
					</ScrollablePane>
				) : (
					<Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { height: `calc(100vh - ${HeaderSize} - 50px - 24px)` } }}>
						<Text variant="xLarge">No errors found</Text>
					</Stack>
				)}
			</Stack>
		</Panel>
	);
}
