import { useEffect, useState } from "react";
import BaseService from "../../../Services/BaseService";
import BaseModel from "../../../Models/BaseModel";
import "./CustomGrid.css"
import GridRow from "./GridRow";
import ChildrenHelper from "../Utils/ChildrenHelper";
import { SelectionMode } from "../Utils/SelectionMode";
import { GridHeaderProps } from "./Headers/HeadersProps";

/**
 * Custom grid properties.
 * @example let gridProps: CustomGridProps<AccountModel, AccountService>
 * @template M - Model that inherit BaseModel
 * @template S - Service that inherit BaseService<M>
 * @optionalParam {service: CustomGridProps<M, S>}
 * @optionalParam {itemList: M[ ]}
 * @optionalParam {headers: GridHeaderProps<M>[ ]}
 * @optionalParam {itemList: M itemList Arra<M>}
 * 
 */

type CustomGridProps<M extends BaseModel, S extends BaseService<M>> = {
	service?: S;
	itemList?: M[];
	headers?: GridHeaderProps<M>[];
	maxWidth?: number | string;
	selectionMode?: SelectionMode;
	onSelection?: (current: M, selection?: M[]) => void;
	onSelectionConstrain?: (current: M) => boolean;
	onSaveConstrain?: (selection: M[]) => boolean;
	onSave?: (selection: M[]) => void;
	onDelete?: (element: M) => void; 
};

export type GridCustomColumnProps<M extends BaseModel> = {
	model?: M
};

/**
 *  Custom AEGIS CustomGrid component.
 * @export CustomGrid
 * @template M - Model
 * @template S - Service
 * @param {CustomGridProps<M, S>} props
 * @return {*}  {JSX.Element}
 */
export default function CustomGrid<M extends BaseModel, S extends BaseService<M>>(props: CustomGridProps<M, S>): JSX.Element {
	const [filterValues, setFilterValues] = useState<{key: keyof M, value: string | number}[]>([]);
	const [filteredList, setFilteredList] = useState<M[] | undefined>(props.itemList)
	
	// eslint-disable-next-line
	const [selection, setSelection] = useState<M[]>([]);

	/**
	 *	Function to handle filtering values.
	 *
	 * @param {M} model
	 * @return {boolean} 
	 */
	const filters = (model: M, filters: {key: keyof M, value: string | number}[] | undefined = undefined): boolean => { 
		/* function body */ 

			let valid = true;
			// eslint-disable-next-line
			(filters ?? filterValues).map((filter) => {
				let header = props.headers?.find(x => x.key === filter.key);
				if (header?.customFilter) {
					if (!header.customFilter(filter.value as string, model)) {
						valid = false;
					}
				} else if (!(model[filter.key] as string).toLowerCase().includes((filter.value as string).toLowerCase())) {
					valid = false;
				}
			});
			return(valid);
	};

	useEffect(()=> {
		setFilteredList(props.itemList?.filter((x) => filters(x, filterValues)));
		setSelection(s => s.filter((x) => props.itemList?.find(y => y.id === x.id)));
	// eslint-disable-next-line
	}, [props.itemList])


	/**
	 * Add selected filter to que.
	 *
	 * @param {(string | number)} value
	 * @param {keyof M} key
	 */
	function addFilterCallback(value: string | number, key: keyof M) {
		let tmpList: {key: keyof M, value: string | number}[] = [];
		filterValues.map(x => tmpList.push(x));
		if (tmpList.filter(x => x.key === key).length > 0) {
			tmpList.map(x => {
				if(x.key === key) {
					x.value = value;
				};
				return x;
			});
		} else {
			tmpList.push({key, value})
		}
		setFilterValues(tmpList);
		setFilteredList(props.itemList?.filter((x) => filters(x, tmpList)));
	};
	
	function onSelection(model: M) {
		let tempSelection: M[] = [];

		function additionalActions(sel: M[]) {
			if (props.selectionMode !== SelectionMode.None){
				if (props.onSelection) { 
					props.onSelection(model, sel);
				}
			}
		}

		switch (props.selectionMode) {
			case SelectionMode.None || undefined:
				break;
			case SelectionMode.Single: {
				tempSelection.push(model);
				setSelection(tempSelection);
				if (props.onSelection) { 
					props.onSelection(model, tempSelection);
				}
				break;
			}
			case SelectionMode.Multiple: {
				selection.map(x => tempSelection.push(x));
				if (selection.filter(x => x.id === model.id).length === 0) {
					tempSelection.push(model);
				} else {
					tempSelection = tempSelection.filter(x => x.id !== model.id);
				}
				setSelection(tempSelection);
				additionalActions(tempSelection);
			}
		}
	}

	function isSelected(value: M): boolean {
		return selection.find(x => x.id === value.id) === undefined ? false : true;
	}

	return (
		<div className="custom-grid" 
		style={{maxWidth: props.maxWidth}}
		>
			<div className="grid-row header-row">
				{
					props.headers?.map( (header, index) => {
						header.callback = addFilterCallback;
						const itemList = filteredList;
						return (
								
							header.component ?
							<div
							key={`header-${index}`}
							className="row-cell header-cell"
							style={
								{
									maxWidth: header.columnWidth,
									color: "#FFFFFF",
								}
							}
							>
								{ChildrenHelper.addPropsToChildren(header.component, {itemList, header})}
							</div>
							: header.defaultHeader === false ? null :
							
							<div 
							key={`header-${index}`}
							className="row-cell header-cell"  
							style={
								{
									padding: "12px",
									maxWidth: header.columnWidth,
									color: "#FFFFFF",
								}
							}
							>
								{ header.customLabel ?? `${header.key?.toString()[0].toUpperCase()}${header.key?.toString().slice(1)}`}
							</div>
						);
					})
				}
				{
					props.onDelete ? 
							<div 
							key={`header-delete`}
							className="row-cell header-cell"  
							style={
								{
									maxWidth: 50,
									padding: "12px",
									color: "#FFFFFF",
								}
							}
							>
								Remove
							</div>
					: null
				}
			</div>
			{
				(filteredList?.length ?? 0) > 0 ?

					filteredList!
						.map((value, index, array) => {
							return (<GridRow key={value.id} selected={isSelected} onSelection={onSelection} onDelete={props.onDelete} headers={props.headers!} model={value}/>);
						})
				:
					<div style={{display: "flex", height: "calc(100% - 65px)", top: 65, bottom: 0, left: 0, right: 0, justifyItems: "center",justifyContent: "center", alignContent: "center", alignItems: "center"}} >
							Nothing to show.
					</div>
			}
			{
				
				props.selectionMode === SelectionMode.Multiple && props.onSave && selection.length > 0 ? 
				<div style={{height: "calc(100% - 65px)", bottom: 0, top: 65, right:0, left: 0, zIndex: 0}}/>
				:
				null
			}
			{
				props.selectionMode === SelectionMode.Multiple && props.onSave && selection.length > 0 ?
					<div className="grid-row header-row" style={{position: "sticky", bottom: "0px", left: 0, right: 0}}>
						<div className="row-cell header-cell selected-count">
							{`Selected entries:		${selection.length}`}
						</div>
						<div 
						className={`row-cell header-cell save-button ${!props.onSaveConstrain || props.onSaveConstrain(selection) ? "active" : ""}`} 
						onClick={() => {
							if ((!props.onSaveConstrain || props.onSaveConstrain(selection)) && props.onSave) {
								props.onSave(selection);
							}
						}}>
							Save
						</div>
					</div>
				:
					<></>
			}
		</div>
	);
}

export type SearchListCardProps<Model extends BaseModel> = {
	value?: Model;
	index?: number;
	array?: Model[];
};