import React, { Component } from 'react';

const LEFT_PAGE = -50;
const RIGHT_PAGE = -100;

const range = (from: number, to: number, step = 1) => {
    let i: number = from;
    const range: number[] = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

interface IProps {
    currentPage: number;
    itemPerPage: number;
    pageNeighbours: number;
    totalRecords: number;
    onPageChanged: Function;

}

interface IState {
    currentPage: number;
    totalPages: number;
    page: string;
    btnVisible: boolean;
}

export class Pagination extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.goTo = this.goTo.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            currentPage: this.props.currentPage,
            totalPages: Math.ceil(this.props.totalRecords / this.props.itemPerPage),
            page: "",
            btnVisible: false,
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.totalRecords !== prevProps.totalRecords) {
            this.setState({ totalPages: Math.ceil(this.props.totalRecords / this.props.itemPerPage) })
        }
    }

    fetchPageNumbers = () => {
        const totalPages = this.state.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.props.pageNeighbours;

        const totalNumbers = (this.props.pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    componentDidMount() {
        this.gotoPage(1);
        this.setState({ currentPage: this.props.currentPage })
    }

    gotoPage = (page: number) => {
        const { onPageChanged = (f: number) => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.state.totalPages));
        const paginationData = {
            currentPage,
            totalPages: this.state.totalPages,
            itemPerPage: this.props.itemPerPage,
            totalRecords: this.props.totalRecords
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData.currentPage));
    }

    handleClick = (page: number) => (evt: any) => {
        evt.preventDefault();
        this.gotoPage(page);
    }

    handleMoveLeft = (evt: any) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - (this.props.pageNeighbours * 2) - 1);
    }

    handleMoveRight = (evt: any) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + (this.props.pageNeighbours * 2) + 1);
    }

    goTo() {
        this.setState({ currentPage: parseInt(this.state.page) })
        this.props.onPageChanged(parseInt(this.state.page))
    }

    handleChange(e: any) {
        let value = parseInt(e.target.value);
        if (value > 0 && value <= this.state.totalPages) {
            this.setState({ btnVisible: true })
        } else {
            this.setState({ btnVisible: false })
        }
        this.setState({ page: value.toString() })
    }

    render() {
        if (!this.props.totalRecords || this.state.totalPages === 1) return null;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <div className="row">
                <div className="col">
                    <ul className="pagination">
                        {pages.map((page, index) => {

                            if (page === LEFT_PAGE) return (
                                <li key={index} className="page-item mt-3">
                                    <div
                                        className="page-link page-link-report"
                                        aria-label="Previous"
                                        onClick={this.handleMoveLeft}
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </div>
                                </li>
                            );

                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="page-item mt-3">
                                    <div
                                        className="page-link page-link-report"
                                        aria-label="Next"
                                        onClick={this.handleMoveRight}
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </div>
                                </li>
                            );

                            return (
                                <li key={index} className={`page-item mt-3${currentPage === page ? ' active' : ''}`}>
                                    <div
                                        className="page-link page-link-report"
                                        onClick={this.handleClick(page)}
                                    >
                                        {page}
                                    </div>
                                </li>
                            );

                        })}

                    </ul>
                </div>
                <div className="col">
                    <div className="input-group mb-3 " style={{ marginTop: "1em" }}>
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <input type="number" value={this.state.page} onChange={(e) => this.handleChange(e)} className="form-control" placeholder="Page" aria-label="Page" aria-describedby="basic-addon2" />
                        </form>
                    </div>
                </div>
                <div className="col">
                    <div className="input-group-append " style={{ marginTop: "1em" }}>
                        <button className="btn btn-dark" disabled={!this.state.btnVisible} onClick={this.goTo}>Go to page</button>
                    </div>
                </div>

            </div>

        );
    }
}


