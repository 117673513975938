import React, { Component } from 'react';
import DownloadRequestTable from './DownloadRequestTable';
import DownloadRequestOffCanvas from './DownloadRequestOffCanvas';
import { deleteQueueDownloadRequest, getJSONHeaders, postQueueDownloadRequestOffCanvas, webApiQueue } from '../../AppSettings';
import { IAccount, IPostQueueDownloadRequest, RequestHandler } from '../../Models/CalendarDay';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DownloadRequestType, DownloadRequestModel } from '../../Models/DownloadRequestTypeModel';
import { fileUtils } from '../../Services/Utils/FileUtils';

interface IProps { 
    activeUser : IAccount
}

interface IState {
    requests: DownloadRequestModel[];
    isVisibleNewRequest: boolean;
    visualRequest: DownloadRequestModel;
    selectedDate: Date;
    filteredList: DownloadRequestModel[];
}

export default class DownloadRequestPage extends Component<IProps, IState> {

    selectorOptions: string[] = ["All", "Pending", "Approved", "Rejected"];

    constructor(props: IProps) {
        super(props);
        this.onAddNewRequest = this.onAddNewRequest.bind(this);
        this.onAddNewRow = this.onAddNewRow.bind(this);
        this.onCloseNewRequest = this.onCloseNewRequest.bind(this);
        this.onShowRequest = this.onShowRequest.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.onAddNewRow = this.onAddNewRow.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.state = {
            requests: [],
            isVisibleNewRequest: false,
            visualRequest: {} as DownloadRequestModel,
            selectedDate: new Date(),
			filteredList: [],
        };
    }

    componentDidMount() {
        this.loadRequests();
    }

    componentWillReceiveProps(nextProps: IProps) { 
        if (this.props.activeUser !== nextProps.activeUser) {
            this.loadRequests();
        }
    }

    async loadRequests() {
        let query: string = webApiQueue + "/" + "enqueueDownloadRequest";

        RequestHandler.requestSend();

        fetch(query, {
            method: 'GET',
            headers: await getJSONHeaders(),
        })
            .then(resReq => resReq.json())
            .then((resultReq) => {
                this.setState({
                    requests: this.convertDate(resultReq),
                });
                RequestHandler.responseReceived("");
            }, (error) => {
                this.setState({
                    requests: [],
                });
                RequestHandler.responseReceived("");
            })
    }

    async onDownloadFile(queueDownloadRequestId : string) { 
        fileUtils.downloadFile(webApiQueue + "/downloadFile/" + queueDownloadRequestId, true); 
    }


    convertDate(req: DownloadRequestModel[]) {
        let convertedRequest: DownloadRequestModel[] = req.map((requestEntry: DownloadRequestModel) => {
            return {
                id: requestEntry.id,
                requestedAt: requestEntry.requestedAt,
                from: requestEntry.from,
                to: requestEntry.to,
                status: requestEntry.status,
                startedAt: requestEntry.startedAt,
                completedAt: requestEntry.completedAt,
                result: requestEntry.result,
				type: requestEntry.type,
				referenceId: requestEntry.referenceId
            }
        })
        return convertedRequest;
    }

    onAddNewRequest() {
        this.setState({
            visualRequest: {
                id: "0001",
                from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                status: "created",
                requestedAt: new Date(),
                startedAt: new Date(),
                completedAt: new Date(),
                result: "",
                type: "",
				referenceId: undefined
            },
            isVisibleNewRequest: true,
        })
        document.body.style.overflowY = "hidden";
    }

    onShowRequest(req: DownloadRequestModel) {
        this.setState({
            visualRequest: req,
            isVisibleNewRequest: true,
        })
        document.body.style.overflowY = "hidden";
    }

    onCloseNewRequest() {
        this.setState({ isVisibleNewRequest: false });
        document.body.style.overflowY = "auto";
    }

    async onAddNewRow(from: Date, to: Date | null, type: DownloadRequestType, referenceId?: string) {
        RequestHandler.requestSend();

        let objectToPost: IPostQueueDownloadRequest = {
            from: format(new Date(from), 'yyyy-MM-dd'),
            to: to ? format(new Date(to), 'yyyy-MM-dd') : null,
			type: DownloadRequestType[type],
			referenceId: referenceId
        }
        if (from) {
            let response = await postQueueDownloadRequestOffCanvas(objectToPost).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (response) {
                RequestHandler.responseReceived("Request sent");
                this.loadRequests()
                return;
            }
        }

        RequestHandler.responseReceived("");
    }

    async onDeleteRow(jobOrderCardDownloadReques: DownloadRequestModel) {
        RequestHandler.requestSend();

        let response = await deleteQueueDownloadRequest(jobOrderCardDownloadReques).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

        if (response) {
            RequestHandler.responseReceived("Delete successful");
            this.loadRequests()
        }

        RequestHandler.responseReceived("");
    }

    render() {
        return (<div style={{ margin: "50px 0px 0px 0px" }}>
            <div className="container">
             
                <button type="button" className="btn btn-outline-dark" onClick={this.onAddNewRequest} style={{marginBottom:"20px"}}>
                    <FontAwesomeIcon icon={faPlus} /> Add request
                </button> 

                {this.state.requests.length > 0 ? (
                    <div>
                        <DownloadRequestTable
                            requestData={this.state.requests}
                            showRequest={this.onShowRequest}
                            onDeleteRow={this.onDeleteRow}
                            onDownloadFile={this.onDownloadFile}
                        />
                    </div>
                ) : (<div><p style={{ fontWeight: "bold" }}>No requests included</p></div>)}
                
                <DownloadRequestOffCanvas
					requestPageOffCanvasIsVisible={this.state.isVisibleNewRequest}
					requestPageOffCanvasTitle="New Request"
					onClose={this.onCloseNewRequest}
					onAddRequest={this.onAddNewRow}
					visualRequest={this.state.visualRequest}
                />
            </div >
            <br />
            <br />
        </div>);
    }
}
