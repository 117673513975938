import { Bearer } from "../AppSettings";
import BaseModel from "../Models/BaseModel";
import { ErrorHandling, LoadingHandling, RequestHandler } from "../Models/CalendarDay";

/**
 * Base services common implementations.
 *
 * @export
 * @class BaseService
 * @param {string} endpoint
 * @template Model
 */
export default class BaseService<Model extends BaseModel> {
	public endpoint: string;
	private defaultHeaders: Headers = new Headers();

	/**
	 * Creates an instance of BaseService.
	 * @param {string} endpoint
	 * @memberof BaseService
	 */
	constructor(endpoint: string) {
		this.endpoint = endpoint;
		this.defaultHeaders.append("Authorization", Bearer);
		this.defaultHeaders.append("Content-Type", "application/json");
		this.defaultHeaders.append("Accept", "application/json");
	}

	/**
	 *	Default get with single object on response.
	 *
	 * @return {*}  {(Model | undefined)}
	 * @memberof BaseService
	 */
	get(callback?: React.Dispatch<React.SetStateAction<Model>>, endpoint: string = this.endpoint): Model | undefined {
		let model: Model | undefined;
		fetch(endpoint, {
			method: "GET",
			headers: this.defaultHeaders,
		})
			.then(ErrorHandling.handleError)
			.then(res => res.json())
			.then((result) => {
				RequestHandler.responseReceived("");
				if (callback !== undefined) {
					callback(result as Model);
				}
				model = result as Model;
			}).catch(error => {
				RequestHandler.badRequestReceived(error, `Somethig went wrong with the request. (${typeof model})`);
			});
		return model;
	}

	/**
	 * Default get with multiple object on response.
	 *
	 * @param {() => void} callback
	 * @return {Array<Model>}  {(Array<Model> | undefined)}
	 * @memberof BaseService
	 */
	getAll(callback?: React.Dispatch<React.SetStateAction<Model[]>> | ((models: any[]) => void), endpoint: string = this.endpoint): Array<Model> | undefined {
		let models: Array<Model> | undefined;

		fetch(endpoint, {
			method: "GET",
			headers: this.defaultHeaders,
		}).then(ErrorHandling.handleError)
			.then(res => res.json())
			.then((result) => {
				RequestHandler.responseReceived("");
				if (callback !== undefined) {
					callback(result as Array<Model>);
				}
				LoadingHandling.hideLoader()
				return result;
			}).catch(error => {
				RequestHandler.badRequestReceived(error, `Somethig went wrong with the request. (${typeof (models as unknown as Model)})`);
			});
		return models;
	}
	
	post(model?: Model, endpoint: string = this.endpoint, body: string = JSON.stringify(model)) {
		fetch(endpoint, {
			method: "POST",
			headers: this.defaultHeaders,
			body: JSON.stringify(body),
		}).then(ErrorHandling.handleError)
			.then(res => res.json())
			.then((result) => {
				RequestHandler.responseReceived("");
				// if (callback !== undefined) {
				// 	callback(result as Array<Model>);
				// }
				LoadingHandling.hideLoader()
				ErrorHandling.successToast("Added Succesfully");
				return result;
			}).catch(error => {
				RequestHandler.badRequestReceived(error, `Somethig went wrong with the request. (${typeof model})`);
			});
	}	

	postAll(models?: Model, endpoint: string = this.endpoint, body: any = JSON.stringify(models), headers: Headers = this.defaultHeaders, callback?: () => void) {
		fetch(endpoint, {
			method: "POST",
			headers: headers,
			body: body,
		}).then(ErrorHandling.handleError)
			.then(res => res.json())
			.then((result) => {
				RequestHandler.responseReceived("");
				if (callback !== undefined) {
					callback();
				}
				LoadingHandling.hideLoader()
				ErrorHandling.successToast("Added Succesfully");
				return result;
			}).catch(error => {
				LoadingHandling.hideLoader()
				RequestHandler.badRequestReceived(error, `Somethig went wrong with the request. (${typeof models})`);
			});
	}

	put(model: Model) {

	}

	delete(models?: Model, endpoint: string = this.endpoint, body: any = JSON.stringify(models), headers: Headers = this.defaultHeaders, callback?: () => void) {
		fetch(endpoint, {
			method: "DELETE",
			headers: headers,
			body: body,
		}).then(ErrorHandling.handleError)
			.then(res => res.json())
			.then((result) => {
				RequestHandler.responseReceived("");
				if (callback !== undefined) {
					callback();
				}
				ErrorHandling.successToast("Removed Succesfully");
				return result;
			}).catch(error => {
				LoadingHandling.hideLoader()
				RequestHandler.badRequestReceived(error, `Somethig went wrong with the request. (${typeof models})`);
			});
	}
};
