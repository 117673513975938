import React, { Component } from 'react';
import JobOrderCardItemOffCanvas from './JobOrderCardItemOffCanvas';
import { IAccount, IActivity } from '../../Models/CalendarDay';
import { ItemsList } from '../Utils/ItemsList';
import { SelectionMode } from "../AegisCommon/SelectionMode";

interface IProps {
    jobOrderOffCanvasIsVisible: boolean;
    jobOrderOffCanvasTitle: string;
    onClose: Function;
    onAddRow: Function;
    userList: IAccount[];
}

interface IState {
    isVisible: boolean;
    isContentVisible: boolean;
    selectedUsers: IActivity[];
    usersList: IActivity[]
    isEmpty: boolean;
}

export default class JobOrderCardUserOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onListShowHide = this.onListShowHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.state = {
            isVisible: this.props.jobOrderOffCanvasIsVisible,
            isContentVisible: false,
            selectedUsers: [],
            usersList: this.props.userList.map(x => { return { id: x.id, description: `${x.firstName} ${x.lastName} (${x.type?.slice(0, 3)} - ${x.company.split(" ")[0]})` } }),
            isEmpty: true
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.jobOrderOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.jobOrderOffCanvasIsVisible
            });
        }
		
		if (nextProps.userList.map(x => { return { id: x.id, description: `${x.firstName} ${x.lastName} (${x.type?.slice(0, 3)} - ${x.company.split(" ")[0]})` } }) !== this.state.usersList) {
            this.setState({
                usersList: nextProps.userList.map(x => { return { id: x.id, description: `${x.firstName} ${x.lastName} (${x.type?.slice(0, 3)} - ${x.company.split(" ")[0]})` } })
            });
        }
    }

    onClose() {
        this.setState({ isVisible: false, selectedUsers: [], isEmpty: true  });
        this.props.onClose();
    }

    onSave() {
        this.props.onAddRow(this.state.selectedUsers)
		let empty: IActivity[] = [];
		this.setState({ selectedUsers: [], isEmpty: true });
		return;
    }

    onChangeAccount(accounts: IActivity[]) {
        this.setState({
            selectedUsers: accounts,
            isEmpty: accounts.length > 0 ?  false : true,
        })
    }

    onListShowHide(isListOpen: boolean) {
        this.setState({ isContentVisible: !isListOpen });
    }

    render() {
        return (
            <div>
                <JobOrderCardItemOffCanvas
                    offCanvasTitle={this.props.jobOrderOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    saveDisabled={this.state.isEmpty}
                >
                    <ItemsList
                        items={this.state.usersList}
                        selectedItems={this.state.selectedUsers}
                        onSelectedItemChange={(account: IActivity[]) => this.onChangeAccount(account)}
                        searchPlaceholder="Search user name"
                        showCompleteList={true}
						selectionMode={SelectionMode.Multiple}
                    />
                </JobOrderCardItemOffCanvas >
            </div>)
    }
}