import { Component } from 'react';
import JobOrderCardItemOffCanvas from './JobOrderCardItemOffCanvas';
import { ItemsList } from '../Utils/ItemsList';
import { IAccount, IActivity, IUpdateActivityAccountTemplate } from '../../Models/CalendarDay';
import { getJSONHeaders, webApiActivities } from '../../AppSettings';
import { IconButton, Stack, StackItem } from '@fluentui/react';

interface IProps {
    jobOrderOffCanvasIsVisible: boolean;
    jobOrderOffCanvasTitle: string;
    onClose: Function;
    onAddActivity: Function;
    activityList: IActivity[];
    currentUser: IAccount;
}

interface IState {
    isVisible: boolean;
    isContentVisible: boolean;
    selectedActivity: IActivity;
    activityList: IActivity[]
    isEmpty: boolean;
}

export default class JobOrderCardActivityOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onListShowHide = this.onListShowHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTemplateChange = this.onTemplateChange.bind(this);
        this.state = {
            isVisible: this.props.jobOrderOffCanvasIsVisible,
            isContentVisible: false,
            selectedActivity: {} as IActivity,
            activityList: this.props.activityList,
            isEmpty: true
        }
    }

    async onTemplateChange(activity: IActivity) {
        let updateActivityAccountTemplate: IUpdateActivityAccountTemplate = {
            isFavourite: activity.isFavourite,
            color: activity.color
        };

        fetch(`${webApiActivities}/${activity.id}/templates/${this.props.currentUser.id}`, {
            method: 'PUT',
            body: JSON.stringify(updateActivityAccountTemplate),
            headers: await getJSONHeaders()
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        }).catch(e => {
            throw new Error(e);
        })
    };

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.jobOrderOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.jobOrderOffCanvasIsVisible
            });
        }
        if (nextProps.activityList !== this.state.activityList) {
            this.setState({
                activityList: this.props.activityList,
            })
        }
    }

    onClose() {
        this.setState({ isVisible: false });
        this.props.onClose();
        this.setState({ selectedActivity: {} as IActivity, isEmpty: true })
    }

    onSave() {
        this.props.onAddActivity(this.state.selectedActivity)
    }

    onChangeAccount(activity: IActivity) {
        this.setState({
            selectedActivity: activity,
            isEmpty: false,
        })

    }

    onListShowHide(isListOpen: boolean) {
        this.setState({ isContentVisible: !isListOpen });
    }

    render() {
        return (
            <div>
                <JobOrderCardItemOffCanvas
                    offCanvasTitle={this.props.jobOrderOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    saveDisabled={this.state.isEmpty}
                >
                    <ItemsList
                        key="Activity"
                        selectedItem={this.state.selectedActivity}
                        onSelectedItemChange={(activity: IActivity) => this.onChangeAccount(activity)}
                        searchPlaceholder="Search activity"
                        showCompleteList={true}
                        onFavouriteOrColorChange={this.onTemplateChange}
                        items={this.state.activityList}
                    />
                </JobOrderCardItemOffCanvas >
            </div>)
    }
}