import React, { useEffect, useState } from 'react';
import { IExpenseEntry } from './../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

interface IProps {
    data: IExpenseEntry,
    onDragStartFunction: Function,
    onDragEndFunction: Function,
    onClick: Function,
    onCopy: Function,
    isMonthEditable: boolean,
    isNotSelected: boolean,
    isExpenseSelected: boolean,
    onExpenseSelection: Function
}

export const Expense = ({ data, onDragStartFunction, onDragEndFunction, onClick, onCopy, isMonthEditable, isNotSelected, isExpenseSelected, onExpenseSelection }: IProps) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    const [indexOnDrag, setIndexOnDrag] = useState(0);
    const [isSelected, setSelected] = useState(isExpenseSelected);

    useEffect(() => {
        setSelected(isExpenseSelected)
    }, [isExpenseSelected])
    
    const isEditable = (isMonthEditable && !isNotSelected);

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        if (isEditable) {
            setIndexOnDrag(1);
            onDragStartFunction(event, data.id, "Expense");
        }
    }

    return (
        <div
            className={"calendar-activity " + (isEditable ? "draggable" : "")}
            draggable={isEditable}
            onDragStart={(event) => onDragStart(event)}
            onDragEnd={() => onDragEndFunction()}
            onClick={() => { if (isEditable) { onClick(data.id); } }}
            onMouseEnter={e => setMouseEnter(true)}
            onMouseLeave={e => setMouseEnter(false)}
            style={{ position: "relative", backgroundColor: data?.expense?.color ?? "#ffffff", zIndex: indexOnDrag, cursor: isEditable ? "pointer" : "cursor", opacity: isNotSelected ? ".3" : "1" }}
        >
            <div className="d-flex align-items-center mb-2">
                <div
                    className="col-12"
                    style={{
                        backgroundColor: data?.activity?.color ?? "#ffffff",
                        border: ".5px solid black",
                        borderRadius: ".45rem",
                        height: ".9rem",
                        width: ".9rem"
                    }}>
                </div>
                <div className="activity-project-description ms-1">
                    {data?.activity?.description}
                </div>
            </div>
           
            <div className="activity-note">
                {data?.receiptNumber}
            </div>

            <div className="activity-note">
                {data?.expense?.description}
            </div>
            <div className="activity-project-footer">
                <div className="footer-left text-start">
                    {data?.amount.toFixed(2).toString()} €
                </div>
            </div>

            {((isEditable && mouseEnter) || isSelected) && (
                <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onClick={(e) => { e.stopPropagation(); }}
                        onChange={(e) => { setSelected(e.target.checked); onExpenseSelection(data?.id, e.target.checked); }}
                    />
                </div>
            )}
            {isEditable && mouseEnter && (
                <div style={{ position: "absolute", bottom: "5px", right: "5px" }}>


                    <button
                        className="btn btn-dark"
                        onClick={(e) => { e.stopPropagation(); if (data?.id) { onCopy(data.id) } }}
                    >
                        <FontAwesomeIcon
                            icon={faCopy}
                        />
                    </button>
                </div>
            )}
        </div>)
};
