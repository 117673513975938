import React, { Component } from 'react';
import { IJobOrderCardTable, IJobOrderCardTableDates, JobOrderCardTableEnum } from '../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    topRow: string[],
    currentDate: Date,
    tableData:IJobOrderCardTable [],
    toggleShowColumns: boolean,
    handleChange?: Function,
    tableType: string,
    isEditable: boolean,
}

interface IState {
    currentDate: Date,
    toggleShowColumns: boolean,
    showDetails: boolean,
}

export default class JobOrderCardTable extends Component<IProps, IState>{

    lst: number[] = [];

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentDate: this.props.currentDate,
            toggleShowColumns: this.props.toggleShowColumns,
            showDetails: false,
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.tableData !== this.props.tableData) {
            this.setState({ showDetails: false })
        }
    }

    checkCol(elem: string) {
        if (this.state.toggleShowColumns) {
            if ((elem === "YTG Amount") || (elem === "YTG Units"))
                return true;
        }

        if (elem === "") { return true }

        var month = elem.split(" ")[0];
        var year = elem.split(" ")[1];
        var date = new Date(month + " " + this.state.currentDate.getDate() + ", " + year + " 23:59:59")
        if (this.state.currentDate.getTime() <= date.getTime()) {
            return true;
        }
        return false;
    }

    checkBorder(i: number) {
        if (this.state.toggleShowColumns) {
            if (i === 6) {
                return true
            }
        } else {
            if (i === 4)
                return true
        }
        return false;
    }

    checkTableType(col: string) {
        this.lst = [];
        if (col === "revenues") {
            return true;
        }
        return false;
    }

    calcFooter(col: JobOrderCardTableEnum, index?: number) {
        let sum = 0;

        switch (col) {
            case JobOrderCardTableEnum.YTDAmount: this.props.tableData.forEach(el => sum += el.YTDAmount); break;
            case JobOrderCardTableEnum.YTGAmount: this.props.tableData.forEach(el => sum += el.YTGAmount); break;
            case JobOrderCardTableEnum.YTDUnits: this.props.tableData.forEach(el => sum += el.YTDUnits); break;
            case JobOrderCardTableEnum.YTGUnits: this.props.tableData.forEach(el => sum += el.YTGUnits); break;
            case JobOrderCardTableEnum.datesData: index !== undefined ? this.props.tableData.forEach(el => { !Number.isNaN(el.datesData[index].value) && !el.isTask ? sum += el.datesData[index].value : sum += 0 }) : sum = 0; break;
        }
        return (sum * 100) / 100;
    }

    checkDate(data: IJobOrderCardTableDates) {
        const dateToCheck = new Date(data.year, data.month);
        const currentDate = new Date(new Date().getFullYear(), new Date().getMonth());

		return (dateToCheck.getTime() > currentDate.getTime());
    }

    changeState() {
        this.setState({ showDetails: !this.state.showDetails })
    }

    formatter(num: number): string {
        if (num === 0) return "-";
        return num.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR"
              }).replace("€", '');
    }

    render() {

        let topRowData = this.props.topRow.map((elem, i) => {
            if (!this.props.toggleShowColumns) {
                if (i > 1 && i < 6) {
                } else {
                    return <th key={i} className={`${this.checkCol(elem) ? "customBG" : ""}`} style={{ borderLeft: `${this.checkBorder(i) ? "1px solid #dee0e3" : ""}` }}>{elem}</th>
                }
            } else {
                return <th key={i} className={`${this.checkCol(elem) ? "customBG" : ""}`} style={{ borderLeft: `${this.checkBorder(i) ? "1px solid #dee0e3" : ""}` }}>{elem}</th>
            }

        })

        let tableData: JSX.Element | JSX.Element[] = [];
        let invoice: JSX.Element | JSX.Element[] = [];
        let invoiceDetails: JSX.Element | JSX.Element[] = [];
        let results: JSX.Element | JSX.Element[] = [];

        if (this.props.tableType === "costs") {
            tableData = this.props.tableData.map((item, index) => (
                <tr key={index}>
                    <th>{item.description}</th>
                    <td>{this.formatter(item.unitPrice)}</td>
                    {this.props.toggleShowColumns && (
                        <>
                            <td>{this.formatter(item.YTDAmount)}</td>
                            <td>{this.formatter(item.YTGAmount)}</td>
                            <td>{this.formatter(item.YTDUnits)}</td>
                            <td>{this.formatter(item.YTGUnits)}</td>
                        </>
                    )}
                    {item.datesData.map((datesData, index) => {
                        if (this.props.tableType === "revenues") {
                            return <td className="datesList" key={index}>{datesData.value}</td>
                        } else {
                            if (this.props.isEditable) {
                                if (!this.checkDate(datesData) || !datesData.isPlan) {
                                    return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
								} else return (
									<td className="datesList" key={index}>
										<form onSubmit={e => { e.preventDefault(); }}>
											<input
												className="customTableInput"
												type="number"
												min="0"
												placeholder="-"
												value={datesData.value ? datesData.value : datesData.value.toString()}
												onChange={(e) => this.props.handleChange ? this.props.handleChange(e, item.id + item.description, index) : null}
											/>
										</form>
									</td>
								);
                            }
                            else return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                        }
                    })}
                </tr>
            ))
        } else if (this.props.tableType === "revenues") {

            invoice = this.props.tableData.filter(elem => { if (elem.rowType === "IS") return true }).map((item, index) => {
                if (item.rowType === "IS") {
                    return (
                        <tr key={index}>
                            <th>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ float: "left" }}>
                                        {item.description}
                                    </div>
                                    <div style={{ float: "right", paddingLeft: "20px" }}>
                                        <span style={{ fontSize: "15px" }}>
                                            {
                                                this.state.showDetails ? 
                                                <FontAwesomeIcon icon={faChevronUp} onClick={(e) => this.changeState()} /> 
                                                : 
                                                <FontAwesomeIcon icon={faChevronDown} onClick={(e) => this.changeState()} />
                                            }
                                        </span>
                                    </div>
                                </div> 
                            </th>
                            <td>{this.formatter(item.unitPrice)}</td>
                            {this.props.toggleShowColumns && (
                                <>
                                    <td>{this.formatter(item.YTDAmount)}</td>
                                    <td>{this.formatter(item.YTGAmount)}</td>
                                    <td>{this.formatter(item.YTDUnits)}</td>
                                    <td>{this.formatter(item.YTGUnits)}</td>
                                </>
                            )}
                            {item.datesData.map((datesData, index) => {
                                if (this.props.tableType === "revenues") {
                                    return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                } else {
                                    if (!this.checkDate(datesData)) {
                                        return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                    } else {
                                        return (
                                            <td className="datesList" key={index}> 
                                                <form onSubmit={e => { e.preventDefault(); }}>
                                                    <input className="customTableInput" 
                                                    type="number" min="0" placeholder="-" 
                                                    value={datesData.value.toString()} 
                                                    onChange={(e) => this.props.handleChange ? this.props.handleChange(e, item.id + item.description, index) : null} 
                                                    />
                                                </form>
                                            </td>
                                        );
                                    }
                                }
                            })}
                        </tr>
                    )
                }
                return <tr></tr>;
            });

            invoiceDetails = this.props.tableData.filter(elem => { if (elem.rowType === "I") return true }).map((item, index) => {
                if (item.rowType === "I") {
                    return (<tr key={index}>
                        <th>{item.description}</th>
                        <td>{this.formatter(item.unitPrice)}</td>
                        {this.props.toggleShowColumns && (
                            <>
                                <td>{this.formatter(item.YTDAmount)}</td>
                                <td>{this.formatter(item.YTGAmount)}</td>
                                <td>{this.formatter(item.YTDUnits)}</td>
                                <td>{this.formatter(item.YTGUnits)}</td>
                            </>
                        )}
                        {item.datesData.map((datesData, index) => {
                            if (this.props.tableType === "revenues") {
                                return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                            } else {
                                if (!this.checkDate(datesData)) {
                                    return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                } else { 
                                    return (
                                        <td className="datesList" key={index}>
                                            <form onSubmit={e => { e.preventDefault(); }}>
                                                <input className="customTableInput" 
                                                type="number" min="0" placeholder="-" 
                                                value={datesData.value.toString()} 
                                                onChange={(e) => this.props.handleChange ? this.props.handleChange(e, item.id + item.description, index) : null} 
                                                />
                                            </form>
                                        </td>
                                    );
                                }
                            }
                        })}
                    </tr>)
                }
                return <tr></tr>;
            })
            results = this.props.tableData.filter(elem => { if (elem.rowType === "1" || elem.rowType === "2") return true }).map((item, index) => {
                if (item.rowType === "1") {
                    return (
                        <tr key={index}>
                            <th>{item.description}</th>
                            <td>{this.formatter(item.unitPrice)}</td>
                            {this.props.toggleShowColumns && (
                                <>
                                    <td>{this.formatter(item.YTDAmount)}</td>
                                    <td>{this.formatter(item.YTGAmount)}</td>
                                    <td>{this.formatter(item.YTDUnits)}</td>
                                    <td>{this.formatter(item.YTGUnits)}</td>
                                </>
                            )}
                            {item.datesData.map((datesData, index) => {
                                if (this.props.tableType === "revenues") {
                                    return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                } else {
                                    if (!this.checkDate(datesData)) {
                                        return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                    } else return <td className="datesList" key={index}> <form onSubmit={e => { e.preventDefault(); }}><input className="customTableInput" type="number" min="0" placeholder="-" value={datesData.value.toString()} onChange={(e) => this.props.handleChange ? this.props.handleChange(e, item.id + item.description, index) : null} /></form></td>;

                                }
                            })}
                        </tr>
                    )
                }

                if (item.rowType === "2") {
                    return (
                        <tr key={index}>
                            <th>{item.description}</th>
                            <td>{this.formatter(item.unitPrice)}</td>
                            {this.props.toggleShowColumns && (
                                <>
                                    <td>{this.formatter(item.YTDAmount)}</td>
                                    <td>{this.formatter(item.YTGAmount)}</td>
                                    <td>{this.formatter(item.YTDUnits)}</td>
                                    <td>{this.formatter(item.YTGUnits)}</td>
                                </>
                            )}
                            {item.datesData.map((datesData, index) => {
                                if (this.props.tableType === "revenues") {
                                    return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                } else {
                                    if (!this.checkDate(datesData)) {
                                        return <td className="datesList" key={index}>{this.formatter(datesData.value)}</td>
                                    } else return <td className="datesList" key={index}> <form onSubmit={e => { e.preventDefault(); }}><input className="customTableInput" type="number" min="0" placeholder="-" value={datesData.value.toString()} onChange={(e) => this.props.handleChange ? this.props.handleChange(e, item.id + item.description, index) : null} /></form></td>;

                                }
                            })}
                        </tr>
                    )
                }
                return <tr></tr>
            })
        }

        return (<div>
            <div>
                <div className="customTableDiv">
                    <table id="divWithCustomScrollBar">
                        <thead>
                            <tr>
                                {topRowData}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.tableType === "costs" && (
                                tableData
                            )}
                            {this.props.tableType === "revenues" && (
                                invoice
                            )}
                            {this.props.tableType === "revenues" && this.state.showDetails && (
                                invoiceDetails
                            )}

                            {this.props.tableType === "revenues" && (
                                results
                            )}
                        </tbody>
                        {this.props.tableType === "costs" && (
                            <tfoot>
                                <tr style={{ fontWeight: "bold" }}>
                                    <th style={{ color: `${this.checkTableType(this.props.tableType) ? "#259bff" : "#d9506c"}` }}>{this.props.tableType.toUpperCase()}</th>
                                    <td></td>
                                    {this.props.toggleShowColumns && (
                                        <>
                                            <td>{this.formatter(this.calcFooter(JobOrderCardTableEnum.YTDAmount))}</td>
                                            <td>{this.formatter(this.calcFooter(JobOrderCardTableEnum.YTGAmount))}</td>
                                            <td>{this.formatter(this.calcFooter(JobOrderCardTableEnum.YTDUnits))}</td>
                                            <td>{this.formatter(this.calcFooter(JobOrderCardTableEnum.YTGUnits))}</td>
                                        </>
                                    )}

                                    {this.props.tableData.map((item, index) => {
                                        if (index === 0) {
                                            return (item.datesData.map((item, index) => (
                                                <td key={index} className="datesList">{this.formatter(this.calcFooter(JobOrderCardTableEnum.datesData, index))}</td>
                                            )))
                                        }
                                    })}
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            </div>

        </div>);
    }
}