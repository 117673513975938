import React, { Fragment, useEffect, useRef, useState } from 'react';
import JobOrderCardInfo from '../JobOrderCard/JobOrderCardInfo';
import MonthSelector from '../Utils/MonthSelector';
import WeekSelector from '../Utils/WeekSelector';
import DropDownSelector from '../Utils/DropDownSelector';
import { IMenuItem, IMonthsStatus, VisualizationType, Menu, ErrorHandling, LoadingHandling, RequestHandler } from '../../Models/CalendarDay';
import { getJSONHeaders, webApiActivityEntries, webApiEntriesMonthStatus } from '../../AppSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faArrowDown, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import MonthStatusComponent from './MonthStatusComponent';
import { format } from 'date-fns';
import { fileUtils } from '../../Services/Utils/FileUtils';

interface CalendarPageHeaderProps {
    toogleWeekend: Function,
    weekend: boolean,
    startDate: Date,
    visualizationType: VisualizationType,
    onMonthChange: Function,
    onWeekChange: Function,
    onVisualizationTypeChange: Function,
    onConfirmMonth: Function,
    onDeleteConfirmMonth: Function,
    calendarClass: string,
    calendarClassWeekend: string
    isConfirmed: boolean | null;
    isApproved: boolean | null;
    isLocked: boolean | null;
    isError: boolean | null;
    onChangeVisualization: Function;
    isConfirmable: boolean;
    totalExpenses: number;
    monthStatus: number;
    selectedMenu: IMenuItem;
    onSelectAllActivities?: Function;
    onSelectAllExpenses?: Function;
    currentAttachment: IMonthsStatus | null;
    totalHours: number;
}

export default function CalendarPageHeader({
    toogleWeekend,
    weekend,
    startDate,
    visualizationType,
    onMonthChange,
    onWeekChange,
    onVisualizationTypeChange,
    onConfirmMonth,
    onDeleteConfirmMonth,
    calendarClass,
    calendarClassWeekend,
    isApproved,
    isConfirmed,
    isLocked,
    isError,
    onChangeVisualization,
    isConfirmable,
    totalExpenses,
    selectedMenu,
    onSelectAllActivities,
    onSelectAllExpenses,
    monthStatus,
    currentAttachment,
    totalHours
}: CalendarPageHeaderProps): React.ReactElement {
    const [isSticky, setSticky] = useState(false);
    const [date, setDate] = useState(startDate);
    const [visType, setVisType] = useState(visualizationType);
    const [totalExp, setTotalExpenses] = useState(totalExpenses);
    const [monthS, setMonthStatus] = useState(monthStatus)
    const [totalH, setTotalHours] = useState(totalHours)
    const ref = useRef<HTMLDivElement>(null);

    const inputRef = useRef<null | HTMLInputElement>(null);
    const [hasAttachment, setHasAttachment] = useState<boolean>(
        currentAttachment?.currentMonth?.hasAttachment ?? false);

    const handleChangeData = (startDate: Date, endDate: Date) => {
        setDate(startDate);
        onMonthChange(startDate);
        onChangeVisualization();
    }

    useEffect(() => { 
        setHasAttachment(currentAttachment?.currentMonth?.hasAttachment ?? false);
    },[currentAttachment])

    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 0);
        }
    };

    const handleVisualizationTypeChange = (value: number) => {
        let visType = value as VisualizationType;
        setVisType(visType);
        onVisualizationTypeChange(visType);
        onChangeVisualization();
    }

    useEffect(() => {
        setDate(startDate);
        if (inputRef.current && inputRef.current.value) inputRef.current.value = "";
    }, [startDate])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    useEffect(() => {
        setTotalExpenses(totalExpenses);
    }, [totalExpenses]);

    useEffect(() => {
        setMonthStatus(monthStatus);
    }, [monthStatus]);

    useEffect(() => {
        setTotalHours(totalHours);
    }, [totalHours]);
  
    const handleUpload = () => {
        inputRef.current?.click();
    };

    async function handleDisplayFileDetails() {
        if ((inputRef.current?.files === null) || (inputRef.current?.files === undefined) || (inputRef.current?.files.length === 0)) { return }

        if (inputRef?.current?.files[0].type !== "application/pdf") { ErrorHandling.errorToast("Wrong file type"); return; }

        if (LoadingHandling.showLoader) {
            LoadingHandling.showLoader();
        }

        let newHeader: Headers = await getJSONHeaders();
        newHeader.delete("content-type");

        const formData = new FormData();
        formData.append('File', inputRef.current.files[0], inputRef?.current?.files[0].name);

        let query: string = webApiEntriesMonthStatus + "/" + date.getFullYear() + "/" + (date.getMonth() + 1) + "/expenses";

        fetch(query,
            {
                method: 'POST',
                body: formData,
                headers: newHeader,

            })
            .then(ErrorHandling.handleError)
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result);
                RequestHandler.responseReceived("File uploaded!");
                setHasAttachment(true);
            })
            .catch((error) => {
                RequestHandler.badRequestReceived(error, "Error while uploading the file");
                //console.error('Error:', error);
                setHasAttachment(false);
            });

        if (inputRef.current && inputRef.current.value) inputRef.current.value = "";
    };

    const deleteFile = async () => {
        let newHeader: Headers = await getJSONHeaders();
        newHeader.delete("content-type");

        const formData = new FormData();

        let query: string = webApiEntriesMonthStatus + "/" + date.getFullYear() + "/" + (date.getMonth() + 1) + "/expenses";

        fetch(query,
            {
                method: 'DELETE',
                body: formData,
                headers: newHeader,
            })
            .then(ErrorHandling.handleError)
            .then((response) => response.json())
            .then((result) => {
                RequestHandler.responseReceived("File Deleted!");
                setHasAttachment(false);
            })
            .catch((error) => {
                RequestHandler.badRequestReceived(error, "Error while deleting the file");
            });
    }

    const downloadFile = async () => {
        if (hasAttachment && currentAttachment) {
            var downloadLink = `${webApiEntriesMonthStatus}/${currentAttachment.currentMonth.accountId}/${currentAttachment.currentMonth.year}/${currentAttachment.currentMonth.month}/downloadExpenses`;
            await fileUtils.downloadFile(downloadLink, false);
        } 
	}

	async function downloadTimesheetReport() {  
        await fileUtils.downloadFile(`${webApiActivityEntries}/report?from=${format(date, "yyyy-MM-dd")}`, false);
	};

    return (
        <Fragment>
            <div className={`sticky-wrapper${isSticky ? ' sticky' : ''} my-4`} ref={ref}>
                <div className="sticky-inner">
                    <div className="row justify-content-between mx-2 my-4 ">
                        <div
                            className="col-6 d-flex"
                            style={{ float: "right", maxHeight: "50px", height: "50px", alignItems: "center" }}
                        >
                            <DropDownSelector data={VisualizationType} selectedData={visType as number} onSelect={handleVisualizationTypeChange} />

                            {(visType === VisualizationType.Month) && (
                                <MonthSelector date={date} onDateSelected={handleChangeData} />
                            )}

                            {(visType === VisualizationType.Week) && (
                                <WeekSelector date={date} onDateChange={onWeekChange} />
                            )}

                            {
                                visType === VisualizationType.Month
                                && <MonthStatusComponent
                                        monthStatus={monthS}
                                        isApproved={isApproved}
                                        isConfirmed={isConfirmed}
                                        isError={isError}
                                        isLocked={isLocked} />
                            }

                        </div>

						{selectedMenu === Menu.Timesheet && (
								<div
                                	className="col-1 d-flex total-expenses"
									style={{ float: "right", maxHeight: "50px", height: "50px" }}
								>
                                    <JobOrderCardInfo title={"Total hours"} data={totalH} type={""} />
                                </div>
						)}
                        {selectedMenu === Menu.Expenses && (
                            <div
                                className="col-1 d-flex total-expenses"
                                style={{ float: "right", maxHeight: "50px", height: "50px" }}
                            >
                                <JobOrderCardInfo title={"Total Expenses"} data={totalExp} type={"€"} />
                            </div>
                        )}


                        <div
                            className="col-5 d-flex justify-content-between align-items-center"
                            style={{ float: "right", maxHeight: "50px", height: "50px" }}
                        >

                            {selectedMenu === Menu.Expenses && visType === VisualizationType.Month && (
                                <div>
                                    <input
                                        ref={inputRef}
                                        onChange={handleDisplayFileDetails}
                                        className="d-none"
                                        type="file"
                                        accept=".pdf"
                                    />
                                    {hasAttachment ? (
                                        <div>
                                            <button
                                                onClick={handleUpload}
                                                className={`btn btn-dark`}
                                                style={{ margin: "10px" }}
                                            >
                                                Overwrite <FontAwesomeIcon icon={faArrowUp} />
                                            </button>
                                            <button
                                                onClick={downloadFile}
                                                className={`btn btn-dark`}
                                                style={{ margin: "10px" }}
                                            >
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </button>
                                            <button
                                                onClick={deleteFile}
                                                className={`btn btn-dark`}
                                                style={{ margin: "10px" }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>

                                    ) : (<button
                                        onClick={handleUpload}
                                        className={`btn btn-dark`}
                                        style={{ margin: "10px" }}
                                    >
                                        Upload <FontAwesomeIcon icon={faArrowUp} />
                                    </button>)}
                                </div>

							)}
							

                            {selectedMenu === Menu.Timesheet && visType === VisualizationType.Month && (
								<div>
									<button
                                        onClick={downloadTimesheetReport}
                                        className={`btn btn-dark`}
                                        style={{ margin: "10px" }}
                                    >
                                        Download <FontAwesomeIcon icon={faArrowDown}/>
                                    </button>
                                </div>

                            )}

                            <div className="col">
                                <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", justifyContent: "flex-end" }}>
                                    {!isConfirmed && (
                                        <div style={{ marginLeft: "2rem" }}>
                                            <button
                                                className="btn btn-dark"
                                                onClick={() => (selectedMenu === Menu.Timesheet && onSelectAllActivities) ? onSelectAllActivities() : ((selectedMenu === Menu.Expenses && onSelectAllExpenses) ? onSelectAllExpenses() : undefined)}
                                                disabled={(selectedMenu === Menu.Timesheet && !onSelectAllActivities) || (selectedMenu === Menu.Expenses && !onSelectAllExpenses)}
                                            >
                                                Select All
                                            </button>
                                        </div>

                                    )}
                                    <div style={{ marginLeft: "2rem" }}>
                                        Weekend
                                        <span
                                            className="ms-1"
                                            style={{ fontSize: "20px" }}
                                            onClick={() => toogleWeekend()}
                                        >
                                            {weekend ?
                                                <FontAwesomeIcon icon={faToggleOn} /> :
                                                <FontAwesomeIcon icon={faToggleOff} />}
                                        </span>
                                    </div>

                                    {visType === VisualizationType.Month && !(isApproved || isLocked) &&
                                        <div style={{ marginLeft: "2rem" }}>
                                            {!isConfirmed && (
                                                <button
                                                    className="btn btn-dark"
                                                    onClick={() => onConfirmMonth()}
                                                    disabled={!isConfirmable}
                                                >
                                                    Confirm
                                                </button>
                                            )}
                                            {isConfirmed && (
                                                <button
                                                    className="btn btn-dark"
                                                    onClick={() => onDeleteConfirmMonth()}
                                                >
                                                    Change Month
                                                </button>
                                            )}
                                        </div>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className={calendarClass}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Monday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClass}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Tuesday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClass}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Wednesday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClass}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Thursday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClass}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Friday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClassWeekend}>
                            <div className="card calendar-card" >
                                <div className="card-title">
                                    Saturday
                                </div>
                            </div>
                        </div>
                        <div className={calendarClassWeekend}>
                            <div className="card calendar-card" >
                                <div className="card-title" >
                                    Sunday
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};