import React, { Component } from 'react';
import SchedulerRequestPageItemOffCanvas from './SchedulerRequestPageItemOffCanvas';
import { IRequest, RequestStatus } from '../../../Models/CalendarDay';

interface IProps {
    requestPageOffCanvasIsVisible: boolean;
    requestPageOffCanvasTitle: string;
    onClose: Function;
    visualRequest: IRequest;
    onApprove: Function;
    onReject: Function;
}

interface IState {
    isVisible: boolean;
    multipleDay: boolean;
    visualRequest: IRequest;
}

export default class RequestPageOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);

        this.state = {
            isVisible: this.props.requestPageOffCanvasIsVisible,
            multipleDay: this.props.visualRequest?.startDate?.getDate() !== this.props.visualRequest?.endDate?.getDate() ? true : false,
            visualRequest: this.props.visualRequest,
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.requestPageOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.requestPageOffCanvasIsVisible
            });
        }

        if (nextProps.visualRequest !== this.state.visualRequest) {
            this.setState({
                visualRequest: nextProps.visualRequest,
                multipleDay: nextProps.visualRequest.startDate.getDate() !== nextProps.visualRequest.endDate.getDate() ? true : false,
            })
        }
    }

    onClose() {
        this.setState({
            isVisible: false,
        });
        this.props.onClose();
    }

    onChangeData<T>(key: string, value: T | number) {

        let requestEntry = {
            ...this.state.visualRequest,
        };

        requestEntry[key] = value;

        this.setState({
            visualRequest: requestEntry,
        })
    }

    render() {
        return (
            <div>
                <SchedulerRequestPageItemOffCanvas
                    offCanvasTitle={this.props.requestPageOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    isPending={this.state.visualRequest.status === "Pending"}
                    onApprove={() => this.props.onApprove([this.state.visualRequest])}
                    onReject={() => this.props.onReject([this.state.visualRequest])}
                >
                    <>{this.state.visualRequest.account?.id !== "" && (
                        <>
                            <div className="row justify-content-start align-items-center m-0 mb-4">
                                <div className="col-10 d-flex justify-content-start align-items-center ps-0">
                                    <div className="icon-letter bg-black text-white ms-0">
                                        {this.state.visualRequest.account?.firstName?.charAt(0)}
                                    </div>
                                    <div>
                                        <p className="account-name text-black">
                                            {this.state.visualRequest.account?.firstName} {this.state.visualRequest.account?.lastName}
                                        </p>
                                        <p className="company">
                                            {this.state.visualRequest.account?.company}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <p className="requestSelectorTitle">Request type</p>
                                </div>
                                <div>
                                    <h5>
                                        {this.state.visualRequest?.absence?.description}
                                    </h5>
                                </div>
                            </div>
                        </>)}

                        {this.state.multipleDay && (
                            <section className="item-off-canvas-component">

                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "1.5vh" }}>
                                    <div className="col-6">
                                        <p className="requestSelectorTitle">
                                            From <strong>{new Date(this.state.visualRequest?.startDate).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {this.state.visualRequest?.startDate?.getDate() + "/" + (this.state.visualRequest?.startDate?.getMonth() + 1) + "/" + this.state.visualRequest?.startDate?.getFullYear()}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <p className="requestSelectorTitle">
                                            Hour
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {(this.state.visualRequest?.startDate?.getHours() < 10 ? '0' : '') + this.state.visualRequest?.startDate?.getHours() +
                                                "." +
                                                (this.state.visualRequest?.startDate?.getMinutes() < 10 ? '0' : '') + this.state.visualRequest?.startDate?.getMinutes()}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <div className="col-6">
                                        <p className="requestSelectorTitle">
                                            To <strong>{new Date(this.state.visualRequest?.endDate).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {this.state.visualRequest?.endDate?.getDate() + "/" + (this.state.visualRequest?.endDate?.getMonth() + 1) + "/" + this.state.visualRequest?.endDate?.getFullYear()}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <p className="requestSelectorTitle">
                                            Hour
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {(this.state.visualRequest?.endDate?.getHours() < 10 ? '0' : '') + this.state.visualRequest?.endDate?.getHours() +
                                                "." +
                                                (this.state.visualRequest?.endDate?.getMinutes() < 10 ? '0' : '') + this.state.visualRequest?.endDate?.getMinutes()}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                        {!this.state.multipleDay && (
                            <section className="item-off-canvas-component">
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <div className="col-6">
                                        <p className="requestSelectorTitle">
                                            <strong>{new Date(this.state.visualRequest?.startDate).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {this.state.visualRequest?.startDate?.getDate() + "/" + (this.state.visualRequest?.endDate?.getMonth() + 1) + "/" + this.state.visualRequest?.startDate?.getFullYear()}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <p className="requestSelectorTitle">
                                            From
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {(this.state.visualRequest?.startDate?.getHours() < 10 ? '0' : '') + this.state.visualRequest?.startDate?.getHours() +
                                                "." +
                                                (this.state.visualRequest?.startDate?.getMinutes() < 10 ? '0' : '') + this.state.visualRequest?.startDate?.getMinutes()}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <p className="requestSelectorTitle">
                                            To
                                        </p>
                                        <div style={{ fontWeight: "bold" }}>
                                            {(this.state.visualRequest?.endDate?.getHours() < 10 ? '0' : '') + this.state.visualRequest?.endDate?.getHours() +
                                                "." +
                                                (this.state.visualRequest?.endDate?.getMinutes() < 10 ? '0' : '') + this.state.visualRequest?.endDate?.getMinutes()}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                        <section className="item-off-canvas-component">
                            <p className="requestSelectorTitle">Note</p>
                            <p>{this.state.visualRequest.note}</p>
                        </section>

                        <hr />

                        <section className="item-off-canvas-component">
                            <p className="requestSelectorTitle">Status</p>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "0.4rem" }}>

                                <div className="col-2" style={{
                                    borderRadius: "100%", width: "10px", height: "10px", backgroundColor: RequestStatus[this.state.visualRequest.status as keyof typeof RequestStatus], display: "table-cell", marginRight: "0.5rem"
                                }}></div>

                                <div className="col-10">
                                    <p style={{ display: "table-cell", fontSize: "small", fontWeight: "bold" }}>{this.state.visualRequest.status}</p>
                                </div>

                            </div>
                        </section>

                        <section className="item-off-canvas-component">
                            <p className="requestSelectorTitle">Note Scheduler</p>
                            {this.state.visualRequest.status === "Pending" ?
                                <textarea
                                    className="custom-color-border"
                                    style={{ width: "100%" }}
                                    rows={5}
                                    onChange={(e) => this.onChangeData<string>("schedulerNote", e.target.value)}
                                    value={this.state.visualRequest.schedulerNote ? this.state.visualRequest.schedulerNote : ""}
                                    required
                                /> : <p>{this.state.visualRequest?.schedulerNote}</p>
                            }
                        </section>
                    </>
                </SchedulerRequestPageItemOffCanvas>
            </div>)
    }
}