import BaseService from "./BaseService";
import {ActivityModel} from "../Models/ActivityModel";
import { webApiActivities } from "../AppSettings";
import { LoadingHandling } from "../Models/CalendarDay";

export default class ActivityService extends BaseService<ActivityModel> {
	
	constructor() {
		super(webApiActivities);
	};

	// body: JSON.stringify(data)

	public getAllAssigned(callback: (models: ActivityModel[]) => void) {
		this.getAll(callback, `${this.endpoint}/assigned`);
	}

	public getRelatedAssignment(toDate: Date, callback?: React.Dispatch<React.SetStateAction<ActivityModel[]>>) {
		LoadingHandling.showLoader()
		this.getAll(callback, `${this.endpoint}/related/assignment/${toDate?.getFullYear()}/${(toDate.getMonth() + 1)}`)
	}

	public getRelated(callback?: React.Dispatch<React.SetStateAction<ActivityModel[]>> | ((model: any[]) => void), userId?: string) {
		LoadingHandling.showLoader()
		this.getAll(callback, `${this.endpoint}/related${userId ? `?userId=${userId}` : ""}`)
	}
}