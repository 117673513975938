/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { AccountModel } from "../../Models/AccountModel";
import AccountService from "../../Services/AccountService";
import BasePageContent from "../AegisCommon/BasePageContent";
import "../AegisCommon/CustomGrid/CustomGrid.css"
import { ActivityModel } from "../../Models/ActivityModel";
import ActivityService from "../../Services/ActivityService";
import CustomGrid, { GridCustomColumnProps } from "../AegisCommon/CustomGrid/CustomGrid";
import { SelectionMode } from "../AegisCommon/Utils/SelectionMode";
import ActivityUserService from "../../Services/ActivityUserService";
import { ActivityUserModel } from "../../Models/ActivityUserModel";
import { initializeIcons } from "@fluentui/react";
import { setIconOptions } from '@fluentui/react/lib/Styling';
import { SearchFilterHeader } from "../AegisCommon/CustomGrid/Headers/SearchFilterHeader";
import DistinctFilterHeader from "../AegisCommon/CustomGrid/Headers/DistinctFIlterHeader/DistinctFilterHeader";
import { MonthFilterHeader } from "../AegisCommon/CustomGrid/Headers/MonthFilterHeader";
import { LoadingHandling } from "../../Models/CalendarDay";

type ProjectAssignmentPageProps = {
	currentUser: AccountModel
}

export default function ProjectAssignmentPage(props: ProjectAssignmentPageProps) {
	const [accountList, setAccountList] = useState<AccountModel[]>([]);
	const [activityList, setActivityList] = useState<ActivityModel[]>([]);
	const [activityUserList, setActivityUserList] = useState<ActivityUserModel[]>([]);
	const [selectedActivity, setSelectedActivity] = useState<ActivityModel | undefined>(undefined)
	const [selectedDate, setSelectedDate] = useState<Date>(new Date())
	const accountService = new AccountService();
	const activityService = new ActivityService();
	const activityUserService = new ActivityUserService();
	initializeIcons();

	// Suppress icon warnings.
	setIconOptions({
	disableWarnings: true
	});
	
	useEffect(() => {
		setSelectedActivity(undefined);
		setActivityUserList([]);
		accountService.getAllActive(selectedDate.getFullYear(), selectedDate.getMonth() + 1, setAccountList);
		activityService.getRelatedAssignment(selectedDate, setActivityList);
	}, [props.currentUser])

	useEffect(() => {
		accountService.getAllActive(selectedDate.getFullYear(), selectedDate.getMonth() + 1, setAccountList); 
		activityService.getRelatedAssignment(selectedDate, setActivityList);
	}, [selectedDate]);

	useEffect(() => {
		LoadingHandling.showLoader()
		if(selectedActivity) {
			activityUserService.getAllActivityUsers(selectedActivity.id, setActivityUserList);
		}
	}, [selectedActivity]);
	
	function onAssignUsers(selection: AccountModel[]) {
		activityUserService.assignAll(selectedActivity!.id, selection.map(x => x.id), reloadActivityUserList); 
	}

	function reloadActivityUserList(): void {
		if (selectedActivity) {
			activityUserService.getAllActivityUsers(selectedActivity?.id, setActivityUserList);
		}
	}

	return(
		<BasePageContent>
			{/* Activities grid. */}
			<CustomGrid<ActivityModel, ActivityService> 
			service={activityService}
			itemList={activityList}
			maxWidth={"25%"}
			onSelection={(current, selection) => { if (selection && selection.length > 0) {setSelectedActivity(current);} else {setSelectedActivity(undefined)} }}
			selectionMode={SelectionMode.Single}
			headers={[
				{
					key: "description" as keyof ActivityModel,
					component: <SearchFilterHeader  placeholder="Search activities"/>, // <div style={{paddingLeft: "8px"}}>Activities</div>,
					columnWidth: "100%",
				},
				{
					columnWidth: 150,
					component: <MonthFilterHeader onSelect={(date) => {setSelectedDate(date)}}/>,
					columnCustomComponent: <CustomCompanyColumn />,
				},
				{
					key: "company" as keyof ActivityModel,
					columnWidth: 150,
					columnCustomComponent: <CustomCompanyColumn />,
					defaultHeader: false
				}
			]}/>

			{/* Accounts grid. */}
			<CustomGrid<AccountModel, AccountService>
			service={accountService}
			itemList={accountList.filter(account => !activityUserList.find(activityUser=> activityUser.user.id === account.id))}
			onSave={onAssignUsers}
			onSaveConstrain={() => {return selectedActivity ? true : false}}
			selectionMode={SelectionMode.Multiple}
			maxWidth={"25%"}
			headers={[
				{
					customFilter: (value, model) => { return ((`${model.firstName} ${model.lastName}`.toLocaleLowerCase()).includes(value.toLowerCase())); },
					key: "firstName" as keyof AccountModel,
					component: <SearchFilterHeader placeholder="Search users"/>,
					columnCustomComponent: <CustomFullNameColumn />,
					// columnWidth: 200,
				},
				{
					key: "type" as keyof AccountModel,
					component: <DistinctFilterHeader<AccountModel>/>,
					columnWidth: 120,
				},
				{
					key: "company" as keyof AccountModel,
					component: <DistinctFilterHeader<AccountModel>/>,
					columnCustomComponent: <CustomCompanyColumn />,
					columnWidth: 130,
				},
			]}
			/>
			
			{/* Activity accounts grid. */}
			<CustomGrid<ActivityUserModel, ActivityUserService> 
			service={activityUserService} 
			itemList={activityUserList}
			maxWidth={"50%"}
			onDelete={(element: ActivityUserModel) => {
				if (selectedActivity && element.user) {
					activityUserService.unassign(selectedActivity?.id, element.user.id, reloadActivityUserList);
				}
			}}
			headers={[
				{
					key: "user" as keyof ActivityUserModel,
					columnCustomComponent: <CustomJoinedAccountColumn />,
				},
				{
					key: "user" as keyof ActivityUserModel,
					customLabel: "Type",
					columnCustomComponent: <CustomJoinedAccountTypeColumn />,
					columnWidth: 200,
				},
				{
					key: "user" as keyof ActivityUserModel,
					customLabel: "Company",
					columnCustomComponent: <CustomJoinedAccountCompanyColumn />,
					columnWidth: 200,
				},
				{
					key: "createdAt" as keyof ActivityUserModel,
					columnCustomComponent: <CustomDateColumn />,
					columnWidth: 150,
				},
			]}
			/>
		</BasePageContent>
	);
}

function CustomJoinedAccountColumn(props: GridCustomColumnProps<ActivityUserModel>) {
	return (
		<>
			{`${props.model?.user.firstName} ${props.model?.user.lastName}` ?? ""}
		</>
	);
}

function CustomJoinedAccountTypeColumn(props: GridCustomColumnProps<ActivityUserModel>) {
	return (
		<>
			{`${props.model?.user.type}` ?? ""}
		</>
	);
}

function CustomJoinedAccountCompanyColumn(props: GridCustomColumnProps<ActivityUserModel>) {
	return (
		<>
			{`${(props.model?.user.company ?? "").split(" ")[0]}` ?? ""}
		</>
	);
}


function CustomDateColumn(props: GridCustomColumnProps<ActivityUserModel>) {
	return (
		<>
			{`${new Date(props.model?.createdAt ?? "").toLocaleDateString()}` ?? ""}
		</>
	);
}

function CustomCompanyColumn(props: GridCustomColumnProps<AccountModel | ActivityModel>) {
	return (
		<>
			{props.model?.company?.split(" ")[0] ?? ""}
		</>
	);
}

function CustomFullNameColumn(props: GridCustomColumnProps<AccountModel>) {
	return (
		<>
			{`${props.model?.firstName ?? ""} ${props.model?.lastName}`}
		</>
	);
}
