import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    data: any;
    selectedData: number;
    onSelect: Function;
    width?: string;
}

interface IState {
    keys: string[];
    selectedData: number;
    selectedText: string;
    isOpen: boolean
}

export default class DropDownSelector extends Component<IProps, IState> {
    monthWeekSelectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        
        this.monthWeekSelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            keys: Object.keys(this.props.data).filter(key => isNaN(Number(key))),
            selectedData: this.props.selectedData,
            selectedText: this.props.data[this.props.selectedData],
            isOpen: false
        };
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }

        if (this.state.selectedData !== this.props.selectedData) {
            this.setState({ selectedData: this.props.selectedData, selectedText: this.props.data[this.props.selectedData] })
        }
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.monthWeekSelectorRef && !this.monthWeekSelectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    onSelectData(selectedData: number) {
        if (selectedData !== this.state.selectedData) {
            this.setState({
                selectedData: selectedData,
                selectedText: this.props.data[selectedData],
                isOpen: false
            })
            this.props.onSelect(selectedData)
        } else {
            this.setState({ isOpen: false });
        }
    }

    render() {
        return (
            <div
                className="mx-2"
                ref={this.monthWeekSelectorRef}
            >
                <button
                    className="btn btn-dark btn-select-day d-flex justify-content-between"
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    style={{ width: this.props.width ? this.props.width : "190px" }}
                >
                    <p className="mx-2 mb-0">{this.state.selectedText}</p>
                    <p className="mx-2 mb-0">
                        {<FontAwesomeIcon icon={faChevronDown} />}
                    </p>
                </button>
                {this.state.isOpen && (
                    <div className="row mx-0" style={{ zIndex: 100, position: "absolute", width: this.props.width ? this.props.width : "190px", backgroundColor: "#f5f6f8", border: "1px solid black", borderRadius: "5px" }}>
                        {this.state.keys.map(key => (
                            <div
                                className="col-12"
                                key={key}
                            >
                                <button
                                    className="btn btn-dark-outline col-12"
                                    onClick={() => this.onSelectData(this.props.data[key])}
                                >
                                    {key}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}
