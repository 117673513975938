import RequestAccessToken from "./Components/Auth/RequestAccessToken";
import { IAccount, IActivity, IActivityEntry, IExpense, IExpenseEntry, IGoodsReceipt, IJobOrderCardPlan, IPostAbsenceRequest, IPostActivityEntry, IPostExpenseEntry, IPostJobOrderCardPlan, IPostJobOrderCardPlanSchedule, IRequest, ISales, IUpdateActivityAccountTemplate, IUpdateExpenseAccountTemplate, IUpdateSale, IFactoryCalendarDate, IPostQueueDownloadRequest, ErrorHandling, RequestHandler } from "./Models/CalendarDay";
import { DownloadRequestModel } from "./Models/DownloadRequestTypeModel";

export const server = process.env.REACT_APP_SERVER;
export const webApi: string = `${server}/api`;
export const webApiVersion: string = 'v1';

export const webApiAccounts: string = `${webApi}/${webApiVersion}/accounts`;
export const webApiFeature: string = `${webApi}/${webApiVersion}/feature`;
export const webApiActivities: string = `${webApi}/${webApiVersion}/activities`;
export const webApiActivityUsers: string = `${webApi}/${webApiVersion}/activityUsers`;
export const webApiActivityEntries: string = `${webApi}/${webApiVersion}/activityEntries`;
export const webApiExpenses: string = `${webApi}/${webApiVersion}/expenses`;
export const webApiExpenseEntries: string = `${webApi}/${webApiVersion}/expenseEntries`;
export const webApiLocations: string = `${webApi}/${webApiVersion}/locations`;
export const webApiConfigurations: string = `${webApi}/${webApiVersion}/configurations`;
export const webApiJobOrderCard: string = `${webApi}/${webApiVersion}/jobordercards`;
export const webApiAbsense: string = `${webApi}/${webApiVersion}/absences`;
export const webApiEntriesMonthStatus: string = `${webApi}/${webApiVersion}/entriesMonthStatus`;
export const webApiRequest: string = `${webApi}/${webApiVersion}/request`;
export const webApiAbsenceRequest: string = `${webApi}/${webApiVersion}/absenceRequests`;
export const webApiSales: string = `${webApi}/${webApiVersion}/billing`;
export const webApiGoods: string = `${webApi}/${webApiVersion}/goodsReceipt`;
export const webApiQueue: string = `${webApi}/${webApiVersion}/queueDownload`;
export const webApiFactoryCalendarDate: string = `${webApi}/${webApiVersion}/factoryCalendarDate`;

export let Bearer: string;
export function setBearer(bearer: string): void {
    Bearer = bearer;
}

export async function getBearer() {
    return await RequestAccessToken();
}

export let HolidayCalendar: IFactoryCalendarDate[] = [];
export function setHolidayCalendar(holidayCalendar: IFactoryCalendarDate[]): void {
    HolidayCalendar = holidayCalendar;
}

export function isHolidayToast(day: Date) {
    if (HolidayCalendar.some(x => isSameDay(new Date(day), new Date(x.date)))) {
        ErrorHandling.warningToast("Stai inserendo straordinari", undefined, "overtime");
    }

    function isSameDay(day1: Date, day2: Date): boolean {
        return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
    }
}

export function isHoliday(day: Date) {
    return HolidayCalendar.some(x => isSameDay(new Date(day), new Date(x.date)))

    function isSameDay(day1: Date, day2: Date): boolean {
        return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
    }
}

//Set Header
export async function getJSONHeaders() {
    let jsonHeaders = new Headers();
    jsonHeaders.append("Authorization", `Bearer ${await getBearer()}`);
    jsonHeaders.append("Content-Type", "application/json");
    jsonHeaders.append("Accept", "application/json");
    return jsonHeaders;
}

export let headers = new Headers();
export function setHeaders() {
    headers.append("Authorization", Bearer);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
}

//Set account
export let Account: IAccount;
export function setAccount(account: IAccount) {
    Account = account;
}

export function isNullOrWhiteSpace(str: string) {
    return str === undefined || str === null || str.trim() === "";
}

export async function getSwitchCompany(companyId: string) {  
    let account : IAccount;
    await fetch(webApiAccounts+"/switchCompany?companyId="+companyId, {
        method: 'GET',
        //body: JSON.stringify(payloadActivityEntryArray),
        headers: await getJSONHeaders()
    }).then(x=>  getMe().then((res: IAccount) => account = res))
    return new Promise<IAccount>((resolve) => { resolve(account) });
}

export async function getMe() {  
    let account : IAccount;     
    await fetch(webApiAccounts+"/me", {
        method: 'GET',
        //body: JSON.stringify(payloadActivityEntryArray),
        headers: await getJSONHeaders()
    }).then(x => x.json()).then((res: IAccount) => account = res);
    return new Promise<IAccount>((resolve) => { resolve(account) });
}

//Function for Activity Entry Web Apis

export async function postActivityEntryArray(activityEntryArray: IActivityEntry[]): Promise<IActivityEntry[]> {
    let responseActivityEntryArray: IActivityEntry[] = [];

    let payloadActivityEntryArray: IPostActivityEntry[] = activityEntryArray.map(activityEntry => {
        let date = new Date(activityEntry.day);
        return {
            accountId: activityEntry.account.id,
            activityId: activityEntry.activity.id,
            locationId: activityEntry.location?.id,
            absenceId: activityEntry.absence?.id,
            year: date.getFullYear(),
            month: (date.getMonth() + 1),
            day: date.getDate(),
            hours: activityEntry.hours,
            note: activityEntry.note ?? "",
            hasAttachment: activityEntry.hasAttachment
        }
    })

    await fetch(webApiActivityEntries, {
        method: 'POST',
        body: JSON.stringify(payloadActivityEntryArray),
        headers: await getJSONHeaders()
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: IActivityEntry[]) => responseActivityEntryArray = res);

    return new Promise<IActivityEntry[]>((resolve) => { resolve(responseActivityEntryArray); });
}

export async function putActivityEntryArray(activityArray: IActivityEntry[]): Promise<IActivityEntry[]> {
    let updatedIdArray: IActivityEntry[] = [];

    let entryArray: IPostActivityEntry[] = activityArray.map(activityEntryArray => {
        let date = new Date(activityEntryArray.day);
        return {
            id: activityEntryArray.id,
            accountId: activityEntryArray.account.id,
            activityId: activityEntryArray.activity.id,
            absenceId: activityEntryArray.absence?.id,
            year: date.getFullYear(),
            month: (date.getMonth() + 1),
            day: date.getDate(),
            hours: activityEntryArray.hours,
            locationId: activityEntryArray.location?.id,
            note: activityEntryArray.note,
            hasAttachment: activityEntryArray.hasAttachment,
        }
    })

    await fetch(webApiActivityEntries, {
        method: 'PUT',
        body: JSON.stringify(entryArray),
        headers: await getJSONHeaders()
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: IActivityEntry[]) => updatedIdArray = res);
    return new Promise<IActivityEntry[]>((resolve) => { resolve(updatedIdArray) });
}

export async function deleteActivityEntryArray(activity: IActivityEntry[]): Promise<string[]> {
    let deletedIdArray: string[] = [];

    let idsToDeleteArray: string[] = activity.map(x => x.id);

    await fetch(webApiActivityEntries, {
        method: 'DELETE',
        body: JSON.stringify(idsToDeleteArray),
        headers: await getJSONHeaders()
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: string[]) => deletedIdArray = res);

    return new Promise<string[]>((resolve) => { resolve(deletedIdArray) });
}

//Function for Expense Entry Web Apis

export async function postExpenseEntryArray(expenseEntryArray: IExpenseEntry[]): Promise<IExpenseEntry[]> {
    let responseExpenseEntryArray: IExpenseEntry[] = [];

    let payloadExpenseEntryArray: IPostExpenseEntry[] = expenseEntryArray.map(expenseEntry => {
        return {
            accountId: expenseEntry.account.id,
            activityId: expenseEntry.activity.id,
            year: expenseEntry.day.getFullYear(),
            month: (expenseEntry.day.getMonth() + 1),
            day: expenseEntry.day.getDate(),
            note: expenseEntry.note,
            receiptNumber: expenseEntry.receiptNumber,
            amount: expenseEntry.amount,
            amountPerDistanceUnit: expenseEntry.amountPerDistanceUnit,
            arrival: expenseEntry.arrival,
            departure: expenseEntry.departure,
            distance: expenseEntry.distance,
            expenseId: expenseEntry.expense.id,
        }
    })

    await fetch(webApiExpenseEntries, {
        method: 'POST',
        body: JSON.stringify(payloadExpenseEntryArray),
        headers: await getJSONHeaders(),
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: IExpenseEntry[]) => responseExpenseEntryArray = res);

    return new Promise<IExpenseEntry[]>((resolve) => { resolve(responseExpenseEntryArray); });
}

export async function putExpenseEntryArray(expense: IExpenseEntry[]): Promise<IExpenseEntry[]> {
    let updatedIdArray: IExpenseEntry[] = [];
    let expenseArray: IPostExpenseEntry[] = expense.map(expenseEntryArray => {
        expenseEntryArray.day = new Date(expenseEntryArray.day);
        return {
            id: expenseEntryArray.id,
            accountId: expenseEntryArray.account.id,
            activityId: expenseEntryArray.activity.id,
            year: expenseEntryArray.day.getFullYear(),
            month: (expenseEntryArray.day.getMonth() + 1),
            day: expenseEntryArray.day.getDate(),
            note: expenseEntryArray.note,
            amount: expenseEntryArray.amount,
            receiptNumber: expenseEntryArray.receiptNumber,
            amountPerDistanceUnit: expenseEntryArray.amountPerDistanceUnit,
            arrival: expenseEntryArray.arrival,
            departure: expenseEntryArray.departure,
            distance: expenseEntryArray.distance,
            expenseId: expenseEntryArray.expense.id,
        }
    })

    await fetch(webApiExpenseEntries, {
        method: 'PUT',
        body: JSON.stringify(expenseArray),
        headers: await getJSONHeaders()
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: IExpenseEntry[]) => updatedIdArray = res);
    return new Promise<IExpenseEntry[]>((resolve) => { resolve(updatedIdArray) });
}

export async function deleteExpenseEntryArray(activity: IExpenseEntry[]): Promise<string[]> {
    let deletedIdArray: string[] = [];

    let idsToDeleteArray: string[] = activity.map(x => x.id);

    await fetch(webApiExpenseEntries, {
        method: 'DELETE',
        body: JSON.stringify(idsToDeleteArray),
        headers: await getJSONHeaders()
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: string[]) => deletedIdArray = res);

    return new Promise<string[]>((resolve) => { resolve(deletedIdArray) });
}

//Function for Activity Web Apis

export async function putActivitySetTemplate(activity: IActivity, account: IAccount): Promise<boolean> {
    let result: boolean;

    let updateActivityAccountTemplate: IUpdateActivityAccountTemplate = {
        isFavourite: activity.isFavourite,
        color: activity.color
    };

    await fetch(`${webApiActivities}/${activity.id}/templates/${account.id}`, {
        method: 'PUT',
        body: JSON.stringify(updateActivityAccountTemplate),
        headers: await getJSONHeaders()
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })
    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function putExpenseSetTemplate(expense: IExpense, account: IAccount): Promise<boolean> {
    let result: boolean;

    let updateExpenseAccountTemplate: IUpdateExpenseAccountTemplate = {
        isFavourite: expense.isFavourite,
        color: expense.color
    };

    await fetch(`${webApiExpenses}/${expense.id}/templates/${account.id}`, {
        method: 'PUT',
        body: JSON.stringify(updateExpenseAccountTemplate),
        headers: await getJSONHeaders()
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })
    return new Promise<boolean>((resolve) => { resolve(result) });
}


export async function putApproveMonth(accountId: string, year: number, month: number): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiAccounts}/${accountId}/entriesMonthStatus/${year}/${month}/approve`, {
        method: 'PUT',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function deleteApproveMonth(accountId: string, year: number, month: number): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiAccounts}/${accountId}/entriesMonthStatus/${year}/${month}/approve`, {
        method: 'DELETE',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function putConfirmMonth(accountId: string, year: number, month: number): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiAccounts}/${accountId}/entriesMonthStatus/${year}/${month}/confirm`, {
        method: 'PUT',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function deleteConfirmMonth(accountId: string, year: number, month: number): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiAccounts}/${accountId}/entriesMonthStatus/${year}/${month}/confirm`, {
        method: 'DELETE',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function deleteLock(accountId: string, year: number, month: number): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiAccounts}/${accountId}/entriesMonthStatus/${year}/${month}/unlock`, {
        method: 'DELETE',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}
//function for JobOrderPlan

export async function postJobOrderCardPlan(plan: IJobOrderCardPlan): Promise<boolean> {
    let result: boolean;

    let postObject: IPostJobOrderCardPlan = {
        accountId: plan.accountId,
        activityId: plan.activityId,
        schedules: plan.schedules.map((schedule: IPostJobOrderCardPlanSchedule) => {
            return {
                accountId: schedule.accountId,
                year: schedule.year,
                month: schedule.month,
                days: schedule.days,
            }
        })
    }
    await fetch(`${webApiJobOrderCard}/plan`, {
        method: 'POST',
        body: JSON.stringify(postObject),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function postQueueDownloadRequestOffCanvas(postObject: IPostQueueDownloadRequest): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiQueue}`, {
        method: 'POST',
        body: JSON.stringify(postObject),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function deleteQueueDownloadRequest(DownloadRequest: DownloadRequestModel): Promise<boolean> {
    let result: boolean;

    let idToDelete = DownloadRequest.id;

    await fetch(`${webApiQueue + "/" + idToDelete}`, {
        method: 'DELETE',
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }

    })

    return new Promise<boolean>((resolve) => { resolve(result) });
}

// Function for AbsenceRequest

export async function postAbsenceRequestArray(absenceRequestArray: IRequest[]): Promise<IRequest[]> {

    let responseAbsenceRequestArray: IRequest[] = [];

    let payloadAbsenceRequestArray: IPostAbsenceRequest[] = absenceRequestArray.map(requestEntry => {
        return {
            absenceId: requestEntry.absence.id,
            startDate: requestEntry.startDate,
            endDate: requestEntry.endDate,
            note: requestEntry.note
        }
    })

    await fetch(webApiAbsenceRequest, {
        method: 'POST',
        body: JSON.stringify(payloadAbsenceRequestArray),
        headers: await getJSONHeaders(),
    }).then(response => { if (!response.ok) { throw new Error(response.statusText) } return response.json() }).then((res: IRequest[]) => responseAbsenceRequestArray = res);

    return new Promise<IRequest[]>((resolve) => { resolve(responseAbsenceRequestArray); });
}

export async function approveAbsenceRequests(absenceRequest: IRequest[]): Promise<IRequest[]> {
    let result: IRequest[];
    let payloadApproveAbsenceRequestArray = absenceRequest.map(requestEntry => {
        return {
            id: requestEntry.id,
            schedulerNote: requestEntry.schedulerNote
        }
    })

    await fetch(`${webApiAbsenceRequest}/approve`, {
        method: 'POST',
        body: JSON.stringify(payloadApproveAbsenceRequestArray),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).then((res: IRequest[]) => result = res);

    return new Promise<IRequest[]>((resolve) => { resolve(result) });
}

export async function rejectAbsenceRequests(absenceRequest: IRequest[]): Promise<IRequest[]> {
    let result: IRequest[];
    let payloadRejectAbsenceRequestArray = absenceRequest.map(requestEntry => {
        return {
            id: requestEntry.id,
            schedulerNote: requestEntry.schedulerNote
        }
    })

    await fetch(`${webApiAbsenceRequest}/reject`, {
        method: 'POST',
        body: JSON.stringify(payloadRejectAbsenceRequestArray),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).then((res: IRequest[]) => result = res);

    return new Promise<IRequest[]>((resolve) => { resolve(result) });
}

export async function cancelAbsenceRequestAsync(absenceRequestIds: string[]): Promise<IRequest[]> {
    let result: IRequest[];

    await fetch(`${webApiAbsenceRequest}/cancel`, {
        method: 'POST',
        body: JSON.stringify(absenceRequestIds),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).then((res: IRequest[]) => result = res);

    return new Promise<IRequest[]>((resolve) => { resolve(result) });
}

export async function putSale(sale: ISales): Promise<boolean> {
    let result: boolean;

    let updateSaleTemplate: IUpdateSale = {
        invoiceId: sale.invoiceId,
        invoiceRowNumber: sale.invoiceRowNumber,
        quantity: sale.quantity,
        value: sale.value,
        noteFromCustomer: sale.noteFromCustomer,
        noteToCustomer: sale.noteToCustomer
    };

    await fetch(`${webApiSales}`, {
        method: 'PUT',
        body: JSON.stringify(updateSaleTemplate),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })
    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function emitSales(sale: ISales[]): Promise<ISales[]> {
    let result: ISales[] = [];

    let payLoadEmitSalesArray = sale.map(emitSale => {
        return {
            invoiceId: emitSale.invoiceId,
            invoiceRowNumber: emitSale.invoiceRowNumber,
        }
    });

    await fetch(`${webApiSales}/emit`, {
        method: 'PUT',
        body: JSON.stringify(payLoadEmitSalesArray),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
    })

    return new Promise<ISales[]>((resolve) => { resolve(result) });
}

export async function putGoodsReceipt(goods: IGoodsReceipt, activityId: string): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiGoods}/${activityId}`, {
        method: 'PUT',
        body: JSON.stringify(goods),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })
    return new Promise<boolean>((resolve) => { resolve(result) });
}

export async function putApproveGoodsReceipt(goods: IGoodsReceipt[], activityId: string): Promise<boolean> {
    let result: boolean;

    await fetch(`${webApiGoods}/${activityId}/approve`, {
        method: 'PUT',
        body: JSON.stringify(goods),
        headers: await getJSONHeaders(),
    }).then(response => {
        if (!response.ok) {
            result = false;
            throw new Error(response.statusText);
        } else {
            result = true;
        }
    })
    return new Promise<boolean>((resolve) => { resolve(result) });
}