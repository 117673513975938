import React, { Component } from 'react';
import GoodsReceiptPageTable from './GoodsReceiptPageTable';
import GoodsReceiptPageOffCanvas from './GoodsReceiptPageOffCanvas';
import { editMode, IActivity, IGoodsReceipt, RequestHandler, ErrorHandling } from '../../Models/CalendarDay';
import { getJSONHeaders, putApproveGoodsReceipt, putGoodsReceipt, webApiActivities, webApiGoods } from '../../AppSettings';
import { toast } from "react-toastify";

interface IProps { }

interface IState {
    goodsReceipts: IGoodsReceipt[];
    multipleEmit: IGoodsReceipt[];
    visualGoodsReceipt: IGoodsReceipt;
    resetField: boolean;
    isVisibleModifyGoodsReceipt: boolean;
    editMode: editMode;
    isVisibleActivity: boolean;
    activityList: IActivity[];
    activity: IActivity | null,
}

export default class GoodsReceiptPage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.successToast = this.successToast.bind(this);
        this.errorToast = this.errorToast.bind(this);
        this.onShowGoodsReceipt = this.onShowGoodsReceipt.bind(this);
        this.onSelectedGoodsReceipt = this.onSelectedGoodsReceipt.bind(this);
        this.onApprovePress = this.onApprovePress.bind(this);
        this.onCloseModifyGoodsReceipt = this.onCloseModifyGoodsReceipt.bind(this);
        this.onAddActivity = this.onAddActivity.bind(this);
        this.onCloseSelectActivity = this.onCloseSelectActivity.bind(this);
        this.onSelectedActivity = this.onSelectedActivity.bind(this)
        this.onChangeGoodsReceipt = this.onChangeGoodsReceipt.bind(this);

        this.state = {
            goodsReceipts: [],
            multipleEmit: [],
            resetField: false,
            isVisibleModifyGoodsReceipt: false,
            visualGoodsReceipt: {} as IGoodsReceipt,
            editMode: editMode.None,
            isVisibleActivity: false,
            activityList: [],
            activity: null
        }
    }

    onShowGoodsReceipt(goodsReceipt: IGoodsReceipt) {
        this.setState({
            visualGoodsReceipt: goodsReceipt,
            isVisibleModifyGoodsReceipt: true,
            editMode: editMode.None
        })
        document.body.style.overflowY = "hidden";
    }

    async loadGoodsReceipt() {

        RequestHandler.requestSend();

        if (this.state.activity !== null) {

            let query = webApiGoods + "/" + this.state.activity.id;
            fetch(query, {
                method: "GET",
                headers: await getJSONHeaders(),
            })
                .then(ErrorHandling.handleError)
                .then(resGoods => resGoods.json())
                .then((resultGoods) => {
                    this.setState({
                        goodsReceipts: resultGoods
                    })
                    RequestHandler.responseReceived("");

                })
                .catch(error => {
                    RequestHandler.badRequestReceived(error, "Somethig went wrong with loading goods receipt");
                });
        }
    }

    async loadActivities() {
        let queryActivities = `${webApiActivities}/related`;

        fetch(queryActivities, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then((resultAct) => {

                this.setState({
                    activityList: resultAct
                })
                RequestHandler.responseReceived("");

            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Somethig went wrong with loading activities");
            });
    }

    onSelectedGoodsReceipt(selectedToEmit: IGoodsReceipt[]) {
        this.setState({ multipleEmit: selectedToEmit })
    }

    async onChangeGoodsReceipt(goods: IGoodsReceipt) {

        RequestHandler.requestSend();

        if (this.state.activity != null) {
            let response = await putGoodsReceipt(goods, this.state.activity.id).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

            if (response) {
                this.loadGoodsReceipt()
                RequestHandler.responseReceived("Change successful");
            }
        }

        RequestHandler.responseReceived("");
    }

    onCloseModifyGoodsReceipt() {
        this.setState({ isVisibleModifyGoodsReceipt: false });
        document.body.style.overflowY = "auto";
    }

    async onApprovePress(elemToEmit?: IGoodsReceipt) {

        let elemToApprove: IGoodsReceipt[] = elemToEmit ? [elemToEmit] : this.state.multipleEmit;

        if (this.state.activity != null) {
            let response = await putApproveGoodsReceipt(elemToApprove, this.state.activity.id).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (response) {
                RequestHandler.responseReceived("Approve successful");
            }
        }

        this.setState({ multipleEmit: [] })
    }

    onAddActivity() {
        this.setState({ isVisibleActivity: true });
        document.body.style.overflowY = "hidden";
    }

    onCloseSelectActivity() {
        this.setState({ isVisibleActivity: false });
        document.body.style.overflowY = "auto";
    }

    onSelectedActivity(activity: IActivity) {
        let act = this.state.activityList.find(x => x.id === activity.id) ?? {} as IActivity

        this.setState({
            activity: act,
        }, () => this.loadGoodsReceipt())
        this.onCloseSelectActivity();
    }

    componentDidMount() {
        this.loadActivities()
    }

    errorToast(msg: string) {
        toast.error(msg, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    }

    successToast(msg: string) {
        toast.success(msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    }

    render() {
        return (
            <div style={{ margin: "50px 0px 0px 0px" }}>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-4 d-flex">
                            <h3 style={{ fontWeight: "bold" }}>Goods Receipt</h3>
                        </div>
                        <div className="col-8 d-flex" style={{ justifyContent: "flex-end" }}>
                            <div>
                                <button type="button" className="btn btn-outline-dark" style={{ fontWeight: "bold", padding: "10px" }} onClick={this.onAddActivity}>Select Activity</button>
                                <div className="col-3">
                                    {/* <JobOrderCardActivityOffCanvas
                                        jobOrderOffCanvasIsVisible={this.state.isVisibleActivity}
                                        activityList={this.state.activityList}
                                        jobOrderOffCanvasTitle="Select activity"
                                        onClose={this.onCloseSelectActivity}
                                        onAddActivity={this.onSelectedActivity}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    {this.state.goodsReceipts.length > 0 ? (
                        <div>
                            <GoodsReceiptPageTable
                                resetField={this.state.resetField}
                                goodsReceiptData={this.state.goodsReceipts}
                                goodsReceiptDetail={this.onShowGoodsReceipt}
                                onGoodsReceiptSelected={this.onSelectedGoodsReceipt}
                                onEmitPress={this.onApprovePress}
                            />
                        </div>
                    ) : (<div><p style={{ fontWeight: "bold" }}>No Goods Receipt</p></div>)}
                    <br />
                    {this.state.multipleEmit.length > 0 && (
                        <button type="button" className="btn btn-outline-dark" onClick={() => this.onApprovePress()}>
                            Approve Invoices ({this.state.multipleEmit.length})
                        </button>
                    )}

                    <GoodsReceiptPageOffCanvas
                        requestPageOffCanvasIsVisible={this.state.isVisibleModifyGoodsReceipt}
                        requestPageOffCanvasTitle={"Invoice"}
                        onClose={this.onCloseModifyGoodsReceipt}
                        editMode={this.state.editMode}
                        onChangeGoodsReceipt={this.onChangeGoodsReceipt}
                        visualGoodsReceipt={this.state.visualGoodsReceipt}
                    />
                </div>
            </div>
        )
    }
}