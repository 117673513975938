import React, { CSSProperties, PropsWithChildren, useState } from "react";

type MStatusProps = {
	style?: CSSProperties;
	monthStatus: number;
	isApproved: boolean | null;
	isConfirmed: boolean | null;
	isLocked: boolean | null;
	isError: boolean | null;
} & PropsWithChildren;

enum MonthStatus {
	Incoplete,
	Completed,
	Overtime,
	Confirmed,
	Approved,
	Locked,
	Error
};

const MonthStatuses = [
	{ label: "Month incomplete", color: "#DC3545" },
	{ label: "Month completed", color: "#198754" },
	{ label: "Month completed with overtime", color: "#FFC107" },
	{ label: "Month confirmed", color: "#3388FF" },
	{ label: "Month approved", color: "#AAAAAA" },
	{ label: "Month approved", color: "#008F39" },
	{ label: "Month in error", color: "#FF0000" }
]

function MonthStatusComponent(props: MStatusProps) {

	function getMonthStatus() {
		return props.isError ? 6
				: props.isLocked && props.isConfirmed && props.isApproved
					? 5
					: props.isApproved && props.isConfirmed
						? 4
						: props.isConfirmed
							? 3
							: props.monthStatus;
	}

	return (
		<div style={{padding: 12}}>
			<div className="col">
				<div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
					<div style={{ marginLeft: "2rem", minWidth: "25px", height: "25px", width: "25px", borderRadius: "100%", display: "inline", backgroundColor: MonthStatuses[getMonthStatus()].color }}></div>
					<div style={{ marginLeft: "1rem" }}>{MonthStatuses[getMonthStatus()].label}</div>
				</div>
			</div>
		</div>
	);
}

export default MonthStatusComponent;