import React, { Component } from 'react';
import { ExpenseOffCanvas } from './ExpenseOffCanvas';
import { ActivityOffCanvas } from './ActivityOffCanvas';
import { DaySelectOptionState } from '../Utils/DaySelectOptionState';
import { Activity } from './Activity'
import { ActivitySelectorForExpenses } from './ActivitySelectorForExpenses'
import { Expense } from './Expense'
import { CalendarDay } from './CalendarDay';
import CalendarPageHeader from './CalendarPageHeader';
import { toast } from "react-toastify";
import { ICalendar, ICalendarDay, IAccount, IActivityEntry, IExpenseEntry, IMenuItem, IActivity, VisualizationType, editMode, IMonthsStatus, IEntriesMonthStatus, selectionType, IExpense, IItem, Menu, Month, Week, RequestHandler, LoadingHandling, ErrorHandling, breastfeeding, paternity, studyPermit, dailyAllowance, paidLeave, vacation } from './../../Models/CalendarDay';
import { webApiAccounts, putActivityEntryArray, putExpenseEntryArray, deleteActivityEntryArray, deleteExpenseEntryArray, postActivityEntryArray, postExpenseEntryArray, putActivitySetTemplate, putConfirmMonth, deleteConfirmMonth, putExpenseSetTemplate, isHolidayToast, isHoliday, getJSONHeaders } from './../../AppSettings'
import { getISOWeek, getISOWeekYear, endOfISOWeek, startOfISOWeek, addMonths, addDays, format } from 'date-fns';
import addMilliseconds from 'date-fns/esm/fp/addMilliseconds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt as farTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ActivityService from '../../Services/ActivityService';

interface IState {
    isLoaded: boolean;
    calendarData: ICalendar | null;
    error: any;
    weekend: boolean;

    selectedMenu: IMenuItem;
    visualizationType: VisualizationType;
    startDate: Date;
    endDate: Date;

    showMultipleDaysSelection: boolean;
    selectedDays: Date[];
    selectionType: selectionType;

    activityOffCanvasTitle: string;
    selectedActivityEntry: IActivityEntry[];
    isActivityOffCanvasVisible: boolean;

    expenseOffCanvasTitle: string;
    selectedExpenseEntry: IExpenseEntry[];
    isExpenseOffCanvasVisible: boolean;

    totalExpenses: number;

    editMode: editMode;

    isDeleteVisible: boolean;
    indexDelete: number;

    isConfirmed: boolean | null;
    isApproved: boolean | null;
    isLocked: boolean | null;
    isError: boolean | null;

    toastId: React.ReactText;

    oldActivities: IActivityEntry[];
    nOrePermessoStudio: number;
    nOrePaternita: number;
}

interface IProps {
    account: IAccount;
    selectedMenu: IMenuItem;
}

export default class CalendarPage extends Component<IProps, IState> {
    activityService: ActivityService = new ActivityService()
    activities: IActivityEntry[] | null = [];
    expense: IExpenseEntry[] | null = [];
    monthsStatus: IMonthsStatus | null = null;
    calendar!: ICalendar;

    constructor(props: IProps) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.onAddProjectClick = this.onAddProjectClick.bind(this);
        this.onAddExpenseClick = this.onAddExpenseClick.bind(this);
        this.onCloseActivityOffCanvas = this.onCloseActivityOffCanvas.bind(this);
        this.onCloseExpenseOffCanvas = this.onCloseExpenseOffCanvas.bind(this);
        this.onActivityClick = this.onActivityClick.bind(this);
        this.onExpenseClick = this.onExpenseClick.bind(this);
        this.onToggleDaySelected = this.onToggleDaySelected.bind(this);
        this.onCopyActivity = this.onCopyActivity.bind(this);
        this.onCopyExpense = this.onCopyExpense.bind(this);
        this.removeActivity = this.removeActivity.bind(this);
        this.removeExpense = this.removeExpense.bind(this);
        this.onDropDelete = this.onDropDelete.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.copyActivity = this.copyActivity.bind(this);
        this.copyExpense = this.copyExpense.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSaveActivity = this.onSaveActivity.bind(this);
        this.onDeleteActivity = this.onDeleteActivity.bind(this);
        this.onSaveExpense = this.onSaveExpense.bind(this);
        this.onDeleteExpense = this.onDeleteExpense.bind(this);
        this.toogleWeekend = this.toogleWeekend.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onVisualizationTypeChange = this.onVisualizationTypeChange.bind(this);
        this.onConfirmMonth = this.onConfirmMonth.bind(this);
        this.onDeleteConfirmMonth = this.onDeleteConfirmMonth.bind(this);
        this.onWeekChange = this.onWeekChange.bind(this);
        this.onSelectMultipleActivities = this.onSelectMultipleActivities.bind(this);
        this.onDeleteMultipleActivities = this.onDeleteMultipleActivities.bind(this);
        this.onSelectAllActivities = this.onSelectAllActivities.bind(this);
        this.onSelectMultipleExpenses = this.onSelectMultipleExpenses.bind(this);
        this.onDeleteMultipleExpenses = this.onDeleteMultipleExpenses.bind(this);
        this.onSelectAllExpenses = this.onSelectAllExpenses.bind(this);

        this.onExpenseFavouriteOrColorChange = this.onExpenseFavouriteOrColorChange.bind(this);
        this.onExpenseSetTemplate = this.onExpenseSetTemplate.bind(this);

        this.onActivityFavouriteOrColorChange = this.onActivityFavouriteOrColorChange.bind(this);
        this.onActivitySetTemplate = this.onActivitySetTemplate.bind(this);

        this.onDragEnd = this.onDragEnd.bind(this);

        this.state = {
            isLoaded: false,
            calendarData: null,
            error: null,
            weekend: false,

            showMultipleDaysSelection: false,
            selectedDays: [],
            selectionType: selectionType.None,

            activityOffCanvasTitle: "Add Project",
            isActivityOffCanvasVisible: false,
            selectedActivityEntry: [],

            expenseOffCanvasTitle: "Add Expense",
            isExpenseOffCanvasVisible: false,
            selectedExpenseEntry: [],

            totalExpenses: 0,

            editMode: editMode.None,

            selectedMenu: this.props.selectedMenu,
            visualizationType: VisualizationType.Month,
            startDate: new Date(new Date((new Date()).setDate(1)).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(addMonths(new Date(), 1).setDate(0)).setHours(23, 59, 59, 999)),

            isDeleteVisible: false,
            indexDelete: 0,

            isConfirmed: true,
            isApproved: true,
            isLocked: true,
            isError: false,

            toastId: "",

            oldActivities: [],
            nOrePermessoStudio: 0,
            nOrePaternita: 0,
        };
    }

    componentDidMount() {
        this.loadData();
        this.getData();
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (this.state.selectedMenu !== nextProps.selectedMenu) {
            this.setState({ selectedMenu: nextProps.selectedMenu })
            this.onCancel();
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.account !== prevProps.account) {
            this.loadData();
        }
    }

    addAllActivitiesToCalendar(): void {
        if (this.activities) {
            this.activities?.map(activity =>
                this.calendar.addActivity(activity))
        }
    }

    addAllExpenseToCalendar(): void {
        if (this.expense) {
            this.expense?.map(expense =>
                this.calendar.addExpense(expense))
        }
    }

    setDayIsEditable(): void {
        if (this.monthsStatus) {
            this.calendar.setDayIsEditable(this.monthsStatus);
        }
    }


    onDragStart(event: React.DragEvent<HTMLDivElement>, id: string, type: string): void {
        event.dataTransfer?.setData("id", id);
        event.dataTransfer?.setData("type", type);
        if (type === "Activity" || type === "Expense") {
            setTimeout(() => { this.setState({ isDeleteVisible: true, indexDelete: 2 }) }, 20)
        }
        this.onCancel()
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
    }

    onDragEnd(event: DragEvent): void {
        setTimeout(() => { this.setState({ isDeleteVisible: false, indexDelete: 0 }) }, 200)
    }

    onDrop(event: DragEvent, dateTo: Date): void {
        let id = event.dataTransfer?.getData("id");
        let type = event.dataTransfer?.getData("type");
        let days: Date[] | undefined;

        if (id) {
            switch (type) {
                case "Activity":
                    this.moveActivity(id, dateTo);
                    break;

                case "Expense":
                    this.moveExpense(id, dateTo);
                    break;

                case "AddProject":
                    this.state.calendarData?.selectDays(new Date(id), dateTo, this.state.weekend);
                    days = this.state.calendarData?.getSelectedDays();
                    this.setState({ selectedDays: days ? days : [], selectionType: selectionType.AddActivityEntry });
                    this.setShowMultipleDaysSelection(true);
                    break;

                case "AddExpense":
                    this.state.calendarData?.selectDays(new Date(id), dateTo, this.state.weekend);
                    days = this.state.calendarData?.getSelectedDays();
                    this.setState({ selectedDays: days ? days : [], selectionType: selectionType.AddExpenseEntry });
                    this.setShowMultipleDaysSelection(true);
                    break;

                default:
                    ErrorHandling.errorToast("Something went wrong! - No drag type selected");
                    break;
            }
            this.setState({ isDeleteVisible: false })
        }
    }

    onDropDelete(event: DragEvent): void {
        let id = event.dataTransfer?.getData("id");
        let type = event.dataTransfer?.getData("type");

        if (id) {
            switch (type) {
                case "Activity":
                    this.removeActivity(id);
                    break;

                case "Expense":
                    this.removeExpense(id);
                    break;
            }
            this.setState({ isDeleteVisible: false })
        }
    }

    removeActivity(activityEntryId: string): void {
        let selectedActivityEntry = this.activities?.filter(act => act.id === activityEntryId)[0];
        if (selectedActivityEntry) {
            deleteActivityEntryArray([selectedActivityEntry])
                .catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            this.state.calendarData?.removeActivity(selectedActivityEntry);
            this.activities?.splice(this.activities?.indexOf(selectedActivityEntry), 1);
            this.forceUpdate();
        }
    }

    removeExpense(expenseEntryId: string): void {
        let selectedExpenseEntry = this.expense?.filter(exp => exp.id === expenseEntryId)[0];
        if (selectedExpenseEntry) {
            deleteExpenseEntryArray([selectedExpenseEntry]).catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong!");
            });
            this.state.calendarData?.removeExpense(selectedExpenseEntry);
            this.expense?.splice(this.expense?.indexOf(selectedExpenseEntry), 1);
            this.forceUpdate();
            this.setState({
                totalExpenses: this.state.calendarData?.getTotalExpenses() ?? 0
            })
        }
    }

    onCopyActivity(activityEntryId: string): void {

        let selectedActivityEntry = this.activities?.filter(act => act.id === activityEntryId)[0];
        if (selectedActivityEntry) {
            this.setState({ selectedActivityEntry: [selectedActivityEntry] })
        }

        this.setState({ selectionType: selectionType.CopyActivityEntry });
        this.setShowMultipleDaysSelection(true);
    }

    onSelectMultipleActivities(activityEntryId: string, isSelected: boolean): void {
        let selectedActivityEntries: IActivityEntry[];

        if (this.state.selectionType === selectionType.SelectMultipleActivityEntry) {
            selectedActivityEntries = [...this.state.selectedActivityEntry];
        } else {
            selectedActivityEntries = [];
        }

        if (isSelected) {
            let activityEntry = this.activities?.filter(act => act.id === activityEntryId)[0];
            if (activityEntry && selectedActivityEntries.map(act => { return act.id }).indexOf(activityEntryId) === -1) {
                selectedActivityEntries.push(activityEntry);
            }
        } else {
            let activityEntry = selectedActivityEntries?.filter(act => act.id === activityEntryId)[0];
            if (activityEntry) {
                let index = selectedActivityEntries.indexOf(activityEntry);
                if (index >= 0) {
                    selectedActivityEntries.splice(index, 1);
                }
            }
        }

        this.setState({
            selectionType: selectionType.SelectMultipleActivityEntry,
            selectedActivityEntry: selectedActivityEntries
        }, () => { this.setShowMultipleDaysSelection(selectedActivityEntries.length > 0); });
    }

    onSelectMultipleExpenses(expenseEntryId: string, isSelected: boolean): void {
        let selectedExpenseEntries: IExpenseEntry[];

        if (this.state.selectionType === selectionType.SelectMultipleExpenseEntry) {
            selectedExpenseEntries = [...this.state.selectedExpenseEntry];
        } else {
            selectedExpenseEntries = [];
        }

        if (isSelected) {
            let expenseEntry = this.expense?.filter(exp => exp.id === expenseEntryId)[0];
            if (expenseEntry && selectedExpenseEntries.map(exp => { return exp.id }).indexOf(expenseEntryId) === -1) {
                selectedExpenseEntries.push(expenseEntry);
            }
        } else {
            let expenseEntry = selectedExpenseEntries?.filter(exp => exp.id === expenseEntryId)[0];
            if (expenseEntry) {
                let index = selectedExpenseEntries.indexOf(expenseEntry);
                if (index >= 0) {
                    selectedExpenseEntries.splice(index, 1);
                }
            }
        }

        this.setState({
            selectionType: selectionType.SelectMultipleExpenseEntry,
            selectedExpenseEntry: selectedExpenseEntries
        }, () => { this.setShowMultipleDaysSelection(selectedExpenseEntries.length > 0); });
    }

    onSelectAllActivities() {
        let selectedActivityEntries: IActivityEntry[] = [];

        this.activities?.forEach(activityEntry => {
            selectedActivityEntries.push(activityEntry);
        })

        this.state.calendarData?.removeAllSelectedDays();

        this.setState({
            selectionType: selectionType.SelectMultipleActivityEntry,
            selectedActivityEntry: selectedActivityEntries,
            selectedDays: [],
        }, () => { this.setShowMultipleDaysSelection(selectedActivityEntries.length > 0); });
    }

    onSelectAllExpenses() {
        let selectedExpenseEntries: IExpenseEntry[] = [];

        this.expense?.forEach(expenseEntry => {
            selectedExpenseEntries.push(expenseEntry);
        })

        this.state.calendarData?.removeAllSelectedDays();

        this.setState({
            selectionType: selectionType.SelectMultipleExpenseEntry,
            selectedExpenseEntry: selectedExpenseEntries,
            selectedDays: [],
        }, () => { this.setShowMultipleDaysSelection(selectedExpenseEntries.length > 0); });
    }

    onCopyExpense(expenseEntryId: string): void {

        let selectedExpenseEntry = this.expense?.filter(exp => exp.id === expenseEntryId)[0];
        if (selectedExpenseEntry) {
            this.setState({ selectedExpenseEntry: [selectedExpenseEntry] })
        }

        this.setState({ selectionType: selectionType.CopyExpenseEntry });
        this.setShowMultipleDaysSelection(true);
    }

    onAddProjectClick(days: Date[]): void {
        document.body.style.overflowY = "hidden";
        this.setState({
            selectedActivityEntry: days.map((day, index) => {
                return {
                    id: (index + 1).toString().padStart(4, "0"),
                    account: this.props.account,
                    activity: {
                        id: "",
                        description: "",
                        isFavourite: false,
                        color: "#000000"
                    },
                    location: null,
                    absence: null,
                    day: day,
                    hours: 0,
                    note: "",
                    hasAttachment: false
                };
            }),
            activityOffCanvasTitle: "Add Project" + (days.length > 1 ? "s" : ""),
            isActivityOffCanvasVisible: true,
            editMode: editMode.Add
        });

        this.onCancel(false);
    }

    onAddExpenseClick(days: Date[], activity: IActivity | null | undefined = undefined): void {
        document.body.style.overflowY = "hidden";
        this.setState({
            selectedExpenseEntry: days.map((day, index) => {
                return {
                    id: (index + 1).toString().padStart(4, "0"),
                    account: this.props.account,
                    activity: activity ?? {
                        id: "",
                        description: "",
                        isFavourite: false,
                        color: "#ffffff"
                    },
                    amount: 0,
                    distance: 0,
                    amountPerDistanceUnit: 0,
                    departure: "",
                    arrival: "",
                    expense: {
                        id: "", description: "", company: "", type: ""
                    },
                    day: day,
                    note: "",
                    receiptNumber: ""
                };
            }),
            expenseOffCanvasTitle: "Add Expense" + (days.length > 1 ? "s" : ""),
            isExpenseOffCanvasVisible: true,
            editMode: editMode.Add
        });

        this.onCancel(false);

    }

    onActivityClick(id: string): void {
        this.onCancel();

        document.body.style.overflowY = "hidden";

        let activity = this.activities?.filter(act => act.id === id)[0]
        if (activity) {
            this.setState({
                selectedActivityEntry: [activity],
                activityOffCanvasTitle: "Edit Project",
                isActivityOffCanvasVisible: true,
                editMode: editMode.Edit
            });
        }
    }

    onExpenseClick(id: string): void {
        this.onCancel();

        document.body.style.overflowY = "hidden";

        let expense = this.expense?.filter(exp => exp.id === id)[0]
        if (expense) {
            this.setState({
                selectedExpenseEntry: [expense],
                expenseOffCanvasTitle: "Edit Expense",
                isExpenseOffCanvasVisible: true,
                editMode: editMode.Edit
            });
        }
    }

    onCloseActivityOffCanvas(): void {
        this.setState({ isActivityOffCanvasVisible: false });
        document.body.style.overflowY = "auto";
    }

    onCloseExpenseOffCanvas(): void {
        this.setState({ isExpenseOffCanvasVisible: false });

        document.body.style.overflowY = "auto";
    }

    onToggleDaySelected(date: Date) {
        this.state.calendarData?.toggleDaySelected(date);
        let days = this.state.calendarData?.getSelectedDays();
        this.setState({ selectedDays: days ? days : [] });
    }

    moveActivity(activityid: string, dateTo: Date): void {
        LoadingHandling.showLoader();

        if (this.activities) {
            let activity: IActivityEntry = this.activities?.filter(act => act.id === activityid)[0];

            if (new Date(activity.day).setHours(0, 0, 0, 0) !== dateTo.setHours(0, 0, 0, 0)) {

                //do checks only if we are moving another activity type breastfeeding
                if (activity.absence?.type === breastfeeding) {
                    let filteredList = this.activities.filter(x => new Date(x.day).setHours(0, 0, 0, 0) === dateTo.setHours(0, 0, 0, 0)).filter(y => y.absence?.type === breastfeeding)

                    if (filteredList.length > 0) {
                        if (activity.hours + filteredList.map(x => { return x.hours }).reduce((totH, current) => totH + current, 0) > 2) {
                            LoadingHandling.hideLoader();
                            return;
                        }
                    }
                }

                //do checks only if we are moving activity type "ferie/permessi"
                if (activity.activity.code === vacation) {
                    let dayActivities = this.activities.filter(x => new Date(x.day).setHours(0, 0, 0, 0) === dateTo.setHours(0, 0, 0, 0));
                    let vacationActivities = dayActivities.filter(y => y.activity.code === vacation);
                    let workActivities = dayActivities.filter(y => y.activity.code !== vacation);
                    let vacationHours = vacationActivities.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);
                    let workHours = workActivities.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);

                    if (vacationActivities.length > 0 || vacationHours + workHours + activity.hours > 8) {
                        LoadingHandling.hideLoader();
                        ErrorHandling.warningToast("You can't insert more vacations time or time off and activities for more than 8 hours.")
                        return;
                    }
                } else {
                    let dayActivities = this.activities.filter(x => new Date(x.day).setHours(0, 0, 0, 0) === dateTo.setHours(0, 0, 0, 0));
                    let vacationActivities = dayActivities.filter(y => y.activity.code === vacation);
                    let workActivities = dayActivities.filter(y => y.activity.code !== vacation);
                    let vacationHours = vacationActivities.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);
                    let workHours = workActivities.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);
                    if (vacationActivities.length > 0 && vacationHours + workHours + activity.hours > 8) {
                        LoadingHandling.hideLoader();
                        ErrorHandling.warningToast("You can't insert more vacations time or time off and activities for more than 8 hours.")
                        return;
                    }
                }

                //do checks only if we are moving activity type "paid leave/assenza pagata" oppure "vacation/ferie"
                if (activity.activity.code === vacation || activity.activity.code === paidLeave) {

                    if (isHoliday(new Date(dateTo))) {
                        ErrorHandling.warningToast("You can't insert this activity on bank Holidays")
                        LoadingHandling.hideLoader();
                        return;
                    }
                }

                this.state.calendarData?.moveActivity(activity, dateTo);
                this.activities?.filter(act => act.id === activityid)?.map(act => act.day = new Date(dateTo));

                let response = putActivityEntryArray(this.activities?.filter(act => act.id === activityid)).catch(error => RequestHandler.badRequestReceived(error, "Somethig went wrong!"));

                if (!response) {
                } else {
                    RequestHandler.responseReceived("Project moved");
                    //check if moved activity is on a holiday day
                    isHolidayToast(new Date(dateTo));
                }
            }
            this.forceUpdate();
        }

        LoadingHandling.hideLoader();
    }

    moveExpense(expenseid: string, dateTo: Date): void {

        LoadingHandling.showLoader();

        if (this.expense) {
            let expense: IExpenseEntry = this.expense?.filter(exp => exp.id === expenseid)[0];

            if (new Date(expense.day).setHours(0, 0, 0, 0) !== dateTo.setHours(0, 0, 0, 0)) {

                if (expense.expense.type?.toLowerCase() === dailyAllowance) {
                    let filteredList = this.expense.filter(x => new Date(x.day).setHours(0, 0, 0, 0) === dateTo.setHours(0, 0, 0, 0)).filter(y => y.expense.type?.toLowerCase() === dailyAllowance)

                    if (filteredList.length > 0) {
                        LoadingHandling.hideLoader();
                        return;
                    }
                }

                this.state.calendarData?.moveExpense(expense, dateTo);
                this.expense?.filter(exp => exp.id === expenseid)?.map(exp => exp.day = new Date(dateTo));

                let response = putExpenseEntryArray(this.expense?.filter(exp => exp.id === expenseid)).catch(error => RequestHandler.badRequestReceived(error, "Somethig went wrong!"));
                if (!response) {
                } else {
                    RequestHandler.responseReceived("Expense moved")
                }
            }
            this.forceUpdate();
        }

        LoadingHandling.hideLoader();
    }

    onConfirm(): void {
        switch (this.state.selectionType) {
            case selectionType.AddActivityEntry:
                this.onAddProjectClick(this.state.selectedDays);
                break;

            case selectionType.AddExpenseEntry:
                this.onAddExpenseClick(this.state.selectedDays);
                break;

            case selectionType.CopyActivityEntry:
                this.copyActivity();
                break;

            case selectionType.CopyExpenseEntry:
                this.copyExpense();
                break;

            case selectionType.SelectMultipleActivityEntry:
                this.editMultipleActivities();
                break;
            case selectionType.SelectMultipleExpenseEntry:
                this.editMultipleExpenses();
                break;
            default:
                ErrorHandling.errorToast("Something went wrong!");
                break;
        }
    }

    editMultipleActivities(): void {
        if (this.state.selectedActivityEntry.length > 1) {
            for (let index = 1; index < this.state.selectedActivityEntry.length; index++) {
                if (this.state.selectedActivityEntry[index]?.activity?.id !== this.state.selectedActivityEntry[index - 1]?.activity?.id) {
                    alert("There are entries with differents activities selected. Clicking on Save will set the same activity on all selected entries.");
                    break;
                }
            }
        }

        this.setState({
            activityOffCanvasTitle: "Edit Projects",
            isActivityOffCanvasVisible: true,
            editMode: editMode.Edit
        });
    }

    editMultipleExpenses(): void {
        if (this.state.selectedExpenseEntry.length > 1) {
            for (let index = 1; index < this.state.selectedExpenseEntry.length; index++) {
                if (this.state.selectedExpenseEntry[index]?.activity?.id !== this.state.selectedExpenseEntry[index - 1]?.expense?.id) {
                    alert("There are entries with differents activities selected. Clicking on Save will set the same activity on all selected entries.");
                    break;
                }
            }
        }

        this.setState({
            expenseOffCanvasTitle: "Edit Expenses",
            isExpenseOffCanvasVisible: true,
            editMode: editMode.Edit
        });
    }

    async copyActivity() {
        LoadingHandling.showLoader();

        if (this.state.selectedActivityEntry[0].absence !== null && this.state.selectedActivityEntry[0].absence.type === studyPermit) {
            let totH = this.state.nOrePermessoStudio + this.getPermitHours(this.activities !== null ? this.activities : [], studyPermit)

            if (this.state.selectedActivityEntry[0].hours * this.state.selectedDays.length + totH > 150) {
                ErrorHandling.errorToast("Ore di permesso studio superate");
                LoadingHandling.hideLoader();
                this.onCancel();
                return;
            }
        }

        if (this.state.selectedActivityEntry[0].absence !== null && this.state.selectedActivityEntry[0].absence.type === paternity) {
            let totH = this.state.nOrePaternita + this.getPermitHours(this.activities !== null ? this.activities : [], paternity)

            if (this.state.selectedActivityEntry[0].hours * this.state.selectedDays.length + totH > 80) {
                ErrorHandling.errorToast("Ore di paternità superate");
                LoadingHandling.hideLoader();
                this.onCancel();
                return;
            }
        }

        let filteredDateOnAbsence: Date[] = [];

        if (this.state.selectedActivityEntry[0].absence !== null && this.state.selectedActivityEntry[0].absence.type === breastfeeding) {
            this.state.selectedDays.forEach(x => {
                if (this.activities) {
                    let filteredActivities = this.activities.filter(y => new Date(new Date(y.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x).setHours(0, 0, 0, 0)).getTime()).filter(z => z.absence?.type === breastfeeding)
                    if (filteredActivities.length > 0) {
                        let hoursBreastFeeding = filteredActivities.map(b => { return b.hours }).reduce((totH, current) => totH + current, 0)
                        if (hoursBreastFeeding < 2) {
                            if (hoursBreastFeeding + this.state.selectedActivityEntry[0].hours <= 2) {
                                filteredDateOnAbsence.push(x)
                            }
                        }
                    } else {
                        filteredDateOnAbsence.push(x)
                    }
                }
            })
        } else {
            filteredDateOnAbsence = [...this.state.selectedDays]
        }

        let filteredDateOnActivity: Date[] = [];
        //check if im copying activity type "ferie/permessi"
        if (this.state.selectedActivityEntry[0].activity.code === vacation) {

            //check if on the dates selected there are other activity with ferie/permesso
            filteredDateOnAbsence.forEach(x => {
                if (this.activities) {
                    let dayActivities = this.activities.filter(y => new Date(new Date(y.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x).setHours(0, 0, 0, 0)).getTime());
                    let vacations = dayActivities.filter(z => z.activity.code === vacation);
                    let working = dayActivities.filter(z => z.activity.code !== vacation);
                    // let vacationHours = vacations.
                    if (vacations.length === 0) {
                        filteredDateOnActivity.push(x)
                    }
                }
            })
        } else {
            filteredDateOnActivity = filteredDateOnAbsence;
        }

        let filteredDateOnActivityHoliday: Date[] = [];
        //do checks only if we are copying activity type "paid leave/assenza pagata" oppure "vacation/ferie"
        if (this.state.selectedActivityEntry[0].activity.code === vacation || this.state.selectedActivityEntry[0].activity.code === paidLeave) {

            filteredDateOnActivity.forEach(x => {
                if (!isHoliday(new Date(x)) ) {
                    filteredDateOnActivityHoliday.push(x);
                }
            })
        } else {
            filteredDateOnActivityHoliday = filteredDateOnActivity;
        }

        filteredDateOnActivityHoliday = filteredDateOnActivityHoliday.filter(x => {
            if (this.activities) {
                let dayActivities = this.activities.filter(y => new Date(new Date(y.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x).setHours(0, 0, 0, 0)).getTime());
                let vacations = dayActivities.filter(z => z.activity?.code === vacation);
                let working = dayActivities.filter(z => z.activity?.code !== vacation);
                let vacationHours = vacations.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);
                let workingHours = working.map(x => x.hours).reduce((prev, curr) => prev + curr, 0);
                if (vacationHours + workingHours + this.state.selectedActivityEntry[0].hours <= 8) {
                    return true;
                }
            }
            return false;
        })

        if (filteredDateOnActivityHoliday.length === 0) {
            ErrorHandling.warningToast("Project not copied");
            LoadingHandling.hideLoader();
            this.onCancel();
            return;
        }

        let activityEntryModel = this.state.selectedActivityEntry[0];
        if (activityEntryModel) {
            let activityEntryToSave: IActivityEntry[];
            activityEntryToSave = filteredDateOnActivityHoliday.map((day, index) => {
                return {
                    id: (index + 1).toString().padStart(4, "0"),
                    account: this.props.account,
                    activity: activityEntryModel.activity,
                    location: activityEntryModel.location,
                    absence: activityEntryModel.absence,
                    day: day,
                    hours: activityEntryModel.hours,
                    note: activityEntryModel.note,
                    hasAttachment: false
                }
            })

            this.onCancel();

            let savedActivities = await postActivityEntryArray(activityEntryToSave).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

            if (savedActivities) {
                savedActivities.forEach(act => this.activities?.push(act));
                this.state.calendarData?.addActivities(savedActivities);
                this.forceUpdate();
            }
            else {
                return
            }

        }

        LoadingHandling.hideLoader();
        ErrorHandling.successToast("Project copied");

        //check if copied something on holiday day
        filteredDateOnActivityHoliday.forEach(x => isHolidayToast(new Date(x)))
    }

    async copyExpense() {
        if (this.expense !== null) {
            LoadingHandling.showLoader();

            let expenseEntryModel = this.state.selectedExpenseEntry[0];
            if (expenseEntryModel) {

                let filteredDate: Date[] = [...this.state.selectedDays];

                this.expense.forEach(x => {
                    if (x.expense.type?.toLowerCase() === dailyAllowance) {
                        let indexToRemove = filteredDate.findIndex(y => new Date(new Date(y).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x.day).setHours(0, 0, 0, 0)).getTime())
                        if (indexToRemove !== -1) {
                            filteredDate.splice(indexToRemove, 1);
                        }
                    }
                })

                let expenseEntryToSave: IExpenseEntry[];
                expenseEntryToSave = filteredDate.map((day, index) => {
                    let activity = this.state.calendarData?.getCalendarDay(day.getDate().toString().padStart(2, '0')).selectedActivity;
                    return {
                        id: (index + 1).toString().padStart(4, "0"),
                        account: this.props.account,
                        activity: activity ? activity : expenseEntryModel.activity,
                        expense: expenseEntryModel.expense,
                        day: day,
                        amount: expenseEntryModel.amount,
                        distance: expenseEntryModel.distance,
                        amountPerDistanceUnit: expenseEntryModel.amountPerDistanceUnit,
                        departure: expenseEntryModel.departure,
                        arrival: expenseEntryModel.arrival,
                        note: expenseEntryModel.note,
                        receiptNumber: null //expenseEntryModel.receiptNumber
                    }
                })
                this.onCancel();

                //per aggiornare in tempo reale
                if (expenseEntryToSave.length > 0) {
                    let savedExpenses = await postExpenseEntryArray(expenseEntryToSave).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
                    if (savedExpenses) {
                        savedExpenses.forEach(exp => this.expense?.push(exp));
                        this.state.calendarData?.addExpenses(savedExpenses);
                        this.forceUpdate();
                    }
                    else {
                        return
                    }
                }
                this.setState({
                    totalExpenses: this.state.calendarData?.getTotalExpenses() ?? 0
                })
                ErrorHandling.successToast(expenseEntryToSave.length > 0 ? "Expense copied" : "Already existing daily allowance");
            }
            LoadingHandling.hideLoader();
        }
    }

    async onActivitySetTemplate(activity: IActivity, account: IAccount) {
        let result = await putActivitySetTemplate(activity, account).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
        if (result && (this.activities?.filter(act => act.activity.id === activity.id)[0]?.activity?.color !== activity?.color
            || this.expense?.filter(exp => exp.activity.id === activity.id)[0]?.activity?.color !== activity?.color)) {
            let color = activity.color;
            if (color !== undefined) {
                this.state.calendarData?.changeActivityColor(activity.id, color);
                this.forceUpdate();
            }
        }
    }

    async onActivityFavouriteOrColorChange(item: IItem) {
        let activity: IActivity = {
            id: item.id,
            description: item.description,
            isFavourite: item.isFavourite,
            color: (item.color ? item.color : "#ffffff")
        };

        await this.onActivitySetTemplate(activity, this.props.account);
    }

    async onExpenseSetTemplate(expense: IExpense, account: IAccount) {
        let result = await putExpenseSetTemplate(expense, account).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
        if (result && (this.expense?.filter(exp => exp.expense.id === expense.id)[0]?.expense?.color !== expense?.color)) {
            let color = expense.color;
            if (color !== undefined) {
                this.state.calendarData?.changeExpenseColor(expense.id, color);
                this.forceUpdate();
            }
        }
    }

    async onExpenseFavouriteOrColorChange(item: IItem) {
        let expense: IExpense = {
            id: item.id,
            description: item.description,
            isFavourite: item.isFavourite,
            color: (item.color ? item.color : "#ffffff")
        };

        await this.onExpenseSetTemplate(expense, this.props.account);
    }


    onCancel(complete: boolean = true): void {
        this.state.calendarData?.removeAllSelectedDays();
        if (complete) {
            this.setState({
                selectedDays: [],
                selectedActivityEntry: [],
                selectedExpenseEntry: [],
                selectionType: selectionType.None,
                showMultipleDaysSelection: false
            })
        } else {
            this.setState({
                selectedDays: [],
                selectionType: selectionType.None,
                showMultipleDaysSelection: false
            })
        }
    }

    toogleWeekend(): void {
        this.setState({
            weekend: !this.state.weekend
        })
    }

    toggleShowMultipleDaysSelection(): void {
        this.setState({
            showMultipleDaysSelection: !this.state.showMultipleDaysSelection
        })
    }

    setShowMultipleDaysSelection(value: boolean): void {
        this.setState({
            showMultipleDaysSelection: value
        })
    }

    async onSaveActivity(activities: IActivityEntry[]) {
        let isIdNew: boolean = false;
        let isIdExisting: boolean = false;

        LoadingHandling.showLoader();

        activities.forEach(activityEntry => {
            if (activityEntry.id.length === 4) {
                isIdNew = true;
            }
            if (activityEntry.id.length === 36) {
                isIdExisting = true;
            }
        })

        if (isIdNew === isIdExisting) {
            LoadingHandling.hideLoader();
            ErrorHandling.errorToast("Something went wrong!");
            return;
        }

        if (isIdNew) {
            //check if im saving things in wrong dates
            let activitiesToEnter: IActivityEntry[] = [];

            activities.forEach(act => {
                if (this.activities) {
                    let vacations = this.activities.filter(y => new Date(new Date(y.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(act.day).setHours(0, 0, 0, 0)).getTime()).filter(z => z.activity.code === vacation);
                    let vacationHours = vacations.map(x => x.hours).reduce((prev, current) => prev + current, 0);

                    let dayActivities = this.activities.filter(y => new Date(new Date(y.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(act.day).setHours(0, 0, 0, 0)).getTime()).filter(z => z.activity.code !== vacation);
                    let dayActivitiesHours = dayActivities.filter(z => z.activityhours !== vacation).map(x => x.hours).reduce((prev, current) => prev + current, 0);
                    
                    let currentDayHoursSum = vacationHours + dayActivitiesHours + act.hours;

                    if ((act.activity.code === vacation && currentDayHoursSum <= 8)) {
                        activitiesToEnter.push(act);
                    } else if (act.activity.code !== vacation 
                                && (vacationHours === 0
                                    || (vacationHours > 0 && currentDayHoursSum <= 8))) {
                        activitiesToEnter.push(act);
                    } else {
                        ErrorHandling.errorToast("You can't insert more vacations time or time off and activities for more than 8 hours.");
                        return;
                    }
                }
            });

            if (activitiesToEnter.length === 0) {
                LoadingHandling.hideLoader();
                ErrorHandling.errorToast("Too many vacations hours.");
                return;
            }

            let savedActivities = await postActivityEntryArray(activitiesToEnter).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (savedActivities) {
                savedActivities.forEach(act => act.day = new Date(new Date(act.day).setHours(0, 0, 0, 0)))
                savedActivities.forEach(act => this.activities?.push(act));
                this.state.calendarData?.addActivities(savedActivities);
                this.forceUpdate();
            }
            else {
                return
            }
        }

        if (isIdExisting) {

            //
            let savedIds = await putActivityEntryArray(activities).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

            if (savedIds) {
                savedIds.forEach(activityId => {
                    let oldActivity = this.activities?.filter(act => act.id === activityId.id)[0];
                    if (oldActivity) {
                        this.state.calendarData?.removeActivity(oldActivity);
                        this.activities?.splice(this.activities?.indexOf(oldActivity), 1);
                    }

                    let newActivity = activities?.filter(act => act.id === activityId.id)[0];
                    if (newActivity) {
                        this.state.calendarData?.addActivity(newActivity);
                        this.activities?.push(newActivity);
                    }
                })
            }
            else {
                return
            }

        }
        LoadingHandling.hideLoader();
        ErrorHandling.successToast("Project saved");
    }

    async onSaveExpense(expenses: IExpenseEntry[]) {
        let isIdNew: boolean = false;
        let isIdExisting: boolean = false;

        LoadingHandling.showLoader();

        expenses.forEach(expenseEntry => {
            if (expenseEntry.id.length === 4) {
                isIdNew = true;
            }
            if (expenseEntry.id.length === 36) {
                isIdExisting = true;
            }
        })

        if (isIdNew === isIdExisting) {
            LoadingHandling.hideLoader();
            ErrorHandling.errorToast("Something went wrong!");
            return;
        }

        if (isIdNew) {

            let filteredExpense: IExpenseEntry[] = [];

            let hasDailyAllowance: boolean = expenses.filter(x => x.expense.type?.toLowerCase() === dailyAllowance).length > 0;

            if (hasDailyAllowance) {
                expenses.filter(y => y.expense.type?.toLowerCase() === dailyAllowance).forEach(x => {
                    let elem = this.expense?.find(n => (new Date(new Date(n.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x.day).setHours(0, 0, 0, 0)).getTime()) && (n.expense.type === dailyAllowance))
                    if (!elem) {
                        filteredExpense.push(x);
                    }
                })
            }
            let savedExpenses = await postExpenseEntryArray(hasDailyAllowance ? filteredExpense : expenses).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (savedExpenses) {
                savedExpenses.forEach(exp => exp.day = new Date(new Date(exp.day).setHours(0, 0, 0, 0)))
                savedExpenses.forEach(exp => this.expense?.push(exp));
                this.state.calendarData?.addExpenses(savedExpenses);
                this.forceUpdate();
            }
            else {
                return
            }
        }

        if (isIdExisting) {
            let savedIds = await putExpenseEntryArray(expenses).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (savedIds) {
                savedIds.forEach(expenseId => {
                    let oldExpense = this.expense?.filter(exp => exp.id === expenseId.id)[0];
                    if (oldExpense) {
                        this.state.calendarData?.removeExpense(oldExpense);
                        this.expense?.splice(this.expense?.indexOf(oldExpense), 1);
                    }

                    let newExpense = expenses?.filter(exp => exp.id === expenseId.id)[0];
                    if (newExpense) {
                        this.state.calendarData?.addExpense(newExpense);
                        this.expense?.push(newExpense);
                    }
                })
            } else {
                return
            }
        }
        this.setState({
            totalExpenses: this.state.calendarData?.getTotalExpenses() ?? 0
        })

        LoadingHandling.hideLoader();
        ErrorHandling.successToast("Expense saved");

    }

    async onDeleteActivity(activities: IActivityEntry[]) {
        LoadingHandling.showLoader();

        let isIdNew: boolean = false;
        let isIdExisting: boolean = false;

        activities.forEach(activityEntry => {
            if (activityEntry.id.length === 4) {
                isIdNew = true;
            }
            if (activityEntry.id.length === 36) {
                isIdExisting = true;
            }
        })

        if (isIdNew === isIdExisting) {
            LoadingHandling.hideLoader();
            ErrorHandling.errorToast("Something went wrong!");
            return;
        }

        if (isIdExisting) {
            let arrayDeletedActivity = await deleteActivityEntryArray(activities).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

            if (arrayDeletedActivity) {
                arrayDeletedActivity.forEach(id => this.removeActivity(id))
                activities.forEach(act => { this.state.calendarData?.removeActivity(act) });
                this.forceUpdate();
            }
            else {
                return
            }
        }

        LoadingHandling.hideLoader();
        ErrorHandling.successToast("Project deleted");
    }

    async onDeleteMultipleActivities() {
        await this.onDeleteActivity(this.state.selectedActivityEntry);
        this.setState({
            selectedActivityEntry: [],
            selectionType: selectionType.None,
            showMultipleDaysSelection: false
        });
    }

    async onDeleteMultipleExpenses() {
        await this.onDeleteExpense(this.state.selectedExpenseEntry);
        this.setState({
            selectedExpenseEntry: [],
            selectionType: selectionType.None,
            showMultipleDaysSelection: false
        });
    }

    async onDeleteExpense(expenses: IExpenseEntry[]) {
        LoadingHandling.showLoader();

        let isIdNew: boolean = false;
        let isIdExisting: boolean = false;

        expenses.forEach(activityEntry => {
            if (activityEntry.id.length === 4) {
                isIdNew = true;
            }
            if (activityEntry.id.length === 36) {
                isIdExisting = true;
            }
        })

        if (isIdNew === isIdExisting) {
            LoadingHandling.hideLoader();
            ErrorHandling.errorToast("Something went wrong!");
            return;
        }

        if (isIdNew) {
            LoadingHandling.hideLoader();
            return;
        }

        if (isIdExisting) {

            let arrayDeletedExpenses = await deleteExpenseEntryArray(expenses).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

            if (arrayDeletedExpenses) {
                arrayDeletedExpenses.forEach(id => this.removeExpense(id))
                expenses.forEach(exp => { this.state.calendarData?.removeExpense(exp) });
            }
            else {
                return
            }
        }
        this.setState({
            totalExpenses: this.state.calendarData?.getTotalExpenses() ?? 0
        })
        LoadingHandling.hideLoader();
        ErrorHandling.successToast("Expense deleted");
    }

    async onConfirmMonth() {
        let year = this.state.startDate.getFullYear();
        let month = this.state.startDate.getMonth() + 1;
        let result = await putConfirmMonth(this.props.account.id, year, month);
        if (result) {
            this.loadData();
            ErrorHandling.successToast("Month confirmed");
        } else {
            ErrorHandling.errorToast("Something went wrong!");
        }

    }

    isDateInArray(needle: Date, haystack: string | any[]) {
        for (var i = 0; i < haystack.length; i++) {
            if (needle.getTime() === haystack[i].getTime()) {
                return true;
            }
        }
        return false;
    }

    monthStatus() {

        let value: number = 1;
        let tempDate: Date[] = [];
        var uniqueDates: Date[] = [];

        if (this.state.calendarData?.isConfirmable) {
            if (this.activities != null) {
                this.activities.forEach(x => { if (x.hours > 8) { value = 2 } });

                if (value === 2) { return value }

                this.activities.forEach(x => {
                    if ((new Date(x.day).getDay() !== 6) && (new Date(x.day).getDay() !== 0)) {
                        tempDate.push(new Date(x.day))
                    }
                });

                for (var i = 0; i < tempDate.length; i++) {
                    if (!this.isDateInArray(tempDate[i], uniqueDates)) {
                        uniqueDates.push(tempDate[i]);
                    }
                }

                let sumHours = this.activities.map(x => { return x.hours }).reduce((sumHours, current) => sumHours + current, 0);
                let sumUniqueDates = uniqueDates.length * 8;

                if (sumHours === sumUniqueDates) {
                    value = 1;
                }
                else if (sumHours > sumUniqueDates) {
                    value = 2;
                }
            }

        } else {
            value = 0;
        }

        return value;
    }

    async onDeleteConfirmMonth() {
        let year = this.state.startDate.getFullYear();
        let month = this.state.startDate.getMonth() + 1;
        let result = await deleteConfirmMonth(this.props.account.id, year, month);
        if (result) {
            this.loadData();
            ErrorHandling.successToast("Month open for changes");
        } else {
            ErrorHandling.errorToast("Something went wrong!");
        }

    }

    onMonthChange(date: Date) {
        this.setState({
            startDate: new Date(new Date(date.setDate(1)).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(addMonths(date, 1).setDate(0)).setHours(23, 59, 59, 999))
        }, () => this.getData())
        this.loadData(date, VisualizationType.Month);
    }

    onWeekChange(date: Date) {
        this.setState({
            startDate: new Date(startOfISOWeek(date).setHours(0, 0, 0, 0)),
            endDate: new Date(endOfISOWeek(date).setHours(23, 59, 59, 999))
        })
        this.loadData(date, VisualizationType.Week);
    }

    onVisualizationTypeChange(visualizationType: VisualizationType) {
        this.setState({ visualizationType: visualizationType })
        switch (visualizationType) {
            case VisualizationType.Month:
                this.onMonthChange(new Date());
                break;
            case VisualizationType.Week:
                this.onWeekChange(new Date());
                break;
        }
    }

    getCalendarPickerStartDate(): Date {
        if (this.state.visualizationType === VisualizationType.Month) {
            return this.state.startDate;
        } else {
            let startDate = new Date(this.state.startDate);
            if (this.monthsStatus) {
                for (const [key, value] of Object.entries(this.monthsStatus)) {
                    let monthStatus = value as IEntriesMonthStatus;
                    let monthStartDate = new Date(new Date(monthStatus.year, monthStatus.month - 1, 1).setHours(0, 0, 0, 0));
                    let monthEndDate = new Date(new Date(monthStatus.year, monthStatus.month, 0).setHours(23, 59, 59, 999));
                    let isEditable: boolean = !(monthStatus.isConfirmed || monthStatus.isApproved || monthStatus.isLocked)

                    if (startDate.getTime() > monthEndDate.getTime() || startDate.getTime() < monthStartDate.getTime()) {
                        continue;
                    }

                    if (startDate.getTime() < monthEndDate.getTime() && !isEditable) {
                        startDate = new Date(addMilliseconds(1, monthEndDate));
                    }
                }

            }
            return startDate;
        }
    }

    getCalendarPickerEndDate(): Date {
        if (this.state.visualizationType === VisualizationType.Month) {
            return this.state.endDate;
        } else {
            let endDate = new Date(this.state.endDate);
            if (this.monthsStatus) {
                for (const [key, value] of Object.entries(this.monthsStatus)) {
                    let monthStatus = value as IEntriesMonthStatus;
                    let monthStartDate = new Date(new Date(monthStatus.year, monthStatus.month - 1, 1).setHours(0, 0, 0, 0));
                    let monthEndDate = new Date(new Date(monthStatus.year, monthStatus.month, 0).setHours(23, 59, 59, 999));
                    let isEditable: boolean = !(monthStatus.isConfirmed || monthStatus.isApproved || monthStatus.isLocked)

                    if (endDate.getTime() < monthStartDate.getTime() || endDate.getTime() > monthEndDate.getTime()) {
                        continue;
                    }

                    if (endDate.getTime() > monthStartDate.getTime() && !isEditable) {
                        endDate = new Date(addMilliseconds(-1, monthStartDate));
                    }
                }
            }

            return endDate;
        }
    }

    async loadData(date: Date | null = null, visualizationType: VisualizationType = VisualizationType.Month) {
        if (!this.props?.account?.id || this.props?.account?.id === "") {
            return;
        }

        RequestHandler.requestSend();

        let startDate: Date = new Date(), endDate: Date = new Date();

        if (visualizationType === VisualizationType.Month) {
            let year = this.state.startDate.getFullYear();
            let month = this.state.startDate.getMonth() + 1;

            if (date) {
                year = date.getFullYear();
                month = date.getMonth() + 1;
            }

            this.calendar = new Month(this.props.account, year, month);
            startDate = new Date(year, month - 1, 1, 0, 0, 0);
            endDate = new Date(year, month, 0, 23, 59, 59, 999);
        }

        if (visualizationType === VisualizationType.Week) {

            startDate = this.state.startDate;
            endDate = this.state.endDate;



            if (date) {
                startDate = startOfISOWeek(date)
                endDate = endOfISOWeek(date)
            }

            this.calendar = new Week(this.props.account, getISOWeekYear(startDate), getISOWeek(startDate));

        }
        let queryIdActivity = webApiAccounts + "/" + this.props.account.id + "/activityEntries" + "?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        let queryIdExpense = webApiAccounts + "/" + this.props.account.id + "/expenseEntries" + "?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        let queryMonthsStatus = webApiAccounts + "/" + this.props.account.id + "/entriesMonthStatus/" + new Date(startDate).getFullYear() + "/" + (new Date(startDate).getMonth() + 1);

        let id = toast.loading("Loading Data...");
        this.setState({ toastId: id, calendarData: this.calendar });

        fetch(queryIdActivity, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then(async (resultAct) => {
                fetch(queryIdExpense, {
                    method: "GET",
                    headers: await getJSONHeaders(),
                })
                    .then(ErrorHandling.handleError)
                    .then(resExp => resExp.json())
                    .then(async (resultExp) => {
                        fetch(queryMonthsStatus, {
                            method: "GET",
                            headers: await getJSONHeaders(),
                        })
                            .then(ErrorHandling.handleError)
                            .then(resMonthStatus => resMonthStatus.json())
                            .then((resultMonthStatus) => {
                                this.activities = resultAct;
                                this.expense = resultExp;
                                this.monthsStatus = resultMonthStatus;
                                this.addAllActivitiesToCalendar();
                                this.addAllExpenseToCalendar();
                                this.setDayIsEditable();
                                this.setState({
                                    isLoaded: true,
                                    isConfirmed: this.state.visualizationType === VisualizationType.Month ? resultMonthStatus.currentMonth.isConfirmed : null,
                                    isApproved: this.state.visualizationType === VisualizationType.Month ? resultMonthStatus.currentMonth.isApproved : null,
                                    isError: this.state.visualizationType == VisualizationType.Month ? resultMonthStatus.currentMonth.isError : null,
                                    isLocked: this.state.visualizationType === VisualizationType.Month ? resultMonthStatus.currentMonth.isLocked : null,
                                    calendarData: this.calendar,
                                    totalExpenses: this.calendar?.getTotalExpenses() ?? 0,
                                    error: null
                                });

                                RequestHandler.responseReceived("");
                                if (this.state.toastId !== "") {
                                    this.dismissToast(this.state.toastId);
                                }
                            })
                            .catch(error => errorOccurred(error, "Status months not loaded", this));
                    })
                    .catch(error => errorOccurred(error, "Expense not loaded", this));
            })
            .catch(error => errorOccurred(error, "Activities not loaded", this));

        function errorOccurred(error: any, msg: string, page: CalendarPage) {
            page.activities = null;
            page.expense = null;
            page.monthsStatus = null;
            page.setState({
                calendarData: null,
                error: error
            });
            if (page.state.toastId !== "") {
                page.dismissToast(page.state.toastId);
            }
            RequestHandler.badRequestReceived(error, msg);
        }
    }

    dismissToast(id: React.ReactText) {
        toast.dismiss(id);
    }

    async getData() {
        let queryActivity = webApiAccounts + "/" + this.props.account.id + "/activityEntries" + "?fromDate=" + format(new Date(addMonths(new Date(), -35)), 'yyyy-MM-dd') + "&toDate=" + format(new Date(addDays(this.state.startDate, -1)), 'yyyy-MM-dd');

        fetch(queryActivity, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then((resultAct: IActivityEntry[]) => {
                this.setState({
                    oldActivities: resultAct,
                    nOrePermessoStudio: this.getPermitHours(resultAct, studyPermit),
                    nOrePaternita: this.getPermitHours(resultAct.filter(x => x.day > addMonths(this.state.startDate, -11) && x.day < this.state.startDate), paternity),
                })
            })
    }

    getPermitHours(activityEntries: IActivityEntry[], type: string): number {
        return activityEntries.filter(x => x.absence?.type === type).map(y => { return y.hours }).reduce((sumHours, current) => sumHours + current, 0);
    }

    render() {
        let elements: JSX.Element | JSX.Element[];

        let currentDay: Date = new Date(this.state.startDate);

        function returnAndIncrementDay(increment: boolean = false): string {
            let returnData: string = currentDay.getDate().toString().padStart(2, '0');
            if (increment) { currentDay = new Date(addDays(currentDay, 1)) };
            return returnData;
        }

        function getCalendarDay(calendar: ICalendar, key: string): ICalendarDay {
            return calendar.getCalendarDay(key);
        }

        let calendarClass: string = this.state.weekend ? "calendar-col-7" : "calendar-col-5"
        let calendarClassWeekend: string = this.state.weekend ? "calendar-col-7" : "calendar-col-5 weekend"

        let confirmButtonText: string = "";

        switch (this.state.selectionType) {
            case selectionType.AddActivityEntry:
            case selectionType.AddExpenseEntry:
                confirmButtonText = "Add";
                break;
            case selectionType.SelectMultipleActivityEntry:
            case selectionType.SelectMultipleExpenseEntry:
                confirmButtonText = "Edit";
                break;
            case selectionType.CopyActivityEntry:
            case selectionType.CopyExpenseEntry:
                confirmButtonText = "Paste";
                break;
            default:
                confirmButtonText = "Error";
        }

        let bannerText: string = "";

        switch (this.state.selectionType) {
            case selectionType.AddActivityEntry:
            case selectionType.AddExpenseEntry:
            case selectionType.CopyActivityEntry:
            case selectionType.CopyExpenseEntry:
                bannerText = this.state.selectedDays.length.toString() + " day" + (this.state.selectedDays.length !== 1 ? "s" : "") + " selected";
                break;
            case selectionType.SelectMultipleActivityEntry:
                bannerText = this.state.selectedActivityEntry.length.toString() + " entr" + (this.state.selectedActivityEntry.length !== 1 ? "ies" : "y") + " selected";
                break;
            case selectionType.SelectMultipleExpenseEntry:
                bannerText = this.state.selectedExpenseEntry.length.toString() + " entr" + (this.state.selectedExpenseEntry.length !== 1 ? "ies" : "y") + " selected";
                break;
        }

        if (this.state.isLoaded === true && this.state.error === null) {
            let maxRowNumber: number = Math.ceil((this.state.calendarData!.daysInMonth + this.state.calendarData!.firstDayOfMonthDayOfWeek) / 7.0);

            elements = [...Array(maxRowNumber)].map((e, rowNumber) => (
                <div
                    className="row"
                    key={rowNumber.toString()}
                >
                    {[...Array(7)].map((s, colNumber) => {
                        return (
                            <div className={colNumber >= 5 ? calendarClassWeekend : calendarClass} key={rowNumber.toString() + "_" + colNumber.toString()}>
                                {((rowNumber > 0 || colNumber >= this.state.calendarData!.firstDayOfMonthDayOfWeek)
                                    && currentDay <= (this.state.endDate))
                                    && [getCalendarDay(this.state.calendarData!, returnAndIncrementDay(true))].map((data) => (
                                        <CalendarDay data={data}
                                            onDragStartFunction={this.onDragStart}
                                            onDragOverFunction={this.onDragOver}
                                            onDropFunction={this.onDrop}
                                            onAddProjectClickFunction={this.onAddProjectClick}
                                            onAddExpenseClickFunction={this.onAddExpenseClick}
                                            onToggleDaySelectedFunction={this.onToggleDaySelected}
                                            isMultipleDaysSelectionVisible={this.state.showMultipleDaysSelection && this.state.selectionType !== selectionType.SelectMultipleActivityEntry && this.state.selectionType !== selectionType.SelectMultipleExpenseEntry}
                                            key={returnAndIncrementDay(false)}
                                            selectedMenu={this.state.selectedMenu}
                                            onDragEndFunction={this.onDragEnd}
                                        >
                                            {this.state.selectedMenu === Menu.Timesheet && data?.dayActivities.map((activity) =>
                                                <Activity data={activity}
                                                    onDragStartFunction={this.onDragStart}
                                                    onDragEndFunction={this.onDragEnd}
                                                    onClick={this.onActivityClick}
                                                    onActivitySelection={this.onSelectMultipleActivities}
                                                    isActivitySelected={this.state.selectionType === selectionType.SelectMultipleActivityEntry && this.state.selectedActivityEntry.map(act => { return act.id; }).indexOf(activity.id) !== -1}
                                                    onCopy={this.onCopyActivity}
                                                    isMonthEditable={data.isEditable}
                                                    isNotSelected={(this.state.selectionType === selectionType.CopyActivityEntry) && (this.state.selectedActivityEntry[0]?.id !== activity.id)}
                                                    key={activity.id}
                                                />
                                            )}

                                            {this.state.selectedMenu === Menu.Expenses && data?.getActivitiesForExpenses().map((activity) =>
                                                <ActivitySelectorForExpenses
                                                    key={activity?.id}
                                                    data={activity}
                                                    onClick={() => {
                                                        if (this.state.showMultipleDaysSelection) {
                                                            if ((this.state.selectionType === selectionType.CopyExpenseEntry) && data?.selectActivityForExpenses) {
                                                                data?.selectActivityForExpenses(activity);
                                                                let days = this.state.calendarData?.getSelectedDays();
                                                                this.setState({ selectedDays: days ? days : [] });
                                                            }
                                                        } else {
                                                            this.onAddExpenseClick([new Date(data.day)], activity);
                                                        }
                                                    }}
                                                    isMonthEditable={data.isEditable}
                                                    isSelectable={this.state.showMultipleDaysSelection && (this.state.selectionType === selectionType.CopyExpenseEntry)}
                                                />
                                            )}

                                            {this.state.selectedMenu === Menu.Expenses && data?.dayExpenses.map((expense) =>
                                                <Expense data={expense}
                                                    onDragStartFunction={this.onDragStart}
                                                    onDragEndFunction={this.onDragEnd}
                                                    onClick={this.onExpenseClick}
                                                    onExpenseSelection={this.onSelectMultipleExpenses}
                                                    isExpenseSelected={this.state.selectionType === selectionType.SelectMultipleExpenseEntry && this.state.selectedExpenseEntry.map(exp => { return exp.id }).indexOf(expense.id) !== -1}
                                                    onCopy={this.onCopyExpense}
                                                    isMonthEditable={data.isEditable}
                                                    isNotSelected={(this.state.selectionType === selectionType.CopyExpenseEntry) && (this.state.selectedExpenseEntry[0]?.id !== expense.id)}
                                                    key={expense.id} />
                                            )}
                                        </CalendarDay>
                                    ))}
                            </div>
                        )
                    })}
                </div>));


        } else {
            elements = <div></div>;
        }

        return (<>
            {this.props?.account?.id && (
                <>
                    <CalendarPageHeader
                        toogleWeekend={this.toogleWeekend}
                        weekend={this.state.weekend}
                        startDate={this.state.startDate}
                        visualizationType={this.state.visualizationType}
                        onMonthChange={this.onMonthChange}
                        onWeekChange={this.onWeekChange}
                        onVisualizationTypeChange={this.onVisualizationTypeChange}
                        onConfirmMonth={this.onConfirmMonth}
                        onDeleteConfirmMonth={this.onDeleteConfirmMonth}
                        calendarClass={calendarClass}
                        calendarClassWeekend={calendarClassWeekend}
                        isConfirmed={this.state.isConfirmed}
                        isApproved={this.state.isApproved}
                        isLocked={this.state.isLocked}
                        isError={this.state.isError}
                        onChangeVisualization={this.onCancel}
                        isConfirmable={(!this.props.account?.isFullTimeAllocation
                            || this.state.calendarData?.isConfirmable) ?? false}
                        totalExpenses={this.state.selectedMenu === Menu.Timesheet ? 0 : this.state.totalExpenses}
                        selectedMenu={this.state.selectedMenu}
                        onSelectAllActivities={(this.activities?.length ?? 0) > 0 ? this.onSelectAllActivities : undefined}
                        onSelectAllExpenses={(this.expense?.length ?? 0) > 0 ? this.onSelectAllExpenses : undefined}
                        monthStatus={this.monthStatus()}
                        totalHours={this.activities?.map(x => { return x.hours }).reduce((sumHours, current) => sumHours + current, 0) ?? 0}
                        currentAttachment={this.monthsStatus}
                    />

                    {elements}

                    <ActivityOffCanvas
                        activityOffCanvasTitle={this.state.activityOffCanvasTitle}
                        activityEntries={this.state.selectedActivityEntry}
                        activityOffCanvasIsVisible={this.state.isActivityOffCanvasVisible}
                        onClose={this.onCloseActivityOffCanvas}
                        onSave={this.onSaveActivity}
                        onDelete={this.onDeleteActivity}
                        onFavouriteOrColorChange={this.onActivityFavouriteOrColorChange}
                        editMode={this.state.editMode}
                        startDate={this.getCalendarPickerStartDate()}
                        endDate={this.getCalendarPickerEndDate()}
                        isSchedulerPage={false}
                        absenceList={[]}
                        locationList={[]}
                        activityList={[]}
                        nHoursStudyPermit={this.state.nOrePermessoStudio + this.getPermitHours(this.activities !== null ? this.activities : [], studyPermit)}
                        nHoursPaternityPermit={this.state.nOrePaternita + this.getPermitHours(this.activities !== null ? this.activities : [], paternity)}
                        activityListComplete={this.activities ? this.activities : []}
                    />

                    <ExpenseOffCanvas
                        expenseOffCanvasTitle={this.state.expenseOffCanvasTitle}
                        expenseEntries={this.state.selectedExpenseEntry}
                        expenseOffCanvasIsVisible={this.state.isExpenseOffCanvasVisible}
                        onClose={this.onCloseExpenseOffCanvas}
                        onSave={this.onSaveExpense}
                        onDelete={this.onDeleteExpense}
                        onFavouriteOrColorChange={this.onActivityFavouriteOrColorChange}
                        onExpenseFavouriteOrColorChange={this.onExpenseFavouriteOrColorChange}
                        editMode={this.state.editMode}
                        startDate={this.getCalendarPickerStartDate()}
                        endDate={this.getCalendarPickerEndDate()}
                        expenseList={[]}
                        activityList={[]}
                        isSchedulerPage={false}
                        expenseListComplete={this.expense ? this.expense : []}
                    />

                    <DaySelectOptionState
                        confirmButtonText={confirmButtonText}
                        text={bannerText}
                        isVibile={this.state.showMultipleDaysSelection && !this.state.isActivityOffCanvasVisible && !this.state.isExpenseOffCanvasVisible}
                        onConfirm={this.onConfirm}
                        onDelete={(this.state.selectionType === selectionType.SelectMultipleActivityEntry) ? this.onDeleteMultipleActivities : ((this.state.selectionType === selectionType.SelectMultipleExpenseEntry) ? this.onDeleteMultipleExpenses : undefined)}
                        onCancel={this.onCancel}
                    />

                    {this.state.isDeleteVisible && (
                        <div
                            className="text-danger text-center droppable w-100"
                            onDragOver={(event: any) => this.onDragOver(event)}
                            onDrop={(event: any) => this.onDropDelete(event)}
                            style={{ position: "fixed", bottom: 0, left: "auto", right: "auto", fontSize: "50px", zIndex: this.state.indexDelete }}
                        >
                            <div className="w-100" style={{ backgroundColor: "white", borderTop: "2px solid black" }}>
                                <FontAwesomeIcon icon={farTrashAlt} />
                            </div>
                        </div>
                    )}
                    <div className="row-calendar-page"></div>
                </>)
            }
        </>
        );
    }
}