import  {DownloadRequestTypeModel, DownloadRequestType } from "../../Models/DownloadRequestTypeModel";

export function GetDownloadRequestTypeDescriptionByTitle(key:string) : string {
    let req = null;
    // console.log(DownloadRequestType[DownloadRequestType.GeneratePaidOut]);
    switch(key)
    {
        case DownloadRequestType[DownloadRequestType.JobOrderCard]:
            req =  DownloadRequestType.JobOrderCard;
            break;
        case DownloadRequestType[DownloadRequestType.MonthAccountSchedule]:
            req =  DownloadRequestType.MonthAccountSchedule;
            break;
		case DownloadRequestType[DownloadRequestType.GeneratePaidOut]:
			req =  DownloadRequestType.GeneratePaidOut;
			break;
		case DownloadRequestType[DownloadRequestType.MonthlyAllDetails]:
			req =  DownloadRequestType.MonthlyAllDetails;  
			break;   
		case DownloadRequestType[DownloadRequestType.TimesheetReport]:
			req =  DownloadRequestType.TimesheetReport;
			break;
		case DownloadRequestType[DownloadRequestType.FinalBalance]:
			req =  DownloadRequestType.FinalBalance;  
			break;   
        default:   
         throw "no description found for this request type: " + key;
    } 
   
    return GetDownloadRequestTypeDescription(req);
    
}
export function GetDownloadRequestTypeDescription(key:DownloadRequestType) : string {
    switch(key)
    {
        case DownloadRequestType.JobOrderCard:
            return "Job order card";
        case DownloadRequestType.MonthAccountSchedule:
            return "Month account schedule";
        case DownloadRequestType.GeneratePaidOut:
            return "Erogato";
		case DownloadRequestType.MonthlyAllDetails:
			return "Dettagli Mese";
		case DownloadRequestType.TimesheetReport:
			return "Consuntivazione";
		case DownloadRequestType.FinalBalance:
			return "Project Manager Final Balance";
        default:
            throw "unknown request type!";
    } 
}

export function GetAllActiveDownloadRequestTypes() : DownloadRequestTypeModel[] {
    var result = [] as DownloadRequestTypeModel[];

    result.push(getDownloadRequestVisualObject(DownloadRequestType.JobOrderCard))
    result.push(getDownloadRequestVisualObject(DownloadRequestType.GeneratePaidOut))
    result.push(getDownloadRequestVisualObject(DownloadRequestType.MonthlyAllDetails))
    result.push(getDownloadRequestVisualObject(DownloadRequestType.TimesheetReport))
    result.push(getDownloadRequestVisualObject(DownloadRequestType.FinalBalance))

    return result;
}

function getDownloadRequestVisualObject(key : DownloadRequestType) : DownloadRequestTypeModel {
    return  {
        id : key.toString(),
        type : key,
        description : GetDownloadRequestTypeDescription(key)
    }
}