import React, { Component } from "react";
import { ILocation } from "./../../Models/CalendarDay";

interface IProps {
    listLocation: ILocation[];
    selectedLocation: ILocation | null;
    onChange: Function;
}

interface IState {
    listLocation: ILocation[];
    selectedLocation: ILocation | null;
}

export class LocationSelect extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            listLocation: this.props.listLocation,
            selectedLocation: this.props.selectedLocation
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.selectedLocation !== this.state.selectedLocation) {
            this.setState({
                selectedLocation: nextProps.selectedLocation
            });
        }
        if (nextProps.listLocation !== this.state.listLocation) {
            this.setState({
                listLocation: nextProps.listLocation
            });
        }
    }

    onClick(location: ILocation) {
        this.setState({ selectedLocation: location });
        this.props.onChange(location);
    }

    render() {
        return (
            <div className="row" >
                {this.state.listLocation.map(loc =>
                    <div
                        className="col-4 align-item-center justify-content-center d-flex"
                        style={{ padding: "5px" }}
                        key={loc.id}
                    >
                        <button
                            type="button"
                            className={"btn " + (loc.id === this.state.selectedLocation?.id ? "btn-primary-custom" : "btn-light rmvHover")}
                            onClick={() => this.onClick(loc)}
                            style={{ fontSize: "12px" }}
                        >
                            {loc.name}
                        </button>
                    </div>
                )}
            </div>
        )
    }
}