import React, { Component } from 'react';
import { IStatusData } from '../../Models/CalendarDay';

interface IProps {
    topRow: string[],
    tableData: IStatusData[],
}

interface IState {
}

export default class JobOrderCardTableStatus extends Component<IProps, IState>{

    formatter(num: number): string {
        return num.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR"
              }).replace("€", '');
    }

    render() {
        let topRowData = this.props.topRow.map((numList, i) => (
            <th key={i}>{numList}</th>
        ))
        return (
            <div>
                <div className="customStatusTableDiv">
                    <table className="statusTable">
                        <thead>
                            <tr>{topRowData}</tr>
                        </thead>
                        <tbody>
                            {this.props.tableData.map((elem, index) => (
                                <tr key={index}>
                                    <td>{elem.description}</td>
                                    <td>{elem.revenues === 0 ? "-" : this.formatter(elem.revenues)}</td>
                                    <td>{elem.costs === 0 ? "-" : this.formatter(elem.costs)}</td>
                                    <td>{elem.margin === 0 ? "-" : this.formatter(elem.margin)}</td>
                                    <td>{elem.marginPerc === 0 ? "-" : this.formatter(elem.marginPerc) + " %"}</td>
                                    <td>{elem.averageCost === 0 ? "-" : this.formatter(elem.averageCost)}</td>
                                    <td>{elem.averageRevenue === 0 ? "-" : this.formatter(elem.averageRevenue)}</td>
                                    <td>{elem.costsExp === 0 ? "-" : this.formatter(elem.costsExp)}</td>
                                    <td>{elem.unpaidInvoices === 0 ? "-" : this.formatter(elem.unpaidInvoices)}</td>
                                    <td>{elem.actualCostsExp === 0 ? "-" : this.formatter(elem.actualCostsExp)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}