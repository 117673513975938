import React, { Component } from 'react';
import { VisualizationType } from '../../Models/CalendarDay';
import { format } from "date-fns";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt as farTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { TeachingBubbleButton } from '../AegisCommon/TeachingBubbleButton';
import { Button, DefaultButton, DirectionalHint } from '@fluentui/react';
import { DownloadRequestType, DownloadRequestModel } from '../../Models/DownloadRequestTypeModel';
import { GetDownloadRequestTypeDescriptionByTitle } from '../Utils/DownloadRequestTypeUtils';

interface IProps {
    requestData: DownloadRequestModel[];
    showRequest: Function;
    onDeleteRow: Function;
    onDownloadFile: Function;
}

interface IState { }

export default class DownloadRequestTable extends Component<IProps, IState> {

    topRow: string[] = ["Data request", "Status", "Period", "Type", "StartedAt", "CompletedAt", "Action"]

    errorToast(msg: string) {
        toast.error(msg, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    }

    render() {
        let topRow: JSX.Element | JSX.Element[] = [];
        let tableData: JSX.Element | JSX.Element[] = [];

        topRow = this.topRow.map((elem, i) => (
            <th key={i}>{elem}</th>
        ));

        tableData = this.props.requestData.map((elem, i) => (
            <tr key={i}>
                <td>{format(new Date(elem.requestedAt), "dd/MM/yyyy")}</td>
                <td>{elem.status}</td>
                <td>{format(new Date(elem.from), "dd/MM/yyyy") + " - " + format(new Date(elem.to), "dd/MM/yyyy")}</td>
                <td>{GetDownloadRequestTypeDescriptionByTitle(elem.type)}</td>
                <td>{elem.startedAt ? format(new Date(elem.startedAt), "dd/MM/yyyy HH:mm:ss") : "-"}</td>
                <td>{elem.completedAt ? format(new Date(elem.completedAt), "dd/MM/yyyy HH:mm:ss") : "-"}</td>
                <td>
                    {
                        elem.status === "Processed"
                            ?   <DefaultButton  onClick={() => this.props.onDownloadFile(elem.id)}>Download</DefaultButton>
                            : (elem.status === "Error")
                                ?   <TeachingBubbleButton
                                        button={{
                                            label: "Show Error",
                                            styles: {
                                                root: {
                                                    maxWidth: "70%",
                                                    borderColor: "var(--bs-danger)",
                                                    color: "var(--bs-danger)"
                                                }
                                            }
                                        }}
                                        bubble={{
                                            text: elem.result,
                                            position: DirectionalHint.leftCenter,
                                            hasCloseButton: true,
                                            closeButtonLabel: "Close",
                                            headline: "Error Message",
                                            background: "var(--bs-danger)",
                                            primaryButton: {
                                                children: 'Copy to clipbaord',
                                                onClick: () => navigator.clipboard.writeText(elem.result),
                                                styles: {
                                                    root: {
                                                        color: "#000000",
                                                    },
                                                    textContainer: {

                                                    }
                                                }
                                            }
                                        }}
                                    />
                                : elem.status === "Created"
                                    ?   <DefaultButton styles={{root: {width: "70%", background: "var(--bs-danger)", border: "var(--bs-danger)", ':focus': {color: "var(--bs-black)"}}}} onClick={() => this.props.onDeleteRow(elem)}>
                                        <FontAwesomeIcon color='var(--bs-gray-400)' icon={farTrashAlt} />
                                        </DefaultButton>
                                    : undefined
                    }
                </td>
            </tr>
        ))

        return (<div>
            <div className="requestTable">
                <table>
                    <thead>
                        <tr>
                            {topRow}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        </div>);
    }
}