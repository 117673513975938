import { CSSProperties, PropsWithChildren } from "react";


type BasePageContentProps = PropsWithChildren & {};

/**
 *	Base page content.
 *	This should wrap every tab page of Aegis.
 *
 * @export
 * @param {BasePageContentProps} props Accepts just children.
 * @return {*} 
 */
export default function BasePageContent(props: BasePageContentProps) {
	const contentStyle: CSSProperties = {
		position: "fixed",
		display: "flex",
		top: "138px",
		bottom: 0,
		width: "100%",
		rowGap: "16px",
		justifyContent: "space-evenly",
		flexFlow: "row wrap",
		padding: "16px",
		overflowY: "scroll",
		columnGap: "12px",
	};

	return (
		<div style={contentStyle}>
			{props.children}
		</div>
	);
}