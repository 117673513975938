import React, { ChangeEvent, Component } from "react";
import { Pagination } from "../Utils/Pagination";
import MonthSelector from "../Utils/MonthSelector";
import { getJSONHeaders, webApiAccounts } from "../../AppSettings";
import { IAccount, ErrorHandling, LoadingHandling, RequestHandler } from "../../Models/CalendarDay";
import { toast } from "react-toastify";
import { addMonths, format } from "date-fns";

interface IProps {
    account: IAccount;
}

interface IState { 
    accounts: (IAccount & { selected: boolean })[];
    accountCount: number;
    numberAccounts: number[];
    currentAccounts: number[];
    startDate: Date;
    endDate: Date;
    itemsPerPage: number;
    currentPage: number;
}

export default class DownloadReport extends Component<IProps, IState>{

    accountsActivitiesExpensesList: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        this.onDateSelect = this.onDateSelect.bind(this)
        this.onPageChanged = this.onPageChanged.bind(this)
        this.loadAccounts = this.loadAccounts.bind(this)
        this.accountsActivitiesExpensesList = React.createRef();

        this.state = { 
            accounts: [],
            accountCount: 0,
            startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(addMonths(new Date(), 1).setDate(0)).setHours(23, 59, 59, 999)),
            itemsPerPage: 40,
            currentPage: 1,

            numberAccounts: [],
            currentAccounts: []
        }
    }

    handleResizeWindow(): void {
        if (this.accountsActivitiesExpensesList && this.accountsActivitiesExpensesList.current) {
            let height = window.innerHeight - this.accountsActivitiesExpensesList.current.getBoundingClientRect().top - 75 + "px";
            this.accountsActivitiesExpensesList.current.style.height = height;
            this.accountsActivitiesExpensesList.current.style.maxHeight = height;
        }
    }

    componentDidMount() {
        if (this.props.account?.id !== undefined && this.props.account?.id !== null && this.props.account?.id !== "") {
            this.loadAccounts();
        }

        window.addEventListener('resize', () => { this.handleResizeWindow() });
        this.handleResizeWindow();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => { this.handleResizeWindow() });
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) { 
        if ((this.props.account !== prevProps.account || this.state.currentPage !== prevState.currentPage) && (this.props.account.id !== "" && this.props.account.id != null && this.state.currentPage > 0)) {
            this.loadAccounts();
        }
    }

    componentWillReceiveProps(nextProps: IProps) { 
        if (this.props.account !== nextProps.account) {
            this.loadAccounts();
        }
    }

    async loadAccounts() {
        let queryAccounts = webApiAccounts + "/relatedAccounts?page=" + this.state.currentPage + "&itemsPerPage=" + this.state.itemsPerPage;

        RequestHandler.requestSend();

        let accountCount: number = 0;

        fetch(queryAccounts, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAcc => {
                const contentRange = resAcc.headers?.get("content-range") ?? "";
                const range = contentRange.split("/")[1];
                accountCount = parseInt(range);

                return resAcc.json();
            })
            .then((resultAcc) => {
                this.setState({
                    accounts: resultAcc.map((acc: { selected: boolean; }) => { acc.selected = false; return acc; }),
                    accountCount: accountCount
                }, () => document.getElementById("scroller")?.scrollIntoView())
                RequestHandler.responseReceived("");
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong with loading accounts")
			})
    }

    onCheckAccount(event: ChangeEvent<HTMLInputElement>, account: IAccount & { selected: boolean }) {
        account.selected = event.target.checked;
        this.forceUpdate();
    }

    onDateSelect(startDate: Date, endDate: Date) {
        this.setState({ startDate: startDate, endDate: endDate });
    }

    async onDownloadClick() {

        RequestHandler.requestSend();

        let selectedAccounts = this.state.accounts.filter(acc => acc.selected);
        let accountsId: string[] = []
        if (selectedAccounts.length > 0) {
            accountsId = selectedAccounts.map(acc => { return "accountIds=" + acc.id })
        } else {
            toast.error("Seleziona almeno un elemento!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });

            LoadingHandling.hideLoader();

            return;
        }

        let accountIdsString = accountsId.join("&");
        let filename = "file.xlsx"
        fetch(webApiAccounts + "/report?" + accountIdsString + "&from=" + format(this.state.startDate, "yyyy-MM-dd") + "&to=" + format(this.state.endDate, "yyyy-MM-dd"), {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(response => {
                ErrorHandling.handleError(response);

                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(response.headers?.get("content-disposition") ?? "");
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }

                return response?.blob();
            })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                RequestHandler.responseReceived("");

                var link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", filename);

                link.click();
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Download went wrong")
            });
    }

    

    onPageChanged(page: number) {
        this.setState({ currentPage: page });
    }

    render() {
        return (
            <>
                <div
                    className="row m-4"
                >
                    <div
                        className="col-10 ps-0"
                    >
                        <MonthSelector onDateSelected={this.onDateSelect} />
                    </div>
                    <div
                        className="col-2 pe-0 d-flex justify-content-end align-items-center"
                    >
                        <button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => this.onDownloadClick()}
                        >
                            Download
                        </button>

                    </div>
                </div>
                <div
                    style={{ overflow: "scroll", border: "1px solid black", borderTopLeftRadius: "7px", borderTopRightRadius: "7px", borderColor: "grey", maxHeight: "200px" }}
                    ref={this.accountsActivitiesExpensesList}
                    className="mx-4"
                >
                    <table
                        className="table job-order-table-scheduler w-100" id="scroller"
                    >
                        <thead
                            className="thead-scheduler"
                        >
                            <tr>
                                <th></th>
                                <th>User</th>
                                <th>Company</th>
                                <th>Area</th>
                            </tr>
                        </thead>
                        <tbody
                            className="tbody-scheduler"
                        >
                            {this.state.accounts?.map(acc =>
                                <tr
                                    key={acc.id}
                                >
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={acc?.selected}
                                            onChange={(event) => this.onCheckAccount(event, acc)}
                                        />
                                    </td>
                                    <td>{acc.lastName} {acc.firstName}</td>
                                    <td>{acc.company}</td>
                                    <td>{acc.userArea?.description}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div
                    className="mx-4 d-flex justify-content-center"
                >
                    <Pagination
                        currentPage={this.state.currentPage}
                        totalRecords={this.state.accountCount}
                        itemPerPage={40}
                        pageNeighbours={1}
                        onPageChanged={this.onPageChanged}
                    />
                </div>
            </>
        )
    }
}

