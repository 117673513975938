/* eslint-disable react-hooks/exhaustive-deps */
import { Fluent2WebLightTheme } from "@fluentui/fluent2-theme";
import { Calendar, CommandBar, ICommandBarItemProps, IContextualMenuItem, Icon, IconButton, Stack, Text, TooltipHost } from "@fluentui/react";
import { useForceUpdate } from "@fluentui/react-hooks";
import { ChevronDown16Regular } from "@fluentui/react-icons";
import React, { ChangeEvent, Component, useCallback, useEffect, useState } from "react";
import EntriesMonthStatusController from "../../Controllers/EntriesMonthStatusController";
import {
	ErrorHandling,
	IAbsence,
	IAccount,
	IAccountMonthStatus,
	IActivity,
	IActivityEntry,
	IExpense,
	IExpenseEntry,
	IItem,
	ILocation,
	IMonthsStatus,
	LoadingHandling,
	RequestHandler,
	SchedulerType,
	editMode,
} from "../../Models/CalendarDay";
import { fileUtils } from "../../Services/Utils/FileUtils";
import { ActivityOffCanvas } from "../Utils/ActivityOffCanvas";
import { DefaultPadding, HeaderSize, ToolbarSize } from "../Utils/ComponentSizes";
import { ExpenseOffCanvas } from "../Utils/ExpenseOffCanvas";
import {
	deleteActivityEntryArray,
	deleteExpenseEntryArray,
	getJSONHeaders,
	isHolidayToast,
	postActivityEntryArray,
	postExpenseEntryArray,
	putActivityEntryArray,
	putExpenseEntryArray,
	webApiEntriesMonthStatus,
} from "./../../AppSettings";
import { ChangeActivityOffCanvas } from "./ChangeActivitiesOffCanvas";
import ErrorsPanel from "./ManagementTable/ErrorsPanel";

enum ViewType {
	Timesheet = 0,
	Expenses = 1,
}

enum LocationToggle {
	Off_Location = 0,
	On_Location = 1,
}

enum ViewDataType {
	Details = 0,
	Pivot = 1,
}

interface IProps {
	activityEntries: IActivityEntry[];
	expenseEntries: IExpenseEntry[];
	setMonthStatusErrorFilter: () => void;

	accountId: string;
	selectedAccount: IAccountMonthStatus | null;

	absenceList: IAbsence[];
	locationList: ILocation[];
	activityList: IItem[];
	expenseList: IExpense[];
	fullActivityList: IActivity[];

	schedulerType: SchedulerType;

	isConfirmed?: boolean;
	isApproved?: boolean;
	isLocked?: boolean;
	unlockMonth: Function;
	startDate: Date;
	endDate: Date;
	onDateChange: (startDate: Date, endDate: Date) => void;
	onApproveMonth: Function;
	onDeleteApproveMonth: Function;
	downloadPdf: Function;
	downloadSchedulerPm: Function;
	selectedActivity: IActivity | null;

	onRefresh?: Function;

	onActivitySelection: Function;
	onExpenseSelection: Function;

	monthStatus: IMonthsStatus | null;
}

interface IState {
	offCanvasTitle: string;

	index: number;

	account: IAccount;
	selectedActivityEntries: IActivityEntry[];
	updateActivityEntries: IActivityEntry[];
	newActivityEntries: IActivityEntry[];
	deletedActivityEntries: IActivityEntry[];
	activityEntries: IActivityEntry[] & { selected?: boolean };

	selectedExpenseEntries: IExpenseEntry[];
	expenseEntries: IExpenseEntry[] & { selected?: boolean };
	newExpenseEntries: IExpenseEntry[];
	deletedExpenseEntries: IExpenseEntry[];
	updateExpenseEntries: IExpenseEntry[];

	editMode: editMode;
	startDate: Date;
	endDate: Date;

	totalExpenses: number;
	totalHours: number;

	isVisibleAddActivity: boolean;
	isVisibleEditActivities: boolean;
	editType: string;
	isVisibleAddExpense: boolean;
	isOpenDownloading: boolean;

	viewType: ViewType;
	viewLocation: LocationToggle;
	viewDataType: ViewDataType;
	isModalOpen: boolean;
	isErrorsPanelOpen: boolean;

	commandBarFaritems: ICommandBarItemProps[];
	dropdownElements: IContextualMenuItem[];
	errorsMonths: IAccountMonthStatus[];
}

export class SchedulerTable extends Component<IProps, IState> {
	activities: IActivityEntry[] | null = [];
	accountsActivitiesExpensesList: React.RefObject<HTMLDivElement>;
	monthStatusController: EntriesMonthStatusController = new EntriesMonthStatusController();

	constructor(props: IProps) {
		super(props);
		this.onCloseFromActivityOffCanvas = this.onCloseFromActivityOffCanvas.bind(this);
		this.onDeleteActivity = this.onDeleteActivity.bind(this);
		this.onSaveFromActivityOffCanvas = this.onSaveFromActivityOffCanvas.bind(this);
		this.onDateSelect = this.onDateSelect.bind(this);

		this.onCloseFromExpenseOffCanvas = this.onCloseFromExpenseOffCanvas.bind(this);
		this.onCloseFromChangeActivityAndExpenseOffCanvas = this.onCloseFromChangeActivityAndExpenseOffCanvas.bind(this);
		this.onDeleteExpense = this.onDeleteExpense.bind(this);
		this.onSaveFromExpenseOffCanvas = this.onSaveFromExpenseOffCanvas.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.accountsActivitiesExpensesList = React.createRef();
		this.populateDropDown = this.populateDropDown.bind(this);
		this.onSelectDropdown = this.onSelectDropdown.bind(this);
		this.updateCommandBarFarItems = this.updateCommandBarFarItems.bind(this);

		this.state = {
			index: 0,

			account: { id: this.props.accountId } as IAccount,
			isModalOpen: false,
			isErrorsPanelOpen: false,

			selectedActivityEntries: [],
			updateActivityEntries: [],
			newActivityEntries: [],
			deletedActivityEntries: [],
			errorsMonths: [],

			newExpenseEntries: [],
			deletedExpenseEntries: [],
			selectedExpenseEntries: [],
			updateExpenseEntries: [],

			offCanvasTitle: "",
			activityEntries: this.props.activityEntries.map((act) => {
				act.selected = false;
				return act;
			}),
			editMode: editMode.None,

			isVisibleAddActivity: false,
			isVisibleEditActivities: false,
			editType: "",
			isVisibleAddExpense: false,
			isOpenDownloading: false,

			totalExpenses: this.props.expenseEntries
				.map((exp) => {
					return exp.amount;
				})
				.reduce((sum, current) => sum + current, 0),
			totalHours: this.props.activityEntries
				.map((act) => {
					return act.hours;
				})
				.reduce((sum, current) => sum + current, 0),

			startDate: this.props.startDate,
			endDate: this.props.endDate,
			viewType: ViewType.Expenses,
			viewLocation: LocationToggle.Off_Location,
			viewDataType: ViewDataType.Pivot,
			expenseEntries: this.props.expenseEntries.map((exp) => {
				exp.selected = false;
				return exp;
			}),
			commandBarFaritems: [],
			dropdownElements: this.populateDropDown(this.props),
		};
	}

	componentDidUpdate(prevProps: IProps, prevState: IState) {
		if (
			this.props.activityEntries !== prevProps.activityEntries ||
			this.state.activityEntries !== prevState.activityEntries ||
			this.props.expenseEntries !== prevProps.expenseEntries ||
			this.state.expenseEntries !== prevState.expenseEntries ||
			this.state.errorsMonths !== prevState.errorsMonths ||
			this.props.startDate !== prevProps.startDate ||
			this.state.viewType !== prevState.viewType
		) {
			this.setState({ startDate: this.props.startDate });
			this.handleResizeWindow();
			this.updateCommandBarFarItems();
		}
	}

	updateCommandBarFarItems() {
		let commandBarItems: ICommandBarItemProps[] = [];
		if (this.props.schedulerType === SchedulerType.Scheduler) {
			if (this.state.errorsMonths.length) {
				commandBarItems.push({
					key: "ErrorsPanelControl",
					text: `Errors Occurred`,
					className: "rounded-1 btn btn-outline-danger text-body-danger mx-1",
					iconProps: { iconName: "Error" },
					onRenderIcon: () => {
						return <Icon iconName="Error" styles={{ root: { color: "inherit" } }} />;
					},
					onRenderMenuIcon: () => {
						return <ChevronDown16Regular />;
					},
					subMenuProps: {
						calloutProps: { theme: Fluent2WebLightTheme },
						isBeakVisible: true,
						// theme: Fluent2WebLightTheme,
						items: [...new Set(this.state.errorsMonths.map((item) => `${item.year}-${item.month}`))].map((key): IContextualMenuItem => {
							const values = key.split("-");
							const date = new Date(parseInt(values[0]), parseInt(values[1]) - 1);
							return {
								key: key,
								text: date.toLocaleDateString(undefined, { year: "numeric", month: "short" }),
								secondaryText: this.state.errorsMonths
									.filter((item) => item.year === parseInt(values[0]) && item.month === parseInt(values[1]))
									.length.toString(),
								onClick: (ev, item) => {
									if (
										date.getFullYear() !== this.props.startDate.getFullYear() ||
										date.getMonth() !== this.props.startDate.getMonth()
									) {
										const endDate = new Date(date);
										endDate.setMonth(endDate.getMonth() + 1);
										endDate.setDate(0);
										this.props.onDateChange(date, endDate);
									}
									this.props.setMonthStatusErrorFilter();
								},
							};
						}),
					},
				});
			}

			if (this.state.viewType === ViewType.Expenses) {
				let dropItems = [];

				if (this.props.monthStatus?.currentMonth.hasAttachment) {
					dropItems.push({ key: "poe", value: "Proof of expenses", text: "Proof of expenses" });
				}
				if (this.state.expenseEntries.length >= 1) {
					dropItems.push({ key: "ee", value: "Export expenses", text: "Export expenses" });
				}
				if (dropItems.length > 0) {
					commandBarItems.push({
						key: "download",
						text: "Download",
						iconProps: { iconName: "Download" },
						onRenderMenuIcon: () => <Icon iconName="ChevronDown" />,
						className: "btn btn-dark mx-1",
						subMenuProps: {
							onItemClick: (ev, item) => {
								if (item) this.onSelectDropdown(item);
							},
							items: dropItems,
						},
					});
				}
			}

			if (!this.props.isLocked && this.props.selectedAccount) {
				if (!this.props.isApproved) {
					commandBarItems.push(
						{
							key: "add-button",
							text: "Add",
							iconProps: { iconName: "Add" },
							className: "btn btn-dark mx-1",
							onClick: () => {
								if (this.state.viewType === ViewType.Timesheet) {
									this.onAddProjectClick([this.props.startDate]);
								} else {
									this.onAddExpenseClick([this.props.startDate]);
								}
							},
						},
						{
							key: "save-button",
							text: "Save",
							iconProps: { iconName: "Save" },
							className: "btn btn-dark mx-1",
							onClick: () => {
								this.onSave();
							},
						},
						{
							key: "approve-button",
							text: "Approve",
							className: "btn btn-dark mx-1",
							iconProps: { iconName: "DocumentApproval" },
							onClick: () => {
								this.props.onApproveMonth();
							},
						}
					);
				} else {
					commandBarItems.push({
						key: "unapprovd-button",
						className: "btn btn-dark mx-1",
						text: "Change Month",
						iconProps: { iconName: "DocumentReply" },
						onClick: () => {
							this.props.onDeleteApproveMonth();
						},
					});
				}
			} else if (this.props.isLocked) {
				commandBarItems.push(
					{
						key: "force-export-button",
						text: "Force Export",
						iconProps: { iconName: "Export" },
						className: "btn btn-dark mx-1",
						onClick: () => {
							this.onForceExport();
						},
					},
					{
						key: "unlock-button",
						text: "Unlock",
						className: "btn btn-dark mx-1",
						iconProps: { iconName: "Unlock" },
						onClick: () => {
							this.props.unlockMonth();
						},
					}
				);
			}

			// TODO: handle closed months

			// commandBarItems.push({
			// 	key: "tools-menu",
			// 	className: "btn btn-outline-dark p-1 mx-1",
			// 	// iconProps: { iconName: "DeveloperTools" },
			// 	onRenderMenuIcon: () => <MoreVertical20Filled />,
			// 	subMenuProps: {
			// 		isBeakVisible: true,
			// 		items: [
			// 			{
			// 				key: "massive-edit",
			// 				text: "Move timesheet entries",
			// 				// Leave iconProps for custom render
			// 				iconProps: { iconName: "" },
			// 				onRenderIcon: () => {
			// 					return <ArrowSwap20Filled />
			// 				},
			// 				onClick: () => {
			// 					this.setState({ isModalOpen: !this.state.isModalOpen });
			// 				},
			// 			},
			// 		],
			// 	},
			// });
		}

		if (
			this.state.viewType === ViewType.Timesheet &&
			this.props.schedulerType === SchedulerType.ProjectManager &&
			this.state.activityEntries.length
		) {
			commandBarItems.push({
				key: "download",
				className: "btn btn-dark rounded-top",
				text: "Download",
				iconProps: { iconName: "Download" },
				onRenderMenuIcon: () => <Icon iconName="ChevronDown" />,
				onClick: () => {
					this.props.downloadSchedulerPm();
				},
			});
		}

		this.setState({
			commandBarFaritems: commandBarItems,
		});
	}

	async onForceExport() {
		let year = this.state.startDate.getFullYear();
		let month = this.state.startDate.getMonth() + 1;
		fetch(`${webApiEntriesMonthStatus}/export/${this.props.selectedAccount?.accountId}/${year}/${month}`, {
			method: "POST",
			headers: await getJSONHeaders(),
		});
		return true;
	}

	handleResizeWindow(): void {
		if (this.accountsActivitiesExpensesList && this.accountsActivitiesExpensesList.current) {
			let height = window.innerHeight - this.accountsActivitiesExpensesList.current.getBoundingClientRect().top - 90 + "px";
			this.accountsActivitiesExpensesList.current.style.maxHeight = height;
		}
	}

	componentDidMount() {
		window.addEventListener("resize", () => {
			this.handleResizeWindow();
		});
		this.monthStatusController.getErrors().then((errors) => {
			// console.log(errors);
			this.setState({
				errorsMonths: [...errors],
			});
		});
		this.handleResizeWindow();
		this.updateCommandBarFarItems();
		this.forceUpdate();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", () => {
			this.handleResizeWindow();
		});
	}

	componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.activityEntries !== this.state.activityEntries) {
			this.updateCommandBarFarItems();
			this.setState({ activityEntries: nextProps.activityEntries });
		}

		if (nextProps.expenseEntries !== this.state.expenseEntries) {
			this.updateCommandBarFarItems();
			this.setState({
				expenseEntries: nextProps.expenseEntries,
				totalExpenses: nextProps.expenseEntries
					.map((exp) => {
						return exp.amount;
					})
					.reduce((sum, current) => sum + current, 0),
				totalHours: nextProps.activityEntries
					.map((act) => {
						return act.hours;
					})
					.reduce((sum, current) => sum + current, 0),
				dropdownElements: this.populateDropDown(nextProps),
			});
		}
	}

	populateDropDown(props: IProps) {
		var dropdownElements: IContextualMenuItem[] = [];

		if (props?.monthStatus?.currentMonth.hasAttachment) {
			dropdownElements.push({ key: "poe", value: "Proof of expenses", text: "Proof of expenses" });
		}
		if (props.expenseEntries.length >= 1) {
			dropdownElements.push({ key: "ee", value: "Export expenses", text: "Export expenses" });
		}

		return dropdownElements;
	}

	compareActivities(a: IActivityEntry, b: IActivityEntry) {
		if (new Date(a.day).getTime() > new Date(b.day).getTime()) {
			return 1;
		} else if (new Date(a.day).getTime() < new Date(b.day).getTime()) {
			return -1;
		} else {
			return 0;
		}
	}

	compareExpenses(a: IExpenseEntry, b: IExpenseEntry) {
		if (new Date(a.day).getTime() > new Date(b.day).getTime()) {
			return 1;
		} else if (new Date(a.day).getTime() < new Date(b.day).getTime()) {
			return -1;
		} else {
			return 0;
		}
	}

	toggleViewDataType() {
		let viewDataType = this.state.viewDataType === ViewDataType.Details ? ViewDataType.Pivot : ViewDataType.Details;
		let viewLocation = this.state.viewLocation;
		let activities: IActivityEntry[] = [];

		let expenses: IExpenseEntry[] = [];

		if (viewDataType === ViewDataType.Pivot) {
			if (viewLocation === LocationToggle.On_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id &&
							act.activity.id === actEnt.activity.id &&
							act.absence?.id === actEnt.absence?.id &&
							act.location?.id === actEnt.location?.id
					);
					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}

			if (viewLocation === LocationToggle.Off_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id && act.activity.id === actEnt.activity.id && act.absence?.id === actEnt.absence?.id
					);
					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}
			this.props.expenseEntries.forEach((expEnt) => {
				let expense = expenses.find(
					(exp) => exp.account.id === expEnt.account.id && exp.activity.id === expEnt.activity.id && exp.expense.id === expEnt.expense.id
				);
				if (expense) {
					expense.amount += expEnt.amount;
				} else {
					expenses.push({ ...expEnt });
				}
			});
		} else {
			activities = this.props.activityEntries;
			expenses = this.props.expenseEntries;
		}
		this.setState({ activityEntries: activities, expenseEntries: expenses, viewDataType: viewDataType });
	}

	toggleViewLocation() {
		let viewDataType = this.state.viewDataType;
		let viewLocation = this.state.viewLocation === LocationToggle.On_Location ? LocationToggle.Off_Location : LocationToggle.On_Location;
		let activities: IActivityEntry[] = [];

		if (viewDataType === ViewDataType.Pivot) {
			if (viewLocation === LocationToggle.Off_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id && act.activity.id === actEnt.activity.id && act.absence?.id === actEnt.absence?.id
					);

					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}
			if (viewLocation === LocationToggle.On_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id &&
							act.activity.id === actEnt.activity.id &&
							act.absence?.id === actEnt.absence?.id &&
							act.location?.id === actEnt.location?.id
					);

					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}
		} else {
			activities = this.props.activityEntries;
		}
		this.setState({ activityEntries: activities, viewLocation: viewLocation });
	}

	onCloseFromActivityOffCanvas(): void {
		this.setState({ isVisibleAddActivity: false, editMode: editMode.None, selectedActivityEntries: [] });
	}

	onCloseFromExpenseOffCanvas(): void {
		this.setState({ isVisibleAddExpense: false, editMode: editMode.None, selectedExpenseEntries: [] });
	}

	onCloseFromChangeActivityAndExpenseOffCanvas(): void {
		this.state.activityEntries.forEach((act) => (act.selected = false));
		this.state.expenseEntries.forEach((exp) => (exp.selected = false));
		this.props.onActivitySelection(this.state.activityEntries?.filter((act) => act.selected === true)?.length ?? 0);
		this.props.onExpenseSelection(this.state.expenseEntries?.filter((exp) => exp.selected === true)?.length ?? 0);
		this.setState({ isVisibleEditActivities: false, selectedExpenseEntries: [], selectedActivityEntries: [] });
		this.forceUpdate();
	}

	onDeleteActivity(activities: IActivityEntry[]) {
		activities.forEach((activityEntry) => {
			let isIdNew: boolean = false;
			let isIdExisting: boolean = false;

			if (activityEntry.id.length === 4) {
				isIdNew = true;
			}
			if (activityEntry.id.length === 36) {
				isIdExisting = true;
			}

			if (isIdNew === isIdExisting) {
				ErrorHandling.errorToast("Something went wrong!");
				return;
			}

			let item = this.state.activityEntries.find((act) => act.id === activityEntry.id);

			if (!item) {
				return;
			}

			if (isIdNew) {
				let newIndex = this.state.newActivityEntries.indexOf(item);

				if (newIndex >= 0) {
					this.state.newActivityEntries.splice(newIndex, 1);
				}
			}
			if (isIdExisting) {
				let updateIndex = this.state.updateActivityEntries.indexOf(item);
				if (updateIndex >= 0) {
					this.state.updateActivityEntries.splice(updateIndex, 1);
				}

				this.state.deletedActivityEntries.push(item);
			}

			let index = this.state.activityEntries.indexOf(item);
			if (index >= 0) {
				this.state.activityEntries.splice(index, 1);
			}
		});

		this.setState({
			totalHours: this.state.activityEntries
				.map((act) => {
					return act.hours;
				})
				.reduce((sum, current) => sum + current, 0),
		});
	}

	onDeleteExpense(expenses: IExpenseEntry[]) {
		let isIdNew: boolean = false;
		let isIdExisting: boolean = false;

		expenses.forEach((expenseEntry) => {
			if (expenseEntry.id.length === 4) {
				isIdNew = true;
			}
			if (expenseEntry.id.length === 36) {
				isIdExisting = true;
			}
		});

		if (isIdNew === isIdExisting) {
			ErrorHandling.errorToast("Something went wrong!");
			return;
		}

		let item = this.state.expenseEntries.find((act) => act.id === expenses[0].id);

		if (!item) {
			return;
		}

		if (isIdNew) {
			let newIndex = this.state.newExpenseEntries.indexOf(item);

			if (newIndex >= 0) {
				this.state.newActivityEntries.splice(newIndex, 1);
			}
		}

		if (isIdExisting) {
			let updateIndex = this.state.updateExpenseEntries.indexOf(item);
			if (updateIndex >= 0) {
				this.state.updateExpenseEntries.splice(updateIndex, 1);
			}

			this.state.deletedExpenseEntries.push(item);
		}

		let index = this.state.expenseEntries.indexOf(item);
		if (index >= 0) {
			this.state.expenseEntries.splice(index, 1);
		}

		this.setState({
			totalExpenses: this.state.expenseEntries
				.map((exp) => {
					return exp.amount;
				})
				.reduce((sum, current) => sum + current, 0),
		});
	}

	onAddExpenseClick(days: Date[]) {
		if (this.props.selectedAccount != null) {
			let selectedAccount = this.props.selectedAccount;
			this.setState({
				selectedExpenseEntries: days?.map((day, index) => {
					return {
						id: (this.state.index + 1).toString().padStart(4, "0"),
						account: { id: selectedAccount.accountId } as IAccount,
						activity: {
							id: "",
							description: "",
							color: "#000000",
							isFavourite: false,
						},
						expense: { company: "", id: "", description: "", type: "", amountPerDistanceUnit: 0 },
						day: day,
						arrival: "",
						departure: "",
						amount: 0,
						amountPerDistanceUnit: 0,
						distance: 0,
						note: "",
						receiptNumber: "",
					};
				}),
				index: this.state.index + 1,
				isVisibleAddExpense: true,
				editMode: editMode.Add,
				offCanvasTitle: "Add Expense",
			});
		}
	}

	onAddProjectClick(days: Date[]) {
		if (this.props.selectedAccount != null) {
			let selectedAccount = this.props.selectedAccount;
			//toast message to check first day of month if is holiday
			days.forEach((x) => isHolidayToast(new Date(x)));
			this.setState({
				selectedActivityEntries: days?.map((day, index) => {
					return {
						id: (this.state.index + 1).toString().padStart(4, "0"),
						account: { id: selectedAccount.accountId } as IAccount,
						activity: {
							id: "",
							description: "",
							isFavourite: false,
							color: "#000000",
						},
						location: { id: "", description: "", name: "" },
						absence: { id: "", type: "", description: "" },
						day: day,
						hours: 0,
						note: "",
						hasAttachment: false,
					};
				}),
				index: this.state.index + 1,
				isVisibleAddActivity: true,
				editMode: editMode.Add,
				offCanvasTitle: "Add Project",
			});
		}
	}

	onSaveFromActivityOffCanvas(activities: IActivityEntry[]) {
		let isIdNew: boolean = false;
		let isIdExisting: boolean = false;

		activities.forEach((activity) => {
			if (activity.id.length === 4) {
				isIdNew = true;
			}

			if (activity.id.length === 36) {
				isIdExisting = true;
			}
		});

		if (isIdNew === isIdExisting) {
			ErrorHandling.errorToast("Something went wrong!");
			return;
		}

		if (isIdNew) {
			activities.forEach((act) => {
				let existing: boolean = false;

				let actIndex = this.state.activityEntries.findIndex((actEnt) => actEnt.id === act.id);
				let newIndex = this.state.newActivityEntries.findIndex((newActEnt) => newActEnt.id === act.id);

				existing = newIndex >= 0 && actIndex >= 0;

				if (existing) {
					this.state.activityEntries.splice(actIndex, 1, act);
					this.state.newActivityEntries.splice(newIndex, 1, act);
				} else {
					if (newIndex === -1 && actIndex === -1) {
						this.state.activityEntries.push(act);
						this.state.newActivityEntries.push(act);
					} else {
						ErrorHandling.errorToast("Something went wrong!");
					}
				}
			});
		}

		if (isIdExisting) {
			activities.forEach((act) => {
				let existing: boolean = false;

				let actIndex = this.state.activityEntries.findIndex((actEnt) => actEnt.id === act.id);
				let updateIndex = this.state.updateActivityEntries.findIndex((newActEnt) => newActEnt.id === act.id);

				existing = updateIndex >= 0 && actIndex >= 0;

				if (existing) {
					this.state.activityEntries.splice(actIndex, 1, act);
					this.state.updateActivityEntries.splice(updateIndex, 1, act);
				} else {
					if (updateIndex === -1 && actIndex >= 0) {
						this.state.activityEntries.splice(actIndex, 1, act);
						this.state.updateActivityEntries.push(act);
					} else {
						ErrorHandling.errorToast("Something went wrong!");
					}
				}
			});
		}
		this.setState({
			totalHours: this.state.activityEntries
				.map((act) => {
					return act.hours;
				})
				.reduce((sum, current) => sum + current, 0),
			isVisibleAddActivity: false,
		});
	}

	onSaveFromExpenseOffCanvas(expenses: IExpenseEntry[]) {
		let isIdNew: boolean = false;
		let isIdExisting: boolean = false;

		expenses.forEach((expense) => {
			if (expense.id.length === 4) {
				isIdNew = true;
			}

			if (expense.id.length === 36) {
				isIdExisting = true;
			}
		});

		if (isIdNew === isIdExisting) {
			ErrorHandling.errorToast("Something went wrong!");
			return;
		}

		if (isIdNew) {
			expenses.forEach((exp) => {
				let existing: boolean = false;

				let expIndex = this.state.expenseEntries.findIndex((expEnt) => expEnt.id === exp.id);
				let newIndex = this.state.newExpenseEntries.findIndex((newExpEnt) => newExpEnt.id === exp.id);

				existing = newIndex >= 0 && expIndex >= 0;

				if (existing) {
					this.state.expenseEntries.splice(expIndex, 1, exp);
					this.state.newExpenseEntries.splice(newIndex, 1, exp);
				} else {
					if (newIndex === -1 && expIndex === -1) {
						this.state.expenseEntries.push(exp);
						this.state.newExpenseEntries.push(exp);
					} else {
						ErrorHandling.errorToast("Something went wrong!");
					}
				}
			});
		}

		if (isIdExisting) {
			expenses.forEach((exp) => {
				let existing: boolean = false;

				let expIndex = this.state.expenseEntries.findIndex((expEnt) => expEnt.id === exp.id);
				let updateIndex = this.state.updateExpenseEntries.findIndex((newExpEnt) => newExpEnt.id === exp.id);

				existing = updateIndex >= 0 && expIndex >= 0;

				if (existing) {
					this.state.expenseEntries.splice(expIndex, 1, exp);
					this.state.updateExpenseEntries.splice(updateIndex, 1, exp);
				} else {
					if (updateIndex === -1 && expIndex >= 0) {
						this.state.expenseEntries.splice(expIndex, 1, exp);
						this.state.updateExpenseEntries.push(exp);
					} else {
						ErrorHandling.errorToast("Something went wrong!");
					}
				}
			});
		}

		this.setState({
			totalExpenses: this.state.expenseEntries
				.map((exp) => {
					return exp.amount;
				})
				.reduce((sum, current) => sum + current, 0),
		});
	}

	onDateSelect(startDate: Date, endDate: Date) {
		this.setState({ startDate: startDate, endDate: endDate });
		this.props.onDateChange(startDate, endDate);
	}

	toggleViewType() {
		this.setState({ viewType: this.state.viewType === ViewType.Timesheet ? ViewType.Expenses : ViewType.Timesheet });
	}

	toggleLocation() {
		let viewLocation = this.state.viewLocation === LocationToggle.On_Location ? LocationToggle.Off_Location : LocationToggle.On_Location;
		let activities: IActivityEntry[] = [];

		let expenses: IExpenseEntry[] = [];

		if (this.state.viewDataType === ViewDataType.Pivot) {
			if (viewLocation === LocationToggle.On_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id &&
							act.activity.id === actEnt.activity.id &&
							act.absence?.id === actEnt.absence?.id &&
							act.location?.id === actEnt.location?.id
					);
					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}

			if (viewLocation === LocationToggle.Off_Location) {
				this.props.activityEntries.forEach((actEnt) => {
					let activity = activities.find(
						(act) =>
							act.account.id === actEnt.account.id && act.activity.id === actEnt.activity.id && act.absence?.id === actEnt.absence?.id
					);
					if (activity) {
						activity.hours += actEnt.hours;
					} else {
						activities.push({ ...actEnt });
					}
				});
			}
			this.props.expenseEntries.forEach((expEnt) => {
				let expense = expenses.find(
					(exp) => exp.account.id === expEnt.account.id && exp.activity.id === expEnt.activity.id && exp.expense.id === expEnt.expense.id
				);
				if (expense) {
					expense.amount += expEnt.amount;
				} else {
					expenses.push({ ...expEnt });
				}
			});
		} else {
			activities = this.props.activityEntries;
			expenses = this.props.expenseEntries;
		}
		this.setState({ activityEntries: activities, expenseEntries: expenses, viewLocation: viewLocation });
	}

	async onSave() {
		LoadingHandling.showLoader();

		let postActivityEntries: IActivityEntry[] = [];
		let putActivityEntry: IActivityEntry[] = [];
		let deleteActivityEntry: string[] = [];
		let postExpenseEntry: IExpenseEntry[] = [];
		let putExpenseEntry: IExpenseEntry[] = [];
		let deleteExpenseEntry: string[] = [];

		if (this.state.newActivityEntries.length > 0) {
			postActivityEntries = await postActivityEntryArray(this.state.newActivityEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}
		if (this.state.updateActivityEntries.length > 0) {
			putActivityEntry = await putActivityEntryArray(this.state.updateActivityEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}
		if (this.state.deletedActivityEntries.length > 0) {
			deleteActivityEntry = await deleteActivityEntryArray(this.state.deletedActivityEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}

		if (this.state.newExpenseEntries.length > 0) {
			postExpenseEntry = await postExpenseEntryArray(this.state.newExpenseEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}
		if (this.state.updateExpenseEntries.length > 0) {
			putExpenseEntry = await putExpenseEntryArray(this.state.updateExpenseEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}
		if (this.state.deletedExpenseEntries.length > 0) {
			deleteExpenseEntry = await deleteExpenseEntryArray(this.state.deletedExpenseEntries).catch((error) => {
				RequestHandler.badRequestReceived(error, "Something went wrong!");
				return [];
			});
		}

		if (
			this.state.newActivityEntries.length === postActivityEntries.length &&
			this.state.updateActivityEntries.length === putActivityEntry.length &&
			this.state.deletedActivityEntries.length === deleteActivityEntry.length &&
			this.state.newExpenseEntries.length === postExpenseEntry.length &&
			this.state.updateExpenseEntries.length === putExpenseEntry.length &&
			this.state.deletedExpenseEntries.length === deleteExpenseEntry.length
		) {
			let activityEntries: IActivityEntry[] = this.state.activityEntries;
			this.state.newActivityEntries.forEach((act) => activityEntries.splice(activityEntries.indexOf(act), 1));
			activityEntries.push(...postActivityEntries);

			let expenseEntries: IExpenseEntry[] = this.state.expenseEntries;
			this.state.newExpenseEntries.forEach((exp) => expenseEntries.splice(expenseEntries.indexOf(exp), 1));
			expenseEntries.push(...postExpenseEntry);

			this.setState(
				{
					activityEntries: activityEntries,
					expenseEntries: expenseEntries,
					newActivityEntries: [],
					updateActivityEntries: [],
					deletedActivityEntries: [],
					newExpenseEntries: [],
					updateExpenseEntries: [],
					deletedExpenseEntries: [],
				},
				() => {
					if (this.props.onRefresh) {
						this.props.onRefresh();
					}
				}
			);

			ErrorHandling.successToast("Timesheet and Expenses saved");
		} else {
			ErrorHandling.errorToast("Something went wrong!");
		}

		LoadingHandling.hideLoader();
	}

	onCheckActivity(event: ChangeEvent<HTMLInputElement>, activity: IActivityEntry) {
		activity.selected = !activity.selected;
		this.props.onActivitySelection(this.state.activityEntries?.filter((act) => act.selected === true)?.length ?? 0);
	}

	onCheckExpense(event: ChangeEvent<HTMLInputElement>, expense: IExpenseEntry) {
		expense.selected = !expense.selected;
		this.props.onExpenseSelection(this.state.expenseEntries?.filter((exp) => exp.selected === true)?.length ?? 0);
	}

	onEditActivities() {
		this.setState({
			selectedActivityEntries: [...this.state.activityEntries?.filter((act) => act.selected === true)],
			editType: "Activities",
			isVisibleEditActivities: true,
		});
	}

	onEditExpenses() {
		this.setState({
			selectedExpenseEntries: [...this.state.expenseEntries?.filter((exp) => exp.selected === true)],
			editType: "Expenses",
			isVisibleEditActivities: true,
		});
	}

	downloadFile() {
		if (this.props.monthStatus != null) {
			if (this.props.monthStatus.currentMonth.hasAttachment != null) {
				let currentMonth = this.props.monthStatus.currentMonth;
				fileUtils.downloadFile(
					`${webApiEntriesMonthStatus}/${currentMonth.accountId}/${currentMonth.year}/${currentMonth.month}/downloadExpenses`,
					false
				);
			}
		}
	}

	onSelectDropdown(elem: IContextualMenuItem) {
		switch (elem.key) {
			case "poe":
				this.downloadFile();
				break;
			case "ee":
				this.props.downloadPdf();
				break;
		}
	}

	render() {
		return (
			<Stack
				className="mh-100 rounded"
				tokens={{ childrenGap: 8 }}
				style={{ maxHeight: `calc(100vh - ${HeaderSize} - ${DefaultPadding} - ${DefaultPadding})`, minWidth: "400px" }}
			>
				<SchedulerToolBar
					onDateChange={this.onDateSelect}
					farItems={this.state.commandBarFaritems}
					viewType={this.state.viewType}
					date={this.state.startDate}
					toggleLocation={() => {
						this.toggleLocation();
					}}
					toggleExpenses={() => {
						this.toggleViewType();
					}}
					togglePivot={() => {
						this.toggleViewDataType();
					}}
				/>
				<div className="rounded" style={{ boxShadow: "0 0 2px #99999999", minWidth: "400px" }}>
					{this.props.selectedAccount && (
						<Stack
							className="w-100 rounded-0 hstack px-2 justify-content-between"
							styles={{ root: { height: ToolbarSize, minWidth: "400px" } }}
						>
							<Text styles={{ root: { userSelect: "none" } }}>
								{this.props.selectedAccount?.firstName} {this.props.selectedAccount?.lastName}
							</Text>
							{this.props.monthStatus?.currentMonth.isError && (
								<Stack
									horizontal
									tokens={{ childrenGap: 8 }}
									verticalAlign="center"
									styles={{
										root: {
											color: "red",
										},
									}}
								>
									<Icon
										title="Error: Click to copy"
										iconName="Error"
										onClick={() => navigator.clipboard.writeText(this.props.monthStatus?.currentMonth.errorMessage ?? "")}
									/>
									<Text styles={{ root: { color: "red" } }}>{this.props.monthStatus?.currentMonth.errorMessage ?? ""}</Text>
								</Stack>
							)}
						</Stack>
					)}
					{
						<div
							className="overflow-y-scroll p-0 rounded overflow-hidden"
							style={{
								margin: 0,
								minWidth: "400px",
								maxHeight: `calc(100vh - ${HeaderSize} - ${ToolbarSize} - ${ToolbarSize} - ${DefaultPadding} - ${DefaultPadding} - 8px)`,
								minHeight: `calc(100vh - 8px - ${HeaderSize} - ${ToolbarSize} - ${DefaultPadding} - ${DefaultPadding}${
									this.props.schedulerType === SchedulerType.Scheduler && this.props.selectedAccount ? " - 44px" : ""
								})`,
							}}
						>
							{this.state.viewType === ViewType.Timesheet &&
							(this.props.selectedAccount || this.props.selectedActivity) &&
							this.state.activityEntries.length ? (
								<table
									className="table table-hover table-bordered rounded m-0 p-0 h-auto"
									style={{ margin: 0, height: "100%", width: "100%", fontSize: "14px", minWidth: "400px" }}
								>
									<thead className="sticky-top rounded">
										<tr className="rounded">
											{this.props.schedulerType === SchedulerType.Scheduler &&
												this.state.viewDataType === ViewDataType.Details &&
												!this.props.isLocked &&
												!this.props.isApproved && <th className="w-5" scope="col"></th>}

											{this.state.viewDataType === ViewDataType.Details && <th scope="col">Day</th>}

											{this.props.schedulerType === SchedulerType.Scheduler && (
												<th scope="col" style={{ width: "25%" }}>
													Activity
												</th>
											)}
											{this.props.schedulerType === SchedulerType.ProjectManager && (
												<th style={{ width: "25%" }} scope="col">
													User
												</th>
											)}
											{this.state.viewLocation === LocationToggle.On_Location && (
												<th scope="col" style={{ width: "5%" }}>
													Location
												</th>
											)}

											{this.props.schedulerType === SchedulerType.Scheduler && (
												<th scope="col" style={{ width: "15%" }}>
													Absence
												</th>
											)}
											{this.state.viewDataType === ViewDataType.Details && <th scope="col">Note</th>}
											<th scope="col" style={{ width: "5%" }}>
												Hour
											</th>
										</tr>
									</thead>
									<tbody className="m-0 p-0 w-100" style={{ minWidth: "400px" }}>
										{this.state.activityEntries.sort(this.compareActivities).map((act) => (
											<tr key={this.state.viewDataType + "-" + act.id} className="w-100 m-0 p-0 align-middle">
												{this.props.schedulerType === SchedulerType.Scheduler &&
													this.state.viewDataType === ViewDataType.Details &&
													!this.props.isLocked &&
													!this.props.isApproved && (
														<td className="w-10" style={{ width: "10%" }}>
															<Stack horizontal verticalAlign="center">
																<IconButton
																	iconProps={{ iconName: "Edit" }}
																	className="btn btn-outline-dark border-color-none "
																	onClick={() => {
																		this.onCloseFromChangeActivityAndExpenseOffCanvas();
																		this.setState({
																			selectedActivityEntries: [act],
																			isVisibleAddActivity: true,
																			editMode: editMode.Edit,
																			offCanvasTitle: "Edit Project",
																		});
																	}}
																/>

																<IconButton
																	className="btn btn-outline-dark border-0"
																	iconProps={{ iconName: act.selected ? "SkypeCircleCheck" : "LocationCircle" }}
																	onClick={(event: any) => {
																		this.onCheckActivity(event, act);
																	}}
																/>
															</Stack>
														</td>
													)}
												{this.state.viewDataType === ViewDataType.Details && (
													<td style={{ textAlign: "center", width: "10%" }}>
														{new Date(act.day).toLocaleString(undefined, { weekday: "short", day: "2-digit" })}
													</td>
												)}
												{this.props.schedulerType === SchedulerType.Scheduler && (
													<td className="text-break">{act.activity.description}</td>
												)}

												{this.props.schedulerType === SchedulerType.ProjectManager && (
													<td>
														{act.account.firstName} {act.account.lastName}
													</td>
												)}
												{this.state.viewLocation === LocationToggle.On_Location && (
													<td style={{ textAlign: "center" }}>
														<TooltipHost
															id={act.id}
															content={act.location?.name}
															styles={{ root: { display: "inline-block" } }}
														>
															<Icon
																aria-describedby={act.id}
																iconName={
																	act.location?.name === "@smartworking"
																		? "Home"
																		: act.location?.name === "@customer"
																		? "WorkforceManagement"
																		: act.location?.name === "@office"
																		? "Work"
																		: "Minus"
																}
															/>
														</TooltipHost>
													</td>
												)}

												{this.props.schedulerType === SchedulerType.Scheduler && (
													<td className="text-break">{act.absence?.description ?? "-"}</td>
												)}
												{this.state.viewDataType === ViewDataType.Details && (
													<td className="text-break" style={{ maxLines: "2" }}>
														{act.note}
													</td>
												)}
												<td style={{ textAlign: "center" }}>{act.hours}</td>
											</tr>
										))}
									</tbody>
									<tfoot className="sticky-bottom z-3 w-100 rounded-bottom">
										<tr className="rounded-bottom border-bottom-0">
											{this.props.schedulerType === SchedulerType.Scheduler &&
												this.state.viewDataType === ViewDataType.Details &&
												!this.props.isLocked &&
												!this.props.isApproved && <th className="w-10"></th>}
											{this.state.viewDataType === ViewDataType.Details && <th></th>}

											{this.props.schedulerType === SchedulerType.Scheduler && <th style={{ width: "25%" }}></th>}
											{this.props.schedulerType === SchedulerType.ProjectManager && (
												<th style={{ textAlign: "end" }}>
													{this.state.viewLocation !== LocationToggle.On_Location &&
														this.state.viewDataType !== ViewDataType.Details && <Text variant="xLarge">Total</Text>}
												</th>
											)}
											{this.state.viewLocation === LocationToggle.On_Location && (
												<th style={{ width: "5%", textAlign: "end" }}>
													{this.props.schedulerType !== SchedulerType.Scheduler &&
														this.state.viewDataType !== ViewDataType.Details && <Text variant="xLarge">Total</Text>}
												</th>
											)}

											{this.props.schedulerType === SchedulerType.Scheduler && (
												<th style={{ width: "15%", textAlign: "end" }}>
													{this.state.viewDataType !== ViewDataType.Details && <Text variant="xLarge">Total</Text>}
												</th>
											)}
											{this.state.viewDataType === ViewDataType.Details && (
												<th style={{ maxHeight: "50px", height: "50px", textAlign: "end" }}>
													<Text variant="xLarge">Total</Text>
												</th>
											)}
											<td style={{ textAlign: "center" }}>
												{(this.props.selectedAccount || this.props.selectedActivity) && (
													// <Stack horizontalAlign='center'>
													<Text variant="xLarge">{this.state.totalHours}</Text>
													// </Stack>
												)}
											</td>
										</tr>
									</tfoot>
								</table>
							) : this.state.viewType === ViewType.Expenses && this.state.expenseEntries.length ? (
								<table
									className="table table-hover table-bordered rounded m-0 h-auto"
									style={{ margin: 0, height: "100%", width: "100%", fontSize: "14px" }}
								>
									<thead className="sticky-top rounded" style={{ minHeight: ToolbarSize }}>
										<tr className="rounded h-100" style={{ minHeight: ToolbarSize }}>
											{this.props.schedulerType === SchedulerType.Scheduler &&
												this.state.viewDataType === ViewDataType.Details &&
												!this.props.isLocked &&
												!this.props.isApproved && <th style={{ width: "0%" }}></th>}
											{this.state.viewDataType === ViewDataType.Details && <th style={{ maxWidth: "10%" }}>Day</th>}

											{this.props.schedulerType === SchedulerType.Scheduler && <th>Activity</th>}
											{this.props.schedulerType === SchedulerType.ProjectManager && <th>User</th>}

											<th>Distance</th>

											<th>Expense</th>

											{this.state.viewDataType === ViewDataType.Details && <th className="w-90">Note</th>}

											<th style={{ width: "5%" }}>Amount</th>
										</tr>
									</thead>
									<tbody className="tbody-scheduler">
										{this.state.expenseEntries.sort(this.compareExpenses).map((exp) => (
											<tr key={this.state.viewDataType + "-" + exp.id} className="w-100 m-0 p-0 align-middle">
												{this.props.schedulerType === SchedulerType.Scheduler &&
													this.state.viewDataType === ViewDataType.Details &&
													!this.props.isLocked &&
													!this.props.isApproved && (
														<td className="w-10" style={{ width: "10%" }}>
															<Stack horizontal verticalAlign="center">
																<IconButton
																	iconProps={{ iconName: "Edit" }}
																	className="btn btn-outline-dark border-color-none "
																	onClick={() => {
																		this.setState({
																			selectedExpenseEntries: [exp],
																			isVisibleAddExpense: true,
																			editMode: editMode.Edit,
																			offCanvasTitle: "Edit Expense",
																		});
																	}}
																/>

																<IconButton
																	className="btn btn-outline-dark border-0"
																	iconProps={{ iconName: exp.selected ? "SkypeCircleCheck" : "LocationCircle" }}
																	onClick={(event: any) => {
																		this.onCheckExpense(event, exp);
																	}}
																/>
															</Stack>
														</td>
													)}
												{this.state.viewDataType === ViewDataType.Details && (
													<td className="text-center" style={{ width: "10%" }}>
														{new Date(exp.day).toLocaleString(undefined, { weekday: "short", day: "2-digit" })}
													</td>
												)}
												{this.props.schedulerType === SchedulerType.Scheduler && <td>{exp.activity.description}</td>}

												{this.props.schedulerType === SchedulerType.ProjectManager && (
													<td>
														{exp.account.firstName} {exp.account.lastName}
													</td>
												)}

												<td>{exp.distance !== 0 ? exp.distance + " KM" : "-"} </td>

												<td>{exp.expense.description}</td>

												{this.state.viewDataType === ViewDataType.Details && (
													<td className="text-break">
														{exp.note}
														<br />
													</td>
												)}
												<td className="text-center" style={{ width: "5%" }}>
													{exp.amount.toFixed(2).toString()} €
												</td>
											</tr>
										))}
									</tbody>
									<tfoot className="sticky-bottom z-3 w-100 rounded-bottom">
										<tr className="rounded-bottom border-bottom-0">
											{this.props.schedulerType === SchedulerType.Scheduler &&
												this.state.viewDataType === ViewDataType.Details &&
												!this.props.isLocked &&
												!this.props.isApproved && <th style={{ width: "0%" }}></th>}
											{this.state.viewDataType === ViewDataType.Details && <th style={{ maxWidth: "10%" }}></th>}

											{this.props.schedulerType === SchedulerType.Scheduler && <th></th>}
											{this.props.schedulerType === SchedulerType.ProjectManager && <th></th>}

											<th></th>

											<th style={{ maxHeight: "50px", height: "50px", textAlign: "end" }}>
												{this.state.viewDataType !== ViewDataType.Details && <Text variant="xLarge">Total</Text>}
											</th>

											{this.state.viewDataType === ViewDataType.Details && (
												<th style={{ maxHeight: "50px", height: "50px", textAlign: "end" }}>
													<Text variant="xLarge">Total</Text>
												</th>
											)}

											<th style={{ width: "15%", textAlign: "center" }}>
												<Text variant="xLarge">{this.state.totalExpenses.toFixed(2)} €</Text>
											</th>
										</tr>
									</tfoot>
								</table>
							) : (
								<Stack
									verticalAlign="center"
									horizontalAlign="center"
									verticalFill
									styles={{
										root: {
											minHeight: `calc(100vh - ${HeaderSize} - ${ToolbarSize} - ${DefaultPadding} - ${DefaultPadding} - 8px${
												this.props.schedulerType === SchedulerType.Scheduler && this.props.selectedAccount ? " - 44px" : ""
											})`,
										},
									}}
								>
									<Text variant="large">
										{!this.props.selectedAccount && !this.props.selectedActivity
											? this.props.schedulerType === SchedulerType.Scheduler
												? "Select an user"
												: "Select a project"
											: "No entries found..."}
									</Text>
								</Stack>
							)}
						</div>
					}
				</div>

				{this.state.isVisibleAddActivity && this.props.selectedAccount !== null && this.state.viewType === ViewType.Timesheet && (
					<ActivityOffCanvas
						activityOffCanvasTitle={this.state.offCanvasTitle}
						activityEntries={this.state.selectedActivityEntries}
						onClose={this.onCloseFromActivityOffCanvas}
						onSave={this.onSaveFromActivityOffCanvas}
						onDelete={this.onDeleteActivity}
						activityOffCanvasIsVisible={this.state.isVisibleAddActivity}
						editMode={this.state.editMode}
						startDate={this.props.startDate}
						endDate={this.props.endDate}
						absenceList={this.props.absenceList}
						locationList={this.props.locationList}
						activityList={this.props.activityList}
						isSchedulerPage={true}
					/>
				)}
				{this.state.isVisibleEditActivities &&
					this.props.selectedAccount !== null &&
					(this.state.viewType === ViewType.Timesheet || this.state.viewType === ViewType.Expenses) && (
						<ChangeActivityOffCanvas
							activityOffCanvasTitle={"Edit " + this.state.editType}
							activityEntries={
								this.state.editType === "Activities" ? this.state.selectedActivityEntries : this.state.selectedExpenseEntries
							}
							onClose={this.onCloseFromChangeActivityAndExpenseOffCanvas}
							onSave={this.state.editType === "Activities" ? this.onSaveFromActivityOffCanvas : this.onSaveFromExpenseOffCanvas}
							onDelete={this.state.editType === "Activities" ? this.onDeleteActivity : this.onDeleteExpense}
							activityList={this.props.fullActivityList}
							defaultLocation={this.props.locationList.filter((x) => x.name === "@smartworking")[0]}
						/>
					)}
				{this.state.isVisibleAddExpense && this.props.selectedAccount !== null && this.state.viewType === ViewType.Expenses && (
					<ExpenseOffCanvas
						expenseOffCanvasTitle={this.state.offCanvasTitle}
						expenseEntries={this.state.selectedExpenseEntries}
						expenseListComplete={this.state.expenseEntries}
						onClose={this.onCloseFromExpenseOffCanvas}
						onSave={this.onSaveFromExpenseOffCanvas}
						onDelete={this.onDeleteExpense}
						expenseOffCanvasIsVisible={this.state.isVisibleAddExpense}
						editMode={this.state.editMode}
						startDate={this.props.startDate}
						endDate={this.props.endDate}
						expenseList={this.props.expenseList}
						activityList={this.props.activityList}
						isSchedulerPage={true}
					/>
				)}

				{/* 
					// TODO: handle closed months
					<MassiveEditModal
						isOpen={this.state.isModalOpen}
						onDismiss={() => {
							this.setState({ isModalOpen: false });
						}}
					/> 
				*/}
				<ErrorsPanel
					list={this.state.errorsMonths}
					isOpen={this.state.isErrorsPanelOpen}
					onDismiss={() => this.setState({ isErrorsPanelOpen: false })}
				/>
			</Stack>
		);
	}
}

type SchedulerToolBarProps = {
	viewType: ViewType;
	farItems?: ICommandBarItemProps[];
	date?: Date;
	onDateChange: (startDate: Date, endDate: Date) => void;
	toggleLocation: () => void;
	toggleExpenses: () => void;
	togglePivot: () => void;
};

function SchedulerToolBar(props: SchedulerToolBarProps) {
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date(props.date ?? 0));
	const [showLocation, setShowLocation] = React.useState(true);
	const [showExpenses, setShowExpensens] = React.useState(false);
	const [showPivot, setShowPivot] = React.useState(false);
	// const [errorsPanelIsOpen, setErrorsPanelIsOpen] = React.useState(false);
	// const ref = createRef<ICommandBar>();
	const [farItems, setFarItems] = useState<ICommandBarItemProps[]>([...(props.farItems ?? [])]);
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		setFarItems(props.farItems ?? []);
		forceUpdate();
	}, [props.farItems]);

	useEffect(() => {
		setSelectedDate(new Date(props.date ?? 0));
		console.log(props.date);
		forceUpdate();
	}, [props.date]);

	const setDate = useCallback(
		(date: Date) => {
			const startDate = new Date(date);
			startDate.setDate(1);
			const endDate = new Date(startDate);
			endDate.setMonth(endDate.getMonth() + 1);
			endDate.setDate(0);
			props.onDateChange(startDate, endDate);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[selectedDate]
	);

	useEffect(() => {
		props.toggleLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showLocation]);

	useEffect(() => {
		props.toggleExpenses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showExpenses]);

	useEffect(() => {
		props.togglePivot();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPivot]);

	let items: ICommandBarItemProps[] = [
		{
			key: "month-picker",
			text: props.date?.toLocaleString(undefined, { year: "numeric", month: "short" }),
			iconProps: { iconName: "Calendar" },
			onRenderMenuIcon: () => {
				return <ChevronDown16Regular />;
			},
			className: "btn btn-dark rounded shadow-sm me-1",
			subMenuProps: {
				items: [
					{
						key: "Calendar",
						onRender(item, dismissMenu) {
							return (
								<Calendar
									onSelectDate={(date) => {
										setDate(date);
										dismissMenu();
									}}
									value={selectedDate}
									minDate={new Date(2021, 0, 1)}
									highlightSelectedMonth={true}
									isDayPickerVisible={false}
									onDismiss={dismissMenu}
									styles={{
										root: {
											selectors: {
												'button[class*="selected"]': {
													backgroundColor: "var(--bs-dark)",
													color: "white",
												},
											},
										},
									}}
								/>
							);
						},
					},
				],
			},
		},
		{
			key: "expenseToggle",
			text: "Expenses",
			canCheck: true,
			className: "rounded border-0 shadow-sm mx-1",
			checked: showExpenses,
			iconProps: { iconName: showExpenses ? "SkypeCircleCheck" : "LocationCircle" },
			onClick: () => {
				setShowExpensens(!showExpenses);
			},
		},
		{
			key: "pivotToggle",
			text: "Pivot",
			canCheck: true,
			className: "rounded border-0 shadow-sm mx-1",
			checked: showPivot,
			iconProps: { iconName: showPivot ? "SkypeCircleCheck" : "LocationCircle" },
			onClick: () => {
				setShowPivot(!showPivot);
			},
		},
	];

	if (!showExpenses) {
		items.push({
			key: "locationToggle",
			text: "Location",
			canCheck: true,
			className: "rounded border-0 shadow-sm mx-1",
			checked: showLocation,
			iconProps: { iconName: showLocation ? "SkypeCircleCheck" : "LocationCircle" },
			onClick: () => {
				setShowLocation(!showLocation);
			},
		});
	}

	return <CommandBar items={items} farItems={farItems} styles={{ root: { padding: 0 } }} />;
}
