import React, { Component } from "react";
import Header from "./Components/Header/Header";
import CalendarPage from "./Components/CalendarPage/CalendarPage";
import Scheduler from "./Components/Scheduler/Scheduler";
import JobOrderCard from "./Components/JobOrderCard/JobOrderCardPage";
import DownloadReport from "./Components/Report/DownloadReport";
import GoodsReceiptPage from "./Components/GoodsReceipt/GoodsReceiptPage";
import SalesPage from "./Components/Sales/SalesPage";
import DownloadRequestPage from "./Components/DownloadRequest/DownloadRequestPage";
import RequestsPage from "./Components/RequestPage/RequestsPage";
import SchedulerRequestsPage from "./Components/RequestPage/Scheduler/SchedulerRequestsPage";
import { Menu as navigationMenu, IAccount, IMenuItem, SchedulerType, RequestHandler, ErrorHandling, LoadingHandling } from "./Models/CalendarDay";
import { webApiAccounts, setAccount, webApiFactoryCalendarDate, setHolidayCalendar, getSwitchCompany, getJSONHeaders, webApiFeature } from "./AppSettings";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { withMsal, WithMsalProps } from "@azure/msal-react";
import { loginRequest } from "./Components/Auth/authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { format } from "date-fns";
import ProjectAssignmentPage from "./Components/ProjectAssignment/ProjectAssignmentPage";
import MaintenancePage from "./Components/AegisCommon/MaintenancePage";
import { Stack } from "@fluentui/react";

interface IState {
	account: IAccount;
	email: string;
	selectedMenu: IMenuItem;
	headers: Headers;
	accessToken: string;
	loadingData: boolean;
	loadedData: boolean;
	switch: boolean;
	manteinance?: boolean;
}

class AppComponent extends Component<WithMsalProps, IState> {
	constructor(props: any) {
		super(props);
		this.callLogin = this.callLogin.bind(this);
		this.callLogout = this.callLogout.bind(this);
		this.loadToken = this.loadToken.bind(this);
		this.getCalendario = this.getCalendario.bind(this);
		this.switchCompany = this.switchCompany.bind(this);
		this.redirectTo = this.redirectTo.bind(this);

		this.state = {
			account: { id: "", firstName: "", lastName: "", company: "", email: "", authorizations: [], site: "", userCompanies: [] },
			selectedMenu: this.getActualRoute(),
			headers: new Headers(),
			email: "",
			accessToken: "",
			loadingData: false,
			loadedData: false,
			switch: false,
			manteinance: false,
		};
	}

	getActualRoute() {
		const location = window.location.href.split("/")[3];
		if (location.length === 0) {
			return navigationMenu.Timesheet;
		}
		const nav = navigationMenu.FindItem(location);
		if (!nav) {
			return navigationMenu.Timesheet;
		}
		return nav;
	}

	callLogout() {
		const msalInstance = this.props.msalContext.instance;
		msalInstance.logoutRedirect().catch((e) => {
			console.error(e);
		});
	}

	callLogin() {
		const accounts = this.props.msalContext.accounts;
		const isAuthenticated = accounts.length > 0;
		const msalInstance = this.props.msalContext.instance;

		// If a user is not logged in and authentication is not already in progress, invoke login
		if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
			msalInstance.loginRedirect().then(
				function () {
					//thisMe.loadToken();
				},
				function (error: any) {
					console.log(error.message);
				}
			);
		} else if (isAuthenticated && !this.state.loadingData && !this.state.loadedData) {
			//thisMe.loadToken();
		}
	}

	loadToken() {
		const accounts = this.props.msalContext.accounts;
		const msalInstance = this.props.msalContext.instance;
		const thisMe = this;

		const request = {
			...loginRequest,
			account: accounts[0],
		};

		msalInstance
			.acquireTokenSilent(request)
			.then(function (response: any) {
				thisMe.setState({ accessToken: response.accessToken, email: response.account.username }, () => {
					thisMe.loadData();
					thisMe.getCalendario();
				});
			})
			.catch(function (e: any) {
				msalInstance.acquireTokenRedirect(request).then(function (response: any) {
					thisMe.setState({ accessToken: response.accessToken, email: response.account.username }, () => {
						thisMe.loadData();
						thisMe.getCalendario();
					});
				});
			});
	}

	async loadData() {
		this.setState({ loadingData: true });
		const isAuthenticated = this.props.msalContext.accounts.length > 0;
		if (isAuthenticated && (!this.state?.account?.id || this.state?.account?.id === "") && this.state.accessToken) {
			// let bearer = `Bearer ${this.state.accessToken}`;

			const options = {
				method: "GET",
				headers: await getJSONHeaders(),
			};

			let webApiMe = webApiAccounts + "/me";
			fetch(webApiMe, options)
				.then(ErrorHandling.handleError)
				.then((x) => x.json())
				.then((result) => {
					setAccount(result);
					this.setState({
						account: result,
						loadingData: false,
						loadedData: true,
						selectedMenu: this.getActualRoute(), //navigationMenu.FindByAuthorizationName(result.authorizations[0]),
					});
				})
				.catch((error) => errorOccurred(error, this));
		}
		function errorOccurred(error: string, page: AppComponent) {
			page.setState({
				account: {
					id: "",
					firstName: "",
					lastName: "",
					company: "",
					email: "",
					site: "",
					userCompanies: [],
				},
				loadingData: false,
				loadedData: false,
			});
			RequestHandler.badRequestReceived(error, "An Error has occured. Contact your administrator.");
		}
	}

	switchCompany(companyId: string) {
		getSwitchCompany(companyId)
			.then((result) => {
				// if switching I'd have no permission on the actual page, reroute to the first page allowed
				if (result.authorizations && !this.checkAuth(result.authorizations)) {
					let route = navigationMenu.FindByAuthorizationName(result.authorizations[0]);
					this.setState({ selectedMenu: route, account: result, switch: true, loadingData: false });
				} else {
					this.setState({ account: result, switch: false, loadingData: false });
				}
			})
			.catch((error) => errorOccurred(error, this));

		function errorOccurred(error: string, page: AppComponent) {
			page.setState({
				account: {
					id: "",
					firstName: "",
					lastName: "",
					company: "",
					email: "",
					site: "",
					userCompanies: [],
				},
				loadingData: false,
				loadedData: false,
			});
			RequestHandler.badRequestReceived(error, "An Error has occured. Contact your administrator.");
		}
	}

	componentDidMount() {
		fetch(`${webApiFeature}/manteinance`)
			.then((x) => x.json())
			.then((result) => {
				this.setState({ manteinance: result as boolean });
			})
			.catch((e) => console.log(e));
		if (this.props.msalContext.accounts.length > 0 && (!this.state?.account?.id || this.state?.account?.id === "") && !this.state.loadingData && !this.state.loadedData) {
			this.loadToken();
		}
	}

	componentDidUpdate(prevProps: WithMsalProps, prevState: IState) {
		if (this.props.msalContext.accounts.length > 0 && prevProps.msalContext.accounts.length === 0) {
			if (this.props.msalContext.accounts.length > 0 && !this.state?.account?.id && !this.state.loadingData && !this.state.loadedData) {
				this.loadToken();
			}
		}
	}

	getItem() {
		let url = window.location.href.split("/");

		//check if the last part of the url have "?", if true i remove it. this realod the page correctly, maybe fix to first log in unauthorized problem also to line 248
		let menuUrl = url[url.length - 1].slice(-1) === "?" ? url[url.length - 1].slice(0, -1) : url[url.length - 1];

		menuUrl = navigationMenu.GetKeys().find((x) => x.toLocaleLowerCase() === menuUrl.toLocaleLowerCase()) ?? "Timesheet";

		let scelta: IMenuItem | null = null;

		scelta = navigationMenu.FindItem(menuUrl);

		return scelta;
	}
	// Check if current route access is granted
	checkAuth(authorizations = this.state.account.authorizations) {
		var hasAuth = false;
		let url = window.location.href.split("/");
		//check if the last part of the url have "?", if true i remove it. this realod the page correctly, maybe fix to first log in unauthorized problem, also to line 190
		let navigationUrl = url[url.length - 1].slice(-1) === "?" ? url[url.length - 1].slice(0, -1) : url[url.length - 1];

		navigationUrl = navigationMenu.GetKeys().find((x) => x.toLocaleLowerCase() === navigationUrl.toLocaleLowerCase()) ?? "";
		if (navigationUrl !== "Unauthorized" && navigationUrl !== "" && this.state.account.id !== "" && authorizations && navigationMenu[navigationUrl as keyof typeof navigationMenu]) {
			if (authorizations?.indexOf(navigationMenu.FindItem(navigationUrl).authorization) !== -1) {
				hasAuth = true;
			} else {
				hasAuth = false;
			}
		}
		LoadingHandling.hideLoader();
		return hasAuth;
	}

	// Check if given route is allowed to be loaded
	checkAuthPath(key: string, authorizations = this.state.account.authorizations) {
		var hasAuth = false;
		//check if the last part of the url have "?", if true i remove it. this realod the page correctly, maybe fix to first log in unauthorized problem, also to line 190
		if (key !== "Unauthorized" && key !== "" && this.state.account.id !== "" && authorizations && navigationMenu[key as keyof typeof navigationMenu]) {
			if (authorizations?.indexOf(navigationMenu.FindItem(key).authorization) !== -1) {
				hasAuth = true;
			} else {
				hasAuth = false;
			}
		}
		return hasAuth;
	}

	onMenuChange(menu: IMenuItem) {
		this.setState({ selectedMenu: menu });
	}

	async getCalendario() {
		fetch(webApiFactoryCalendarDate + "/calendar/" + format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd") + "/" + format(new Date(new Date().getFullYear(), 11, 31), "yyyy-MM-dd"), {
			method: "GET",
			headers: await getJSONHeaders(),
		})
			.then(ErrorHandling.handleError)
			.then((resFactoryCalendarPage) => resFactoryCalendarPage.json())
			.then((resultFactoryCalendarPage) => {
				setHolidayCalendar(resultFactoryCalendarPage);
			})
			.catch((error) => {
				RequestHandler.badRequestReceived(error, "Error loading data!");
			});
	}

	//Redirect to selected route in case of company switch otherwise returns Unauthorized route path
	redirectTo() {
		return "/" + this.state.switch ? this.state.selectedMenu.key : "Unauthorized";
	}

	render() {
		const isAuthenticated = this.props.msalContext.accounts.length > 0;
		if (!this.state.manteinance) {
			if (isAuthenticated) {
				return (
					<Stack styles={{ root: { height: "100vh", maxWidth: "100vw", overflowX: "hidden" } }}>
						<div>
							<ToastContainer transition={Zoom} enableMultiContainer />
							<Router>
								<Header
									account={this.state.account}
									selectedMenu={this.state?.selectedMenu ?? this.getItem()}
									onMenuChange={(menu: IMenuItem) => this.onMenuChange(menu)}
									logoutFunction={this.callLogout}
									loginFunction={this.callLogin}
									switchCompanyFunction={this.switchCompany}
								/>
								{this.state.account.id !== "" && (
									<Switch>
										<Route path={"/" + navigationMenu.Timesheet.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.Timesheet.key) ? <CalendarPage account={this.state.account} selectedMenu={navigationMenu.Timesheet} /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.Expenses.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.Expenses.key) ? <CalendarPage account={this.state.account} selectedMenu={navigationMenu.Expenses} /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.Scheduler.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.Scheduler.key) ? (
												<Scheduler key={navigationMenu.Scheduler.key} account={this.state.account} schedulerType={SchedulerType.Scheduler} />
											) : (
												<></>
											)}
										</Route>
										<Route path={"/" + navigationMenu.ProjectAssignment.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.ProjectAssignment.key) ? (
												<ProjectAssignmentPage key={navigationMenu.ProjectAssignment.key} currentUser={this.state.account} />
											) : (
												<></>
											)}
										</Route>
										<Route path={"/" + navigationMenu.ProjectManagement.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.ProjectManagement.key) ? (
												<Scheduler key={navigationMenu.ProjectManagement.key} account={this.state.account} schedulerType={SchedulerType.ProjectManager} />
											) : (
												<></>
											)}
										</Route>
										<Route path={"/" + navigationMenu.JobOrderCard.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.JobOrderCard.key) ? <JobOrderCard currentUser={this.state.account} /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.DownloadReport.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.DownloadReport.key) ? <DownloadReport account={this.state.account} /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.Permits.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.Permits.key) ? <RequestsPage /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.PermitsManagement.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.PermitsManagement.key) ? <SchedulerRequestsPage activeUser={this.state.account} /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.Sales.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.Sales.key) ? <SalesPage /> : <></>}
										</Route>
										<Route path={"/" + navigationMenu.DownloadRequest.key}>
											<DownloadRequestPage activeUser={this.state.account} />
										</Route>
										<Route path={"/" + navigationMenu.GoodsManagement.key}>
											{this.checkAuth() ? <></> : <Redirect to={this.redirectTo()} />}
											{this.checkAuthPath(navigationMenu.GoodsManagement.key) ? <GoodsReceiptPage /> : <></>}
										</Route>
										<Route path="/Unauthorized">
											<div className="d-flex justify-content-center align-items-center w-100" style={{ height: "calc(100vh - 138px)" }}>
												<div style={{ fontSize: "15vh", fontWeight: "bold" }}>Unauthorized</div>
											</div>
										</Route>
										<Route path="/">
											<Redirect to={this.redirectTo()} />
										</Route>
									</Switch>
								)}
							</Router>
						</div>
						<div className="loader-container">
							<div className="loader"></div>
						</div>

						<Stack
							horizontal
							horizontalAlign="end"
							tokens={{ padding: 10 }}
							styles={{ root: { userSelect: "none", width: "100%", position: "fixed", zIndex: 100, bottom: "0", color: "#66666666", fontSize: "small", pointerEvents: "none" } }}
						>
							AEGIS &copy; {process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : ""}
						</Stack>
					</Stack>
				);
			} else {
				return (
					<Stack tokens={{ maxHeight: "100vh" }}>
						<Header
							account={this.state.account}
							selectedMenu={this.getItem()}
							onMenuChange={(menu: IMenuItem) => this.onMenuChange(menu)}
							logoutFunction={this.callLogout}
							loginFunction={this.callLogin}
							switchCompanyFunction={this.switchCompany}
						/>

						<div className="d-flex justify-content-center align-items-center w-100" style={{ height: "calc(100vh - 138px)" }}>
							<div style={{ fontSize: "15vh", fontWeight: "bold" }}>ARSENALIA</div>
						</div>

						<Stack
							horizontal
							horizontalAlign="end"
							tokens={{ padding: 10 }}
							styles={{ root: { userSelect: "none", width: "100%", position: "sticky", bottom: "0", color: "#66666666", fontSize: "small" } }}
						>
							AEGIS &copy; {process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : ""}
						</Stack>
					</Stack>
				);
			}
		} else {
			return <MaintenancePage />;
		}
	}
}

export const App = withMsal(AppComponent);
