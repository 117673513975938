import React, { Component, ChangeEvent } from 'react';
import { IGoodsReceipt, IUpdateGoodsReceipt } from '../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    goodsReceiptData: IGoodsReceipt[];
    goodsReceiptDetail: Function;
    onGoodsReceiptSelected: Function;
    onEmitPress: Function;
    resetField: boolean;
}

interface IState {
    selectedGoodsReceipts: IGoodsReceipt[] & { selected?: boolean };
    resetField: boolean;
    checkAll: boolean;
}

export default class GoodsReceiptPageTable extends Component<IProps, IState> {

    topRow: string[] = ["", "Project", "Consultant", "Supplier", "Unit price", "Quantity", "Value", "Order ID", "Order Num.", "Description", "", ""]

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedGoodsReceipts: this.props.goodsReceiptData.map(goods => { goods.selected = false; return goods }),
            resetField: false,
            checkAll: false
        }
    }

    componentWillReceiveProps(nextProps: IProps) {

        if (nextProps.resetField !== this.state.resetField) {
            this.setState({ selectedGoodsReceipts: this.props.goodsReceiptData.map(goods => { goods.selected = false; return goods }) })
        }

    }

    onCheckSales(event: ChangeEvent<HTMLInputElement>, goodsReceipts?: IGoodsReceipt) {
        if (goodsReceipts) {
            goodsReceipts.selected = event.target.checked;
            this.setState({ checkAll: false });
        } else {
            this.setState({ checkAll: event.target.checked })
            if (event.target.checked) {
                this.setState({ selectedGoodsReceipts: this.props.goodsReceiptData.map(sales => { sales.selected = true; return sales }) })
            } else {
                this.setState({ selectedGoodsReceipts: this.props.goodsReceiptData.map(sales => { sales.selected = false; return sales }) })
            }
        }

        this.props.onGoodsReceiptSelected(this.state.selectedGoodsReceipts.filter(x => (x.selected === true)));
    }

    convertToObj(elem: IGoodsReceipt) {

        let obj: IUpdateGoodsReceipt = {
            idSupplier: elem.idSupplier,
            goodsReceiptDate: elem.goodsReceiptDate,
            quantity: elem.quantity,
            description: "wasd"
        }

        return obj;
    }

    render() {

        let topRow: JSX.Element | JSX.Element[] = [];
        let tableData: JSX.Element | JSX.Element[] = [];

        topRow = this.topRow.map((elem, i) => (
            <th key={i}>{i === 0 ? <input type="checkbox" checked={this.state.checkAll} onChange={(event) => this.onCheckSales(event)} /> : elem}</th>
        ));

        tableData = this.props.goodsReceiptData.map((elem, i) => (
            <tr key={i}>
                <td>{<input type="checkbox" checked={elem?.selected} onChange={(event) => this.onCheckSales(event, elem)} />}</td>
                <td>{elem.activity.description}</td>
                <td>{elem.consultantName}</td>
                <td>{elem.supplierName}</td>
                <td>{elem.unitPrice}</td>
                <td>{elem.quantity}</td>
                <td>{elem.value}</td>
                <td>{elem.oda}</td>
                <td>{elem.odaRow}</td>
                <td>{elem.description}</td>
                <td>{<button type="button" className="btn btn-outline-dark" onClick={() => this.props.onEmitPress(this.convertToObj(elem))} >Approve</button>}</td>
                <td><button className="btn btn-outline-dark" style={{ borderRadius: "100%", border: "none", outline: "none !important", boxShadow: "none" }} onClick={() => this.props.goodsReceiptDetail(elem)}><FontAwesomeIcon icon={faArrowRight} /></button></td>
            </tr>
        ))

        return (
            <div>
                <div className="salesTable">
                    <table>
                        <thead>
                            <tr>
                                {topRow}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}