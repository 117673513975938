import React, { ChangeEvent, Component } from 'react';
import MonthSelector from '../../Utils/MonthSelector';
import DropDownSelector from '../../Utils/DropDownSelector';
import SchedulerRequestsPageTable from './SchedulerRequestPageTable';
import SchedulerRequestPageOffCanvas from './SchedulerRequestPageOffCanvas';
import { approveAbsenceRequests, getJSONHeaders, rejectAbsenceRequests, webApiAbsenceRequest } from '../../../AppSettings';
import { IAbsence, IRequest, RequestVisualizationType, ErrorHandling, LoadingHandling, RequestHandler, IAccount } from '../../../Models/CalendarDay';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    activeUser : IAccount
}

interface IState {
    requests: IRequest[];
    selectedRequests: IRequest[];
    isVisibleNewRequest: boolean;
    visualRequest: IRequest;
    selectedDate: Date;
    visType: RequestVisualizationType;
    filteredList: IRequest[];
    filteredSearchList: IRequest[];
    isFilteredName: boolean;
}

export default class SchedulerRequestPage extends Component<IProps, IState> {
    selectedAbsence: IAbsence[] | null = [];


    getBlankState(){
        return {
            requests: [],
            selectedRequests: [],
            isVisibleNewRequest: false,
            visualRequest: {} as IRequest,
            selectedDate: new Date(),
            visType: RequestVisualizationType.All,
            filteredList: [],
            filteredSearchList: [],
            isFilteredName: false,
        };
    }

    constructor(props: IProps) {
        super(props);
        this.onCloseNewRequest = this.onCloseNewRequest.bind(this);
        this.onShowRequest = this.onShowRequest.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onRejectAbsenceRequest = this.onRejectAbsenceRequest.bind(this);
        this.onApproveAbsenceRequest = this.onApproveAbsenceRequest.bind(this);
        this.updateAbsenceRequests = this.updateAbsenceRequests.bind(this);
        this.onChangeSelector = this.onChangeSelector.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = this.getBlankState();
    }

    componentDidMount() {
        this.loadRequests();
    }
    
    componentWillReceiveProps(nextProps: IProps) { 
        if (this.props.activeUser !== nextProps.activeUser) {
            //this.setState(this.getBlankState());
            this.loadRequests();
        }
    }

    async loadRequests(startDate?: Date, endDate?: Date) {
        LoadingHandling.showLoader();

        var date: Date = new Date();

        let query: string = "";
        if (startDate && endDate) {
            query = webApiAbsenceRequest + "/related?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        } else if (startDate) {
            date = startDate;
            startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            query = webApiAbsenceRequest + "/related?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        }
        else {
            startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            query = webApiAbsenceRequest + "/related?fromDate=" + format(startDate, 'yyyy-MM-dd') + "&toDate=" + format(endDate, 'yyyy-MM-dd');
        }

        fetch(query, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resReq => resReq.json())
            .then((resultReq) => {
                this.setState({
                    requests: this.convertDate(resultReq)
                })
                RequestHandler.responseReceived("");
            })
            .catch(error => RequestHandler.badRequestReceived(error, "Something went wrong"))
    }

    onDateChange(start: Date, end: Date) {
        this.setState({ selectedDate: start, visType: RequestVisualizationType.All })
        this.loadRequests(start, end);
    }

    convertDate(req: IRequest[]) {
        let convertedRequest: IRequest[] = req.map((requestEntry: IRequest) => {
            return {
                id: requestEntry.id,
                account: requestEntry.account,
                requestDate: new Date(requestEntry.requestDate),
                absence: requestEntry.absence,
                startDate: new Date(requestEntry.startDate),
                endDate: new Date(requestEntry.endDate),
                status: requestEntry.status,
                note: requestEntry.note,
                schedulerNote: requestEntry.schedulerNote
            }
        })

        return convertedRequest;
    }

    onShowRequest(req: IRequest) {
        this.setState({
            visualRequest: req,
            isVisibleNewRequest: true,
        })
        document.body.style.overflowY = "hidden";
    }

    onCloseNewRequest() {
        this.setState({ isVisibleNewRequest: false });
        document.body.style.overflowY = "auto";
    }

    onChangeSelector(value: number) {
        let visType = value as RequestVisualizationType;
        this.setState({ visType: visType, filteredList: this.state.requests.filter(x => x.status === RequestVisualizationType[visType]) })
    }

    async onApproveAbsenceRequest(absenceRequest: IRequest[]) {

        RequestHandler.requestSend();

        let result = await approveAbsenceRequests(absenceRequest).catch(error => RequestHandler.badRequestReceived(error, "Error!"));

        if (result) {
            this.updateAbsenceRequests(this.convertDate(result));
            RequestHandler.responseReceived("Absence request approved");
        }
        this.onCloseNewRequest();
    }

    async onRejectAbsenceRequest(absenceRequest: IRequest[]) {
        RequestHandler.requestSend();

        let result = await rejectAbsenceRequests(absenceRequest).catch(error => RequestHandler.badRequestReceived(error, "Error!"));

        if (result) {
            this.updateAbsenceRequests(this.convertDate(result));
            RequestHandler.responseReceived("Absence request rejected");
        }

        this.onCloseNewRequest();
    }

    updateAbsenceRequests(absenceRequests: IRequest[]) {

        let stateAbsenceRequests = [...this.state.requests];

        absenceRequests.forEach(abs => {
            let index = stateAbsenceRequests.findIndex(req => abs.id === req.id);
            if (index !== -1) {
                stateAbsenceRequests.splice(index, 1, abs);
            }
        })

        this.setState({ requests: stateAbsenceRequests });
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        let query: string = event.target.value.trim().toString().toLowerCase();

        this.setState({
            filteredSearchList: this.state.requests.filter(item => query === '' ||
                item.account.firstName.toLowerCase().trim().includes(query) ||
                item.account.lastName.toLowerCase().trim().includes(query)),
            isFilteredName:true
            
        })
    };

    render() {

        return (<div style={{ margin: "50px 0px 0px 0px" }}>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4">
                        <h3 style={{ fontWeight: "bold" }}>My Requests</h3>
                    </div>
                    <div className="col-8 d-flex" style={{ justifyContent: "flex-end" }}>
                        
                        <div>

                            <form className="box-inputs" onSubmit={(e) => { e.preventDefault(); }}>
                                <div>
                                    <FontAwesomeIcon className="icon" icon={faSearch} style={{ height: "28px" }} />
                                </div>
                                <input
                                    type="text"
                                    className="searchable-item-input d-flex border-radius ps-5"
                                    onChange={(event) => this.handleInputChange(event)}
                                    placeholder="Search"
                                />
                            </form>

                        </div>
                        <div>
                            <DropDownSelector
                                data={RequestVisualizationType}
                                selectedData={this.state.visType as number}
                                onSelect={this.onChangeSelector}
                            />
                        </div>
                        <div>
                            <MonthSelector
                                onDateSelected={this.onDateChange}
                                date={new Date(this.state.selectedDate)}
                            />
                        </div>
                        
                        <button 
                            className="btn btn-dark btn-select-day d-flex justify-content-between"
                            style={{marginLeft: 5}}
                            onClick={async () => { 
                                LoadingHandling.showLoader()
                                fetch(webApiAbsenceRequest + "/" + this.state.selectedDate.getFullYear() + "/" + (this.state.selectedDate.getMonth() + 1) + "/related/export", {
                                    method: "GET",
                                    headers: await getJSONHeaders(),
                                })
                                    .then(response => {
                                        ErrorHandling.handleError(response);
                                        return response?.blob();
                                    })
                                    .then(blob => URL.createObjectURL(blob))
                                    .then(url => {
                                        RequestHandler.responseReceived("");
                        
                                        var link = document.createElement("a");
                                        link.setAttribute("href", url);
                                        link.setAttribute("download", this.state.selectedDate.toDateString().replaceAll(" ", "_"));
                                        LoadingHandling.hideLoader()
                                        link.click();
                                    })
                                    .catch(error => {
                                        RequestHandler.badRequestReceived(error, "Download went wrong")
                                        LoadingHandling.hideLoader()
                                    });
                            }}
                        >
                            Download requests
                        </button>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                {this.state.requests.length > 0 ? (
                    <div>
                        <SchedulerRequestsPageTable
                            requestData={this.state.visType === RequestVisualizationType.All && this.state.isFilteredName ? this.state.filteredSearchList : this.state.visType === RequestVisualizationType.All ? this.state.requests : this.state.filteredList}
                            showRequest={this.onShowRequest}
                        />
                    </div>
                ) : (<div><p style={{ fontWeight: "bold" }}>No requests included</p></div>)}
                <br />

                <SchedulerRequestPageOffCanvas
                    onApprove={this.onApproveAbsenceRequest}
                    onReject={this.onRejectAbsenceRequest}
                    requestPageOffCanvasIsVisible={this.state.isVisibleNewRequest}
                    requestPageOffCanvasTitle="Permit request"
                    onClose={this.onCloseNewRequest}
                    visualRequest={this.state.visualRequest}
                />
            </div >
        </div>);
    }
}
