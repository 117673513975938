import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    date: Date;
    onDateChange: Function;
    dateFormat?: string;
    startDay?: number;
    startDate?: Date;
    endDate?: Date;
    isDarkStyled?: boolean;
}

interface IState {
    date: Date;
    isOpen: boolean;
}

export class DaySelect extends Component<IProps, IState> {
    daySelectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.daySelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            date: this.props.date,
            isOpen: false
        };
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.daySelectorRef && !this.daySelectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }
        if (this.props.date !== this.state.date) {
            this.setState({
                date: this.props.date
            });
        }
    }

    toggleIsOpen() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleChange = (date: Date) => {
        this.setState({ date: date });
        this.props.onDateChange(date);
        this.toggleIsOpen();
    };

    handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        this.toggleIsOpen();
    };

    render() {
        let dateFormat: string = this.props.dateFormat ?? "MMM d";
        let startDay: number = this.props.startDay ?? 1;

        return (
            <div ref={this.daySelectorRef}>
                <button
                    className={"btn btn-select-day d-flex " + (this.props.isDarkStyled ? "btn-dark" : "btn-primary-custom")}
                    onClick={(e) => this.handleClick(e)}
                >
                    <p className="mx-2 mb-0">{<FontAwesomeIcon icon={faCalendar} />}</p>
                    <p className="mx-2 mb-0">{format(new Date(this.state.date), dateFormat)}</p>
                    <p className="mx-2 mb-0">
                        {<FontAwesomeIcon icon={faChevronDown} />}
                    </p>
                </button>
                {this.state.isOpen && (
                    <DatePicker
                        selected={this.state.date}
                        minDate={this.props.startDate}
                        maxDate={this.props.endDate}
                        onChange={(date: Date) => this.handleChange(date)}
                        calendarStartDay={startDay}
                        inline
                    />
                )}
            </div>
        );
    }
}
