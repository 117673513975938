import { webApiAccounts } from "../AppSettings";
import { AccountModel } from "../Models/AccountModel";
import { LoadingHandling } from "../Models/CalendarDay";
import BaseService from "./BaseService";


/**
 * Account services.
 *
 * @class AccountService
 * @extends {BaseService<AccountModel>}
 * @example let service = new AccountService();
 * @export
 * 
 */
export default class AccountService extends BaseService<AccountModel> {

    constructor() {
        super(webApiAccounts);
    }

	
    /**
     * Get active users.
     *
     * @param {React.Dispatch<React.SetStateAction<AccountModel>>} callback
     * @memberof AccountService
     */
    getAllActive(year: number, month: number, callback: React.Dispatch<React.SetStateAction<AccountModel[]>>) {
        LoadingHandling.showLoader()
        this.getAll(callback, `${this.endpoint}/${year}/${month}`)
    }

    /**
     * Get current user account informations. 
     *
     * @param {React.Dispatch<React.SetStateAction<AccountModel>>} callback
     * @memberof AccountService
     */
    getMe(callback: React.Dispatch<React.SetStateAction<AccountModel>>) {
        this.get(callback, `${this.endpoint}/me`)
    }

}