export type DownloadRequestTypeModel = {
	id: string,
	description: string,
	type: DownloadRequestType
}

export enum DownloadRequestType {
    JobOrderCard,
    MonthAccountSchedule,
    GeneratePaidOut,
    MonthlyAllDetails,
    TimesheetReport,
    FinalBalance
}

export type DownloadRequestModel = {
    id: string;
    requestedAt: Date;
    from: Date;
    to: Date;
    status: string;
    startedAt: Date;
    completedAt: Date;
    result: string;
	type: string;
	referenceId?: string;
}