import React, { Component, ChangeEvent } from 'react';
import { ISales, SalesStatus } from '../../Models/CalendarDay';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    salesData: ISales[];
    salesDetail: Function;
    onSalesSelected: Function;
    onEmitPress: Function;
    resetField: boolean;
}

interface IState {
    selectedSales: ISales[] & { selected?: boolean };
    resetField: boolean;
    checkAll: boolean;
}

export default class SalesPageTable extends Component<IProps, IState> {

    topRow: string[] = ["", "Activity", "Invoice ID", "Invoice Num.", "Invoice date", "Status", "Unit price", "Quantity", "Value", "", ""]

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedSales: this.props.salesData.map(sales => { sales.selected = false; return sales }),
            resetField: false,
            checkAll: false
        }
    }

    componentDidMount() { }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.resetField !== this.state.resetField) {
            this.setState({ selectedSales: this.props.salesData.map(sales => { sales.selected = false; return sales }) })
        }
    }

    onCheckSales(event: ChangeEvent<HTMLInputElement>, sales?: ISales) {
        if (sales) {
            sales.selected = event.target.checked;
            this.setState({ checkAll: false });
        } else {
            this.setState({ checkAll: event.target.checked })
            if (event.target.checked) {
                this.setState({ selectedSales: this.props.salesData.filter(x => x.invoiceStatus === "Open").map(sales => { sales.selected = true; return sales }) })
            } else {
                this.setState({ selectedSales: this.props.salesData.filter(x => x.invoiceStatus === "Open").map(sales => { sales.selected = false; return sales }) })
            }
        }

        this.props.onSalesSelected(this.state.selectedSales.filter(sales => (sales.selected === true)));
    }

    onCheckAll(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ checkAll: event.target.checked })
    }

    render() {

        let topRow: JSX.Element | JSX.Element[] = [];
        let tableData: JSX.Element | JSX.Element[] = [];

        topRow = this.topRow.map((elem, i) => (
            <th key={i}>{i === 0 ? <input type="checkbox" checked={this.state.checkAll} onChange={(event) => this.onCheckSales(event)} /> : elem}</th>
        ));

        tableData = this.props.salesData.map((elem, i) => (
            <tr key={i}>
                <td>{elem.invoiceStatus === "Open" ? <input type="checkbox" checked={elem?.selected} onChange={(event) => this.onCheckSales(event, elem)} /> : ""}</td>
                <td>{elem.activity !== null ? elem.activity.description : "Error"}</td>
                <td>{elem.invoiceId}</td>
                <td>{elem.invoiceRowNumber}</td>
                <td>{elem.invoiceRowDate ? format(new Date(elem.invoiceRowDate), "dd/MM/yyyy") : undefined}</td>
                <td><div style={{
                    borderRadius: "100%", width: "15px", height: "15px", backgroundColor: `${SalesStatus[elem.invoiceStatus as keyof typeof SalesStatus]}`, display: "inline-block"
                }}></div><div style={{ display: "inline-block", marginLeft: "10px" }}>{elem.invoiceStatus}</div></td>
                <td>{elem.unitPrice}</td>
                <td>{elem.quantity}</td>
                <td>{elem.value}</td>
                <td>{elem.invoiceStatus === "Open" ? <button type="button" className="btn btn-outline-dark" onClick={() => this.props.onEmitPress([elem])} >Emit</button> : ""}</td>
                <td><button className="btn btn-outline-dark" style={{ borderRadius: "100%", border: "none", outline: "none !important", boxShadow: "none" }} onClick={() => this.props.salesDetail(elem)}><FontAwesomeIcon icon={faArrowRight} /></button></td>
            </tr>
        ))

        return (
            <div>
                <div className="salesTable">
                    <table>
                        <thead>
                            <tr>
                                {topRow}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}