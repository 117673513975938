import React, { Component } from "react";
import { IHour } from "./../../Models/CalendarDay";

interface IProps {
    hoursList: IHour[];
    selectedHour: number;
    isButtonsVisible: boolean;
    onChange: Function;    
}

interface IState {
    hoursList: IHour[];
    selectedHour: number;
    inputValue: string;
}

export class HoursSelect extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hoursList: this.props.isButtonsVisible ? this.props.hoursList : [],
            selectedHour: this.props.selectedHour,
            inputValue: this.calculateInputValue(this.props.selectedHour, this.props.hoursList)
        };

    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.selectedHour !== this.props.selectedHour
            || this.state.selectedHour !== this.props.selectedHour) {
            this.setState({
                selectedHour: this.props.selectedHour,
                inputValue: this.calculateInputValue(this.props.selectedHour)
            });
        }

        if (prevProps.isButtonsVisible !== this.props.isButtonsVisible) {
            this.setState({
                hoursList: this.props.isButtonsVisible ? this.props.hoursList : []
            })
        }
    }

    onClick(hour: IHour) {
        this.setState({ selectedHour: hour.hour });
        this.props.onChange(hour.hour);
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ inputValue: event.target.value });
    }

    calculateInputValue(value: number, hoursList: IHour[] = this.state.hoursList): string {
        return (hoursList.filter((hr) => hr.hour === value).length > 0 && this.props.isButtonsVisible) ? "0" : value.toString();
    }

    onBlur() {
        let valueFromInputText: number = parseInt(this.state.inputValue);

        if (isNaN(valueFromInputText)) {
            valueFromInputText = 0;
        }

        this.setState({
            selectedHour: valueFromInputText,
            inputValue: this.calculateInputValue(valueFromInputText)
        });

        this.props.onChange(valueFromInputText);
    }

    render() {

        return (
            <>
                {this.state.hoursList.map((hr) => (
                    <div
                        className="col-2 align-item-center justify-content-start d-flex "
                        style={{ padding: "5px" }}
                        key={hr.id}
                    >
                        <button
                            type="button"
                            className={
                                "btn shadow-none " +
                                (hr.hour === this.state.selectedHour
                                    ? "btn-primary-custom"
                                    : "btn-light rmvHover")
                            }
                            onClick={() => this.onClick(hr)}
                            style={{ fontSize: "12px" }}
                        >
                            {hr.hour}
                        </button>
                    </div>
                ))}
                <div
                    className="col-2 align-item-center justify-content-start d-flex "
                    style={{ padding: "5px" }}
                >

                    <input
                        type="text"
                        className="text-center w-100 custom-color-border"
                        placeholder="0"
                        value={this.state.inputValue}
                        onChange={(event) => this.onChange(event)}
                        onBlur={() => this.onBlur()}
                    ></input>
                </div>
            </>
        );
    }
}
