import React, { Component } from 'react';
import JobOrderCardTable from './JobOrderCardTable';
import JobOrderCardTableStatus from './JobOrderCardTableStatus';
import JobOrderCardActivityOffCanvas from './JobOrderCardActivityOffCanvas';
import JobOrderCardUserOffCanvas from './JobOrderCardUserOffCanvas';
import JobOrderCardInfo from './JobOrderCardInfo';
import MonthSelector from '../Utils/MonthSelector';
import { IAccount, IActivity, IJobOrderCardTable, IJobOrderCardTableDates, IAPIResponse, IJobOrderCardPlan, IResourceEntry, ITopRowDates, IStatusData, IDownloadSelector, ErrorHandling, LoadingHandling, RequestHandler } from '../../Models/CalendarDay';
import { Account, getJSONHeaders, postJobOrderCardPlan, webApiActivities, webApiJobOrderCard } from '../../AppSettings';
import addMonths from 'date-fns/addMonths/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faPlus } from "@fortawesome/free-solid-svg-icons";
import DownloadTypeSelector from '../Utils/DownloadTypeSelector';
import { SelectionMode } from '../AegisCommon/SelectionMode';

interface IProps {
    currentUser: IAccount
}

interface IState {
    currentDate: Date,
    fatturazioneColumnYTGYTD: boolean,
    costiColumnYTGYTD: boolean,
    fatturazioneData: IJobOrderCardTable[],
    costiValue: IJobOrderCardTable[],
    costiData: IJobOrderCardTable[],
    isVisiblePeople: boolean,
    activity: IActivity | null,
    dateProgetto: IJobOrderCardTableDates[],

    year: number,
    month: number,

    jobOrderCard: IAPIResponse | null;

    dateToUpdateCosts: IJobOrderCardPlan,

    accounts: IAccount[]
    selectedAccount: IAccount,

    isVisibleActivity: boolean,
    activityList: IActivity[],

    baseDate: Date,

    isOpenDownloading: boolean,
    newUserAdded: boolean,

    showRevenueTable: boolean,
    showCostsTable: boolean,
    showStatusTable: boolean,

    hasChanges: boolean,

    dropdownElements: IDownloadSelector[];
}

export default class JobOrderCard extends Component<IProps, IState>{

    topRow = ["", "Unit Price", "YTD Amount", "YTG Amount", "YTD Units", "YTG Units"];
    topRowStatus = ["", "Revenues", "Costs", "Margin", "Margin %", "Average u.p. (Cost)", "Average u.p. (Revenues)", "Costs exp.", "Unpaid invoices", "Actual costs exp."];

    getBlankState() {
        return {
            currentDate: new Date(),
            fatturazioneColumnYTGYTD: true,
            costiColumnYTGYTD: true,
            fatturazioneData: [],
            costiValue: [],
            costiData: [],
            isVisiblePeople: false,
            activity: null,

            year: -1,
            month: -1,

            jobOrderCard: null,

            dateProgetto: [],
            dateToUpdateCosts: { accountId: "", activityId: "", schedules: [] },
            accounts: [],
            selectedAccount: {} as IAccount,
            isVisibleActivity: false,
            activityList: [],
            baseDate: addMonths(new Date(), -1),
            isOpenDownloading: false,
            newUserAdded: false,
            showRevenueTable: true,
            showCostsTable: true,
            showStatusTable: true,

            hasChanges: false,

            dropdownElements: [{ key: "sc", value: "Scheda commessa" }]
        }
    }

    constructor(props: IProps) {
        super(props);
        this.handleChangeCosti = this.handleChangeCosti.bind(this);
        this.getMonths = this.getMonths.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onAddPeople = this.onAddPeople.bind(this);
        this.onCloseAddPeople = this.onCloseAddPeople.bind(this);
        this.onAddRow = this.onAddRow.bind(this);
        this.onSelectedActivity = this.onSelectedActivity.bind(this);
        this.onAddActivity = this.onAddActivity.bind(this);
        this.onCloseSelectActivity = this.onCloseSelectActivity.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.totaleContrattuale = this.totaleContrattuale.bind(this);
        this.populateDatesData = this.populateDatesData.bind(this);
        this.toggleShowRevenueTable = this.toggleShowRevenueTable.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onSelectDropdown = this.onSelectDropdown.bind(this);

        this.state = this.getBlankState();
    }

    handleChangeCosti(event: any, chiave: string, index: number) {
        let value = parseFloat(event.target.value);

        if (!isNaN(value)) {
            if (Math.floor(value) !== value) {
                var nDecimal = value.toString().split(".")[1].length || 0;
                if (nDecimal > 3) {
                    return;
                }
            }
        }

        let row = this.state.costiData.filter((e: IJobOrderCardTable) => e.id + e.description === chiave)[0];
        let col = row.datesData[index];

        let rateCost = 0;
        const resource = this.state.jobOrderCard?.resourceEntries?.find(x => (x.id + `${x.firstName} ${x.lastName} (${x.type === "Internal" ? ((x.company !== this.state.activity?.company) ? "Intercompany" : x.type) : x.type}, ${x.profileCost})`) === chiave)
        const monthEntry = resource?.plans.find(x => x.month === col.month && x.year === col.year)
        rateCost = monthEntry?.rateCost ?? 0;

        if (rateCost === 0) {

            let unitPrice = this.state.costiData?.find(x => (x.id + x.description) === chiave)?.unitPrice ?? 0;

            if (unitPrice > 0) {
                if (this.state.dateToUpdateCosts.schedules.length > 0) {
                    let item = this.state.dateToUpdateCosts.schedules.find(x => x.year === col.year && x.month === col.month && (x.accountId + x.key) === chiave && x.rateCost === (unitPrice / 8));

                    if (item) {
                        item.days = isNaN(value) ? 0 : value;
                    } else {
                        this.state.dateToUpdateCosts.schedules.push({ accountId: row.id, year: col.year, month: col.month, days: isNaN(value) ? 0 : value, rateCost: (unitPrice / 8), key: row.description })
                    }
                } else {
                    if (this.state.activity) {
                        this.setState({
                            dateToUpdateCosts: {
                                accountId: Account.id,
                                activityId: this.state.activity.id,
                                schedules: [{ accountId: row.id, year: col.year, month: col.month, days: isNaN(value) ? 0 : value, rateCost: (unitPrice / 8), key: row.description }]
                            }
                        })
                    }

                }
            }

            this.setState({ newUserAdded: true, hasChanges: true })

        }

        if (rateCost > 0) {
            if (this.state.dateToUpdateCosts.schedules.length > 0) {
                let item = this.state.dateToUpdateCosts.schedules.find(x => x.year === col.year && x.month === col.month && (x.accountId + x.key) === chiave && x.rateCost === rateCost);
                if (item) {
                    item.days = isNaN(value) ? 0 : value;
                } else {
                    this.state.dateToUpdateCosts.schedules.push({ accountId: row.id, year: col.year, month: col.month, days: isNaN(value) ? 0 : value, rateCost: rateCost, key: row.description })
                }
            } else {
                if (this.state.activity) {
                    this.setState({
                        dateToUpdateCosts: {
                            accountId: Account.id,
                            activityId: this.state.activity.id,
                            schedules: [{ accountId: row.id, year: col.year, month: col.month, days: isNaN(value) ? 0 : value, rateCost: rateCost, key: row.description }]
                        }
                    })
                }

            }
        }

        this.changeValue(row, index, value);

        let x = this.state.costiValue;
        let isPresent = x.indexOf(row);

        if (isPresent === -1) {
            x.push(row);
        } else {
            x[isPresent] = row;
        }

        this.setState({ costiValue: x, hasChanges: true });
    }

    changeValue(row: IJobOrderCardTable, index: number, value: number) {
        row.datesData[index].value = value;

        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        let sumYTGUnits = this.sum(row.datesData.filter(x => x.year > currentYear || (x.year === currentYear && x.month >= currentMonth)).map(x => isNaN(x.value) ? 0 : x.value));

        row.YTGUnits = sumYTGUnits;
        row.YTGAmount = row.unitPrice * sumYTGUnits;
    }

    populateDatesData(resource: IResourceEntry, dateToUpdateCosts: IJobOrderCardPlan) {
        let temp: IJobOrderCardTableDates[] = this.state.dateProgetto.map(x => ({ ...x }));

        if (this.state.activity) {

            resource.finalBalances.forEach(elem => {
                let item = temp.find(x => x.year === elem.year && x.month === elem.month);
                if (item) {
                    if (!resource.isTask) {
                        item.value += elem.hours / 8;
                    }
                    item.key = `${resource.firstName} ${resource.lastName} (${resource.type === "Internal" ? ((resource.company !== this.state.activity?.company) ? "Intercompany" : resource.type) : resource.type}, ${resource.profileCost})`
                    // item.profileCost = elem.profileCost;
                    item.isPlan = false;
                }
            })

            resource.goods.forEach(elem => {
                let item = temp.find(x => x.year === elem.year && x.month === elem.month);
                if (item) {
                    item.value += resource.isTask ? elem.hours : elem.hours / 8;
                    item.key = `${resource.firstName} ${resource.lastName} (${resource.type === "Internal" ? ((resource.company !== this.state.activity?.company) ? "Intercompany" : resource.type) : resource.type}, ${resource.profileCost})`
                    // item.profileCost = elem.profileCost;
                    item.isPlan = false;
                }
            })

            resource.plans.forEach(elem => {

                let item = temp.find(x => x.year === elem.year && x.month === elem.month);
                if (item) {
                    if (!resource.isTask) {
                        item.value += elem.hours / 8;
                    }
                    item.key = `${resource.firstName} ${resource.lastName} (${resource.type === "Internal" ? ((resource.company !== this.state.activity?.company) ? "Intercompany" : resource.type) : resource.type}, ${resource.profileCost})`
                    // item.profileCost = elem.profileCost;
                    item.isPlan = !resource.isTask;
                    dateToUpdateCosts.schedules.push({ accountId: resource.id, year: item.year, month: item.month, days: item.value, rateCost: elem.rateCost, key: item.key })
                }
            })
        }

        return temp;
    }

    getDataFromApi() {
        let dataCosti: IJobOrderCardTable[] = [];
        let dataFatturazione: IJobOrderCardTable[] = [];

        // nuovo metodo
        if (this.state.jobOrderCard && this.state.activity) {
            let dateToUpdateCosts: IJobOrderCardPlan = {
                accountId: Account.id,
                activityId: this.state.activity.id,
                schedules: []
            }

            this.state.jobOrderCard.resourceEntries.forEach(resource => {

                let unitPrice = !resource.isTask
                    ? resource.finalBalances.length > 0
                        ? resource.finalBalances.find(x => x.rateCost > 0)?.rateCost ?? 0
                        : resource.goods.length > 0
                            ? resource.goods.find(x => x.rateCost > 0)?.rateCost ?? 0
                            : resource.plans.length > 0
                                ? resource.plans.find(x => x.rateCost > 0)?.rateCost ?? 0
                                : 0
                    : 0

                let sumYTDAmountFB = this.sum(resource.finalBalances.map(x => x.hours * x.rateCost));
                let sumYTDAmountG = this.sum(resource.goods.map(x => x.hours * x.rateCost));
                let sumYTGAmount = this.sum(resource.plans.map(x => x.hours * x.rateCost));

                let sumYTDUnitsFB = this.sum(resource.finalBalances.map(x => x.hours));
                let sumYTDUnitsG = this.sum(resource.goods.map(x => x.hours));
                let sumYTGUnits = this.sum(resource.plans.map(x => x.hours));

                let sumYTDAmount = sumYTDAmountFB + sumYTDAmountG;
                let sumYTDUnits = sumYTDUnitsFB + sumYTDUnitsG;

                dataCosti.push({
                    rowType: "U",
                    isTask: resource.isTask ?? false,
                    // profileCost: this.state.jobOrderCard?.activeAccounts.find(x => x.id === resource.id)?.profileCost ?? "unknown",
                    id: resource.id,
                    description: `${resource.firstName} ${resource.lastName} (${resource.type === "Internal" ? ((resource.company !== this.state.activity?.company) ? "Intercompany" : resource.type) : resource.type}, ${resource.profileCost})`,
                    unitPrice: unitPrice * 8,
                    YTDAmount: sumYTDAmount,
                    YTGAmount: sumYTGAmount,
                    YTDUnits: resource.isTask ? 0 : sumYTDUnits / 8,
                    YTGUnits: sumYTGUnits / 8,
                    datesData: this.populateDatesData(resource, dateToUpdateCosts)
                });
            });
            this.setState({ dateToUpdateCosts: dateToUpdateCosts })
        }


        if (this.state.jobOrderCard) {
            let sumYTDAmount = this.sum(this.state.jobOrderCard.invoices.map(x => x.amount));
            let sumYTDUnits = this.sum(this.state.jobOrderCard.invoices.map(x => x.quantity));

            let temp: IJobOrderCardTableDates[] = this.state.dateProgetto.map(x => ({ ...x }));

            this.state.jobOrderCard.invoices.forEach(elem => {
                let item = temp.find(x => x.year === elem.year && x.month === elem.month);
                if (item) {
                    item.value += elem.quantity / 8;
                }
            })

            dataFatturazione.push({
                rowType: "IS",
                // profileCost: "unknown",
                id: "idSum",
                description: "Invoices",
                unitPrice: 0,
                YTDAmount: sumYTDAmount,
                YTGAmount: 0,
                YTDUnits: sumYTDUnits,
                YTGUnits: 0,
                datesData: temp
            })
        }

        if (this.state.jobOrderCard) {
            this.state.jobOrderCard.invoices.forEach(invoice => {

                let temp: IJobOrderCardTableDates[] = this.state.dateProgetto.map(x => ({ ...x }));

                let item = temp.find(x => x.year === invoice.year && x.month === invoice.month);
                if (item) {
                    item.value += invoice.quantity;
                }

                let unitPrice = invoice.rateCost * 8;

                dataFatturazione.push({
                    rowType: "I",
                    // profileCost: "unknown",
                    id: invoice.id + invoice.position,
                    description: invoice.id + " " + invoice.description + " " + invoice.status,
                    unitPrice: unitPrice,
                    YTDAmount: invoice.amount,
                    YTGAmount: 0,
                    YTDUnits: item ? item.value : 0,
                    YTGUnits: 0,
                    datesData: temp
                })
            })
        }

        //Guardare su jobOrderTable come fare per mostrare i dati
        if (this.state.jobOrderCard && this.state.activity) {
            if (this.state.jobOrderCard.results) {
                if (this.state.activity.type === "task" || this.state.activity.type === "retainer") {

                    let jocResults = this.state.jobOrderCard.results;
                    if (jocResults != null) {
                        let arr: string[] = [];
                        jocResults.filter(y => y.revenueType === "WIP a capitalizzazione obbligatoria" || y.revenueType === "Profitto calcolato (metodo POC)").forEach(x => arr.push(x.revenueType))

                        let revType = arr.filter((item, i, ar) => ar.indexOf(item) === i);

                        revType.forEach(rev => {
                            let temp: IJobOrderCardTableDates[] = this.state.dateProgetto.map(x => ({ ...x }));

                            jocResults.filter(x => x.revenueType === rev).forEach(elem => {
                                let item = temp.find(x => x.year === elem.year && x.month === elem.month);
                                if (item) {
                                    item.value += elem.value;
                                }
                            })


                            let sumYTDAmount = this.sum(jocResults
                                .filter(z => z.revenueType === rev)
                                .filter(y => new Date(y.year, y.month - 1, 1, 0, 0, 0).getTime() <= new Date(this.state.year, this.state.month, 0, 23, 59, 59).getTime())
                                .map(x => x.value));

                            let sumYTGAmount = this.sum(jocResults
                                .filter(z => z.revenueType === rev)
                                .filter(y => new Date(y.year, y.month - 1, 1, 0, 0, 0).getTime() > new Date(this.state.year, this.state.month, 0, 23, 59, 59).getTime())
                                .map(x => x.value));

                            dataFatturazione.push({
                                rowType: revType[0] === rev ? "1" : "2",
                                // profileCost: "unknown",
                                id: rev.substring(0, 3),
                                description: rev,
                                unitPrice: 0,
                                YTDAmount: (Math.round((sumYTDAmount) * 100) / 100),
                                YTGAmount: (Math.round((sumYTGAmount) * 100) / 100),
                                YTDUnits: 0,
                                YTGUnits: 0,
                                datesData: temp
                            })
                        })
                    }
                }
            }
        }

        //Da spostare e capire dove
        //popolo le date nella top row
        this.topRow.length = 6;
        let projectDate: ITopRowDates[] = this.getMonths();
        projectDate.forEach(x => this.topRow.push(x.month + " " + x.year))

        this.setState({
            costiData: dataCosti,
            fatturazioneData: dataFatturazione,
        })
    }

    async loadData() {

        RequestHandler.requestSend();

        this.setState({ hasChanges: false, newUserAdded: false })
        let dates: IJobOrderCardTableDates[] = [];

        if (this.state.activity) {

            if (this.state.activity.startDate && this.state.activity.endDate) {
                for (let currentDate: Date = new Date(this.state.activity.startDate); currentDate <= new Date(this.state.activity.endDate); currentDate = addMonths(currentDate, 1)) {
                    dates.push({ year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, value: 0, isPlan: false, key: "" /* profileCost: "N/A" */ });
                }
            }

            let queryJobOrderCard = webApiJobOrderCard + "/" + this.state.activity.id + "/" + this.state.year + "/" + this.state.month;

            fetch(queryJobOrderCard, {
                method: "GET",
                headers: await getJSONHeaders(),
            })
                .then(ErrorHandling.handleError)
                .then(resJobOrderCard => resJobOrderCard.json())
                .then((resultJobOrderCard) => {
                    this.setState({
                        jobOrderCard: resultJobOrderCard,
                        dateProgetto: dates,
                    }, () => this.getDataFromApi())
                    RequestHandler.responseReceived("");
                }).catch(error => {
                    RequestHandler.badRequestReceived(error, "Error loading data!")
                });
        }
    }

    componentDidMount() {
        this.loadActivities();
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (this.props.currentUser !== nextProps.currentUser) {
            this.setState(this.getBlankState());
            this.loadActivities();
        }
    }

    async loadActivities() {
        let queryActivities = `${webApiActivities}/related`;

        RequestHandler.requestSend();

        fetch(queryActivities, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then((resultAct) => {

                this.setState({
                    activityList: resultAct
                })
                RequestHandler.responseReceived("");

            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Somethig went wrong with loading activities");
            });
    }

    getMonths() {
        let temp: ITopRowDates[] = [];

        this.state.dateProgetto.forEach(elem => {
            let date = new Date(elem.year, elem.month - 1, 1);
            let monthLiteral = new Intl.DateTimeFormat('en-US', { month: "short" }).format(date);
            temp.push({ month: monthLiteral.charAt(0).toUpperCase() + monthLiteral.slice(1), year: date.getFullYear() })
        });

        return temp;
    }

    toggleColumnYTGYTD(type: string): void {
        if (type === "F") {
            this.setState({
                fatturazioneColumnYTGYTD: !this.state.fatturazioneColumnYTGYTD
            })
        } else {
            this.setState({
                costiColumnYTGYTD: !this.state.costiColumnYTGYTD
            })
        }

    }

    toggleShowRevenueTable() {
        this.setState({ showRevenueTable: !this.state.showRevenueTable })
    }

    toggleShowCostsTable() {
        this.setState({ showCostsTable: !this.state.showCostsTable })
    }

    toggleShowStatusTable() {
        this.setState({ showStatusTable: !this.state.showStatusTable })
    }

    onCloseAddPeople() {
        this.setState({ isVisiblePeople: false });
        document.body.style.overflowY = "auto";
    }

    onCloseSelectActivity() {
        this.setState({ isVisibleActivity: false });
        document.body.style.overflowY = "auto";
    }

    onSave() {
        this.postData();
    }

    async postData() {
        LoadingHandling.showLoader();

        let res = await postJobOrderCardPlan(this.state.dateToUpdateCosts).catch((error) => RequestHandler.badRequestReceived(error, "Something went wrong!"));

        if (res) {
            RequestHandler.responseReceived("Schedule successfully saved");
        }

        this.loadData()

    }

    onAddPeople() {
        this.setState({ isVisiblePeople: true })
        document.body.style.overflowY = "hidden";
    }

    onAddActivity() {
        this.setState({ isVisibleActivity: true });
        document.body.style.overflowY = "hidden";
    }

    onAddRow(users: IAccount[]) {

        let newRow: IJobOrderCardTable[] = this.state.costiData;
        users.map(user => {
            let tempDatesData: IJobOrderCardTableDates[] = this.state.dateProgetto.map(x => ({ ...x }));
            let selectedUser: IAccount = this.state.jobOrderCard?.activeAccounts.find(x => x.id === user.id)!;
            let isIntercompany: boolean = selectedUser.type === "Internal" && selectedUser.company !== this.state.activity?.company;

            newRow.push({
                rowType: "U",
                isTask: selectedUser.isTask ?? false,
                // profileCost: selectedUser.profileCost ?? "unknown",
                id: selectedUser.id,
                description: `${selectedUser.firstName} ${selectedUser.lastName} (${isIntercompany ? "Intercompany" : selectedUser.type}, ${selectedUser.profileCost})`,
                unitPrice: ((isIntercompany ? selectedUser.rateIC : selectedUser?.rateCost) ?? 0) * 8,
                YTDAmount: 0,
                YTGAmount: 0,
                YTDUnits: 0,
                YTGUnits: 0,
                datesData: tempDatesData.map(x => {
                    if ((x.year * 100) + x.month >= (this.state.baseDate.getFullYear() * 100) + (this.state.baseDate.getMonth() + 1)) {
                        x.isPlan = true;
                    }
                    return x;
                })
            });
        })
        this.setState({ costiData: newRow })
        this.onCloseAddPeople();
    }

    onSelectedActivity(activity: IActivity) {
        let act = this.state.activityList.find(x => x.id === activity.id) ?? {} as IActivity

        this.setState({
            activity: act,
            baseDate: this.getStartDate(act),
        }, () => this.onDateChange(this.state.baseDate, this.state.baseDate))
        this.onCloseSelectActivity();
    }

    sum(values: number[]) {
        return values.reduce((total, current) => total + current, 0);
    }

    //Funzione per popolare la tabella finale
    statusData() {
        let statusData: IStatusData[] = [];

        let revenuesTot: number = 0;
        let revenuesYTD: number = 0;
        let revenuesYTG: number = 0;

        let costsTot: number = 0;
        let costsYTD: number = 0;
        let costsYTG: number = 0;

        let marginTot: number = 0;
        let marginYTD: number = 0;
        let marginYTG: number = 0;

        let marginPercTot: number = 0;
        let marginPercYTD: number = 0;
        let marginPercYTG: number = 0;

        let averageCostTot: number = 0;
        let averageCostYTD: number = 0;
        let averageCostYTG: number = 0;

        let averageRevenuesTot: number = 0;
        let averageRevenuesYTD: number = 0;
        let averageRevenuesYTG: number = 0;

        let costsExpTot: number = 0;
        let costsExpYTD: number = 0;
        let costsExpYTG: number = 0;

        let unpaidInvoicesTot: number = 0;
        let unpaidInvoicesYTD: number = 0;
        let unpaidInvoicesYTG: number = 0;

        let actualCostsExpTot: number = 0;
        let actualCostsExpYTD: number = 0;
        let actualCostsExpYTG: number = 0;

        if (this.state.activity !== null) {
            //revenuesYTD && revenuesYTG
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                if (this.state.activity.type === "t&m") {
                    let invoicesNotI = this.state.fatturazioneData.filter(x => x.rowType !== "I");
                    revenuesYTD = (Math.round(this.sum(invoicesNotI.map(y => y.YTDAmount)) * 100) / 100);
                    revenuesYTG = (Math.round(this.sum(invoicesNotI.map(y => y.YTGAmount)) * 100) / 100);
                }

                if (this.state.activity.type === "task" || this.state.activity.type === "retainer") {
                    let invoicesType1Or2 = this.state.fatturazioneData.filter(x => x.rowType === "1" || x.rowType === "2");
                    revenuesYTD = (Math.round(this.sum(invoicesType1Or2.map(y => y.YTDAmount)) * 100) / 100);
                    revenuesYTG = (Math.round(this.sum(invoicesType1Or2.map(y => y.YTGAmount)) * 100) / 100);
                }
            }

            //revenueTot
            if (this.state.jobOrderCard && this.state.jobOrderCard != null) {
                revenuesTot = this.state.activity.type === "task" || this.state.activity.type === "retainer"
                    ? (Math.round((this.state.jobOrderCard.results?.filter(x => x.revenueType === "Blank")[0].value) * 100) / 100)
                    : revenuesYTD + revenuesYTG;
            }
        }

        //costsYTD && costsYTG
        if (this.state.costiData && this.state.costiData != null) {
            costsYTD = (Math.round(this.sum(this.state.costiData.map(x => x.YTDAmount)) * 100) / 100);
            costsYTG = (Math.round(this.sum(this.state.costiData.map(x => x.YTGAmount)) * 100) / 100);
        }

        //costsTot
        costsTot = (Math.round((costsYTD + costsYTG) * 100) / 100);

        //marginYTD
        marginYTD = (Math.round((revenuesYTD - costsYTD) * 100) / 100);
        //marginYTG
        marginYTG = (Math.round((revenuesYTG - costsYTG) * 100) / 100);
        //marginTot
        marginTot = (Math.round((revenuesTot - costsTot) * 100) / 100);

        //marginPercYTD
        if (marginYTD !== 0 && revenuesYTD !== 0) {
            marginPercYTD = (Math.round((marginYTD / revenuesYTD) * 10000) / 100)
        }

        //marginPercYTG
        if (marginYTG !== 0 && revenuesYTG !== 0) {
            marginPercYTG = (Math.round((marginYTG / revenuesYTG) * 10000) / 100)
        }

        //marginPercTot
        if (marginTot !== 0 && revenuesTot !== 0) {
            marginPercTot = (Math.round((marginTot / revenuesTot) * 10000) / 100)
        }

        //averageCostYTD
        let sumYTDUnitsCosts: number = 0;
        if (this.state.costiData && this.state.costiData != null) {
            sumYTDUnitsCosts = this.sum(this.state.costiData.map(x => x.YTDUnits));
        }
        if (costsYTD !== 0 && sumYTDUnitsCosts !== 0) {
            averageCostYTD = (Math.round((costsYTD / sumYTDUnitsCosts) * 100) / 100)
        }

        //averageCostYTG
        let sumYTGUnitsCosts: number = 0;
        if (this.state.costiData && this.state.costiData != null) {
            sumYTGUnitsCosts = this.sum(this.state.costiData.map(x => x.YTGUnits));
        }
        if (costsYTG !== 0 && sumYTGUnitsCosts !== 0) {
            averageCostYTG = (Math.round((costsYTG / sumYTGUnitsCosts) * 100) / 100)
        }

        //averageCostTot
        if (costsTot !== 0 && (sumYTDUnitsCosts + sumYTGUnitsCosts) !== 0) {
            averageCostTot = (Math.round((costsTot / (sumYTDUnitsCosts + sumYTGUnitsCosts)) * 100) / 100)
        }

        //averageRevenuesYTD
        if (revenuesYTD !== 0 && sumYTDUnitsCosts !== 0) {
            averageRevenuesYTD = (Math.round((revenuesYTD / sumYTDUnitsCosts) * 100) / 100)
        }

        //averageRevenuesYTG
        if (revenuesYTG !== 0 && sumYTGUnitsCosts !== 0) {
            averageRevenuesYTG = (Math.round((revenuesYTG / sumYTGUnitsCosts) * 100) / 100)
        }

        //averageRevenuesTot
        if (revenuesTot !== 0 && (sumYTDUnitsCosts + sumYTGUnitsCosts) !== 0) {
            averageRevenuesTot = (Math.round((revenuesTot / (sumYTDUnitsCosts + sumYTGUnitsCosts)) * 100) / 100)
        }

        //costsExpYTD -> YTDCosts - sumYTDAmount
        if ((revenuesYTD !== 0 || costsYTD !== 0) && this.state.jobOrderCard) {
            costsExpYTD = Math.round((costsYTD - this.sum(this.state.jobOrderCard.invoices.map(x => x.amount))) * 100) / 100;
        }

        //costsExpYTG
        if (revenuesYTG !== 0 || costsYTG !== 0) {
            costsExpYTG = Math.round((costsYTG + revenuesYTG) * 100) / 100;
        }

        //costsExpTot
        if (revenuesTot !== 0 || costsTot !== 0) {
            costsExpTot = Math.round((costsTot - revenuesTot) * 100) / 100;
        }

        if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
            let invoiced = this.state.fatturazioneData.filter(x => x.description.includes("Invoiced"));
            let payed = this.state.fatturazioneData.filter(x => x.description.includes("Payed"));

            //unpaidInvoicesYTD -> SUM(Invoices.YTDAmount) WHERE Invoice.Status == Invoiced
            unpaidInvoicesYTD = Math.round(this.sum(invoiced.map(x => x.YTDAmount)) * 100) / 100;

            //unpaidInvoicesYTG
            unpaidInvoicesYTG = Math.round(this.sum(invoiced.map(x => x.YTGAmount)) * 100) / 100;

            //actualCostsExpYTD -> YTD.Costs - SUM(Invoices.YTDAmount) WHERE Invoice.Status == Paid
            actualCostsExpYTD = costsYTD - Math.round(this.sum(payed.map(x => x.YTDAmount)) * 100) / 100;

            //actualCostsExpYTG
            actualCostsExpYTG = costsYTG - Math.round(this.sum(payed.map(x => x.YTGAmount)) * 100) / 100;
        }

        if (this.state.activity != null) {
            //unpaidInvoicesTot
            if (this.state.jobOrderCard && this.state.jobOrderCard != null) {
                unpaidInvoicesTot = unpaidInvoicesYTD + unpaidInvoicesYTG;
            }

            //actualCostsExpTot
            if (this.state.jobOrderCard && this.state.jobOrderCard != null) {
                actualCostsExpTot = actualCostsExpYTD + actualCostsExpYTG;
            }
        }

        //statusData.push({ description: "Total", revenues: revenuesTot, costs: costsTot, margin: marginTot, marginPerc: marginPercTot, averageCost: averageCostTot, averageRevenue: averageRevenuesTot, costsExp: costsExpTot, unpaidInvoices: unpaidInvoicesTot, actualCostsExp: actualCostsExpTot });
        statusData.push({ description: "Total", revenues: revenuesTot, costs: costsTot, margin: marginTot, marginPerc: marginPercTot, averageCost: averageCostTot, averageRevenue: averageRevenuesTot, costsExp: 0, unpaidInvoices: 0, actualCostsExp: 0 });
        statusData.push({ description: "YTD", revenues: revenuesYTD, costs: costsYTD, margin: marginYTD, marginPerc: marginPercYTD, averageCost: averageCostYTD, averageRevenue: averageRevenuesYTD, costsExp: costsExpYTD, unpaidInvoices: unpaidInvoicesYTD, actualCostsExp: actualCostsExpYTD });
        statusData.push({ description: "YTG", revenues: revenuesYTG, costs: costsYTG, margin: marginYTG, marginPerc: marginPercYTG, averageCost: averageCostYTG, averageRevenue: averageRevenuesYTG, costsExp: 0, unpaidInvoices: 0, actualCostsExp: 0 });
        //statusData.push({ description: "YTG", revenues: revenuesYTG, costs: costsYTG, margin: marginYTG, marginPerc: marginPercYTG, averageCost: averageCostYTG, averageRevenue: averageRevenuesYTG, costsExp: costsExpYTG, unpaidInvoices: unpaidInvoicesYTG, actualCostsExp: actualCostsExpYTG });

        return statusData;
    }

    //Funzioni per popolare le card
    totaleContrattuale() {
        let tot: number = 0;
        let revenuesYTD: number = 0;
        let revenuesYTG: number = 0;
        if (this.state.jobOrderCard && this.state.jobOrderCard != null) {
            tot = this.state.jobOrderCard.results?.filter(x => x.revenueType === "Blank")[0].value;
        }

        if (this.state.activity !== null && this.state.activity.type === "t&m") {
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                revenuesYTD = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType !== "I").map(y => y.YTDAmount)) * 100) / 100);
                revenuesYTG = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType !== "I").map(y => y.YTGAmount)) * 100) / 100);

                tot = revenuesYTD + revenuesYTG;
            }
        }

        return tot;
    }

    ytdRevenueFatt() {
        let sum = 0;
        if (this.state.activity !== null && this.state.activity.type === "t&m") {
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                sum = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType !== "I").map(y => y.YTDAmount)) * 100) / 100);
            }
        }
        if (this.state.activity !== null && (this.state.activity.type === "task" || this.state.activity.type === "retainer")) {
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                sum = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType === "1" || x.rowType === "2").map(y => y.YTDAmount)) * 100) / 100);
            }
        }

        return sum;
    }

    ytgRevenueFatt() {
        let sum = 0;
        if (this.state.activity !== null && this.state.activity.type === "t&m") {
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                sum = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType !== "I").map(y => y.YTGAmount)) * 100) / 100);
            }
        }
        if (this.state.activity !== null && (this.state.activity.type === "task" || this.state.activity.type === "retainer")) {
            if (this.state.fatturazioneData && this.state.fatturazioneData != null) {
                sum = (Math.round(this.sum(this.state.fatturazioneData.filter(x => x.rowType === "1" || x.rowType === "2").map(y => y.YTGAmount)) * 100) / 100);
            }
        }
        return sum;
    }

    ytdRevenueCosti() {
        let sum = 0;
        this.state.costiData.forEach(e => sum += e.YTDAmount);
        return (Math.round(sum * 100) / 100);
    }

    marginFatt() {
        let totaleContrattuale = 0;
        let sumYTDAmountCosts = 0;
        let sumYTGAmountCosts = 0;

        totaleContrattuale = this.totaleContrattuale();
        this.state.costiData.filter(x => x.rowType === "U").forEach(y => sumYTDAmountCosts += y.YTDAmount);
        this.state.costiData.filter(x => x.rowType === "U").forEach(y => sumYTGAmountCosts += y.YTGAmount);

        if (totaleContrattuale !== 0) {
            let tot = (totaleContrattuale - (sumYTDAmountCosts + sumYTGAmountCosts)) / totaleContrattuale;
            return (Math.round(tot * 10000) / 100);
        }
        return 0;
    }

    onDateChange(start: Date, end: Date) {
        this.setState({
            year: start.getFullYear(),
            month: start.getMonth() + 1,
            fatturazioneData: [],
            costiData: [],
        }, () => this.loadData())
    }

    getStartDate(activity: IActivity) {
        let baseDate: Date = addMonths(new Date(), -1);

        if (activity.endDate && baseDate.getTime() > new Date(activity.endDate).getTime()) {
            baseDate = new Date(activity.endDate);
        }

        return baseDate;
    }

    checkDate() {
        let date = new Date(this.state.baseDate.getFullYear(), this.state.baseDate.getMonth(), 1);
        let selected = new Date(this.state.year, this.state.month - 1, 1);

        return (date.getTime() === selected.getTime());
    }

    async onDownloadClick() {

        RequestHandler.requestSend();

        if (this.state.activity?.id) {

            let filename = "file.xlsx"
            fetch(webApiJobOrderCard + "/" + this.state.activity.id + "/" + this.state.year + "/" + this.state.month + "/export", {
                method: "GET",
                headers: await getJSONHeaders(),
            })
                .then(response => {
                    ErrorHandling.handleError(response);
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(response.headers?.get("content-disposition") ?? "");
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                    return response?.blob();
                })
                .then(blob => URL.createObjectURL(blob))
                .then(url => {
                    RequestHandler.responseReceived("");
                    var link = document.createElement("a");
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.click();
                }).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
        }
    }

    onSelectDropdown(elem: IDownloadSelector) {
        switch (elem.key) {
            case "sc": this.onDownloadClick(); break;
        }
    }

    render() {
        return (<div style={{ margin: "50px 0px 0px 0px" }}>
            <div className="container">
                <div className="row justify-content-between" style={{ margin: 0 }}>
                    {this.state.activity ?
                        <div className="activityName">
                            <h2>Activity: {this.state.activity.description}</h2>
                        </div>
                        : <div></div>
                    }

                    <div className="col-3">
                        <button type="button" className="btn btn-outline-dark" style={{ fontWeight: "bold", padding: "10px" }} onClick={this.onAddActivity}>Select Activity</button>
                        <JobOrderCardActivityOffCanvas
                            jobOrderOffCanvasIsVisible={this.state.isVisibleActivity}
                            activityList={this.state.activityList}
                            jobOrderOffCanvasTitle="Select activity"
                            onClose={this.onCloseSelectActivity}
                            onAddActivity={this.onSelectedActivity}
                            currentUser={this.props.currentUser}
                        />
                    </div>
                    {this.state.activity ?
                        <div className="col-3">
                            <MonthSelector
                                onDateSelected={this.onDateChange}
                                date={new Date(this.state.baseDate)}
                                maxDate={new Date(this.state.baseDate)}
                            />
                        </div>
                        : <div></div>
                    }

                    {this.state.year !== -1 && this.state.month !== -1 ?


                        <div className="col-3" style={{ textAlign: "right" }}>
                            <div className="row">
                                <div className="col-sm"
                                    style={{ flexWrap: "wrap", height: "50px" }}
                                >
                                    <DownloadTypeSelector title="Export" elements={this.state.dropdownElements} onSelect={(e: IDownloadSelector) => this.onSelectDropdown(e)} />
                                </div>
                                <div className="col-sm">
                                    <button disabled={(this.state.hasChanges
                                        && (this.state.dateToUpdateCosts.schedules.length > 0)
                                        || this.state.newUserAdded) ? false : true}
                                        style={{ width: "100px", height: "50px" }}
                                        className="btn btn-dark"
                                        onClick={this.onSave}>Save</button>
                                </div>
                            </div>
                        </div>
                        : <div></div>
                    }
                </div>
                <br />
                {this.state.year !== -1 && this.state.month !== -1 ?
                    <div>
                        {this.state.jobOrderCard ?
                            <div className="divEsterno">
                                <div className="row">
                                    <div className="col-sm"><JobOrderCardInfo title="Totale Contrattuale" type="€" data={this.totaleContrattuale()} /></div>
                                    <div className="col-sm"><JobOrderCardInfo title="YTD Revenue" type="€" data={this.ytdRevenueFatt()} /></div>
                                    <div className="col-sm"><JobOrderCardInfo title="YTG Revenue" type="€" data={this.ytgRevenueFatt()} /></div>
                                    <div className="col-sm"><JobOrderCardInfo title="Margin" type="%" data={this.marginFatt()} notCalculable={this.totaleContrattuale() === 0} /></div>
                                </div>
                                <br />
                                <div>
                                    <div className="row justify-content-between" style={{ margin: 0 }}>
                                        <div className="col-3" style={{ fontWeight: "bold", paddingLeft: "0px" }}>
                                            <div style={{ maxHeight: "50px", height: "50px", }}> Fatturazione <span style={{ fontSize: "15px" }}>{this.state.showRevenueTable ? <FontAwesomeIcon icon={faToggleOn} onClick={(e) => this.toggleShowRevenueTable()} /> : <FontAwesomeIcon icon={faToggleOff} onClick={(e) => this.toggleShowRevenueTable()} />}</span></div>
                                        </div>
                                        {this.state.showRevenueTable ?
                                            <div className="col-3" style={{ paddingRight: "0px" }}>
                                                <div style={{ float: "right", maxHeight: "50px", height: "50px", }}> Column YTG YTD <span style={{ fontSize: "15px" }}>{this.state.fatturazioneColumnYTGYTD ? <FontAwesomeIcon icon={faToggleOn} onClick={(e) => this.toggleColumnYTGYTD("F")} /> : <FontAwesomeIcon icon={faToggleOff} onClick={(e) => this.toggleColumnYTGYTD("F")} />}</span></div>
                                            </div> : <></>
                                        }


                                    </div>
                                    {this.state.showRevenueTable ?
                                        <>
                                            <div className="row">
                                                <JobOrderCardTable topRow={this.topRow} currentDate={this.state.currentDate} tableData={this.state.fatturazioneData} toggleShowColumns={this.state.fatturazioneColumnYTGYTD} tableType="revenues" isEditable={false} />
                                            </div>
                                            <br />
                                            <br />
                                        </>
                                        : <></>}
                                </div>
                                <div>
                                    <div className="row justify-content-between" style={{ margin: 0 }}>
                                        <div className="col-3" style={{ fontWeight: "bold", paddingLeft: "0px" }}>
                                            <div style={{ maxHeight: "50px", height: "50px", }}> Costi <span style={{ fontSize: "15px" }}>{this.state.showCostsTable ? <FontAwesomeIcon icon={faToggleOn} onClick={(e) => this.toggleShowCostsTable()} /> : <FontAwesomeIcon icon={faToggleOff} onClick={(e) => this.toggleShowCostsTable()} />}</span></div>
                                        </div>
                                        {this.state.showCostsTable ?
                                            <div className="col-3" style={{ paddingRight: "0px" }}>
                                                <div style={{ float: "right", maxHeight: "50px", height: "50px", }}> Column YTG YTD <span style={{ fontSize: "15px" }}>{this.state.costiColumnYTGYTD ? <FontAwesomeIcon icon={faToggleOn} onClick={(e) => this.toggleColumnYTGYTD("C")} /> : <FontAwesomeIcon icon={faToggleOff} onClick={(e) => this.toggleColumnYTGYTD("C")} />}</span></div>
                                            </div> : <></>}
                                    </div>

                                    {this.state.showCostsTable ?
                                        <>
                                            <div className="row">
                                                <JobOrderCardTable topRow={this.topRow} currentDate={this.state.currentDate} tableData={this.state.costiData} toggleShowColumns={this.state.costiColumnYTGYTD} handleChange={this.handleChangeCosti} tableType="costs" isEditable={this.checkDate()} />
                                            </div>
                                            <br />
                                            {this.checkDate() && (
                                                <button type="button" className="btn btn-outline-dark" style={{ fontWeight: "bold", padding: "10px" }} onClick={this.onAddPeople}><FontAwesomeIcon icon={faPlus} /> Add people</button>
                                            )}
                                            <br />
                                            <br />
                                            <br />
                                        </>
                                        : <></>}

                                    <JobOrderCardUserOffCanvas
                                        jobOrderOffCanvasIsVisible={this.state.isVisiblePeople}
                                        onClose={this.onCloseAddPeople}
                                        onAddRow={this.onAddRow}
                                        jobOrderOffCanvasTitle="Add User"
                                        userList={this.state.jobOrderCard?.activeAccounts.filter(user => (this.state.costiData.filter(row => row.id === user.id && row.rowType === "U").length) === 0)}
                                    />
                                </div>
                                <div>
                                    <div className="row" style={{ fontWeight: "bold" }}>
                                        <div style={{ maxHeight: "50px", height: "50px", }}> Project status <span style={{ fontSize: "15px" }}>{this.state.showStatusTable ? <FontAwesomeIcon icon={faToggleOn} onClick={(e) => this.toggleShowStatusTable()} /> : <FontAwesomeIcon icon={faToggleOff} onClick={(e) => this.toggleShowStatusTable()} />}</span></div>
                                    </div>
                                    {this.state.showStatusTable ?
                                        <>
                                            <div className="row">
                                                <JobOrderCardTableStatus topRow={this.topRowStatus} tableData={this.statusData()} />
                                            </div>
                                            <br />
                                        </>
                                        : <></>}
                                </div>
                                <br />
                                <br />
                                <br />
                            </div>
                            : "Loading..."}
                    </div>
                    : <div></div>
                }
            </div>
        </div>);
    }
}