import React, { Component } from "react";
import { DaySelect } from "../Utils/DaySelect";
import ItemOffCanvas from "../Utils/ItemOffCanvas";
import { ItemsList } from "../Utils/ItemsList";
import { IActivity, IExpense, IExpenseEntry, editMode, ErrorHandling, RequestHandler, IItem, dailyAllowance } from "./../../Models/CalendarDay";
import { Account, getJSONHeaders, isHoliday, isNullOrWhiteSpace, webApiAccounts, webApiActivities } from './../../AppSettings';
import { ShowDayWeekAndDate } from "../Utils/ShowDayWeekAndDate";
import { format } from "date-fns";

interface IProps {
    expenseOffCanvasTitle: string;
    expenseEntries: IExpenseEntry[];
    onClose: Function;
    onSave: Function;
    onDelete: Function;
    expenseOffCanvasIsVisible: boolean;
    onFavouriteOrColorChange?: Function;
    onExpenseFavouriteOrColorChange?: Function;
    editMode: editMode;
    startDate: Date;
    endDate: Date;
    expenseList: IExpense[];
    activityList: IItem[];
    isSchedulerPage: boolean;
    expenseListComplete?: IExpenseEntry[];
}

interface IState {
    expenseOffCanvasTitle: string;
    expenseEntries: IExpenseEntry[];
    isVisible: boolean;
    activityList: IItem[];
    expenseList: IExpense[];
    error: string | null;
    isContentVisible: boolean;
    isEmpty: boolean;
    filteredExpenseList: IExpense[];
    unfilteredList: IExpense[];
    hasExceededCeilings: boolean;
}

export class ExpenseOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onChangeData = this.onChangeData.bind(this);
        this.onExpenseSelect = this.onExpenseSelect.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onListShowHide = this.onListShowHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.checkExceededCeiling = this.checkExceededCeiling.bind(this);

        this.state = {
            expenseOffCanvasTitle: this.props.expenseOffCanvasTitle,
            expenseEntries: this.props.expenseEntries,
            isContentVisible: true,
            isEmpty: this.checkEmptyFields(this.props.expenseEntries),
            expenseList: this.props.expenseList,
            isVisible: this.props.expenseOffCanvasIsVisible,
            activityList: this.props.activityList,
            error: null,
            filteredExpenseList: this.props.expenseList.filter(x => x.type?.toLowerCase() !== dailyAllowance),
            unfilteredList: this.props.expenseList,
            hasExceededCeilings: false,
        }
    }

    onListShowHide(isListOpen: boolean) {
        this.setState({ isContentVisible: !isListOpen });
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.expenseOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.expenseOffCanvasIsVisible
            });
        }

        if (nextProps.expenseEntries !== this.state.expenseEntries) {
            if (nextProps.expenseOffCanvasIsVisible === true) {
                this.getDailyAllowance(nextProps.expenseEntries[0].day);
            }

            if (nextProps.expenseEntries.length === 1) {
                this.checkExceededCeiling(nextProps.expenseEntries[0]);
            }

            this.setState({
                expenseEntries: nextProps.expenseEntries,
                isEmpty: this.checkEmptyFields(nextProps.expenseEntries),
            });
        }

        if (nextProps.expenseOffCanvasTitle !== this.state.expenseOffCanvasTitle) {
            this.setState({
                expenseOffCanvasTitle: nextProps.expenseOffCanvasTitle
            })
        }
    }

    async componentDidMount() {
        if (this.props.isSchedulerPage) {
            this.getDailyAllowance(this.props.expenseEntries[0].day);
        }
        if (!this.props.isSchedulerPage) {
            RequestHandler.requestSend();
            fetch(webApiAccounts + "/" + Account.id + "/expenses", {
                method: "GET",
                headers: await getJSONHeaders(),
            })
                .then(ErrorHandling.handleError)
                .then(resExp => resExp.json())
                .then(
                    async (resultExp: IExpense[]) => {
                        fetch(`${webApiActivities}/assigned?fromDate=` + format(this.props.startDate, 'yyyy-MM-dd') + '&toDate=' + format(this.props.endDate, 'yyyy-MM-dd'), {
                            method: "GET",
                            headers: await getJSONHeaders(),
                        })
                            .then(ErrorHandling.handleError)
                            .then(resAct => resAct.json())
                            .then(
                                (resultAct) => {
                                    this.setState({
                                        expenseList: resultExp,
                                        activityList: resultAct,
                                        error: null,
                                        unfilteredList: resultExp,
                                        filteredExpenseList: resultExp.filter(x => x.type?.toLowerCase() !== dailyAllowance)
                                    });
                                })
                            .catch(error => errorOccurred(error, this));
                    })
                .catch(error => errorOccurred(error, this));
        }

        function errorOccurred(error: string, page: ExpenseOffCanvas) {
            page.setState({
                expenseList: [],
                activityList: [],
                error: error
            });
            RequestHandler.badRequestReceived(error, "Something went wrong!")
        }
    }

    checkEmptyFields(expenseEntries: IExpenseEntry[]) {
        let isEmpty = false;
        expenseEntries.forEach(exp => {
            if (exp.activity.id.length !== 36) {
                isEmpty = true;
            }

            if (exp.expense.id.length !== 36) {
                isEmpty = true;
            }

            if (expenseEntries[0].expense.type === "mileage") {
                if (isNullOrWhiteSpace(exp.departure)) {
                    isEmpty = true;
                }

                if (isNullOrWhiteSpace(exp.arrival)) {
                    isEmpty = true;
                }

                if (exp.distance <= 0) {
                    isEmpty = true;
                }
            }

            if (exp.amount <= 0) {
                isEmpty = true;
            }

            if (isNullOrWhiteSpace(exp.note)) {
                isEmpty = true;
            }

            if (isEmpty) {
                return isEmpty;
            }
        })
        return isEmpty;
    }

    onChangeData<T>(key: string, value: T, updateAmountValue: boolean = false) {

        let expenseEntries = [...this.state.expenseEntries];

        for (let arrayIndex: number = 0; arrayIndex < expenseEntries.length; arrayIndex++) {

            let expenseEntry = {
                ...this.state.expenseEntries[arrayIndex],
            };

            expenseEntry[key] = value;

            if (key === "expense") {
                //reset all field each time we change expense type
                expenseEntry.arrival = "";
                expenseEntry.amount = 0;
                expenseEntry.departure = "";
                expenseEntry.distance = 0;
                expenseEntry.receiptNumber = "";

                if (typeof value === "object" && (value as unknown as IExpense)["type"]?.toString().toLocaleLowerCase() === dailyAllowance) {
                    if ((value as unknown as IExpense)["amountPerDistanceUnit"]) {
                        expenseEntry.amount = (value as unknown as IExpense)["amountPerDistanceUnit"] ?? 0;
                    } else {
                        expenseEntry.amount = 0;
                    }
                }

                if (typeof value === "object" && (value as unknown as IExpense)["type"]?.toString().toLocaleLowerCase() === "mileage") {
                    if ((value as unknown as IExpense)["amountPerDistanceUnit"]) {
                        expenseEntry.amountPerDistanceUnit = (value as unknown as IExpense)["amountPerDistanceUnit"] ?? 0;
                    } else {
                        expenseEntry.amountPerDistanceUnit = 0;
                    }
                }
            }

            if (key === "distance" && updateAmountValue && typeof value == "number") {
                if (expenseEntry.amountPerDistanceUnit) {
                    expenseEntry.amount = value * expenseEntry.amountPerDistanceUnit;
                } else {
                    expenseEntry.amount = 0;
                }
            }

            if (key === "amount" && typeof value == "number") {
                this.checkExceededCeiling(expenseEntry)
            }

            if (key === "day" && value instanceof Date) {
                this.getDailyAllowance(value);
                expenseEntry.expense = { id: "", description: "", company: "", type: "" };
                expenseEntry.amount = 0;
            }

            expenseEntries[arrayIndex] = expenseEntry;
        }

        this.setState({
            expenseEntries: expenseEntries,
            isEmpty: this.checkEmptyFields(expenseEntries)
        })
    }

    onExpenseSelect(expenseId: string) {
        let selectedExpense = this.state.expenseList.filter((exp) => exp.id === expenseId)[0];
        if (selectedExpense) {
            this.onChangeData<IExpense>("expense", selectedExpense);
        }
        this.setState({ hasExceededCeilings: false })
    }

    onBlurDistance() {
        let distance: number = this.state.expenseEntries[0].distance;

        if (isNaN(distance)) {
            distance = 0;
        }

        this.onChangeData<number>("distance", distance, true);
    }

    onBlurAmount() {
        if (isNaN(this.state.expenseEntries[0].amount)) {
            this.onChangeData<number>("amount", 0);
        }
    }

    onClose() {
        this.setState({ isVisible: false, hasExceededCeilings: false });
        this.props.onClose();
    }

    async onSave() {
        await this.props.onSave(this.state.expenseEntries);
        this.onClose();
    }

    async onDelete() {
        await this.props.onDelete(this.state.expenseEntries);
        this.onClose();
    }

    async getDailyAllowance(startDate: Date) {
        if (this.props.expenseListComplete) {
            var currentDate = new Date();
            var currentMonth = currentDate.getMonth();
            var currentYear = currentDate.getFullYear();

            var expenseDate = new Date(startDate);
            var expenseMonth = expenseDate.getMonth();
            var expenseYear = expenseDate.getFullYear();

            var lastDay = new Date(new Date(currentYear, currentMonth + 1, 0).setHours(23, 59, 59))

            //same month same year, filter based on pre existing expenses daily allowance in that day
            if (expenseMonth === currentMonth && expenseYear === currentYear) {
                //capire se nel giorno selezionato ci sono altre activities di tipo dailyAllowance
                let dailyExpenseList = this.props.expenseListComplete.filter(x => new Date(new Date(x.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(startDate).setHours(0, 0, 0, 0)).getTime())
                let hasDailyAllowance: boolean = dailyExpenseList.map(y => { return y.expense.type }).filter(x => x?.toLowerCase() === dailyAllowance).length > 0 ? true : false;
                this.setState({ expenseList: hasDailyAllowance ? this.state.filteredExpenseList : this.state.unfilteredList });
            }

            //date greater then current month last day
            else if (new Date(expenseDate.setHours(0, 0, 0)).getTime() > lastDay.getTime()) {

                this.setState({ expenseList: this.state.filteredExpenseList })
            }

            //date is 1 month before current, and current day < second working day
            else if (expenseMonth === currentMonth - 1 && expenseYear === currentYear) {

                let workingDayCount = 0;
                let day = 1;
                for (day; workingDayCount < 1; day++) {
                    if (!isHoliday(new Date(currentYear, currentMonth, day))) {
                        workingDayCount++;
                    }
                }

                var secondWorkingDay = new Date(new Date(currentYear, currentMonth, day).setHours(23, 59, 59));
                if (currentDate.getTime() > secondWorkingDay.getTime()) {
                    this.setState({ expenseList: this.state.filteredExpenseList });
                }
            } else {
                this.setState({ expenseList: this.state.filteredExpenseList });
            }
        }
    }

    //function to check if selected expense has an amount above the limit
    checkExceededCeiling(expenseEntry: IExpenseEntry) {
        if (expenseEntry.expense.amountPerDistanceUnit && expenseEntry.expense.amountPerDistanceUnit > 0) {
            if (expenseEntry.amount !== 0 && expenseEntry.amount > expenseEntry.expense.amountPerDistanceUnit) {
                this.setState({ hasExceededCeilings: true })
            } else {
                this.setState({ hasExceededCeilings: false })
            }
        } else {
            this.setState({ hasExceededCeilings: false })
        }
    }

    render() {
        return (

            <div>
                <ItemOffCanvas
                    offCanvasTitle={this.state.expenseOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    onDelete={this.onDelete}
                    editMode={this.props.editMode}
                    saveDisabled={this.state.isEmpty}
                >

                    {this.state.expenseEntries && this.state.expenseEntries.length >= 1 && (
                        <>
                            <div className="col-12">
                                <ItemsList
                                    key="Activity"
                                    items={this.state.activityList}
                                    selectedItem={this.state.expenseEntries[0]?.activity}
                                    onSelectedItemChange={(activity: IActivity) => this.onChangeData<IActivity>("activity", activity)}
                                    onListShowHide={this.onListShowHide}
                                    onFavouriteOrColorChange={this.props.onFavouriteOrColorChange}
                                    searchPlaceholder="Project name, activity, holiday"
                                />
                                {this.state.isContentVisible && (
                                    <>
                                        {this.state.expenseEntries.length === 1 && (
                                            <>
                                                <label htmlFor="" className="mt-4 mb-1">Date</label>
                                                <DaySelect
                                                    startDate={this.props.startDate}
                                                    endDate={this.props.endDate}
                                                    date={new Date(this.state.expenseEntries[0].day)}
                                                    onDateChange={(day: Date) => this.onChangeData<Date>("day", day)}
                                                />
                                            </>
                                        )}

                                        {this.state.expenseEntries.length > 1 && (
                                            <>
                                                <label htmlFor="" className="mt-4 mb-1">Dates</label>
                                                <div className="row">
                                                    {this.state.expenseEntries.map((expense) => (
                                                        <div className="col-2" key={expense?.id}>
                                                            <ShowDayWeekAndDate day={expense.day} />
                                                        </div>))}
                                                </div>
                                            </>
                                        )}

                                        <label htmlFor="" className="mt-4 mb-1">Expense Type</label>


                                        <ItemsList
                                            key="Expense"
                                            items={this.state.expenseList}
                                            selectedItem={this.state.expenseEntries[0]?.expense}
                                            onSelectedItemChange={(expense: IExpense) => this.onExpenseSelect(expense.id)}
                                            searchPlaceholder="Expense type"
                                            onFavouriteOrColorChange={this.props.onExpenseFavouriteOrColorChange}
                                            showCompleteList={true}
                                        />
                                        {this.state.expenseEntries[0].expense.type === "mileage" && (
                                            <>
                                                <div className="mt-4 d-flex justify-content-center">
                                                    <div>
                                                        <label htmlFor="" className="mb-1">Departure</label>
                                                        <input
                                                            className="w-75 form-control custom-color-border"
                                                            type="text"
                                                            onChange={(event) => this.onChangeData<string>("departure", event.target.value)}
                                                            value={this.state.expenseEntries[0].departure}
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="" className="mb-1">Arrival</label>
                                                        <input
                                                            className="w-75 form-control custom-color-border"
                                                            type="text"
                                                            onChange={(event) => this.onChangeData<string>("arrival", event.target.value)}
                                                            value={this.state.expenseEntries[0].arrival}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 d-flex justify-content-center">
                                                    <div>
                                                        <label htmlFor="" className="mb-1">Distance</label>
                                                        <input
                                                            className="w-75 form-control custom-color-border"
                                                            type="number"
                                                            onKeyDown={(e) => (e.key === '-' || e.key === '+') && e.preventDefault()}
                                                            onChange={(event) => this.onChangeData<number>("distance", parseFloat(event.target.value))}
                                                            onBlur={() => this.onBlurDistance()}
                                                            value={this.state.expenseEntries[0].distance.toString()}
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="" className="mb-1">Amount Per Distance</label>
                                                        <input
                                                            className="w-75 input-group-text custom-color-border"
                                                            type="number"
                                                            value={this.state.expenseEntries[0]?.amountPerDistanceUnit?.toFixed(2)}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <label htmlFor="" className="mt-4 mb-1">Amount</label>
                                        <div className="input-group">
                                            <input className="input-group-text w-25 custom-color-border" value="€" readOnly />
                                            {(this.state.expenseEntries[0].expense.type === "mileage"
                                                || this.state.expenseEntries[0].expense.type?.toLocaleLowerCase() === dailyAllowance) && (
                                                    <input
                                                        type="number"
                                                        className="input-group-text w-50 custom-color-border"
                                                        value={this.state.expenseEntries[0]?.amount?.toFixed(2)}
                                                        readOnly
                                                    />)}
                                            {this.state.expenseEntries[0].expense.type !== "mileage" &&
                                                this.state.expenseEntries[0].expense.type?.toLocaleLowerCase() !== dailyAllowance && (
                                                    <input
                                                        type="number"
                                                        className="form-control w-50 custom-color-border"
                                                        onBlur={() => this.onBlurAmount()}
                                                        onChange={(event) => this.onChangeData<number>("amount", parseFloat(event.target.value))}
                                                        value={this.state.expenseEntries[0]?.amount.toString()}
                                                        step="0.01"
                                                        required
                                                    />
                                                )}
                                            {this.state.hasExceededCeilings && (
                                                <label className="text-danger">Massimale superato ({this.state.expenseEntries[0].expense.amountPerDistanceUnit} €)</label>
                                            )}
                                        </div>

                                        {this.state.expenseEntries[0].expense.type !== "mileage" &&
                                            this.state.expenseEntries[0].expense.type?.toLocaleLowerCase() !== dailyAllowance && (
                                                <>
                                                    <label htmlFor="" className="mt-4 mb-1">Receipt number</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="custom-color-border"
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => this.onChangeData<string>("receiptNumber", e.target.value)}
                                                            value={this.state.expenseEntries[0].receiptNumber ?? ""}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        <label htmlFor="" className="mt-4 mb-1">Description</label>
                                        <div className="input-group mb-3">
                                            <textarea
                                                className="custom-color-border"
                                                style={{ width: "100%" }}
                                                rows={5}
                                                onChange={(e) => this.onChangeData<string>("note", e.target.value)}
                                                value={this.state.expenseEntries[0].note}
                                                placeholder="Describe the expense"
                                                required
                                            />
                                        </div>
                                    </>)}
                            </div>
                        </>
                    )}

                </ItemOffCanvas >
            </div >
        )
    }
}
