import React, { Component } from 'react';
import { SearchableAccounts } from './SearchableAccounts';
import { SchedulerTable } from './SchedulerTable';
import SearchableActivities from './SearchableActivities';
import { IAbsence, IAccount, IAccountMonthStatus, IActivity, IActivityEntry, IExpense, IExpenseEntry, ILocation, IMonthsStatus, MonthStatus, SchedulerType, UserType, IItem, ErrorHandling, LoadingHandling, RequestHandler } from '../../Models/CalendarDay';
import { deleteApproveMonth, deleteLock, getJSONHeaders, putApproveMonth, putConfirmMonth, webApiAbsense, webApiAccounts, webApiActivities, webApiLocations } from './../../AppSettings'
import { DaySelectOptionState } from '../Utils/DaySelectOptionState';
import { addMonths, format } from 'date-fns';
import { AnimationClassNames } from '@fluentui/react';

interface IProps {
    account: IAccount;
    schedulerType: SchedulerType;
}

interface IState {
    accounts: IAccount[];
    activities: IActivity[];
    accountsMonthStatus: IAccountMonthStatus[];
    monthStatusFilter?: MonthStatus;

    selectedAccount: IAccountMonthStatus | null,
    selectedAccountId: string,
    selectedActivity: IActivity | null;
    selectedExpense: IExpense | null;

    activityEntries: IActivityEntry[];
    expenseEntries: IExpenseEntry[];

    absenceList: IAbsence[];
    locationList: ILocation[];
    activityList: IItem[];
    expenseList: IExpense[];
    fullActivityList: IActivity[];

    isLoadedAccount: boolean;
    isLoadedMonthStaus: boolean;
    schedulerType: SchedulerType

    showMultipleItemsSelection: boolean;
    selectionType: string;
    itemsText: string;
    selectedItems: number;

    startDate: Date,
    endDate: Date,

    monthStatus: IMonthsStatus | null;
    error: string | null;

    userType: UserType,
    monthStatusType: MonthStatus,
    filteredList: IAccountMonthStatus[],

}

export default class Scheduler extends Component<IProps, IState>{
    schedulerTableRef: React.RefObject<SchedulerTable>;

    getBlankState() {
        return {
            accounts: [],
            activities: [],
            accountsMonthStatus: [],

            selectedAccount: null,
            selectedAccountId: this.props.account.id,
            selectedActivity: null,
            selectedExpense: null,

            activityEntries: [],
            expenseEntries: [],

            absenceList: [],
            activityList: [],
            locationList: [],
            expenseList: [],
            fullActivityList: [],

            isLoadedAccount: false,
            isLoadedMonthStaus: false,

            schedulerType: this.props.schedulerType,

            showMultipleItemsSelection: false,
            selectionType: "",
            itemsText: "",
            selectedItems: 0,

            startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(addMonths(new Date(), 1).setDate(0)).setHours(23, 59, 59, 999)),

            monthStatus: null,

            error: null,

            userType: UserType.All,
            monthStatusType: MonthStatus.All,
            filteredList: [],
        }
    }

    constructor(props: IProps) {
        super(props);
        this.onAccountSelect = this.onAccountSelect.bind(this);
        this.onActivitySelect = this.onActivitySelect.bind(this);
        this.loadEntries = this.loadEntries.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onApproveMonth = this.onApproveMonth.bind(this);
        this.onDeleteApproveMonth = this.onDeleteApproveMonth.bind(this);
        this.onActivitySelection = this.onActivitySelection.bind(this);
        this.onExpenseSelection = this.onExpenseSelection.bind(this);
        this.onDeleteLockMonth = this.onDeleteLockMonth.bind(this);
        this.downloadPdf = this.downloadPdf.bind(this);
        this.downloadSchedulerPm = this.downloadSchedulerPm.bind(this);

        this.onConfirm = this.onConfirm.bind(this);

        this.schedulerTableRef = React.createRef();

        this.state = this.getBlankState()
    }

    onAccountSelect(account: IAccountMonthStatus) {
        this.setState({ selectedAccount: account, selectedActivity: null });
    }

    onActivitySelect(activity: IActivity) {
        this.setState({ selectedActivity: activity, selectedAccount: null });
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if ((this.state.schedulerType === SchedulerType.Scheduler && this.state.selectedAccount !== prevState.selectedAccount) ||
            (this.state.schedulerType === SchedulerType.ProjectManager && this.state.selectedActivity !== prevState.selectedActivity) ||
            this.state.startDate.getTime() !== prevState.startDate.getTime() ||
            this.state.endDate.getTime() !== prevState.endDate.getTime()
        ) {
            this.loadEntries();
        }

        if (this.props.account !== prevProps.account) {
            this.loadData();
        }

        if ((prevState.userType !== this.state.userType) || (prevState.monthStatusType !== this.state.monthStatusType)) {
            this.filterList();
        }
        if (this.state.accountsMonthStatus !== prevState.accountsMonthStatus) {
            this.filterList();
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (this.props.account !== nextProps.account) {
            this.setState(this.getBlankState());
        }
    }

    async loadEntries() {
        RequestHandler.requestSend();

        let queryActivity: string = "";
        let queryExpense: string = "";
        let queryMonthsStatus: string = "";

        switch (this.state.schedulerType) {
            case SchedulerType.Scheduler:
                if (!this.state.selectedAccount?.accountId) {
                    this.setState({
                        activityEntries: [],
                        expenseEntries: [],
                        monthStatus: null,
                        isLoadedMonthStaus: false
                    })
                    LoadingHandling.hideLoader();
                    return;
                }
                queryActivity = webApiAccounts + "/" + this.state.selectedAccount?.accountId + "/activityEntries?fromDate=" + format(this.state.startDate, 'yyyy-MM-dd') + "&toDate=" + format(this.state.endDate, 'yyyy-MM-dd');
                queryExpense = webApiAccounts + "/" + this.state.selectedAccount?.accountId + "/expenseEntries?fromDate=" + format(this.state.startDate, 'yyyy-MM-dd') + "&toDate=" + format(this.state.endDate, 'yyyy-MM-dd');
                queryMonthsStatus = webApiAccounts + "/" + this.state.selectedAccount?.accountId + "/entriesMonthStatus/" + new Date(this.state.startDate).getFullYear() + "/" + (new Date(this.state.startDate).getMonth() + 1);
                break;
            case SchedulerType.ProjectManager:
                if (!this.state.selectedActivity?.id) {
                    LoadingHandling.hideLoader();
                    return;
                }
                queryActivity = webApiActivities + "/" + this.state.selectedActivity?.id + "/activityEntries?fromDate=" + format(this.state.startDate, 'yyyy-MM-dd') + "&toDate=" + format(this.state.endDate, 'yyyy-MM-dd');
                queryExpense = webApiActivities + "/" + this.state.selectedActivity?.id + "/expenseEntries?fromDate=" + format(this.state.startDate, 'yyyy-MM-dd') + "&toDate=" + format(this.state.endDate, 'yyyy-MM-dd');
                break;
        }

        fetch(queryActivity, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then(async (resultAct) => {
                fetch(queryExpense, {
                    method: "GET",
                    headers: await getJSONHeaders(),
                })
                    .then(ErrorHandling.handleError)
                    .then(resExp => resExp.json())
                    .then(async (resultExp) => {
                        if (this.state.schedulerType === SchedulerType.Scheduler) {
                            fetch(queryMonthsStatus, {
                                method: "GET",
                                headers: await getJSONHeaders(),
                            })
                                .then(ErrorHandling.handleError)
                                .then(resMonthStatus => resMonthStatus.json())
                                .then((resultMonthStatus) => {
                                    this.setState({
                                        activityEntries: resultAct,
                                        expenseEntries: resultExp,
                                        monthStatus: resultMonthStatus,
                                        isLoadedMonthStaus: true
                                    })
                                    if (this.state.isLoadedAccount && this.state.isLoadedMonthStaus) {
                                        RequestHandler.responseReceived("");
                                    }
                                })
                                .catch(error => {
                                    RequestHandler.badRequestReceived(error, "Somethig went wrong!");
                                });
                        } else {
                            this.setState({
                                activityEntries: resultAct,
                                expenseEntries: resultExp
                            })
                            RequestHandler.responseReceived("");
                        }
                    })
                    .catch(error => {
                        RequestHandler.badRequestReceived(error, "Somethig went wrong!");
                    });
            })
            .catch(error => {
                this.setState({
                    activityEntries: [],
                    expenseEntries: []
                })
                RequestHandler.badRequestReceived(error, "Somethig went wrong!");
            });
        this.loadActivitiesExpensies();
    }

    async loadActivitiesExpensies() {
        LoadingHandling.showLoader();

        let onisFavouriteVisible = false;

        let isFavouriteVisible: boolean = (onisFavouriteVisible === undefined || onisFavouriteVisible === null) ? true : onisFavouriteVisible;

        fetch(webApiLocations, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resLoc => resLoc.json())
            .then(
                async (resultLoc) => {
                    fetch(webApiAbsense, {
                        method: "GET",
                        headers: await getJSONHeaders(),
                    })
                        .then(ErrorHandling.handleError)
                        .then(resAbs => resAbs.json())
                        .then(
                            async (resAbs) => {
                                fetch(`${webApiAccounts}/${this.state.selectedAccount?.accountId ?? this.props.account.id}/activities?fromDate=${format(this.state.startDate, 'yyyy-MM-dd')}&toDate=${format(this.state.endDate, 'yyyy-MM-dd')}`, {
                                    method: "GET",
                                    headers: await getJSONHeaders(),
                                })
                                    .then(ErrorHandling.handleError)
                                    .then(resAct => resAct.json())
                                    .then(
                                        async (resultAct) => {
                                            fetch(webApiAccounts + "/" + this.props.account.id + "/expenses", {
                                                method: "GET",
                                                headers: await getJSONHeaders(),
                                            })
                                                .then(ErrorHandling.handleError)
                                                .then(resExp => resExp.json())
                                                .then((resultExp) => {
                                                    this.setState({
                                                        absenceList: resAbs,
                                                        locationList: resultLoc,
                                                        expenseList: resultExp,
                                                        activityList: isFavouriteVisible ? resultAct : resultAct.map((act: IActivity) => { act.isFavourite = undefined; act.color = undefined; return act }),
                                                        fullActivityList: resultAct,
                                                        error: null
                                                    });
                                                    RequestHandler.responseReceived("");
                                                })
                                                .catch(error => errorOccurred(error, this));
                                        })
                                    .catch(error => errorOccurred(error, this));
                            })
                        .catch(error => errorOccurred(error, this));
                })
            .catch(error => errorOccurred(error, this));
        function errorOccurred(error: string, page: Scheduler) {
            page.setState({
                locationList: [],
                activityList: [],
                expenseList: [],
                absenceList: [],
                fullActivityList: [],
                error: error
            });
            RequestHandler.badRequestReceived(error, "Something went wrong!")
        }
    }

    loadData() {

        switch (this.state.schedulerType) {
            case SchedulerType.Scheduler:
                this.loadAccounts(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1);
                break;
            case SchedulerType.ProjectManager:
                this.loadActivities();
                break;
        }
    }

    async loadActivities() {
        RequestHandler.requestSend();

        let queryActivities = `${webApiActivities}/related`;
        fetch(queryActivities, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAct => resAct.json())
            .then((resultAct) => {
                this.setState({
                    accounts: [],
                    activities: resultAct,

                })
                RequestHandler.responseReceived("");
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong with loading activities!");
            })
    }

    async loadAccounts(year: number, month: number) {
        RequestHandler.requestSend();

        let queryAccounts = webApiAccounts + "/relatedAccounts/" + year + "/" + month

        fetch(queryAccounts, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAcc => resAcc.json())
            .then((resultAcc: IAccountMonthStatus[]) => {
                this.setState({
                    accountsMonthStatus: resultAcc,
                    activities: [],
                    isLoadedAccount: true
                })
                if (this.state.isLoadedAccount && this.state.isLoadedMonthStaus || this.state.selectedAccount === null) {
                    RequestHandler.responseReceived("");
                }
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong with loading accounts!");
            })
    }

    async downloadPdf() {
        LoadingHandling.showLoader();
        let queryAccounts = webApiAccounts + "/" + this.state.selectedAccount?.accountId + "/expenses/download?from=" + format(this.state.startDate, 'yyyy-MM-dd') + "&to=" + format(this.state.endDate, 'yyyy-MM-dd')
        let filename = "file.xlsx"
        fetch(queryAccounts, {
            method: "GET",
            headers: await getJSONHeaders()
        })
            .then(response => {
                ErrorHandling.handleError(response);

                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(response.headers?.get("content-disposition") ?? "");
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
                return response?.blob();
            })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                RequestHandler.responseReceived("");
                var link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.click();
                LoadingHandling.hideLoader();
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong with PDF download request!");
                LoadingHandling.hideLoader();
            })
    }

    async downloadSchedulerPm() {
        LoadingHandling.showLoader();

        let queryAccounts = webApiActivities + "/" + this.state.selectedActivity?.id + "/export?from=" + format(this.state.startDate, 'yyyy-MM-dd') + "&to=" + format(this.state.endDate, 'yyyy-MM-dd')

        let filename = "file.xlsx"

        fetch(queryAccounts, {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(response => {
                ErrorHandling.handleError(response);

                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(response.headers?.get("content-disposition") ?? "");
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
                return response?.blob();
            })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                RequestHandler.responseReceived("");
                var link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.click();
                LoadingHandling.hideLoader();
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong with Excel download request!");
                LoadingHandling.hideLoader();
            })
    }

    onDateChange(startDate: Date, endDate: Date) {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            isLoadedMonthStaus: false,
            isLoadedAccount: false
        })
        if (this.state.schedulerType === SchedulerType.Scheduler) {
            this.loadAccounts(startDate.getFullYear(), startDate.getMonth() + 1);
        }
    }

    componentDidMount() {
        if (this.props.account?.id !== undefined && this.props.account?.id !== null && this.props.account?.id !== "") {
            this.loadData();
        }
    }

    async onApproveMonth() {
        let year = this.state.startDate.getFullYear();
        let month = this.state.startDate.getMonth() + 1;
        let startAccount = this.state.accountsMonthStatus.filter(y => y.accountId === this.state.selectedAccount?.accountId)[0]
        if (this.state.selectedAccount) {

            if (!startAccount.isConfirmed) {
                await putConfirmMonth(this.state.selectedAccount.accountId, year, month).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            }

            let resultApprove = await putApproveMonth(this.state.selectedAccount.accountId, year, month).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (resultApprove) {
                startAccount.isConfirmed = true;
                startAccount.isApproved = true;
                let indexToRemove = this.state.accountsMonthStatus.findIndex(x => x.accountId === this.state.selectedAccount?.accountId)
                if (indexToRemove !== -1) {
                    this.state.accountsMonthStatus.splice(indexToRemove, 1, startAccount)
                }

                this.filterList();

                this.loadEntries();
                ErrorHandling.successToast("Month approved", 2000);
            }
        } else {
            ErrorHandling.errorToast("Something went wrong!");
        }
    }

    async onDeleteApproveMonth() {
        let year = this.state.startDate.getFullYear();
        let month = this.state.startDate.getMonth() + 1;
        if (this.state.selectedAccount) {
            let result = await deleteApproveMonth(this.state.selectedAccount.accountId, year, month).catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
            if (result) {

                let startAccount = this.state.accountsMonthStatus.filter(y => y.accountId === this.state.selectedAccount?.accountId)[0]

                startAccount.isApproved = false;
                let indexToRemove = this.state.accountsMonthStatus.findIndex(x => x.accountId === this.state.selectedAccount?.accountId)
                if (indexToRemove !== -1) {
                    this.state.accountsMonthStatus.splice(indexToRemove, 1, startAccount)
                }

                this.filterList();

                this.loadEntries();
                ErrorHandling.successToast("Month open for changes", 2000);
            }
        } else {
            ErrorHandling.errorToast("Something went wrong!");
        }
    }

    async onDeleteLockMonth() {
        let year = this.state.startDate.getFullYear();
        let month = this.state.startDate.getMonth() + 1;
        if (this.state.selectedAccount) {
            let result = await deleteLock(this.state.selectedAccount.accountId, year, month).catch(error => RequestHandler.badRequestReceived(error, "Somthing went wrong!"));
            if (result) {
                let startAccount = this.state.accountsMonthStatus.filter(y => y.accountId === this.state.selectedAccount?.accountId)[0]
                startAccount.isLocked = false;
                startAccount.isError = false;
                let indexToRemove = this.state.accountsMonthStatus.findIndex(x => x.accountId === this.state.selectedAccount?.accountId)
                if (indexToRemove !== -1) {
                    this.state.accountsMonthStatus.splice(indexToRemove, 1, startAccount)
                }
                this.filterList();
                this.loadEntries();
                ErrorHandling.successToast("Month unlocked", 2000);
            }
        } else {
            ErrorHandling.errorToast("Something went wrong!");
        }
    }

    onActivitySelection(numberSelectedActivities: number) {
        this.setState({
            selectedItems: numberSelectedActivities,
            selectionType: "activity",
            itemsText: numberSelectedActivities + " activit" + (numberSelectedActivities > 1 ? "ies" : "y") + " selected",
            showMultipleItemsSelection: numberSelectedActivities > 0
        })
    }

    onExpenseSelection(numberSelectedExpenses: number) {
        this.setState({
            selectedItems: numberSelectedExpenses,
            selectionType: "expense",
            itemsText: numberSelectedExpenses + " expense" + (numberSelectedExpenses > 1 ? "s" : "") + " selected",
            showMultipleItemsSelection: numberSelectedExpenses > 0
        })
    }

    onConfirm() {
        if (this.state.selectionType === "activity") {
            this.schedulerTableRef.current?.onEditActivities();
        }

        if (this.state.selectionType === "expense") {
            this.schedulerTableRef.current?.onEditExpenses();
        }
    }

    filterList() {
        var accountMonthStatus = [...this.state.accountsMonthStatus]

        var firstFilter = accountMonthStatus.filter(x => {
            switch (this.state.userType) {
                case UserType.External:
                    return x.accountType === "external";
                case UserType.Internal:
                    return x.accountType === "internal";
                case UserType.Stager:
                    return x.accountType === "stager";
                case UserType.Temporary:
                    return x.accountType === "temporary";
                case UserType.Dismissed:
                    return x.accountType === "dismissed";
                case UserType.All: return x;
                default: return x;
            }
        });

        var secondFilter = firstFilter.filter(x => {
            switch (this.state.monthStatusType) {
                case MonthStatus['Not confirmed']:
                    return x.isConfirmed === false && x.isApproved === false && x.isLocked === false && x.isError === false;
                case MonthStatus.Confirmed:
                    return x.isConfirmed === true && x.isApproved === false && x.isLocked === false && x.isError === false;
                case MonthStatus.Approved:
                    return x.isConfirmed === true && x.isApproved === true && x.isLocked === false && x.isError === false;
                case MonthStatus.Locked:
                    return x.isConfirmed === true && x.isApproved === true && x.isLocked === true && x.isError === false;
                case MonthStatus.Error:
                    return x.isError === true;
                case MonthStatus.All: return x;
                default: return x;

            }
        });

        this.setState({ filteredList: secondFilter })
    }
    selectStatusFilterError() {
        this.setState({ monthStatusFilter: undefined });
        setTimeout(() => this.setState({ monthStatusFilter: MonthStatus.Error }), 100);
    }

    render() {
        return (
            <div>
                <div className='p-3 m-0 row'>
                    <div className={`col-lg-3 col-sm-12 rounded ${AnimationClassNames.fadeIn500}`}
                    >
                        {this.state.schedulerType === SchedulerType.Scheduler && (
                            <SearchableAccounts
                                accounts={this.state.accountsMonthStatus}
                                onAccountSelect={this.onAccountSelect}
                                selectStatusFilter={this.state.monthStatusFilter}
                            />
                        )}
                        {this.state.schedulerType === SchedulerType.ProjectManager && (
                            <SearchableActivities projects={this.state.activities} onProjectSelect={this.onActivitySelect} />
                        )}
                    </div>

                    <div className={`col-lg-9 col-sm-12 rounded ${AnimationClassNames.fadeIn500}`}>
                        <SchedulerTable
                            ref={this.schedulerTableRef}
                            activityEntries={this.state.activityEntries}
                            schedulerType={this.state.schedulerType}
                            isConfirmed={this.state.monthStatus?.currentMonth.isConfirmed}
                            isApproved={this.state.monthStatus?.currentMonth.isApproved}
                            isLocked={this.state.monthStatus?.currentMonth.isLocked}
                            unlockMonth={this.onDeleteLockMonth}
                            selectedAccount={this.state.selectedAccount}
                            accountId={this.state.selectedAccountId}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            downloadPdf={this.downloadPdf}
                            selectedActivity={this.state.selectedActivity}
                            downloadSchedulerPm={this.downloadSchedulerPm}
                            expenseEntries={this.state.expenseEntries}
                            onDateChange={this.onDateChange}
                            onApproveMonth={this.onApproveMonth}
                            onDeleteApproveMonth={this.onDeleteApproveMonth}
                            onActivitySelection={this.onActivitySelection}
                            onExpenseSelection={this.onExpenseSelection}
                            onRefresh={this.loadEntries}
                            monthStatus={this.state.monthStatus}
                            absenceList={this.state.absenceList}
                            locationList={this.state.locationList}
                            activityList={this.state.activityList}
                            expenseList={this.state.expenseList}
                            fullActivityList={this.state.fullActivityList}
                            setMonthStatusErrorFilter={() => this.selectStatusFilterError()}
                        />
                    </div>
                </div >
                <DaySelectOptionState
                    confirmButtonText={"Edit"}
                    text={this.state.itemsText}
                    isVibile={this.state.showMultipleItemsSelection}
                    onConfirm={this.onConfirm}
                    onCancel={() => this.schedulerTableRef.current?.onCloseFromChangeActivityAndExpenseOffCanvas()}
                />
            </div>
        )
    }
}
