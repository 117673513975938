import React, { } from 'react';
import { IActivityForExpense } from '../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus as fasPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    data: IActivityForExpense,
    isSelectable: boolean,
    isMonthEditable: boolean,
    onClick: Function
}

export const ActivitySelectorForExpenses = ({ data, isSelectable, onClick, isMonthEditable }: IProps) => {
    return (
        <button
            className="btn btn-outline-light calendar-activity"
            onClick={() => {
                if (isMonthEditable) {
                    onClick();
                }
            }}>

            <div className="activity-project-description m-0">
                <div className="row">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className={"text-start ms-1 d-flex align-items-center justify-content-center"}>
                            <div
                                className="col-12 me-1"
                                style={{
                                    backgroundColor: data?.color ?? "#ffffff",
                                    border: ".5px solid black",
                                    borderRadius: ".45rem",
                                    height: ".9rem",
                                    width: ".9rem"
                                }}>
                            </div>
                            <p className="m-0 col-12">{data?.description}</p>
                        </div>

                        {isMonthEditable && isSelectable && (
                            <div
                                className={`${data?.isSelected ? "btn-primary-custom d-flex align-items-center justify-content-center" : "btn-outline-primary d-flex align-items-center justify-content-center"} shadow-none active-element m-0 p-0 col-4`}
                                style={{ width: "1.5rem", height: "1.5rem" }}
                            >
                                <FontAwesomeIcon icon={fasPlus} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </button>
    );
};
