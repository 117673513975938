import React, { Component } from 'react';
import { IRequest, RequestStatus, } from '../../../Models/CalendarDay';
import { diffHours } from '../RequestsPage';
import { format } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    requestData: IRequest[];
    showRequest: Function;
}

interface IState {
}

export default class RequestsPageTable extends Component<IProps, IState> {

    topRow: string[] = ["User", "Data request", "Absence type", "Start", "End", "Total hours", "Status", ""]

    render() {
        let topRow: JSX.Element | JSX.Element[] = [];
        let tableData: JSX.Element | JSX.Element[] = [];

        topRow = this.topRow.map((elem, i) => (
            <th key={i}>{elem}</th>
        )
        );

        tableData = this.props.requestData.map((elem, i) => (
            elem.status === "Canceled" ? <div></div> : 
            <tr key={i}>
                <td>{elem.account.firstName} {elem.account.lastName}</td>
                <td>{format(new Date(elem.requestDate), "dd/MM/yyyy")}</td>
                <td>{elem.absence.description}</td>
                <td>{format(new Date(elem.startDate), "dd/MM/yyyy - HH:mm")}</td>
                    <td>{format(new Date(elem.endDate), "dd/MM/yyyy - HH:mm")}</td>
                    <td>{diffHours(elem.startDate, elem.endDate)}</td>
                <td><div style={{
                    borderRadius: "100%", width: "15px", height: "15px", backgroundColor: `${elem.status === "Pending" ? RequestStatus.Pending : elem.status === "Approved" ? RequestStatus.Approved : RequestStatus.Rejected}`, display: "inline-block"
                }}></div><div style={{ display: "inline-block", marginLeft: "10px" }}>{elem.status}</div></td>
                <td><button className="btn btn-outline-dark" style={{ borderRadius: "100%", border: "none", outline: "none !important", boxShadow: "none" }} onClick={() => this.props.showRequest(elem)}><FontAwesomeIcon icon={faArrowRight} /></button></td>
            </tr>
        ))

        return (<div>
            <div className="requestTable">
                <table>
                    <thead>
                        <tr>
                            {topRow}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        </div>);
    }

}
