import React, { Component } from "react";

interface IProps {
    text: string;
    confirmButtonText: string;
    onCancel: Function;
    onConfirm: Function;
    onDelete?: Function;
    isVibile: boolean;
}

interface IState {
    text: string;
    confirmButtonText: string;
    isVisible: boolean;
}

export class DaySelectOptionState extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props)
        this.state = {
            text: this.props.text,
            confirmButtonText: this.props.confirmButtonText,
            isVisible: this.props.isVibile,
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.isVibile !== this.state.isVisible) {
            this.setState({ isVisible: nextProps.isVibile });
        }

        if (nextProps.text !== this.state.text) {
            this.setState({ text: nextProps.text });
        }

        if (nextProps.confirmButtonText !== this.state.confirmButtonText) {
            this.setState({ confirmButtonText: nextProps.confirmButtonText });
        }
    }

    onConfirm() {
        this.props.onConfirm()
    }

    onCancel() {
        this.props.onCancel()
    }

    render() {
        let bannerClass: string = this.props.onDelete ? "col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10" : "col-xl-3 col-lg-4 col-md-4 col-sm-6 col-8";
        let buttonClass: string = this.props.onDelete ? "col-2" : "col-3";

        return (
            <>
                {this.state.isVisible && (
                    <div
                        className="dark-banner d-flex justify-content-center align-items-center row"
                        style={{ position: "relative" }}
                    >
                        <div
                            className={"color-button px-2 d-flex justify-content-between align-items-center bg-dark dark-banner text-center " + bannerClass}
                            style={{ position: "fixed", bottom: 30, left: "auto", right: "auto" }}
                        >
                            <p className="ps-2 mb-0 col-6 text-start">{this.state.text}</p>

                            <button className={"btn " + buttonClass} onClick={() => this.onConfirm()}>{this.state.confirmButtonText}</button>

                            {this.props.onDelete && (<button className={"btn " + buttonClass} onClick={() => { if (this.props.onDelete) { this.props.onDelete() } }}>Delete</button>)}

                            <button className={"btn pe-0 " + buttonClass} onClick={() => this.onCancel()}>Cancel</button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}