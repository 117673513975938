import React, { Component } from 'react';
import DropDownSelector from '../Utils/DropDownSelector';
import MonthSelector from '../Utils/MonthSelector';
import SalesPageTable from './SalesPageTable';
import SalesPageOffCanvas from './SalesPageOffCanvas'
import { editMode, SalesVisualizationType, ISales, IUpdateSale, RequestHandler, ErrorHandling } from '../../Models/CalendarDay';
import { emitSales, headers, putSale, webApiSales } from '../../AppSettings';
import { lastDayOfMonth } from 'date-fns/esm/fp';

interface IProps {
}

interface IState {
    sales: ISales[];
    selectedDate: Date;
    visType: SalesVisualizationType;
    filteredList: ISales[];
    multipleEmit: ISales[];
    visualSale: ISales;
    resetField: boolean;
    isVisibleNewSales: boolean;
    editMode: editMode;
    updateSale: IUpdateSale;
}

export default class SalesPage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.onChangeSelector = this.onChangeSelector.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.onShowSales = this.onShowSales.bind(this);
        this.onSelectedSales = this.onSelectedSales.bind(this);
        this.onEmitPress = this.onEmitPress.bind(this);
        this.onCloseNewSales = this.onCloseNewSales.bind(this);
        this.onChangeQuantity = this.onChangeQuantity.bind(this);

        this.state = {
            sales: [],
            filteredList: [],
            selectedDate: new Date(),
            visType: SalesVisualizationType.All,
            multipleEmit: [],
            resetField: false,
            isVisibleNewSales: false,
            visualSale: { invoiceRowDate: new Date('1970-01-01') } as ISales,
            editMode: editMode.None,
            updateSale: {} as IUpdateSale

        }
    }

    onDateChange(toDate: Date, end: Date) {
        this.setState({ selectedDate: toDate, visType: SalesVisualizationType.All }/*, () => this.onEmitPress()*/)
        this.loadSales(toDate);
    }

    onChangeSelector(value: number) {
        let visType = value as SalesVisualizationType;
        this.setState({ visType: visType, filteredList: this.state.sales.filter(x => x.invoiceStatus === SalesVisualizationType[visType]) })
    }

    onShowSales(sales: ISales) {
        this.setState({
            visualSale: sales,
            isVisibleNewSales: true,
            editMode: sales.invoiceStatus === "Open" ? editMode.Edit : editMode.None
        })
        document.body.style.overflowY = "hidden";
    }

    loadSales(toDate: Date) {
        RequestHandler.requestSend();

        let query = webApiSales + "/" + toDate.getFullYear() + "/" + (toDate.getMonth() + 1) + "/" + lastDayOfMonth(toDate).getDate();

        fetch(query, {
            method: "GET",
            headers: headers
        })
            .then(ErrorHandling.handleError)
            .then(resSal => resSal.json())
            .then((resultSal) => {
                this.setState({
                    sales: this.convertDate(resultSal),
                })
                RequestHandler.responseReceived("");
            })
            .catch(error => {
                RequestHandler.badRequestReceived(error, "Something went wrong!");
                this.setState({ sales: [] })
            });
    }

    convertDate(req: ISales[]) {
        if (req) {
            let convertedSales: ISales[] = req.map((salesEntry: ISales) => {
                return {
                    invoiceId: salesEntry.invoiceId,
                    activity: salesEntry.activity,
                    materialDescription: salesEntry.materialDescription,
                    isToInvoice: salesEntry.isToInvoice,
                    materialCode: salesEntry.materialCode,
                    invoiceRowNumber: salesEntry.invoiceRowNumber,
                    invoiceRowDate: salesEntry.invoiceRowDate ? new Date(salesEntry.invoiceRowDate) : undefined,
                    quantity: salesEntry.quantity,
                    unitPrice: salesEntry.unitPrice,
                    value: salesEntry.value,
                    invoiceStatus: salesEntry.invoiceStatus,
                    noteFromCustomer: salesEntry.noteFromCustomer,
                    noteToCustomer: salesEntry.noteToustomer,

                }
            })
            convertedSales.sort((a, b) => a.invoiceStatus.localeCompare(b.invoiceStatus) || a.invoiceId.localeCompare(b.invoiceId) || a.invoiceRowNumber.localeCompare(b.invoiceRowNumber));

            return convertedSales;
        } else {
            return [];
        }
    }

    onSelectedSales(selectedToEmit: ISales[]) {
        this.setState({ multipleEmit: selectedToEmit })
    }

    async onChangeQuantity(sale: ISales) {       
        RequestHandler.requestSend();
  
        if (sale) {
            let response = await putSale(sale).catch(error => RequestHandler.badRequestReceived(error, "Error!"));

            if (response) {
                if (sale.invoiceId.substring(0, 3) === "004") {
                    let startSale = this.state.sales.filter(y => y.quantity === this.state.visualSale?.quantity && y.value === this.state.visualSale?.value)[0]

                    startSale.quantity = sale.quantity;
                    startSale.value = sale.value;
                    startSale.noteToCustomer = sale.noteToCustomer;

                    let indexToRemove = this.state.sales.findIndex(x => x.quantity === this.state.visualSale?.quantity && x.value === this.state.visualSale?.value)
                    if (indexToRemove !== -1) {
                        this.state.sales.splice(indexToRemove, 1, startSale)
                    }
                }
                if (sale.invoiceId.substring(0, 3) === "007") {
                    //let startSale = this.state.sales.filter(y => y.quantity === this.state.visualSale?.quantity && y.value === this.state.visualSale?.value)[0]
                    this.state.sales.push(sale)
                }

                this.updateSales(this.convertDate(this.state.sales))

                RequestHandler.responseReceived("Quantity Modified");
            } 
        }

        this.setState({
            sales: this.state.sales
        })
    }

    onCloseNewSales() {
        this.setState({ isVisibleNewSales: false });
        document.body.style.overflowY = "auto";
    }

    async onEmitPress(elemToEmit: ISales[]) {
        RequestHandler.requestSend();

        if (elemToEmit[0].invoiceStatus === "Open") {

            elemToEmit[0].invoiceStatus = "Invoiced"
        }
      
        if (elemToEmit) {
            let response = await emitSales(elemToEmit).catch(error => RequestHandler.badRequestReceived(error, "Error!"));
            if (response) {
                this.updateSales(this.convertDate(response))
                RequestHandler.responseReceived("Sales Emitted");
            }         
        }
       
        this.setState({ multipleEmit: elemToEmit, resetField: true }, () => this.setState({ resetField: false }))
    }

    updateSales(emitSale: ISales[]) {

        let stateEmit = [...this.state.sales];

        emitSale.forEach(inv => {
            let index = stateEmit.findIndex(emit => inv.invoiceId === emit.id);
            if (index !== -1) {
                stateEmit.splice(index, 1, inv);
            }
        })

        this.setState({ multipleEmit: stateEmit });
    }

    componentDidMount() {
        this.loadSales(this.state.selectedDate);
    }

    render() {
        return (<div style={{ margin: "50px 0px 0px 0px" }}>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4 d-flex">
                        <h3 style={{ fontWeight: "bold" }}>Sales</h3>
                    </div>
                    <div className="col-8 d-flex" style={{ justifyContent: "flex-end" }}>
                        <div>
                            <DropDownSelector
                                data={SalesVisualizationType}
                                selectedData={this.state.visType as number}
                                onSelect={this.onChangeSelector}
                            />
                        </div>
                        <div>
                            <MonthSelector
                                onDateSelected={this.onDateChange}
                                date={new Date(this.state.selectedDate)}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                {this.state.sales.length > 0 ? (
                    <div>
                        <SalesPageTable
                            salesData={this.state.visType === SalesVisualizationType.All ? this.state.sales : this.state.filteredList}
                            salesDetail={this.onShowSales}
                            onSalesSelected={this.onSelectedSales}
                            onEmitPress={this.onEmitPress}
                            resetField={this.state.resetField}
                        />
                    </div>
                ) : (<div><p style={{ fontWeight: "bold" }}>No Sales</p></div>)}
                <br />
                {this.state.multipleEmit.length > 0 && (
                    <button type="button" className="btn btn-outline-dark" onClick={() => this.onEmitPress([this.state.visualSale])}>
                        Emit Invoices ({this.state.multipleEmit.length})
                    </button>
                )}

                <SalesPageOffCanvas
                    requestPageOffCanvasIsVisible={this.state.isVisibleNewSales}
                    requestPageOffCanvasTitle={"Invoice"}
                    onClose={this.onCloseNewSales}
                    editMode={this.state.editMode}
                    onChangeQuantity={this.onChangeQuantity}
                    visualSale={this.state.visualSale}
                />
            </div>
        </div>
        )
    }
}