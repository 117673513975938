import React, { Component, FocusEvent } from "react";
import { IItem } from "../../Models/CalendarDay";
import { SelectionMode } from "../AegisCommon/SelectionMode";
import { ITextProps, IconButton, SearchBox, Stack, StackItem, Text } from "@fluentui/react";
import tinycolor from "tinycolor2";

interface IItemProps {
    item: IItem;
    onItemClick: Function;
    onFavouriteOrColorChange?: Function;
    index: number;
    onItemColorChanged: Function;
	selected?: Boolean;
}

interface IItemState {
    item: IItem;
	selected?: Boolean;
    colorPickerRef?: React.LegacyRef<HTMLInputElement>;
}

export class Item extends Component<IItemProps, IItemState> {
    onChangeColor(e: React.ChangeEvent<HTMLInputElement>) {
        let item = this.state.item;
        item.color = e.target.value;
        this.props.onItemColorChanged(this.props.index);

        this.setState({ item: item });
    }

    onClickChange() {
        let item = this.state.item;
        item.isFavourite = !item.isFavourite;

        this.setState({ item: item });
        if (this.props.onFavouriteOrColorChange) {
            this.props.onFavouriteOrColorChange(item)
        }
    }

    onBlur(item: IItem) {
        if (this.props.onFavouriteOrColorChange) {
            this.props.onFavouriteOrColorChange(item)
        }
    }

    constructor(props: IItemProps) {
        super(props);
        this.onClickChange = this.onClickChange.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);

        this.state = {
            item: this.props.item,
            colorPickerRef: undefined,
        }
    }

    render() {
        return (
            <Stack
                horizontal
                horizontalAlign="start"
                tokens={{maxWidth: "100%"}}
                verticalAlign="center"
                styles={{
                    root: {
                        background: this.props.selected ? "#25599966" : undefined,
                        borderRadius: 5,
                        margin: "2px 0",
                        boxShadow: "0 0 2px #33333366",
                        width: "100%",
                        backgroundColor: this.state.item.color,
                        color: tinycolor(this.props.item.color).isLight() ? "black" : "white",
                    },
                }}
            >
                {
                    this.state.item.isFavourite !== undefined &&
                    <StackItem styles={{root: {padding: 0, margin: 0}}}>
                        <IconButton
                            iconProps={{iconName: this.state.item.isFavourite ? "FavoriteStarFill" : "FavoriteStar"}}
                            onClick={this.onClickChange}
                            styles={{
                                root: {
                                    minHeight: "3em",
                                    borderRadius: 5,
                                    margin: 0
                                },
                                rootHovered: {
                                    backgroundColor: tinycolor(this.props.item.color).isLight()
                                    ? tinycolor(this.props.item.color).darken(15).toHexString()
                                    : tinycolor(this.props.item.color).brighten(20).toHexString(),
                                },
                                rootPressed: {
                                    backgroundColor: tinycolor(this.props.item.color).isLight()
                                    ? tinycolor(this.props.item.color).darken(25).toHexString()
                                    : tinycolor(this.props.item.color).brighten(30).toHexString(),
                                }
                            }}
                        />
                    </StackItem>
                }

                <StackItem
                    onClick={() => this.props.onItemClick(this.state.item)}
                    styles={{root: {width: "100%", cursor: "pointer"}}}
                >
                    {
                        this.state.item.onRender 
                            ? this.state.item.onRender(this.state.item, (props?: ITextProps) => {
                                return (
                                    <Text styles={{root: {padding: "0 2px", color: tinycolor(this.props.item.color ?? "#ffffff00").isDark() ? "white" : "black"}}} {...props}>
                                        {this.state.item?.description ?? ""}
                                    </Text>
                                );
                              })
                            : (
                                <Text styles={{root: {display: "flex", minHeight: "3em", alignItems: "center", padding: "0 8px", color: tinycolor(this.props.item.color ?? "#ffffff00").isDark() ? "white" : "black"}}}>
                                    {this.state.item?.description ?? ""}
                                </Text>
                            )
                    }
                </StackItem>

                {
                    this.props.item.endDate !== undefined && new Date(this.props.item.endDate) < new Date() && 
                        <StackItem align='center'>
                            <IconButton
                                title={`Closed: ${new Date(this.props.item.endDate).toLocaleDateString()}`}
                                iconProps={{iconName: 'Warning'}} 
                                styles={{
                                    root: {backgroundColor: "transparent",},
                                    rootHovered: {backgroundColor: "transparent",},
                                    rootPressed: {backgroundColor: "transparent",},
                                }}
                            />
                        </StackItem>
                }

                {this.state.item.color !== undefined && this.state.item.isFavourite ? (
                    <StackItem>
                        <IconButton
                            iconProps={{iconName: "Color"}}
                            onClick={() => {
                                const element = document.getElementById(`colorPicker-${this.props.item.id}`);
                                element?.click();
                            }}
                            styles={{
                                root: {
                                    minHeight: "3em",
                                    borderRadius: 5,
                                    margin: 0
                                },
                                rootHovered: {
                                    backgroundColor: tinycolor(this.props.item.color).isLight()
                                    ? tinycolor(this.props.item.color).darken(15).toHexString()
                                    : tinycolor(this.props.item.color).brighten(20).toHexString(),
                                },
                                rootPressed: {
                                    backgroundColor: tinycolor(this.props.item.color).isLight()
                                    ? tinycolor(this.props.item.color).darken(25).toHexString()
                                    : tinycolor(this.props.item.color).brighten(30).toHexString(),
                                }
                            }}
                        />
                        <input
                            id={`colorPicker-${this.props.item.id}`}
                            type="color"
                            list="presets"
                            style={{
                                left: "85%",
                                position: "absolute",
                                visibility: "hidden"
                            }}
                            // className="form-control form-control-color borderFix"
                            value={
                                this.state.item.color === null
                                    ? "#ffffff00"
                                    : this.state.item.color
                            }
                            onChange={(e) => this.onChangeColor(e)}
                            onBlur={event => this.onBlur(this.state.item)}
                        />
                        <datalist id="presets">
                            <option value="#FF6347">Tomato</option>
                            <option value="#FFA500">Orange</option>
                            <option value="#1E90FF">Dodger Blue</option>
                            <option value={tinycolor("rgb(128, 255, 255)").toHexString()}>Ciel</option>
                            <option value="#3CB371">Medium Sea Green</option>
                            <option value={tinycolor("rgb(64, 128, 128)").toHexString()}>Aqua</option>
                            <option value="#ffff00">Yellow</option>
                            <option value="#EE82EE">Violet</option>
                            <option value="#6A5ACD">State Blue</option>
                            <option value={tinycolor("rgb(132, 255, 132)").toHexString()}>Light Green</option>
                        </datalist>
                    </StackItem>
                ) : (
                    ""
                )}
            </Stack>
        );
    }
}

interface IItemsListProps {
    items: IItem[];
    selectedItem?: IItem | null;
    selectedItems?: IItem[];
    onSelectedItemChange: Function;
    onListShowHide?: Function;
    onFavouriteOrColorChange?: Function;
    showCompleteList?: boolean;
    searchPlaceholder: string;
	selectionMode?: SelectionMode;
}

interface IItemsListState {
    visibleItems: IItem[];
    selectedItem?: IItem | null;
    selectedItems?: IItem[];
    isListOpen: boolean;
    lastItemColorChangedIndex: number;
}

export class ItemsList extends Component<IItemsListProps, IItemsListState> {
	selectionMode: SelectionMode = this.props.selectionMode ?? SelectionMode.Single;
    itemListRef: React.RefObject<HTMLDivElement>;
    searchElement: React.RefObject<Search>;
    itemElement: Item[];

    componentWillMount() {
        this.setState({ visibleItems: [], selectedItem: this.props.selectedItem, selectedItems: this.props.selectedItems });
    }

    onSearchFocus(event: FocusEvent<HTMLInputElement>): void {
        this.setState({ isListOpen: true, lastItemColorChangedIndex: -1 });
        this.itemElement = [];
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        this.setState({ lastItemColorChangedIndex: -1});
        this.itemElement = [];
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps: IItemsListProps) {
        if (this.state.selectedItem !== nextProps.selectedItem) {
            this.setState({ selectedItem: nextProps.selectedItem});
        }
		if (this.state.selectedItems !== nextProps.selectedItems) {
            this.setState({ selectedItems: nextProps.selectedItems });
        }
    }

    componentDidUpdate(prevProps: IItemsListProps, prevState: IItemsListState) {
        if (this.props.onListShowHide && (this.state.isListOpen !== prevState.isListOpen)) {
            this.props.onListShowHide(this.state.isListOpen);
        }
    }

    addItem(element: Item, index: number) {
        if (element) {
            this.itemElement[index] = element;
        }
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.itemListRef && !this.itemListRef?.current?.contains(event.target as Node)) {
            if (this.itemElement?.length > 0
                && this.state.lastItemColorChangedIndex >= 0
                && this.itemElement[this.state.lastItemColorChangedIndex]?.state?.item
            ) {
                this.itemElement[this.state.lastItemColorChangedIndex]?.onBlur(this.itemElement[this.state.lastItemColorChangedIndex].state.item);
            }

            this.setState({ isListOpen: false, lastItemColorChangedIndex: -1 });
            this.itemElement = [];
            this.searchElement?.current?.getSelectedItemFromProperties();
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    onItemSelect(item: IItem) {
		switch (this.selectionMode) {
			case SelectionMode.Multiple: {
				let tempItems: IItem[] = this.state.selectedItems ?? [];
				if (tempItems.filter((x: IItem) => x.id === item.id).length === 0) {
					tempItems.push(item);
					this.setState({ selectedItems: tempItems});
					this.props.onSelectedItemChange(tempItems);
				} else {
					tempItems = tempItems.filter((x: IItem) => x.id !== item.id);
					this.setState({ selectedItems: tempItems});
					this.props.onSelectedItemChange(tempItems);
				}
				break;
			};
			case SelectionMode.Single: {
				this.setState({ selectedItem: item, isListOpen: false });
				this.props.onSelectedItemChange(item);
				break;
			}
			case SelectionMode.None:
				break;
		}
    }

    onItemColorChanged(index: number) {
        this.setState({ lastItemColorChangedIndex: index })
    }

    onUpdateItems(items: IItem[]) {
        this.setState({ visibleItems: items, lastItemColorChangedIndex: -1 });
        this.itemElement = [];
    }

    constructor(props: IItemsListProps) {
        super(props);
        this.itemListRef = React.createRef();
        this.searchElement = React.createRef();
        this.itemElement = [];
        this.addItem = this.addItem.bind(this);
        this.onItemColorChanged = this.onItemColorChanged.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onSearchFocus = this.onSearchFocus.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);

        this.state = {
            visibleItems: [],
            selectedItem: this.props.selectedItem,
            isListOpen: false,
            lastItemColorChangedIndex: -1,
        }
    }

    render() {
        return (
            <div style={{maxWidth: "100%", overflow: "hidden"}} ref={this.itemListRef}>
                <Search
                    ref={this.searchElement}
                    items={this.props.items}
                    filterItem={(query, item) =>
                        item.description?.toLowerCase()?.indexOf(query.toLowerCase()) >= 0
                    }
                    onUpdateItems={(items) => this.setState({ visibleItems: items })}
                    selectedItem={this.state.selectedItem}
                    onFocus={(event: FocusEvent<HTMLInputElement>) => this.onSearchFocus(event)}
                    searchPlaceholder={this.props.searchPlaceholder}
                    showCompleteList={this.props.showCompleteList}
                />
                {this.state.isListOpen && (
                    <Stack
                        verticalAlign="center"
                        tokens={{}}
                        style={{
                            padding: "2px 4px",
                            position: "relative",
                            maxWidth: "100%",
                            width: "100%",
                            overflow: "scroll"
                        }}
                    >
                        {this.state.visibleItems.map((item, index) => {
                            return (
                                <Item
                                    ref={(element: Item) => this.addItem(element, index)}
                                    index={index}
                                    onItemClick={this.onItemSelect}
                                    onItemColorChanged={this.onItemColorChanged}
                                    item={item}
                                    key={item.id}
                                    onFavouriteOrColorChange={this.props.onFavouriteOrColorChange}
									selected={(this.state.selectedItems?.filter(x => x.id === item.id).length ?? 0) > 0  ? true : false}
                                />
                            );
                        })}
                    </Stack>
                )}
            </div>
        );
    }
}

interface ISearchProps {
    items: IItem[];
    filterItem(query: string, item: IItem): boolean;
    onUpdateItems(items: IItem[]): void;
    selectedItem?: IItem | null;
    onFocus: Function;
    searchPlaceholder: string;
    showCompleteList?: boolean;
}

interface ISearchState {
    selectedItem?: IItem | null;
    text: string;
    isIconVisible: boolean;
}

export class Search extends Component<ISearchProps, ISearchState> {

    componentDidMount() {
        const { items, onUpdateItems } = this.props;
        onUpdateItems(items);
    }

    constructor(props: ISearchProps) {
        super(props);
        this.onFocus = this.onFocus.bind(this);
        this.getSelectedItemFromProperties = this.getSelectedItemFromProperties.bind(this);

        this.state = {
            selectedItem: this.props.selectedItem,
            text: this.props.selectedItem?.description ?? "",
            isIconVisible: false
        };
    }

    compare(a: IItem, b: IItem): number {

        if (a.isFavourite && !b.isFavourite) return -1;
        if (!a.isFavourite && b.isFavourite) return 1;

        if (a.isFavourite === b.isFavourite) {
            return a.description > b.description ? 1 : (b.description > a.description ? -1 : 0);
        }

        return 0;
    }

    componentWillReceiveProps(nextProps: ISearchProps) {
        if (nextProps.selectedItem !== this.props.selectedItem) {
            this.setState({
                selectedItem: nextProps.selectedItem,
                text: nextProps.selectedItem?.description ?? "",
                isIconVisible: false
            });
        }
    }

    getSelectedItemFromProperties() {
        this.setState({
            selectedItem: this.props.selectedItem,
            text: this.props.selectedItem?.description ?? "",
        });
        this.handleInputChange(this.props.selectedItem?.description ?? "");

    }

    onFocus(event: FocusEvent<HTMLInputElement>) {
        this.getSelectedItemFromProperties();
        this.setState({ isIconVisible: true });
        this.props.onFocus(event);
    }

    handleInputChange = (query: string) => {
        this.setState({ selectedItem: null, text: query ?? "" });
        const { items, filterItem, onUpdateItems } = this.props;
        const itemsToShowCount = query.trim() === "" && this.props.showCompleteList ? 999999999 : 15;
        const filteredItems = items.filter(
            (item) => ((!query || query.trim() === "") && (item.isFavourite || this.props.showCompleteList)) || (query && query.trim() !== "" && filterItem(query, item))
        ).sort(this.compare).slice(0, itemsToShowCount);

        onUpdateItems(filteredItems);
    };

    render() {
        return (
            <div>
                <SearchBox 
                    placeholder={this.props.searchPlaceholder}
                    onChange={(event) => this.handleInputChange(event?.target?.value ?? "")}
                    value={this.state.text}
                    required
                    onFocus={(event) => this.onFocus(event)}
                    clearButtonProps={{hidden: true}}
                    styles={{
                        root: {
                            position: "sticky",
                            top: 100,
                            height: "3em",
                            borderRadius: 5,
                            "::after": {
                                height: "3em",
                                borderRadius: 5,
                            },

                        },

                    }}
                />
            </div>
        );
    }
}
