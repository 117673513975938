import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    data: IGenericDdlData[]; 
    onSelect: Function;
    width?: string;
}

interface IState {
    data: IGenericDdlData[];
    selectedItem : IGenericDdlData; 
    isOpen: boolean
}

interface IGenericDdlData {
    id: string,
    text: string,
    selected: boolean
}

export default class DropDownGenericSelector extends Component<IProps, IState> {
    selectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        this.selectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
        let selectedItem = props.data.find(x=>x.selected);
        if(selectedItem)
        {
            this.state = {
                data: this.props.data,
                selectedItem: selectedItem, 
                isOpen: false
            };
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }

        /*let selectedItem = this.props.data.find(x=>x.selected);
        if (selectedItem && this.state.selectedItem !== selectedItem) {
            
            this.setState({ selectedItem : selectedItem!,selectedText: selectedItem!.text })
        }*/
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.selectorRef && !this.selectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    onSelectData(selItem: IGenericDdlData) {
        
        if (selItem !== this.state.selectedItem) {
            
            this.setState({
                selectedItem: selItem,      
                isOpen: false
            })
            this.props.onSelect(selItem.id)
        } else {
            this.setState({ isOpen: false });
        }
    }

    render() {
        return (
            <div
                className="mx-2"
                ref={this.selectorRef}
            >
                <button
                    className="btn btn-dark btn-select-day d-flex justify-content-between"
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    style={{ width: this.props.width ? this.props.width : "190px" }}
                >
                    <p className="mx-2 mb-0">{this.state.selectedItem.text}</p>
                    <p className="mx-2 mb-0">
                        {<FontAwesomeIcon icon={faChevronDown} />}
                    </p>
                </button>
                {this.state.isOpen && (
                    <div className="row mx-0" style={{ zIndex: 100, position: "absolute", width: this.props.width ? this.props.width : "190px", backgroundColor: "#f5f6f8", border: "1px solid black", borderRadius: "5px" }}>
                        {this.state.data.map(item => (
                            <div className="col-12" key={item.id}>
                                <button
                                    className="btn btn-dark-outline col-12"
                                    onClick={() => this.onSelectData(item)}>
                                    {item.text}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}
