import React, { useEffect, useState } from 'react';
import { IActivityEntry } from './../../Models/CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import tinycolor from 'tinycolor2';

interface IProps {
    data: IActivityEntry,
    onDragStartFunction: Function,
    onDragEndFunction: Function,
    onClick: Function,
    onCopy: Function,
    onActivitySelection: Function,
    isActivitySelected: boolean,
    isMonthEditable: boolean,
    isNotSelected: boolean
}

export const Activity = ({ data, onDragStartFunction, onDragEndFunction, onClick, onCopy, onActivitySelection, isActivitySelected, isMonthEditable, isNotSelected }: IProps) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    const [indexOnDrag, setIndexOnDrag] = useState(0);
    const [isSelected, setSelected] = useState(isActivitySelected);

    useEffect(() => {
        setSelected(isActivitySelected)
    }, [isActivitySelected])

    const isEditable = (isMonthEditable && !isNotSelected);

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        if (isEditable) {
            setIndexOnDrag(1);
            onDragStartFunction(event, data.id, "Activity");
        }
    }

    return (
        <div
            className={"calendar-activity " + (isEditable ? "draggable" : "")}
            draggable={isEditable}
            onDragStart={(event) => onDragStart(event)}
            onDragEnd={() => { onDragEndFunction(); setIndexOnDrag(0); }}
            onClick={() => { if (isEditable) { onClick(data.id); } }}
            onMouseEnter={e => setMouseEnter(true)}
            onMouseLeave={e => setMouseEnter(false)}
            style={{ 
                position: "relative",
                backgroundColor: data?.activity?.color ?? "#ffffff00",
                zIndex: indexOnDrag,
                cursor: isEditable ? "pointer" : "default",
                opacity: isNotSelected ? ".3" : "1",
                color: tinycolor(data?.activity?.color ?? "#ffffff00").isDark() ? "white" : "black"
            }}
        >
            <div className="activity-project-description">
                {data?.activity?.description}
            </div>
            <div className="activity-note">
                {data?.absence ? data?.absence?.description : data?.location?.name}
                <br />
                {data?.note?.length > 50 ? data?.note?.substring(0, 50) + "..." : data?.note}

            </div>
            <div className="activity-project-footer">

                <div className="footer-left">
                    {"h. " + data?.hours?.toString() + ".00"}
                </div>
                <div className="footer-right">
                    {data?.hasAttachment ? <FontAwesomeIcon icon={["far", "file"]} /> : ""}
                </div>

            </div>

            {((isEditable && mouseEnter) || isSelected) && (
                <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                    <input
                        type="checkbox"
                        checked={isActivitySelected}
                        onClick={(e) => { e.stopPropagation(); }}
                        onChange={(e) => { setSelected(e.target.checked); onActivitySelection(data?.id, e.target.checked); }}
                    />
                </div>
            )}

            {isEditable && mouseEnter && (
                <div className="d-flex">
                    <div style={{ position: "absolute", bottom: "5px", right: "5px" }}>
                        <button
                            className="btn btn-dark"
                            onClick={(e) => { e.stopPropagation(); onCopy(data?.id); }}
                        >
                            <FontAwesomeIcon
                                icon={faCopy} />
                        </button>
                    </div>

                </div>
            )}           
            
        </div>);
};
