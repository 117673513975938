import { useState } from "react";
import BaseModel from "../../../../Models/BaseModel";
import { FilterHeaderProps } from "./HeadersProps";

type SearchFilterHeaderProps<Model extends BaseModel> = {
	placeholder?: string;
} & FilterHeaderProps<Model>;

export function SearchFilterHeader<Model extends BaseModel>(props: SearchFilterHeaderProps<Model>): JSX.Element {
	const [keyword, setKeyword] = useState("");

	return (
		<input 
			className="searchInput"
			type="text"
			placeholder= {props.placeholder ?? `Search`}
			value={keyword}
			onChange={(e) => {
				setKeyword(e.target.value);
				if(props.header && props.header.callback) {
					props.header.callback(e.target.value, props.header.key!); 
				}; 
			}}
		/>
	);
};