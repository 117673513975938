import React, { ChangeEvent, Component } from 'react';
import { DaySelect } from '../Utils/DaySelect';
import ItemOffCanvas from '../Utils/ItemOffCanvas';
import { editMode, IGoodsReceipt, IUpdateGoodsReceipt } from '../../Models/CalendarDay';

interface IProps {
    requestPageOffCanvasIsVisible: boolean;
    requestPageOffCanvasTitle: string;
    onClose: Function;
    editMode: editMode
    visualGoodsReceipt: IGoodsReceipt;
    onChangeGoodsReceipt: Function;
}

interface IState {
    isVisible: boolean;
    visualGoodsReceipt: IGoodsReceipt;
    isCompleteDays: boolean;
    goodsReceiptDate: Date
}

export default class GoodsReceiptPageOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        let data: IGoodsReceipt = { ...this.props.visualGoodsReceipt };
        data.goodsReceiptDate = new Date(data.goodsReceiptDate);

        this.state = {
            isVisible: this.props.requestPageOffCanvasIsVisible,
            goodsReceiptDate: new Date(),
            visualGoodsReceipt: data,
            isCompleteDays: false
        }
    }

    getDefaultDateGoodsReceipt(goods: IGoodsReceipt): IGoodsReceipt {

        return {
            activity: goods.activity,
            wbe: goods.wbe,
            wbs: goods.wbs,
            idSupplier: goods.idSupplier,
            supplierName: goods.supplierName,
            idConsultant: goods.idConsultant,
            consultantName: goods.consultantName,
            goodsReceiptDate: goods.goodsReceiptDate,
            oda: goods.oda,
            odaRow: goods.odaRow,
            quantity: goods.quantity,
            unitPrice: goods.unitPrice,
            value: goods.value,
            description: goods.description,
            daysDelivered: goods.daysDelivered
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.requestPageOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.requestPageOffCanvasIsVisible
            });
        }

        if (nextProps.visualGoodsReceipt !== this.state.visualGoodsReceipt) {
            let data: IGoodsReceipt = { ...nextProps.visualGoodsReceipt };
            data.billingPlanDate = new Date(data.billingPlanDate);

            this.setState({
                visualGoodsReceipt: data
            })
        }
    }

    onClose() {
        this.setState({
            isVisible: false,
            isCompleteDays: false
        });
        this.props.onClose();

    }

    onCheckCompleteDays(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            this.onChangeData<Date>("multipleStartHour", new Date(new Date().setHours(9, 0, 0, 0)))
            this.onChangeData<Date>("multipleEndHour", new Date(new Date().setHours(18, 0, 0, 0)))
            this.setState({ isCompleteDays: true });
        } else {
            this.setState({ isCompleteDays: false });
        }
    }

    onChangeData<T>(key: string, value: T | number) {

        let saleEntry = {
            ...this.state.visualGoodsReceipt,
        };

        saleEntry[key] = value;

        if (key === "quantity") {
            saleEntry.value = saleEntry.unitPrice * saleEntry.quantity;
        }

        this.setState({
            visualGoodsReceipt: saleEntry,
        })
    }

    onSave() {

        let elem: IUpdateGoodsReceipt = {
            idSupplier: this.state.visualGoodsReceipt.idSupplier,
            goodsReceiptDate: this.state.goodsReceiptDate,
            quantity: this.state.visualGoodsReceipt.quantity,
            description: this.state.visualGoodsReceipt.description
        }

        this.props.onChangeGoodsReceipt(elem);
        this.props.onClose();
    }

    render() {
        return (
            <div>
                <ItemOffCanvas
                    offCanvasTitle={this.props.requestPageOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    onDelete={() => null}
                    editMode={this.props.editMode}
                    saveDisabled={false}
                >
                    <section className="item-off-canvas-component">

                        <div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 mb-5">
                                    <p className="requestSelectorTitle">
                                        Project
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt?.activity?.description}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle">
                                        Provider
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.supplierName}
                                    </div>
                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2 w-100">
                                        Consultant
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.consultantName}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2">
                                        Order id
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.oda}
                                    </div>
                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle">
                                        Order number
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.odaRow}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">

                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle">
                                        Unit price
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.unitPrice}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle">
                                        Quantity
                                    </p>
                                    <input type="number"
                                        onChange={(e) => this.onChangeData("quantity", e.target.value)}
                                        value={this.state.visualGoodsReceipt.quantity}
                                    />

                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle">
                                        Value
                                    </p>
                                    <div>
                                        {this.state.visualGoodsReceipt.value}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-6 mb-5">
                                    <p className="requestSelectorTitle">
                                        Description
                                    </p>
                                    <textarea
                                        className="custom-color-border"
                                        style={{ width: "100%", resize: "none" }}
                                        rows={5}
                                        onChange={(e) => this.onChangeData<string>("description", e.target.value)}
                                        value={this.state.visualGoodsReceipt.description}
                                        placeholder="Motivation for the goods receipt"
                                        required
                                    />
                                </div>
                                <div className="col-6 mb-5">
                                    <p className="requestSelectorTitle">
                                        Date
                                    </p>
                                    <DaySelect
                                        date={new Date(this.state.goodsReceiptDate)}
                                        onDateChange={(e: Date) => this.setState({ goodsReceiptDate: e })}
                                    />
                                </div>
                            </div>

                        </div>
                    </section>
                </ItemOffCanvas >
            </div >
        )
    }
}