import React, { Component } from "react";
import ItemOffCanvas from "../Utils/ItemOffCanvas";
import { ItemsList } from "../Utils/ItemsList";
import { IActivity, IActivityEntry, editMode, IExpenseEntry, ILocation } from "./../../Models/CalendarDay";

interface IProps {
    activityOffCanvasTitle: string;
    activityEntries: IActivityEntry[] | IExpenseEntry[];
    onClose: Function;
    onSave: Function;
    onDelete: Function;
    activityList: IActivity[];
    defaultLocation: ILocation;
}

interface IState {
    activityOffCanvasTitle: string;
    activityEntries: IActivityEntry[] | IExpenseEntry[];
    selectedItem: IActivity | null;
    error: string | null;
}

export class ChangeActivityOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getFilteredListActivity = this.getFilteredListActivity.bind(this);

        this.state = {
            activityOffCanvasTitle: this.props.activityOffCanvasTitle,
            activityEntries: this.props.activityEntries,
            selectedItem: this.checkSingleActivitySelected(this.props.activityEntries),
            error: null,
        }
    }

    checkSingleActivitySelected(activityEntries: IActivityEntry[] | IExpenseEntry[]): IActivity | null {
        let activity: IActivity | null;
        if (activityEntries.length === 0) {
            activity = null;
        } else {
            activity = activityEntries[0].activity;
            for (let act of activityEntries) {
                if (activity && act.activity.id !== activity.id) {
                    activity = null;
                    break;
                }
            }
        }
        return activity;
    }

    onChangeActivity(activity: IActivity) {
        let activityEntries: IActivityEntry[] | IExpenseEntry[] = [...this.state.activityEntries] as IActivityEntry[] | IExpenseEntry[];

        for (let arrayIndex: number = 0; arrayIndex < activityEntries.length; arrayIndex++) {
            let activityEntry = {
                ...this.state.activityEntries[arrayIndex],
            };

            if (activityEntry.activity.isAbsent && !activity.isAbsent) {
                activityEntry.location = this.props.defaultLocation;
            }

            activityEntry.activity = activity;

            if (activity.isAbsent) {
                activityEntry.location = null;
            } else {
                activityEntry.absence = null;
            }

            activityEntries[arrayIndex] = activityEntry;
        }

        this.setState({
            activityEntries: activityEntries,
            selectedItem: this.checkSingleActivitySelected(activityEntries)
        })
    }

    async onSave() {
        await this.props.onSave(this.state.activityEntries);
        this.props.onClose();
    }

    async onDelete() {
        await this.props.onDelete(this.state.activityEntries);
        this.props.onClose();
    }

    //remove all activities that are absences
    getFilteredListActivity() {
        let activityList = [...this.props.activityList]

        activityList = activityList.filter(x => x.isAbsent === false)

        return activityList;
    }

    render() {
        return (<div>
            <ItemOffCanvas
                offCanvasTitle={this.state.activityOffCanvasTitle}
                onClose={this.props.onClose}
                isVisible={true}
                onSave={this.onSave}
                onDelete={this.onDelete}
                editMode={editMode.Edit}
                saveDisabled={false}
            >
                <div>
                    <ItemsList
                        items={this.getFilteredListActivity()}
                        selectedItem={this.state.selectedItem}
                        onSelectedItemChange={(activity: IActivity) => this.onChangeActivity(activity)}
                        onListShowHide={() => void 0}
                        searchPlaceholder="Project name, activity, holiday"
                    />
                </div>
            </ItemOffCanvas >
        </div >
        )
    }
}