import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { isNullOrWhiteSpace } from "../../AppSettings";

interface IButtonToInputNoteProps {
    note: string;
    onChange: Function;
}

interface IButtonToInputNoteState {
    note: string;
    isButtonVisible: boolean;
}

export class ButtonToInputNote extends Component<IButtonToInputNoteProps, IButtonToInputNoteState> {
    constructor(props: IButtonToInputNoteProps) {
        super(props);

        this.state = {
            note: this.props.note,
            isButtonVisible: isNullOrWhiteSpace(this.props.note)
        };
    }

    componentWillUpdate(nextProps: IButtonToInputNoteProps) {
        if (nextProps.note !== this.state.note) {
            this.setState({
                note: nextProps.note,
                isButtonVisible: isNullOrWhiteSpace(nextProps.note)})
        }
    }

    onClick() {        
        this.setState({ isButtonVisible: !this.state.isButtonVisible });        
    }

    onChange(note: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            note: note.target.value           
        });
        this.props.onChange(note.target.value);
    }

    onBlur() {
        if (isNullOrWhiteSpace(this.state.note)) {
            this.setState({ isButtonVisible: true })
        }
    }

    render() {

        return (
            <>
                {this.state.isButtonVisible ? (
                    <div className="w-100">
                        <button className="text-gray btn d-block-button" onClick={() => this.onClick()}>
                            <FontAwesomeIcon className="text-gray me-2" icon={faPlus} />
                            Add Description
                        </button>
                    </div>
                ) : (
                    <div>
                        <input                            
                                className="ms-3 w-75 custom-color-border"
                                onChange={(event) => this.onChange(event)}
                                onBlur={() => this.onBlur()}
                                value={this.state.note}
                                type="text"
                                maxLength={300}
                                autoFocus
                        />
                    </div>
                )}
            </>
        );
    }
}
