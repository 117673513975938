import React from "react";
import BaseModel from "../../../Models/BaseModel";


/**
 *	Helper utils for childrens manipulation.
 *
 * @export
 * @class ChildrenHelper
 */
export default class ChildrenHelper {
	
	/**
	 *	Pass props to React Element.
	 *
	 * @static
	 * @param {React.ReactElement} element
	 * @param {*} props
	 * @return {*}  {React.ReactElement<any, string | React.JSXElementConstructor<any>>}
	 * @memberof ChildrenHelper
	 */
	static addPropsToElement(element: React.ReactElement, props: any): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
		if (React.isValidElement(element)) {
			return React.cloneElement(element, props);
		}
		return element;
	}


	/**
	 *	Pass props to React children.
	 *
	 * @static
	 * @param {React.ReactElement} children
	 * @param {*} props
	 * @return {*}  {(React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[])}
	 * @memberof ChildrenHelper
	 */
	static addPropsToChildren(children: React.ReactElement, props: any): React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
	{
		if (!Array.isArray(children)) {
			return this.addPropsToElement(children, props);
		}
		return children.map(childElement => this.addPropsToElement(childElement, props));
	}


	/**
	 *	Return distinct values for given key of an object.
	 *
	 * @static
	 * @template T	Model type.
	 * @template K	keyof given model type.
	 * @param {T[]} data Models list.
	 * @param {K} key Key of property {"key" as keyof ModelType}
	 * @return {*}  {T[K][]} Distinct values array.
	 * @memberof ChildrenHelper
	 */
	static getDistinct<T extends BaseModel, K extends keyof T>(data: T[], key: K): T[K][] {
		const allValues = data.reduce((values: T[K][], current) => {
			if (current[key]) {
				values.push(current[key]);
			}
			return values;
		}, []);

		return [...new Set(allValues)];
	}
};