import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

// In this component the part of DAY in all the variables DATE is usefull

interface IProps {
    date: Date;
    onHourChange: Function;
    startHour?: Date;
    endHour?: Date;
    interval?: number;
}

interface IState {
    date: Date;
    isOpen: boolean;
}

export class TimeSelect extends Component<IProps, IState> {
    timeSelectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.timeSelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            date: this.props.date,
            isOpen: false
        };
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.timeSelectorRef && !this.timeSelectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }

        if (this.props.date !== this.state.date) {
            this.setState({
                date: this.props.date
            });
        }
    }

    toggleIsOpen() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleChange = (date: Date) => {
        this.setState({ date: date });
        this.props.onHourChange(date);
        this.toggleIsOpen();
    };

    handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        this.toggleIsOpen();
    };

    render() {
        let interval: number = this.props.interval ?? 30;
        let startHour: Date = this.props.startHour ?? (new Date(new Date().setHours(9, 0, 0, 0)));
        let endHour: Date = this.props.endHour ?? (new Date(new Date().setHours(18, 0, 0, 0)));
        let dateFormat = "HH:mm";

        return (
            <div ref={this.timeSelectorRef}>
                <button
                    className="btn btn-primary-custom btn-select-day d-flex"
                    onClick={(e) => this.handleClick(e)}
                >
                    <p className="mx-2 mb-0">{format(new Date(this.state.date), dateFormat)}</p>
                </button>

                {this.state.isOpen && (
                    <DatePicker
                        selected={this.state.date}
                        onChange={(date: Date) => this.handleChange(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={interval}
                        timeCaption="Time"
                        dateFormat={dateFormat}
                        timeFormat={dateFormat}
                        minTime={startHour}
                        maxTime={endHour}
                        excludeTimes={[new Date(new Date().setHours(13, 30, 0, 0))]}
                        inline
                    />
                )}
            </div>
        );
    }
}
