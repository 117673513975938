import React, { PropsWithChildren, ReactElement } from "react";
import { getJSONHeaders, webApiFeature } from "../../../../AppSettings";

type FeatureFlagsType = {
    intercompany: Boolean;
};

export const FeatureFlagsContext = React.createContext<FeatureFlagsType>(null!);

export function FeatureFlagsProvider(props: PropsWithChildren): ReactElement<any, any> {
    const [features, setFeatures] = React.useState<FeatureFlagsType>({
        intercompany: false,
    });

	async function fetchFeatures() {
		
		fetch(`${webApiFeature}/intercompany`, {
			method: "GET",
			headers: await getJSONHeaders()
		})
			.then(x => x.json())
			.then(result => {
				setFeatures({ intercompany: result as boolean });
			})
			.catch(e => console.log(e))
	}

    React.useEffect(() => {
		fetchFeatures();
    }, []);
 
    return (
      <FeatureFlagsContext.Provider value={ features }>
            {/* {isLoading ? "Loading..." : props.children} */}
            {props.children}
      </FeatureFlagsContext.Provider>
    );
};