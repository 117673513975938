import React, { Component } from "react";
import ItemOffCanvas from "../Utils/ItemOffCanvas";
import { DaySelect } from "../Utils/DaySelect";
import { HoursSelect } from "../Utils/HoursSelect";
import { ItemsList } from "../Utils/ItemsList";
import { ButtonToInputNote } from '../Utils/ButtonToInputNote';
import { LocationSelect } from "../Utils/LocationSelect";
import { IActivity, IActivityEntry, IHour, ILocation, editMode, IAbsence, IItem, RequestHandler, ErrorHandling, breastfeeding, studyPermit, paternity, paidLeave, vacation } from "./../../Models/CalendarDay";
import { webApiLocations, webApiAbsense, Account, isNullOrWhiteSpace, isHolidayToast, isHoliday, webApiActivities, getJSONHeaders } from './../../AppSettings';
import { ShowDayWeekAndDate } from '../Utils/ShowDayWeekAndDate';
import { format } from "date-fns";

interface IProps {
    activityOffCanvasTitle: string;
    activityEntries: IActivityEntry[];
    onClose: Function;
    onSave: Function;
    onDelete: Function;
    activityOffCanvasIsVisible: boolean;
    onFavouriteOrColorChange?: Function;
    editMode: editMode;
    startDate: Date;
    endDate: Date;
    absenceList: IAbsence[];
    locationList: ILocation[];
    activityList: IItem[];
    isSchedulerPage: boolean;
    nHoursStudyPermit?: number;
    nHoursPaternityPermit?: number;
    nHoursMaternityPermit?: number;
    activityListComplete?: IActivityEntry[];
}

interface IState {
    activityOffCanvasTitle: string;
    isVisible: boolean;
    isContentVisible: boolean;
    activityList: IItem[];
    locationList: ILocation[];
    absenceList: IAbsence[];
    filteredAbsenceList: IItem[];
    hoursList: IHour[];
    isEmpty: boolean;
    hasBreastFeeding: boolean;
	hasVacation: boolean;
	activityEntries: IActivityEntry[];
}

export class ActivityOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onChangeData = this.onChangeData.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onListShowHide = this.onListShowHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.state = {
            activityOffCanvasTitle: this.props.activityOffCanvasTitle,
            isVisible: this.props.activityOffCanvasIsVisible,
            isContentVisible: true,
            isEmpty: this.checkEmptyFields(this.props.activityEntries),
            activityList: this.props.activityList,
            locationList: this.props.locationList,
            absenceList: this.props.absenceList,
            filteredAbsenceList: this.props.absenceList.filter(x => x?.activityId === this.props.activityEntries[0].activity.id),
            hoursList: [{ id: "1", hour: 2 }, { id: "2", hour: 4 }, { id: "3", hour: 6 }, { id: "4", hour: 8 }],
            hasBreastFeeding: false,
			hasVacation: false,
			activityEntries: []
        }
	}
	
	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		
        if (this.props.activityOffCanvasIsVisible !== prevState.isVisible) {
            this.setState({
                isVisible: this.props.activityOffCanvasIsVisible
            });
        }

        if (this.props.activityEntries !== prevProps.activityEntries) {
            if (this.props.activityOffCanvasIsVisible === true) {
                this.getCurrentDayActivity(this.props.activityEntries);
                this.props.activityEntries.forEach(x => isHolidayToast(new Date(x.day)))
            }
            this.setState({
				activityEntries: this.props.activityEntries,
				isEmpty: this.checkEmptyFields(this.props.activityEntries)
            });
        }

        if (this.props.activityOffCanvasTitle !== prevState.activityOffCanvasTitle) {
            this.setState({
                activityOffCanvasTitle: this.props.activityOffCanvasTitle
            })
        }
	}


    onListShowHide(isListOpen: boolean) {
        this.setState({ isContentVisible: !isListOpen });
	}

    async componentDidMount() {
        if (!this.props.isSchedulerPage) {
            RequestHandler.requestSend();

            fetch(webApiLocations, {
                method: "GET",
                headers: await getJSONHeaders(),
            })
                .then(ErrorHandling.handleError)
                .then(resLoc => resLoc.json())
                .then(
                    async (resultLoc) => {
                        fetch(webApiAbsense, {
                            method: "GET",
                            headers: await getJSONHeaders(),
                        })
                            .then(ErrorHandling.handleError)
                            .then(resAbs => resAbs.json())
                            .then(
                                async (resAbs) => {
                                    fetch(`${webApiActivities}/assigned?fromDate=` + format(this.props.startDate, 'yyyy-MM-dd') + '&toDate=' + format(this.props.endDate, 'yyyy-MM-dd'), {
                                        method: "GET",
                                        headers: await getJSONHeaders(),
                                    })
                                        .then(ErrorHandling.handleError)
                                        .then(resAct => resAct.json())
                                        .then(
                                            (resultAct) => {
                                                this.setState({
                                                    absenceList: resAbs,
                                                    locationList: resultLoc,
                                                    activityList: resultAct,
                                                });
                                            })
                                        .catch(error => errorOccurred(error, this));
                                })
                            .catch(error => errorOccurred(error, this));
                    })
                .catch(error => errorOccurred(error, this));
        }
        function errorOccurred(error: string, page: ActivityOffCanvas) {
            page.setState({
                absenceList: [],
                locationList: [],
                activityList: [],
            });
            RequestHandler.badRequestReceived(error, "Something went wrong!")
        }
    }

    removeActivityEntry(index: number) {
        let activityEntries = [...this.state.activityEntries.splice(index, 1)];

        this.setState({
            activityEntries: activityEntries,
			activityOffCanvasTitle: "Add Project" + (this.state.activityEntries.length > 1 ? "s" : ""),
			isEmpty: this.checkEmptyFields(activityEntries),
        });
    }

    onChangeActivity(activity: IActivity) {
        let activityEntries = [...this.state.activityEntries];

        for (let arrayIndex: number = 0; arrayIndex < activityEntries.length; arrayIndex++) {
            let activityEntry = {...this.state.activityEntries[arrayIndex]};

            activityEntry.activity = activity;

            if (activity.isAbsent) {
                activityEntry.location = null;
                activityEntry.absence = this.state.absenceList.find(x => x?.activityId === activity.id) ?? null;
            } else {
                activityEntry.absence = null;
            }
            activityEntry.hours = 0;
            activityEntries[arrayIndex] = activityEntry;
        }

        this.setState({
			filteredAbsenceList: this.state.absenceList.filter(x => x?.activityId === activity.id),
			activityEntries: activityEntries,
			isEmpty: this.checkEmptyFields(activityEntries)
        })
    }

    onChangeAbsence(absence: IAbsence) {
        let activityEntries = [...this.state.activityEntries];

        for (let arrayIndex: number = 0; arrayIndex < activityEntries.length; arrayIndex++) {
            let activityEntry = {
                ...this.state.activityEntries[arrayIndex],
            };
            activityEntry.absence = absence;
            activityEntry.hours = 0;
            activityEntries[arrayIndex] = activityEntry;
        }

        this.setState({
            activityEntries: activityEntries,
            isEmpty: this.checkEmptyFields(activityEntries)
        })
    }

    checkEmptyFields(activityEntries: IActivityEntry[]) {
        let isEmpty = false;
        activityEntries.forEach(act => {
            if (act.activity.id.length !== 36) {
                isEmpty = true;
            }

            if (!act.activity.isAbsent && act.location?.id.length !== 36) {
                isEmpty = true;
            }

            if (act.activity.isAbsent && act.absence?.id.length !== 36) {
                isEmpty = true;
            }

            if (!act.activity.isAbsent && isNullOrWhiteSpace(act.note)) {
                isEmpty = true;
            }

            if (act.hours <= 0) {
                isEmpty = true;
            }

            if (isEmpty) {
                return isEmpty;
            }
        })
        return isEmpty;
    }

    onChangeData<T>(arrayIndex: number, key: string, value: T | number) {
        let activityEntries = [...this.state.activityEntries];

        let activityEntry = {
            ...this.state.activityEntries[arrayIndex],
        };

        if (key === "day" && value instanceof Date) {
            isHolidayToast(value)
            activityEntry[key] = value;
            this.getCurrentDayActivity([activityEntry])
            activityEntry.absence = null
        }

        if (activityEntry.activity.isAbsent && key === "hours" && typeof value === "number" && value > 8) {
            value = 8;
        }

		if (activityEntry?.activity.isAbsent && activityEntry.absence !== null
			&& Account.isFullTimeAllocation && activityEntry.absence.type === breastfeeding
			&& key === "hours" && typeof value === "number" && value > 2)
		{
			ErrorHandling.warningToast("Puoi inserire massimo 2 ore di allattamento giornaliere"); value = 0;
		}

        let tot = 0;

        if (activityEntry.absence !== null) {
            if (activityEntry.absence.type === studyPermit && this.props.nHoursStudyPermit) {
                if (this.props.editMode === editMode.Add) {
                    tot = this.props.nHoursStudyPermit + (activityEntries.map(x => { return x.hours }).reduce((sumH, current) => sumH + current, 0)) - activityEntries[arrayIndex].hours + (value as number)
                } else if (this.props.editMode === editMode.Edit) {
                    tot = this.props.nHoursStudyPermit - this.props.activityEntries[arrayIndex].hours + (value as number)
                }

                if (this.props.nHoursStudyPermit) {
                    if (activityEntry.activity.isAbsent && key === "hours" && typeof value === "number") {
                        if (tot > 150) {
                            ErrorHandling.warningToast("Puoi inserire ancora " + (150 - tot + value) + " ore");
                            value = 0;
                        }
                    }
                }
            }

            if (activityEntry.absence.type === paternity && this.props.nHoursPaternityPermit) {
                if (this.props.editMode === editMode.Add) {
                    tot = this.props.nHoursPaternityPermit + (activityEntries.map(x => { return x.hours }).reduce((sumH, current) => sumH + current, 0)) - activityEntries[arrayIndex].hours + (value as number)
                } else if (this.props.editMode === editMode.Edit) {
                    tot = this.props.nHoursPaternityPermit - this.props.activityEntries[arrayIndex].hours + (value as number)
                }

                if (this.props.nHoursPaternityPermit) {
                    if (activityEntry.activity.isAbsent && key === "hours" && typeof value === "number") {
                        if (tot > 80) {
                            ErrorHandling.warningToast("Puoi inserire ancora " + (80 - tot + value) + " ore");
                            value = 0;
                        }
                    }
                }
            }

            if (Account.isFullTimeAllocation) {
                if (this.props.activityListComplete) {
                    let dailyActivityList = this.props.activityListComplete.filter(x => new Date(new Date(x.day).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(activityEntry.day).setHours(0, 0, 0, 0)).getTime())
                    let x = dailyActivityList.filter(x => x.absence?.type === breastfeeding)
                    if (x.length > 0) {
                        let insertedHours = x.map(x => { return x.hours }).reduce((totH, current) => totH + current, 0)
                        if (insertedHours < 2) {
                            if (this.props.editMode === editMode.Add) {
                                tot = insertedHours + (value as number)
                            } else if (this.props.editMode === editMode.Edit) {
                                tot = insertedHours - this.props.activityEntries[arrayIndex].hours + (value as number)
                            }
                            if (tot > 2) {
                                value = 0;
                                ErrorHandling.warningToast("Puoi inserire ancora " + (tot - 2) + " ore");
                            }
                        }
                    }
                }
            }
        }

		activityEntry[key] = value;

        activityEntries[arrayIndex] = activityEntry;

        this.setState({
			activityEntries: activityEntries,
            isEmpty: this.checkEmptyFields(activityEntries)
        })
    }

    onClose() {
		this.setState({
			isVisible: false,
			activityEntries: [],
		});
        this.props.onClose();
    }

    async onSave() {
        await this.props.onSave(this.state.activityEntries);
        this.onClose()
    }

    async onDelete() {
        await this.props.onDelete(this.state.activityEntries);
        this.onClose()
    }

    //checks if the current day has activity with description "breastfeeding" and sets state
    getCurrentDayActivity(activityEntries: IActivityEntry[]) {
        if (this.props.activityListComplete) {

            let dateArray: Date[] = activityEntries.map(x => x.day).sort((first, second) => first.getTime() - second.getTime());

            let dailyActivityList = this.props.activityListComplete.filter(x => {
                return dateArray.find(y => {
                    return new Date(new Date(y).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(x.day).setHours(0, 0, 0, 0)).getTime()
                })
            })

            //breastfeeding case
            let breastfeedingList = dailyActivityList.filter(x => x.absence?.type === breastfeeding)
            if (breastfeedingList.length === 0) {
                this.setState({ hasBreastFeeding: false })
            } else {
                if (breastfeedingList.map(x => { return x.hours }).reduce((totH, current) => totH + current, 0) < 2) {
                    this.setState({ hasBreastFeeding: false })
                } else {
                    this.setState({ hasBreastFeeding: true })
                }
            }

            //ferie/permesso case
            let vacationList = dailyActivityList.filter(x => x.activity.code === vacation);
            if (vacationList.length > 0) {
                this.setState({ hasVacation: true })
            } else {
                this.setState({ hasVacation: false })
            }
        }
    }

    getFilteredListAbsence() {
        let absenceList = [...this.state.filteredAbsenceList]
        if (this.state.hasBreastFeeding) {
            absenceList = absenceList.filter(x => x.type !== breastfeeding)
        }
        if (!((new Date().getTime() > new Date(this.props.startDate).getTime()) && (new Date().getTime() < new Date(this.props.endDate).getTime()))) {
            absenceList = absenceList.filter(x => x.type !== studyPermit)
        }
        return absenceList;
    }

    getFilteredListActivity() {
        let activityList = [...this.state.activityList]
        let isActivityEntryHoliday: boolean = false;

        if (this.state.hasVacation) {
            activityList = activityList.filter(x => x.code !== vacation)
        }

        this.state.activityEntries.forEach(x => {
            if (isHoliday(new Date(x.day))) {
                isActivityEntryHoliday = true;
            }
        })

        if (isActivityEntryHoliday) {
            activityList = activityList.filter(x => x.code != vacation).filter(y => y.code != paidLeave)
        }

        return activityList;
    }

    render() {
        return (
            <div>
                <ItemOffCanvas
                    offCanvasTitle={this.state.activityOffCanvasTitle}
                    onClose={this.props.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    onDelete={this.onDelete}
                    editMode={this.props.editMode}
                    saveDisabled={this.state.isEmpty}
                >
                    <div>
                        <ItemsList
                            key="Activity"
                            items={this.getFilteredListActivity()}
                            selectedItem={this.state.activityEntries[0]?.activity}
							onSelectedItemChange={(activity: IActivity) => this.onChangeActivity(activity)}
                            onListShowHide={this.onListShowHide}
                            onFavouriteOrColorChange={this.props.onFavouriteOrColorChange}
                            searchPlaceholder="Project name, activity, holiday"
                        />
                    </div>
					{
						this.state.isContentVisible && this.state.activityEntries[0]?.activity.isAbsent &&
                        <div className="mt-3">
                            <ItemsList
                                key="Absence"
                                items={this.getFilteredListAbsence()}
                                selectedItem={this.state.activityEntries[0]?.absence}
									onSelectedItemChange={(absence: IAbsence) => this.onChangeAbsence(absence)}
                                searchPlaceholder="Absence name"
                                showCompleteList={true}
                            />
                        </div>
					}
					{
						this.state.isContentVisible && this.state.activityEntries && this.state.activityEntries.length === 1 && (
							<div className="col-12">
								<section className="item-off-canvas-component">
									<DaySelect
										startDate={this.props.startDate}
										endDate={this.props.endDate}
										date={new Date(this.state.activityEntries[0]?.day)}
										onDateChange={(day: Date) => this.onChangeData<Date>(0, "day", day)}
									/>
								</section>
								
								{
									!this.state.activityEntries[0]?.activity.isAbsent &&
									<section className="item-off-canvas-component">
										<label>Where</label>
										<LocationSelect
											listLocation={this.state.locationList}
											selectedLocation={this.state.activityEntries[0].location}
											onChange={(location: ILocation) => this.onChangeData<ILocation>(0, "location", location)}
										/>
									</section>
                            		}

								<section className="item-off-canvas-component">
									<label className="pb-1">Description</label>
									<textarea
										className="custom-color-border"
										style={{ width: "100%" }}
										rows={5}
										onChange={(e) => this.onChangeData<string>(0, "note", e.target.value)}
										value={this.state.activityEntries[0].note}
										placeholder="Describe the activities you carried out e.g (wireframe)"
										required
									/>
								</section>

								<section className="item-off-canvas-component">
									<label>Hours</label>
									<div className="row">
										<HoursSelect
											hoursList={this.state.hoursList}
											selectedHour={this.state.activityEntries[0].hours}
											onChange={(hours: number) => this.onChangeData<number>(0, "hours", hours)}
											isButtonsVisible={true}
										/>
									</div>
								</section>
                        </div>
                    )}
                    {this.state.isContentVisible && this.state.activityEntries && this.state.activityEntries.length > 1 && this.state.activityEntries.map((act, index) => (
                        <div
                            className="row mt-3"
                            key={index}
                            style={{ position: "relative" }}
                        >
                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    className="btn-close text-reset d-flex align-itmes-center h-25"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => this.removeActivityEntry(index)}
                                    style={{ position: "absolute", top: "5px", right: "5px" }}
                                >
                                </button>

                                <div className="col-2">
                                    <ShowDayWeekAndDate day={new Date(act.day)} />
                                </div>

                                <HoursSelect
                                    hoursList={this.state.hoursList}
                                    selectedHour={act.hours}
                                    onChange={(hours: number) => this.onChangeData<number>(index, "hours", hours)}
                                    isButtonsVisible={false}
                                />

                                <div className="col-8">
                                    <ButtonToInputNote
                                        note={act.note}
                                        onChange={(note: string) => this.onChangeData<string>(index, "note", note)}
                                    />
                                </div>
                            </div>

                            {!this.state.activityEntries[0]?.activity.isAbsent && (
                                <div className="mt-2">
                                    <LocationSelect
                                        listLocation={this.state.locationList}
                                        selectedLocation={act.location}
                                        onChange={(location: ILocation) => this.onChangeData<ILocation>(index, "location", location)}
                                    />
                                </div>
                            )}
                        </div>))}
                </ItemOffCanvas>
            </div >
        )
    }
}