import React, { ChangeEvent, Component } from 'react';
import ItemOffCanvas from '../Utils/ItemOffCanvas';
import { editMode, ISales} from '../../Models/CalendarDay';
import { format } from 'date-fns';

interface IProps {
    requestPageOffCanvasIsVisible: boolean;
    requestPageOffCanvasTitle: string;
    onClose: Function;
    editMode: editMode
    visualSale: ISales;
    onChangeQuantity: Function;
}

interface IState {
    isVisible: boolean;
    visualSale: ISales;
    isCompleteDays: boolean;
}

export default class SalesPageOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);

        let data: ISales = { ...this.props.visualSale };
        data.billingPlanDate = new Date(data.billingPlanDate);

        this.state = {
            isVisible: this.props.requestPageOffCanvasIsVisible,
            visualSale: data,
            isCompleteDays: false
        }
    }

    getDefaultDateSales(req: ISales): ISales {
        return {
            invoiceId: req.invoiceId,
            activity: req.activity,
            isToInvoice: req.isToInvoice,
            materialDescription: req.materialDescription,
            materialCode: req.materialCode,
            invoiceRowNumber: req.invoiceRowNumber,
            invoiceRowDate: req.invoiceRowDate,
            quantity: req.quantity,
            unitPrice: req.unitPrice,
            value: req.value,
            invoiceStatus: req.invoiceStatus,
            noteFromCustomer: req.noteFromCustomer,
            noteToCustomer: req.noteToCustomer
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.requestPageOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.requestPageOffCanvasIsVisible
            });
        }

        if (nextProps.visualSale !== this.state.visualSale) {
            let data: ISales = { ...nextProps.visualSale };
            data.invoiceRowDate = data.invoiceRowDate ? new Date(data.invoiceRowDate) : undefined;


            this.setState({
                visualSale: data
            })
        }
    }

    onClose() {
        this.setState({
            isVisible: false,
            isCompleteDays: false
        });
        this.props.onClose();

    }

    onCheckCompleteDays(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            this.onChangeData<Date>("multipleStartHour", new Date(new Date().setHours(9, 0, 0, 0)))
            this.onChangeData<Date>("multipleEndHour", new Date(new Date().setHours(18, 0, 0, 0)))
            this.setState({ isCompleteDays: true });
        } else {
            this.setState({ isCompleteDays: false });
        }
    }

    onChangeData<T>(key: string, value: T | number) {

        let saleEntry = {
            ...this.state.visualSale,
        };

        saleEntry[key] = value;

        if (key === "quantity") {
            saleEntry.value = saleEntry.unitPrice * saleEntry.quantity;
        }

        this.setState({
            visualSale: saleEntry,
        })
    }

    onSave() {
        this.props.onChangeQuantity(this.state.visualSale);
        this.props.onClose();
    }

    render() {
        return (
            <div>
                <ItemOffCanvas
                    offCanvasTitle={this.props.requestPageOffCanvasTitle}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    onDelete={() => null}
                    editMode={editMode.None}
                    saveDisabled={this.props.editMode === editMode.None ? true : false}
                >
                    <section className="item-off-canvas-component">

                        <div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 mb-5">
                                    <p className="requestSelectorTitle">
                                        Activity
                                    </p>
                                    <div>
                                        {this.state.visualSale?.activity?.description}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2">
                                        Invoice ID
                                    </p>
                                    <div>
                                        {this.state.visualSale.invoiceId}
                                    </div>
                                </div>
                                <div className="col-2 mb-5">

                                </div><div className="col-2 mb-5">

                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2 w-100">
                                        Invoice Row Number
                                    </p>
                                    <div>
                                        {this.state.visualSale.invoiceRowNumber}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2">
                                        Invoice date
                                    </p>

                                    <div>
                                        {this.state.visualSale.invoiceRowDate && format(new Date((this.state.visualSale.invoiceRowDate)), "dd/MM/yyyy")}
                                    </div>
                                </div>
                                <div className="col-2 mb-5">

                                </div><div className="col-2 mb-5">

                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2">
                                        Status
                                    </p>
                                    <div>
                                        {this.state.visualSale.invoiceStatus}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-4 mb-5">
                                </div>
                                <div className="col-2 mb-5">

                                </div><div className="col-2 mb-5">

                                </div>
                                <div className="col-4 mb-5">
                                    <p className="requestSelectorTitle mb-2">
                                        Unit price
                                    </p>
                                    <div>
                                        {this.state.visualSale.unitPrice}
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between align-items-center">

                                <div className="col-6 mb-5">
                                    <p className="requestSelectorTitle mb-3">
                                        Quantity
                                    </p>
                                    {this.props.editMode === editMode.None ? (
                                        <p>{this.state.visualSale.quantity}</p>
                                    ) :
                                        <input type="number"
                                            onChange={(e) => this.onChangeData("quantity", e.target.value)}
                                            value={this.state.visualSale.quantity}
                                            className="mt-2"
                                        />}

                                </div>
                                <div className="col-2 mb-5">

                                </div>
                                <div className="col-4 mb-5 mb-2">
                                    <p className="requestSelectorTitle">
                                        Value
                                    </p>
                                    <div>
                                        {this.state.visualSale.value}
                                    </div>
                                </div>
                                {this.props.editMode === editMode.None ? (
                                    <p>{this.state.visualSale.noteToCustomer}</p>
                                ) :
                                    <div className="col-4 mb-5 mb-2">
                                        <p className="requestSelectorTitle">
                                            Note
                                        </p>
                                        <textarea
                                            onChange={(e) => this.onChangeData<string>("noteToCustomer", e.target.value)}
                                            value={this.state.visualSale?.noteToCustomer}
                                            required
                                        >
                                            {this.state.visualSale?.noteToCustomer}
                                        </textarea>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </ItemOffCanvas>
            </div >
        )
    }
}