import React, { ChangeEvent, Component } from 'react';
import { DaySelect } from '../Utils/DaySelect';
import { TimeSelect } from '../Utils/TimeSelect';
import { ItemsList } from '../Utils/ItemsList';
import RequestPageItemOffCanvas from './RequestPageItemOffCanvas';
import { Account, getJSONHeaders, isNullOrWhiteSpace, webApiAccounts } from '../../AppSettings';
import { editMode, IAbsence, IRequest, RequestStatus, IItem, RequestHandler, ErrorHandling } from '../../Models/CalendarDay';
import { diffHours } from './RequestsPage';
import { format } from "date-fns";

interface IProps {
    requestPageOffCanvasIsVisible: boolean;
    requestPageOffCanvasTitle: string;
    onClose: Function;
    onAddRequest: Function;
    onCanceledRequest: Function;
    editMode: editMode
    visualRequest: IRequest;
}

interface IState {
    isVisible: boolean;
    isContentVisible: boolean;
    absenceList: IAbsence[];
    isEmpty: boolean;
    multipleDay: boolean;
    visualRequest: IRequest;
    // Used to check witch selector is currently opened
    isCompleteDays: boolean;
}

export default class RequestPageOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onListShowHide = this.onListShowHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClickMultipleDay = this.onClickMultipleDay.bind(this);
        this.onClickSingleDay = this.onClickSingleDay.bind(this);

        this.state = {
            isVisible: this.props.requestPageOffCanvasIsVisible,
            isContentVisible: true,
            absenceList: [],
            isEmpty: this.checkEmptyFields(this.props.visualRequest),
            multipleDay: this.props.visualRequest?.startDate?.getDate() !== this.props.visualRequest?.endDate?.getDate() ? true : false,
            visualRequest: this.props.visualRequest,
            isCompleteDays: false
        }

    }

    getDefaultDateRequest(req: IRequest): IRequest {
        return {
            id: req.id,
            account: req.account,
            requestDate: new Date(),
            absence: req.absence,
            startDate: new Date(new Date().setHours(9, 0, 0, 0)),
            endDate: new Date(new Date().setHours(18, 0, 0, 0)),
            note: req.note,
            status: req.status,
            schedulerNote: req.schedulerNote,
            schedulerResponseDate: req.schedulerResponseDate,
            schedulerAccount: req.schedulerAccount
        }
    }

    async componentDidMount() {
        fetch(webApiAccounts + "/" + Account.id + "/absences", {
            method: "GET",
            headers: await getJSONHeaders(),
        })
            .then(ErrorHandling.handleError)
            .then(resAbs => resAbs.json())
            .then((resultAbs: IAbsence[]) => {
                this.setState({
                    absenceList: resultAbs,
                })
            })
            .catch(error => RequestHandler.badRequestReceived(error, "Something went wrong!"));
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.requestPageOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.requestPageOffCanvasIsVisible
            });
        }
        if (nextProps.visualRequest !== this.state.visualRequest) {
            this.setState({
                visualRequest: nextProps.visualRequest,
                isEmpty: this.checkEmptyFields(nextProps.visualRequest),
                multipleDay: nextProps.visualRequest.startDate.getDate() !== nextProps.visualRequest.endDate.getDate() ? true : false,
            })
        }
    }

    onClose() {
        this.setState({
            isVisible: false,
            isCompleteDays: false
        });
        this.props.onClose();
    }

    onSave() {
        this.props.onAddRequest(this.state.visualRequest)
        this.onClose();
    }

    onChangeActivity(request: IItem) {
        let absence: IAbsence | undefined = this.state.absenceList.find(elem => elem.id === request.id);
        if (absence !== undefined) {
            let req = { ...this.state.visualRequest }

            req.absence = absence;

            this.setState({
                visualRequest: req,
                isEmpty: this.checkEmptyFields(req)
            })
        }

    }

    onListShowHide(isListOpen: boolean) {
        this.setState({ isContentVisible: !isListOpen });
    }

    onClickMultipleDay() {
        // this if theorically will not create e rerender of the offcanvas (if anywhere than remove it)
        if (!this.state.multipleDay)
            this.setState({
                multipleDay: true,
                visualRequest: this.getDefaultDateRequest(this.state.visualRequest)
            });
    }

    onClickSingleDay() {
        if (this.state.multipleDay)
            this.setState({
                multipleDay: false,
                visualRequest: this.getDefaultDateRequest(this.state.visualRequest),
                isCompleteDays: false
            });
    }

    onCheckCompleteDays(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            this.onChangeData<Date>("multipleStartHour", new Date(new Date().setHours(9, 0, 0, 0)))
            this.onChangeData<Date>("multipleEndHour", new Date(new Date().setHours(18, 0, 0, 0)))
            this.setState({ isCompleteDays: true });
        } else {
            this.setState({ isCompleteDays: false });
        }
    }

    checkEmptyFields(req: IRequest) {
        let isEmpty = false;
        if (req.id != null) {
            if (req.absence.id.length !== 36) {
                isEmpty = true;
            }
            if (isNullOrWhiteSpace(req.note)) {
                isEmpty = true;
            }
            if (diffHours(req.startDate, req.endDate) <= 0) {
                isEmpty = true;
            }
            if (isEmpty) {
                return isEmpty;
            }
        }
        return isEmpty;
    }

    onChangeData<T>(key: string, value: T | number) {

        let requestEntry = {
            ...this.state.visualRequest,
        };

        if (key === "day" && value instanceof Date) {

            let h = requestEntry["startDate"].getHours();
            let m = requestEntry["startDate"].getMinutes();

            let newStartDate: Date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), h, m, 0, 0);

            requestEntry["startDate"] = newStartDate;

            let h1 = requestEntry["endDate"].getHours();
            let m1 = requestEntry["endDate"].getMinutes();

            let newEndDate: Date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), h1, m1, 0, 0);

            requestEntry["endDate"] = newEndDate;

        } else if (key === "startHour" && value instanceof Date) {

            (requestEntry["startDate"]).setHours(value.getHours(), value.getMinutes(), 0, 0);

        } else if (key === "endHour" && value instanceof Date) {

            (requestEntry["endDate"]).setHours(value.getHours(), value.getMinutes(), 0, 0);

        } else if (key === "multipleDayStart" && value instanceof Date) {

            let h = requestEntry["startDate"].getHours();
            let m = requestEntry["startDate"].getMinutes();

            let newStartDate: Date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), h, m, 0, 0);

            requestEntry["startDate"] = newStartDate;

        } else if (key === "multipleDayEnd" && value instanceof Date) {

            let h = requestEntry["endDate"].getHours();
            let m = requestEntry["endDate"].getMinutes();

            let newEndDate: Date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), h, m, 0, 0);

            requestEntry["endDate"] = newEndDate;

        } else if (key === "multipleStartHour" && value instanceof Date) {

            (requestEntry["startDate"]).setHours(value.getHours(), value.getMinutes(), 0, 0);

        } else if (key === "multipleEndHour" && value instanceof Date) {

            (requestEntry["endDate"]).setHours(value.getHours(), value.getMinutes(), 0, 0);
        } else {

            requestEntry[key] = value;

        }

        this.setState({
            visualRequest: requestEntry,
            isEmpty: this.checkEmptyFields(requestEntry)
        })
    }

    render() {

        let diffHoursInfo = (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "0.4rem" }}>
                <div className="col-2" style={{
                    borderRadius: "100%", width: "10px", height: "10px", backgroundColor: "#FFC107", display: "table-cell", marginRight: "0.5rem"
                }}></div>
                <div className="col-10">
                    <p style={{ display: "table-cell", fontSize: "small", fontWeight: "bold" }}>Totale ore richieste: {diffHours(new Date(this.state.visualRequest.startDate ?? new Date().setHours(9, 0, 0, 0)), new Date(this.state.visualRequest.endDate ?? new Date().setHours(18, 0, 0, 0)))} ore</p>
                </div>
            </div>
        );

        return (
            <div>
                <RequestPageItemOffCanvas
                    offCanvasTitle={this.props.editMode === editMode.Add ? this.props.requestPageOffCanvasTitle : "Review request"}
                    onClose={this.onClose}
                    isVisible={this.state.isVisible}
                    onSave={this.onSave}
                    isPending={this.state.visualRequest.status === "Pending"}
                    onCanceledRequest={() => this.props.onCanceledRequest([this.state.visualRequest])}
                    saveDisabled={this.state.isEmpty}
                    editMode={this.props.editMode}
                >

                    {this.props.editMode === editMode.Add ? (
                        <>
                            <ItemsList
                                items={this.state.absenceList}
                                selectedItem={this.state.visualRequest.absence}
                                onSelectedItemChange={(request: IItem) => this.onChangeActivity(request)}
                                onListShowHide={this.onListShowHide}
                                searchPlaceholder="Search request type"
                                showCompleteList={true}
                            />

                            {this.state.isContentVisible && (
                                <>
                                    <br />

                                    <section >
                                        <button className={"btn " + (this.state.multipleDay ? 'btn-day-type' : 'btn-day-type-selected')} style={{ display: "inlineBlock", marginRight: "20px", outline: "none !important", boxShadow: "none" }} onClick={this.onClickSingleDay}>
                                            Single day
                                        </button>
                                        <button className={"btn " + (this.state.multipleDay ? 'btn-day-type-selected' : 'btn-day-type')} style={{ display: "inlineBlock", marginRight: "20px", outline: "none !important", boxShadow: "none" }} onClick={this.onClickMultipleDay}>
                                            Multiple day
                                        </button>
                                    </section>

                                    {this.state.multipleDay && (

                                        <section className="item-off-canvas-component">

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                <div className="col-6">
                                                    <p className="requestSelectorTitle">
                                                        Selected day
                                                    </p>
                                                    <div>
                                                        <DaySelect
                                                            date={new Date(this.state.visualRequest.startDate ?? new Date())}
                                                            onDateChange={(h: Date) => this.onChangeData<Date>("multipleDayStart", h)}
                                                            key="fromDaySelector"
                                                        />
                                                    </div>
                                                </div>

                                                {!this.state.isCompleteDays && (
                                                    <div className="col-3">
                                                        <p className="requestSelectorTitle">
                                                            from
                                                        </p>
                                                        <div>
                                                            <TimeSelect
                                                                date={new Date(this.state.visualRequest.startDate ?? new Date().setHours(9, 0, 0, 0))}
                                                                onHourChange={(h: Date) => this.onChangeData<Date>("multipleStartHour", h)}
                                                                key="fromHourMultiple"
                                                            />
                                                        </div>
                                                    </div>
                                                )}


                                            </div>

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                <div className="col-6">
                                                    <p className="requestSelectorTitle">
                                                        Selected day
                                                    </p>
                                                    <div>
                                                        <DaySelect
                                                            date={new Date(this.state.visualRequest.endDate ?? new Date())}
                                                            onDateChange={(h: Date) => this.onChangeData<Date>("multipleDayEnd", h)}
                                                            key="toDaySelector"
                                                        />
                                                    </div>
                                                </div>

                                                {!this.state.isCompleteDays && (
                                                    <div className="col-3">
                                                        <p className="requestSelectorTitle">
                                                            to
                                                        </p>
                                                        <div>
                                                            <TimeSelect
                                                                date={new Date(this.state.visualRequest.endDate ?? new Date().setHours(18, 0, 0, 0))}
                                                                onHourChange={(h: Date) => this.onChangeData<Date>("multipleEndHour", h)}
                                                                key="toHourMultiple"
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                            </div>


                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "0.4rem" }}>
                                                <div style={{ display: "table-cell", marginRight: "0.5rem" }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isCompleteDays}
                                                        onChange={(event) => this.onCheckCompleteDays(event)}
                                                    />
                                                </div>

                                                <div>
                                                    <p style={{ display: "table-cell", fontSize: "small", color: "grey" }}>Complete days</p>
                                                </div>
                                            </div>


                                            {diffHoursInfo}

                                        </section>

                                    )}

                                    {!this.state.multipleDay && (

                                        <section className="item-off-canvas-component">

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                <div className="col-6">
                                                    <p className="requestSelectorTitle">
                                                        Selected day
                                                    </p>
                                                    <div>
                                                        <DaySelect
                                                            date={new Date(this.state.visualRequest.startDate ?? new Date())}
                                                            onDateChange={(h: Date) => this.onChangeData<Date>("day", h)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <p className="requestSelectorTitle">
                                                        from
                                                    </p>
                                                    <div>
                                                        <TimeSelect
                                                            date={new Date(this.state.visualRequest.startDate ?? new Date().setHours(9, 0, 0, 0))}
                                                            onHourChange={(h: Date) => this.onChangeData<Date>("startHour", h)}
                                                            key="fromHour"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <p className="requestSelectorTitle">
                                                        to
                                                    </p>
                                                    <div>
                                                        <TimeSelect
                                                            date={new Date(this.state.visualRequest.endDate ?? new Date().setHours(18, 0, 0, 0))}
                                                            onHourChange={(h: Date) => this.onChangeData<Date>("endHour", h)}
                                                            key="toHour"
                                                        />
                                                    </div>
                                                </div>


                                            </div>

                                            {diffHoursInfo}

                                        </section>

                                    )}

                                    <section className="item-off-canvas-component">
                                        <label className="pb-1">Note</label>
                                        <textarea
                                            className="custom-color-border"
                                            style={{ width: "100%", resize: "none" }}
                                            rows={5}
                                            onChange={(e) => this.onChangeData<string>("note", e.target.value)}
                                            value={this.state.visualRequest.note}
                                            placeholder="Motivation for the request"
                                            required
                                        />
                                    </section>


                                </>
                            )}
                        </>

                    ) : (

                        <>
                            <div>
                                <div>
                                    <p className="requestSelectorTitle">Request type</p>
                                </div>
                                <div>
                                    <h5>
                                        {this.state.visualRequest?.absence?.description}
                                    </h5>
                                </div>
                            </div>

                            {this.state.multipleDay && (

                                <section className="item-off-canvas-component">

                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "1.5vh" }}>
                                        <div className="col-6">
                                            <p className="requestSelectorTitle">
                                                From <strong>{new Date(this.state.visualRequest?.startDate ?? new Date()).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.startDate ?? new Date()), "dd/MM/yyyy")}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <p className="requestSelectorTitle">
                                                Hour
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.startDate ?? new Date()), "HH:mm")}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div className="col-6">
                                            <p className="requestSelectorTitle">
                                                To <strong>{new Date(this.state.visualRequest?.endDate ?? new Date()).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.endDate ?? new Date()), "dd/MM/yyyy")}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <p className="requestSelectorTitle">
                                                Hour
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.endDate ?? new Date()), "HH:mm")}
                                            </div>
                                        </div>
                                    </div>

                                </section>

                            )}

                            {!this.state.multipleDay && (

                                <section className="item-off-canvas-component">
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <div className="col-6">
                                            <p className="requestSelectorTitle">
                                                <strong>{new Date(this.state.visualRequest?.startDate ?? new Date()).toLocaleString('en-us', { weekday: 'long' })}</strong>
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.startDate ?? new Date()), "dd/MM/yyyy")}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <p className="requestSelectorTitle">
                                                From
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.startDate ?? new Date()), "HH:mm")}
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <p className="requestSelectorTitle">
                                                To
                                            </p>
                                            <div style={{ fontWeight: "bold" }}>
                                                {format(new Date(this.state.visualRequest?.endDate ?? new Date()), "HH:mm")}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}

                            <section className="item-off-canvas-component">
                                <p className="requestSelectorTitle">Note</p>
                                <p>{this.state.visualRequest.note}</p>
                            </section>

                            <hr />

                            <section className="item-off-canvas-component">
                                <p className="requestSelectorTitle">Status</p>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "0.4rem" }}>

                                    <div className="col-2" style={{
                                        borderRadius: "100%", width: "10px", height: "10px", backgroundColor: `${RequestStatus[this.state.visualRequest.status as keyof typeof RequestStatus]}`, display: "table-cell", marginRight: "0.5rem"
                                    }}>
                                    </div>

                                    <div className="col-10">
                                        <p style={{ display: "table-cell", fontSize: "small", fontWeight: "bold" }}>{this.state.visualRequest.status}</p>
                                    </div>

                                </div>
                            </section>

                            {this.state.visualRequest.schedulerNote &&

                                <section className="item-off-canvas-component">
                                    <p className="requestSelectorTitle">Note Scheduler</p>
                                    <p>{this.state.visualRequest.schedulerNote}</p>
                                </section>

                            }
                        </>
                    )}

                </RequestPageItemOffCanvas>
            </div >)
    }
}