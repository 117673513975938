import React, { Component, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IgenericDropDownProps {
    data: IGenericDdlData[];  
    width?: string;
    onSelectData: (value: any) => void;
} 

interface IGenericDdlData {
    id: string,
    text: string,
    selected: boolean,
    value : any
}
 
export default function GenericDropDown  (props: IgenericDropDownProps) {
    
    const [data, setData] = useState(props.data);
    const [selectedItem, setSelectedItem] = useState(props.data.find(x=>x.selected));
    const [isOpen, setIsOpen] = useState(false);     
    const ref = useRef(null) as React.MutableRefObject<any>; 

    useEffect(() => {
        setData(props.data)

        var sel = props.data.find(x=>x.selected);
        if(!sel)
        {
            sel = props.data[0];
        }
        setSelectedItem(sel);

        setIsOpen(false);
      }, [,props.data]);
    
    useEffect(() => {
        const handleClickOutside = (event : any) => {
            if (ref.current && !ref.current.contains(event.target)) {
               setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);  

    function onSelectData(selItem: IGenericDdlData) {        
        if (selItem !== selectedItem) 
        {            
            setSelectedItem(selItem); 
            props.onSelectData(selItem.value);
        } 
        setIsOpen(false);
    }
    
    return (
        <div
            className="mx-2"
            ref={ref}
        >
            <button
                className="btn btn-dark btn-select-day d-flex justify-content-between"
                onClick={() => setIsOpen(!isOpen)}
                style={{ width: props.width ? props.width : "190px" }}
            >
                <p className="mx-2 mb-0">{selectedItem?.text}</p>
                <p className="mx-2 mb-0">
                    {<FontAwesomeIcon icon={faChevronDown} />}
                </p>
            </button>
            {isOpen && (
                <div className="row mx-0" style={{ zIndex: 100, position: "absolute", width: props.width ? props.width : "190px", backgroundColor: "#f5f6f8", border: "1px solid black", borderRadius: "5px" }}>
                    {data.map(item => (
                        <div className="col-12" key={item.id}>
                            <button
                                className="btn btn-dark-outline col-12"
                                onClick={() => onSelectData(item)}>
                                {item.text}
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>      
      );
}
