import React, { Component } from 'react';
import { addMonths, format } from 'date-fns';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    date?: Date;
    onDateSelected: Function;
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
}

interface IState {
    selectedDate: Date;
    isOpen: boolean;
}

export default class MonthSelector extends Component<IProps, IState>{
    monthSelectorRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.monthSelectorRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            selectedDate: this.props.date ? new Date(new Date(new Date(this.props.date).setDate(1)).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
            isOpen: false
        }
    }

    componentDidUpdate(prevProsp: IProps, prevState: IState) {
        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }
        if (this.props.date?.getTime() !== prevProsp.date?.getTime()) {
            this.setState({
                selectedDate: this.props.date ? new Date(new Date(new Date(this.props.date).setDate(1)).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
                isOpen: false
            })
        }
    }

    handleClickOutside(event: MouseEvent): void {
        if (this.monthSelectorRef && !this.monthSelectorRef?.current?.contains(event.target as Node)) {
            this.setState({ isOpen: false });
        }
    }

    onDateSelect(date: Date) {
        let dateStart = new Date(new Date(new Date(date).setDate(1)).setHours(0, 0, 0, 0));
        let dateEnd = new Date(new Date(addMonths(new Date(date), 1).setDate(0)).setHours(23, 59, 59, 999));
        if (this.state.selectedDate.getTime() !== dateStart.getTime()) {
            this.setState({
                selectedDate: dateStart,
                isOpen: false
            });
            this.props.onDateSelected(dateStart, dateEnd);
        } else {
            this.setState({
                isOpen: false
            });
        }
    }

    toggleIsOpen() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        return (
            <>
                <div
                    className="justify-content-around"
                    style={{ display: "inline-block" }}
                    ref={this.monthSelectorRef}
                >
                    <div>
                        <button
                            className="btn btn-dark btn-select-day justify-content-between d-flex me-1"
                            onClick={() => this.toggleIsOpen()}
                            style={{ width: "220px" }}
                        >
                            <p className="mx-2 mb-0">{<FontAwesomeIcon icon={faCalendar} />}</p>
                            <p className="mx-2 mb-0">{format(new Date(this.state.selectedDate), "MMM yyy")}</p>
                            <p className="mx-2 mb-0">{<FontAwesomeIcon icon={faChevronDown} />}</p>
                        </button>
                    </div>

                    {this.state.isOpen && (
                        <div style={{ position: "absolute" }}>
                            <DatePicker
                                selected={this.state.selectedDate}
                                onChange={(date: Date) => this.onDateSelect(date)}
                                minDate={this.props.minDate}
                                maxDate={this.props.maxDate}
                                dateFormat="MMMM"
                                showMonthYearPicker
                                inline
                            />
                        </div>)}

                </div>
            </>
        )
    }
}
