import React, { useContext, useEffect, useState } from 'react';
import { Menu, IAccount, IMenuItem } from '../../Models/CalendarDay';
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { env } from 'process';
import { FeatureFlagsContext, FeatureFlagsProvider } from '../AegisCommon/Utils/Contexts/FeatureFlagsContext';
import DropDownGenericSelector from '../Utils/DropDownGenericSelector';

interface IProps {
    account: IAccount
    selectedMenu: IMenuItem | null;
    onMenuChange: Function;
    logoutFunction: Function;
    loginFunction: Function;
    switchCompanyFunction: Function;
}

type MenuButtonProps = {
    item: IMenuItem,
    selected: IMenuItem | null
    onMenuClick: (menuItem: IMenuItem) => void
};

export function MenuButton(props: MenuButtonProps) {
    const features = useContext(FeatureFlagsContext);

    return (
        props.item.key === Menu.ProjectAssignment.key && !features?.intercompany ? null :
        <Link to={`/${props.item.key}`} key={props.item.key}>
            <button
                key={props.item.key}
                className={"btn text-white removeBorder " + (props.selected === Menu.FindItem(props.item.key) ? "border-button" : "")}
                onClick={() => props.onMenuClick(Menu.FindItem(props.item.key))}
            >
                {Menu.FindItem(props.item.key).description}
            </button>
        </Link>
    );
}

export default function Header({ account, selectedMenu, onMenuChange, logoutFunction, loginFunction,switchCompanyFunction }: IProps) {
    const [selectedMenuState, setSelectedMenu] = useState(selectedMenu);
    
    const onMenuClick = function (menu: IMenuItem) {
        setSelectedMenu(menu);
        onMenuChange(menu);
    }

    useEffect(() => { 
        setSelectedMenu(selectedMenu);
    }, [selectedMenu, account]);

    return (
        <header>
            <div className="header w-100">
                {account?.id !== "" ? (<>
                    <div className="row justify-content-between align-items-center m-0">
                        <div className="col-10 d-flex align-items-center">
                            <div className="icon-letter">
                                {account?.firstName?.charAt(0)}
                            </div>
                            <div>
                                <p className="account-name">
                                    {account?.firstName} {account?.lastName}
                                </p>
                                {account.userCompanies.length > 0
                                    ? <DropDownGenericSelector 
                                        data={account.userCompanies.map((x)=> ({id:x.companyId,selected:x.isSelected,text:x.companyName}))} 
                                        onSelect={(selectedCompanyId : string) => {
                                            switchCompanyFunction(selectedCompanyId);
                                           // account.userCompanies = account.userCompanies.map(x=> ({company: x.company,id: x.id,isSelected: x.company == selectedCompanyId}));
                                        }} /> 
                                : <p className="company">
                                    {account?.company}
                                </p>}
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-end pe-4" >
                            <button
                                className="btn btn-logout"
                                onClick={() => logoutFunction()}
                            >
                                <FontAwesomeIcon icon={faPowerOff} />
                            </button>
                        </div>

                    </div>
                    <div className="d-flex flex-wrap z-index-1" >
                        <div className="container-fluid">
							<FeatureFlagsProvider>
								{Menu.GetKeys().map((key) => (
									<div style={{ display: "inline-block" }} key={key}>
										{((account?.authorizations?.indexOf(Menu.FindItem(key).authorization) ?? -1) !== -1) && (
												<MenuButton item={Menu.FindItem(key)} onMenuClick={onMenuClick} selected={selectedMenuState} />
										)}
									</div>
								))}
							</FeatureFlagsProvider>
                        </div>
                    </div>
                </>) :
                    (
                        <div className="row justify-content-between align-items-center m-0 h-100">
                            <div
                                className="col-10 d-flex align-items-center"
                                style={{ color: "white", fontSize: "35px" }}
                            >
                                AEGIS
                            </div>

                            <div className="col-2 d-flex justify-content-end align-items-center pe-4" >
                                <button
                                    className="btn btn-logout"
                                    onClick={() => loginFunction()}
                                >
                                    <FontAwesomeIcon icon={faPowerOff} />
                                </button>
                            </div>
                        </div>)}
            </div>
        </header>
    );
};