import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    title: string,
    data: number,
    type: string,
    notCalculable?: boolean,
}

interface IState {
}

export default class JobOrderCardInfo extends Component<IProps, IState>{

    formatter(num: number): string {
        if (num === 0) return "-";
        return num.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR"
              }).replace("€", '');
    }

    render() {
        return (
            <div>
                <div>
                    <h6 style={{fontSize: "1rem"}}>{this.props.title}</h6>
                </div>
                <div>
                    {
                        this.props.notCalculable ? 
                            <h4><FontAwesomeIcon icon={faExclamationTriangle} /> Not Calculable</h4> 
                            : 
                            <h6 style={{fontSize: "1.5rem"}}>{this.formatter(this.props.data)} {this.props.type}</h6>
                    }
                </div>
            </div>
        )
    }
}