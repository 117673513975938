import { InteractionRequiredAuthError, PopupRequest, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import { msalInstance } from "../..";

	/**
	 * Requests an access token.
	 *
	 * @return {Promise<string>} The access token.
	 */
	export default async function RequestAccessToken(): Promise<string> {
		// const msalInstance: PublicClientApplication =
		// 	new PublicClientApplication(msalConfig);

		const activeAccount = msalInstance.getActiveAccount();
		const accounts = msalInstance.getAllAccounts();

		if (!activeAccount && accounts.length === 0) {
			throw new Error("User is not authenticated.");
		}

		const request = {
			...loginRequest,
			account: activeAccount || accounts[0],
			// forceRefresh: true
		};

		try {
			const authResult = await msalInstance.acquireTokenSilent(request);
			return authResult.accessToken;
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				return (await msalInstance.acquireTokenPopup(request)).accessToken;
			} else {
				throw error;
			}
		}
	}