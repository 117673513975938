import { toast } from "react-toastify";
import { getJSONHeaders, webApiEntriesMonthStatus } from "../AppSettings";

export default class EntriesMonthStatusController {
	private baseEndpoint: string = webApiEntriesMonthStatus;

	public async getErrors() {
		return toast.promise(
			fetch(`${this.baseEndpoint}/errors`, {
				method: "GET",
				headers: await getJSONHeaders(),
			}).then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return Promise.reject(response.statusText);
				}
			}),
			{
				pending: { render: "Loading error list...", containerId: "error-list-toast-container", theme: "colored" },
				error: { render: "Loading error list failed.", type: toast.TYPE.ERROR, containerId: "error-list-toast-container", hideProgressBar: true, theme: "colored", autoClose: 1500 },
				success: { render: "Error list loaded.", type: toast.TYPE.SUCCESS, containerId: "error-list-toast-container", hideProgressBar: true, theme: "colored", autoClose: 1500 },
			}
		);
	}
}
