import { Component } from 'react';
import DownloadRequestItemOffCanvas from './DownloadRequestItemOffCanvas';
import { DaySelect } from '../Utils/DaySelect';
import MonthSelector from '../Utils/MonthSelector';
import { DownloadRequestType, DownloadRequestModel, DownloadRequestTypeModel } from '../../Models/DownloadRequestTypeModel';
import GenericDropDown from '../AegisCommon/GenericDropDown';
import { GetAllActiveDownloadRequestTypes } from '../Utils/DownloadRequestTypeUtils';
import { ActivityModel } from '../../Models/ActivityModel';
import { getJSONHeaders, webApiActivities, webApiQueue } from '../../AppSettings';
import { ErrorHandling, RequestHandler } from '../../Models/CalendarDay';
import { ItemsList } from '../Utils/ItemsList';

interface IProps {
    requestPageOffCanvasIsVisible: boolean;
    requestPageOffCanvasTitle: string;
    onClose: Function;
    onAddRequest: Function;
	visualRequest: DownloadRequestModel;
}

interface IState {
    isVisible: boolean;
    isContentVisible: boolean;
    visualRequest: DownloadRequestModel;
    visualizationType: DownloadRequestType;
    availableRequestTypes: DownloadRequestTypeModel[];
	activities: ActivityModel[];
	requestTypes: string[];
}

export default class RequestPageOffCanvas extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onChangeData = this.onChangeData.bind(this);
        this.selectType = this.selectType.bind(this);
        this.getRequestTypes = this.getRequestTypes.bind(this);

        this.state = {
            isVisible: this.props.requestPageOffCanvasIsVisible,
            isContentVisible: true,
            visualRequest: this.props.visualRequest,
            visualizationType: DownloadRequestType.JobOrderCard,
			availableRequestTypes: GetAllActiveDownloadRequestTypes(),
			activities: [],
			requestTypes: [],
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.requestPageOffCanvasIsVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.requestPageOffCanvasIsVisible
            });
        }
        if (nextProps.visualRequest !== this.state.visualRequest) {
            this.setState({
                visualRequest: nextProps.visualRequest,
            })
		}
		this.getRequestTypes();
    }

    onClose() {
        this.setState({
            isVisible: false,
            visualizationType: this.state.requestTypes[0] as any as DownloadRequestType,
            availableRequestTypes: GetAllActiveDownloadRequestTypes()
        });
        this.props.onClose();
    }

    onSave() {
        let from = this.state.visualRequest.from;
        let to =  this.state.visualRequest.to;
        let type = this.state.visualizationType;
        let referenceId: string | undefined = this.state.visualRequest.referenceId;

        this.props.onAddRequest(from, to, type, referenceId)
        this.onClose();
	}
	
	async getRequestTypes() {
		fetch(`${webApiQueue}/me/requestTypes`, {
			method: 'GET',
			headers: await getJSONHeaders(),
		}).then(ErrorHandling.handleError)
		.then(types => types.json())
		.then((types) => {
			this.setState({
				requestTypes: types,
				visualizationType: DownloadRequestType[types[0] as keyof typeof DownloadRequestType],
			})
			RequestHandler.responseReceived("");
		})
		.catch(error => {
			RequestHandler.badRequestReceived(error, "Something went wrong with loading activities!");
		});
	};

    onChangeData(key: string, value: Date) {

        let requestEntry = {
            ...this.state.visualRequest,
        };

        if (key === "startDate") {
            requestEntry.from = new Date(value)
        } else if (key === "endDate") {
            requestEntry.to = new Date(value)
        }

        this.setState({ visualRequest: requestEntry })
    }

    async selectType(type: DownloadRequestType) {

        let requestEntry = {
            ...this.state.visualRequest,
        };

        requestEntry.from = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        requestEntry.to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

		if (type === DownloadRequestType.FinalBalance) {
			fetch(`${webApiActivities}/related`, {
				method: "GET",
				headers: await getJSONHeaders(),
			}).then(ErrorHandling.handleError)
				.then(resAct => resAct.json())
				.then((resultAct) => {
					requestEntry.referenceId = undefined;
					this.setState({ activities: resultAct, })
					RequestHandler.responseReceived("");
				})
				.catch(error => {
					RequestHandler.badRequestReceived(error, "Something went wrong with loading activities!");
				});
		}

		this.setState({ visualizationType: type, visualRequest: requestEntry });

    }

    render() {
        return (
            <div>
                <DownloadRequestItemOffCanvas
					offCanvasTitle={this.props.requestPageOffCanvasTitle}
					canSave={() => {
						return this.state.visualizationType === DownloadRequestType.FinalBalance &&
							this.state.visualRequest.referenceId === undefined
							? false : true;
					}}
                    onClose={this.onClose}
					isVisible={this.state.isVisible}
                    onSave={this.onSave}
                >
                    <p className="mx-2">Select type of request</p>
                    <div>
						<GenericDropDown
							// to change how is handled
							data={this.state.availableRequestTypes.filter(y => this.state.requestTypes.includes(DownloadRequestType[y.type])).map((x)=>({
                                id:x.id,
                                selected: x.type == this.state.visualizationType,
                                text: x.description,
                                value: x.type                            
                            }))}
                            onSelectData={this.selectType} 
                            key={"DownloadRequestTypeSelectorDdl"}
                            width="250px"
                        /> 
                    </div>
                    <br />

                    {this.state.visualizationType === DownloadRequestType.JobOrderCard
                    || this.state.visualizationType === DownloadRequestType.MonthlyAllDetails ? (
                        <div>
                            <p className="mx-2">Select Date</p>
                            <div className="mx-2" >
                                <MonthSelector date={this.state.visualRequest.requestedAt} onDateSelected={(startDate: Date) => this.onChangeData("startDate", startDate)} />
                            </div>
                        </div>
					) : this.state.visualizationType === DownloadRequestType.FinalBalance ? (
							<div>
								<br />
								<div style={{padding: "8px"}}>
									<ItemsList
										key="Activity"
										items={this.state.activities}
										selectedItem={undefined}
										onSelectedItemChange={(activity: ActivityModel) => {
											let requestEntry = {
												...this.state.visualRequest,
											};
											requestEntry.referenceId = activity.id

											this.setState({ visualRequest: requestEntry })
										}}
										// onListShowHide={this.onListShowHide}
										// onFavouriteOrColorChange={this.props.onFavouriteOrColorChange}
										searchPlaceholder="Project name"
									/>
								</div>
								<p className="mx-2">From date</p>
								<div className="mx-2">
									<DaySelect
										date={new Date(this.state.visualRequest.from ?? new Date())}
										onDateChange={(startDate: Date) => this.onChangeData("startDate", startDate)}
										key="fromDaySelector"
										isDarkStyled={true}
									/>
								</div>

								<br />
								<p className="mx-2">To date</p>
								<div className="mx-2">
									<DaySelect
										date={new Date(this.state.visualRequest.to ?? new Date())}
										onDateChange={(endDate: Date) => this.onChangeData("endDate", endDate)}
										key="toDaySelector"
										isDarkStyled={true}
									/>
								</div>
							</div>
						) : (
                        <div>
                            <p className="mx-2">From date</p>
                            <div className="mx-2">
                                <DaySelect
                                    date={new Date(this.state.visualRequest.from ?? new Date())}
                                    onDateChange={(startDate: Date) => this.onChangeData("startDate", startDate)}
                                    key="fromDaySelector"
                                    isDarkStyled={true}
                                />
                            </div>

                            <br />
                            <p className="mx-2">To date</p>
                            <div className="mx-2">
                                <DaySelect
                                    date={new Date(this.state.visualRequest.to ?? new Date())}
                                    onDateChange={(endDate: Date) => this.onChangeData("endDate", endDate)}
                                    key="toDaySelector"
                                    isDarkStyled={true}
                                />
                            </div>
                        </div>)}
                </DownloadRequestItemOffCanvas >
            </div >)
    }
}
